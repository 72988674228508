export const BOOKING_STATUS_PENDING_APPROVAL = '5101'
export const BOOKING_STATUS_APPROVED = '5102'
export const BOOKING_STATUS_REJECTED = '5103'
export const BOOKING_STATUS_CANCELLED = '5104'
export const BOOKING_STATUS_AMENDED = '5105'
export const BOOKING_STATUS_CLOSED = '5106'
export const BOOKING_STATUS_DRAFT = '5107'

export const MOVE_TYPE_PORT_TO_PORT = 'PTP'
export const MOVE_TYPE_PORT_TO_DOOR = 'PTD'
export const MOVE_TYPE_DOOR_TO_DOOR = 'DTD'
export const MOVE_TYPE_DOOR_TO_PORT = 'DTP'

export const FULL_CONTAINER_LOAD = 'FCL'
export const CONTAINER_ISO_TYPE_22G1 = '22G1'

export const SCHEDULE_WAYPOINT_START = '1'
export const SCHEDULE_WAYPOINT_STOPOVER = '2'
export const SCHEDULE_WAYPOINT_FINAL = '3'

export const TRANSPORT_MODE_SEA = 'SEA'
export const TRANSPORT_MODE_AIR = 'AIR'
export const TRANSPORT_MODE_RAIL = 'RAIL'
export const TRANSPORT_MODE_TRUCK = 'TRUCK'
export const TRANSPORT_MODE_FEEDER = 'FEEDER'
export const TRANSPORT_MODE_LAND = 'LAND'

export const CAL_DG_IMO = 'CAL_DG_IMO'
export const CAL_DG_TEMP_UOM = 'CAL_DG_TEMP_UOM'
export const CAL_DG_MARINE = 'CAL_DG_MARINE'
export const CAL_DG_PSN_INH_HZD_ZN = 'CAL_DG_PSN_INH_HZD_ZN'
export const CAL_DG_AGGRE = 'CAL_DG_AGGRE'
export const CAL_DG_MPA_CLASS = 'CAL_DG_MPA_CLASS'
export const CAL_DG_PKG_TYPE = 'CAL_DG_PKG_TYPE'
export const CAL_CONT_STATUS = 'CAL_CONT_STATUS'
export const CAL_BK_MOVE_TYPE = 'CAL_BK_MOVE_TYPE'
export const CAL_CONT_CAR_STS = 'CAL_CONT_CAR_STS'
export const CAL_BL_TYPE = 'CAL_BL_TYPE'
export const CAL_FORM_TYPE = 'CAL_FORM_TYPE'
export const CAL_CONT_STOWAGE = 'CAL_CONT_STOWAGE'
export const CAL_CONT_HANDLING = 'CAL_CONT_HANDLING'
export const CAL_CARGO_LENGTH = 'CAL_CARGO_LENGTH'
export const CAL_CARRIER_CLAUSE = 'CAL_CARRIER_CLAUSE'
export const CAL_REEFER_TEMP_UOM = 'CAL_REEFER_TEMP_UOM'
export const CAL_REEFER_VENT_SETTING = 'CAL_REEFER_VENT_SETTING'

export const REEFER_TEMP_CEL = 'CEL'
export const REEFER_TEMP_FAH = 'FAH'

export const SI_STATUS_PENDING_SUBMISSION = '5201'
export const SI_STATUS_PENDING_CARRIER_CONFIRMATION = '5202'
export const SI_STATUS_SI_APPROVED = '5203'
export const SI_STATUS_PENDING_SHIPPER_CONFIRMATION = '5209'
export const SI_STATUS_SI_CONFIRMED = '5210'
export const SI_STATUS_PENDING_SI_APPROVED = '5211'
export const SI_STATUS_PENDING_SI_RESUBMISSION = '5212'
export const SI_STATUS_SI_CANCELLED = '5213'
export const SI_STATUS_BL_ISSUED = '5301'
export const SI_STATUS_BL_SURRENDERED = '5302'
export const SI_STATUS_BOOKING_CANCELLED = '5208'

export const PERMIT_STATUS_PENDING_SUBMISSION = '5401'
export const PERMIT_STATUS_CREATED = '5402'
export const PERMIT_STATUS_SUBMITTED = '5403'
export const PERMIT_STATUS_APPROVED = '5404'
export const PERMIT_STATUS_REJECTED = '5405'
export const PERMIT_STATUS_AMENDMENT_CREATED = '5406'
export const PERMIT_STATUS_AMENDMENT_SUBMITTED = '5407'

export const EVGM_STATUS_NEW_VGM_CREATED = 'NEW'
export const EVGM_STATUS_PENDING_ACCEPTANCE = 'SMT'
export const EVGM_STATUS_PENDING_UPDATE = 'UPDATE'
export const EVGM_STATUS_CANCELLATION_RECEIVED = 'CSM'
export const EVGM_STATUS_PENDING_CANCELLATION = 'CNL'
export const EVGM_STATUS_ACCEPTED = 'ACT'
export const EVGM_STATUS_REJECTED = 'RJT'
export const EVGM_STATUS_DRAFT = 'DRAFT'

export const MODULE_FRB = 'CLOGS_FRB'
export const MODULE_SI = 'CLOGS_SI'
export const MODULE_EDI = 'CLOGS_EDI'

export const EAWB_STATUS_ALL = 'ALL'
export const EAWB_STATUS_DRAFT = 'D'
export const EAWB_STATUS_SENT = 'S'
export const EAWB_STATUS_ACCEPTED = 'A'
export const EAWB_STATUS_REJECTED = 'R'
export const EAWB_STATUS_ON_HOLD = 'O'
export const EAWB_STATUS_CHECK_AIRLINES_FOR_STATUS = 'AP'
export const EAWB_STATUS_AIRLINE_RECEIVED = 'AR'
export const EAWB_STATUS_READY_FOR_CARRIAGE = 'RCS'
export const EAWB_STATUS_CONSIGNMENT_RECEIVED_FROM_CARRIER = 'RCT'
export const EAWB_STATUS_GOODS_RECEIVED_FROM_FLIGHT = 'RCF'
export const EAWB_STATUS_GOODS_BOOKED_ON_FLIGHT = 'BKD'
export const EAWB_STATUS_SHIPMENT_MANIFESTED_ON_FLIGHT = 'MAN'
export const EAWB_STATUS_CONSIGNMENT_DEPARTED = 'DEP'
export const EAWB_STATUS_CONSIGNMENT_PREPARED_FOR_LOADING = 'PRE'
export const EAWB_STATUS_CONSIGNMENT_MANIFESTED_TO_OTHER_CARRIER = 'TRM'
export const EAWB_STATUS_SHIPMENT_TRANSFERRED = 'TFD'
export const EAWB_STATUS_AGENT_NOTIFIED = 'NFD'
export const EAWB_STATUS_ARRIVAL_DOCUMENT_DELIVERED = 'AWD'
export const EAWB_STATUS_CONSIGNMENT_CLEARED_FOR_CUSTOMS = 'CCD'
export const EAWB_STATUS_CONSIGNMENT_DELIVERED_TO_CONSIGNEE = 'DLV'
export const EAWB_STATUS_ARRIVAL_DOCUMENTATION_RECEIVED = 'AWR'
export const EAWB_STATUS_CONSIGNMENT_ARRIVED = 'ARR'
export const EAWB_STATUS_CONSIGNMENT_DELIVERED_TO_DOOR = 'DDL'
export const EAWB_STATUS_CONSIGNMENT_TRANSFERRED_TO_CUSTOMS = 'TGC'

export const EAWB_PARTY_TYPE_SHIPPER = 'S'
export const EAWB_PARTY_TYPE_CONSIGNEE = 'C'
export const EAWB_PARTY_TYPE_AGENT = 'A'
export const EAWB_PARTY_TYPE_NOTIFY_PARTY = 'N'

export const EAWB_ACCOUNT_IDENTIFIER_CREDIT_CARD_NUMBER = 'CRN'
export const EAWB_ACCOUNT_IDENTIFIER_CREDIT_CARD_EXPIRY_DATE = 'CRD'
export const EAWB_ACCOUNT_IDENTIFIER_CREDIT_CARD_ISSUANCE_NAME = 'CRI'
export const EAWB_ACCOUNT_IDENTIFIER_GENERAL_INFORMATION_SPOT_RATE = 'GEN'
export const EAWB_ACCOUNT_IDENTIFIER_GOVERNMENT_BILL_OF_LADING = 'GBL'
export const EAWB_ACCOUNT_IDENTIFIER_MISCELLANEOUS_CHARGE_ORDER = 'MCO'
export const EAWB_ACCOUNT_IDENTIFIER_MODE_OF_SETTLEMENT = 'STL'
export const EAWB_ACCOUNT_IDENTIFIER_RETURN_TO_ORIGIN = 'RET'
export const EAWB_ACCOUNT_IDENTIFIER_SHIPPERS_REFERENCE_NUMBER = 'SRN'

export const EAWB_PREPAID = 'P'
export const EAWB_COLLECT = 'C'

export const EAWB_RATE_CLASS_BASIC_CHARGE = 'B'
export const EAWB_RATE_CLASS_CLASS_RATE_REDUCTION = 'R'
export const EAWB_RATE_CLASS_CLASS_RATE_SURCHARGE = 'S'
export const EAWB_RATE_CLASS_INTL_PRIORITY_SERVICE_RATE = 'P'
export const EAWB_RATE_CLASS_MINIMUM_CHARGE = 'M'
export const EAWB_RATE_CLASS_NORMAL_RATE = 'N'
export const EAWB_RATE_CLASS_QUANTITY_RATE = 'Q'
export const EAWB_RATE_CLASS_RATE_PER_KILOGRAM = 'K'
export const EAWB_RATE_CLASS_SPECIFIC_COMMODITY_RATE = 'C'
export const EAWB_RATE_CLASS_UNIT_LOAD_ADDITIONAL_INFORMATION = 'X'
export const EAWB_RATE_CLASS_UNIT_LOAD_ADDITIONAL_RATE = 'E'
export const EAWB_RATE_CLASS_UNIT_LOAD_DEVICE_BASIC_CHARGE_OR_RATE = 'U'
export const EAWB_RATE_CLASS_UNIT_LOAD_DEVICE_DISCOUNT = 'Y'

export const EAWB_VOLUME_CUBIC_CENTIMETERS = 'CC'
export const EAWB_VOLUME_CUBIC_FEET = 'CF'
export const EAWB_VOLUME_CUBIC_INCHES = 'CI'
export const EAWB_VOLUME_CUBIC_METERS = 'MC'

export const EAWB_CHARGE_AGENT = 'A'
export const EAWB_CHARGE_CARRIER = 'C'

export const EAWB_WEIGHT_CODE_KILOGRAMS = 'K'
export const EAWB_WEIGHT_CODE_POUNDS = 'L'
export const EAWB_WEIGHT_CODE_CENTIMETER = 'CMT'
export const EAWB_WEIGHT_CODE_INCH = 'INH'

export const EAWB_INFO_ID_AGENTS_VALID_TRADER_ID_NO = 'AGT'
export const EAWB_INFO_ID_CONSIGNEE_VALID_TRADER_ID_NO = 'CNE'
export const EAWB_INFO_ID_DECLARANT_VALID_TRADER_ID_NO = 'DCL'
export const EAWB_INFO_ID_DANGEROUS_GOODS_ID = 'DNR'
export const EAWB_INFO_ID_DOCUMENT_ID = 'DOC'
export const EAWB_INFO_ID_CUSTOMS_EXPORT = 'EXP'
export const EAWB_INFO_ID_CUSTOMS_IMPORT = 'IMP'
export const EAWB_INFO_ID_NOTIFY_PARTY_VALID_TRADER_ID_NO = 'NFY'
export const EAWB_INFO_ID_SHIPPER_VALID_TRADER_ID_NO = 'SHP'
export const EAWB_INFO_ID_CUSTOMS_TRANSIT = 'TRA'

export const EAWB_CCI_ACCOUNT_SIGNOR = 'AC'
export const EAWB_CCI_AIRCRAFT_OPERATOR = 'AO'
export const EAWB_CCI_CERTIFICATE_NUMBER = 'C'
export const EAWB_CCI_DANGEROUS_GOODS_NUMBER = 'D'
export const EAWB_CCI_EXPIRY_DATE = 'ED'
export const EAWB_CCI_KNOWN_CONSIGNOR = 'KC'
export const EAWB_CCI_EXEMPTION_LEGEND = 'L'
export const EAWB_CCI_MOVEMENT_REFERENCE_NUMBER = 'M'
export const EAWB_CCI_PACKING_LIST_NUMBER = 'P'
export const EAWB_CCI_REGULATED_AGENT = 'RA'
export const EAWB_CCI_REGULATED_CARRIER = 'RC'
export const EAWB_CCI_SECURITY_STATUS_DATETIME = 'SD'
export const EAWB_CCI_SCREENING_METHOD = 'SM'
export const EAWB_CCI_SECURITY_STATUS_NAME = 'SN'
export const EAWB_CCI_SECURITY_TEXTUAL_STATEMENT = 'ST'
export const EAWB_CCI_TRADER_ID_NO = 'T'
export const EAWB_CCI_UNIQUE_CONSIGNMENT_REF_NO = 'U'
export const EAWB_CCI_INVOICE_NO = 'V'

export const EAWB_SCREENING_OTHER_SCREENING = 'AOM'
export const EAWB_SCREENING_EXPLOSIVE_DETECT_SYS = 'EDS'
export const EAWB_SCREENING_FREE_RUNNING_DOGS = 'FRD'
export const EAWB_SCREENING_METAL_DETECT_EQ = 'MDE'
export const EAWB_SCREENING_PHYS_INSP_HAND_SEARCH = 'PHS'
export const EAWB_SCREENING_PARTICLE_TRACE = 'PRT'
export const EAWB_SCREENING_REMOTE_EXPLOSIVE_SCENT_DOGS = 'RES'
export const EAWB_SCREENING_SUBJECT_FLIGHT_SIM = 'SIM'
export const EAWB_SCREENING_VISUAL_CHECK = 'VCK'
export const EAWB_SCREENING_VAPOR_TRACE = 'VPT'
export const EAWB_SCREENING_XRAY_EQ = 'XRY'

export const EAWB_EXEMPTION_BIO_MED_SAMPLES = 'BIOM'
export const EAWB_EXEMPTION_DIPLOMATIC_BAGS_MAIL = 'DIPL'
export const EAWB_EXEMPTION_LIFE_SAVING_MATS = 'LFSM'
export const EAWB_EXEMPTION_MAIL = 'MAIL'
export const EAWB_EXEMPTION_NUCLEAR_MATS = 'NUCL'
export const EAWB_EXEMPTION_SMALL_UNDERSIZED_SHIPMENTS = 'SMUS'
export const EAWB_EXEMPTION_TRANSFER_TRANSHIPMENT = 'TRNS'
export const EAWB_EXEMPTION_ACCOUNT_CONSIGNOR = 'AC'
export const EAWB_EXEMPTION_AIRCRAFT_OPERATOR = 'AO'
export const EAWB_EXEMPTION_KNOWN_CONSIGNOR = 'KC'
export const EAWB_EXEMPTION_REGULATED_AGENT = 'RA'
export const EAWB_EXEMPTION_REGULATED_CARRIER = 'RC'

export const EAWB_REGULATED_PARTY_REGULATED_AGENT = 'RA'
export const EAWB_REGULATED_PARTY_KNOWN_CONSIGNOR = 'KC'
export const EAWB_REGULATED_PARTY_AIRCRAFT_OPERATOR = 'OA'