import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'

function CargoValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.weightVolumeRegx)
    } else {
      return true
    }
  }

  function validateLengthWidthHeightNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.lengthWidthHeightRegx)
    } else {
      return true
    }
  }

  function validateHSCode(value) {
    return ValidationRegex.HSCodeRegx.test(value)
  }

  return Yup.object({
    shippingInfoCargoes: Yup.array().when('hazardous', {
      is: false,
      then: Yup.array(
        Yup.object({
          quantity: Yup.number()
            .transform((curr, orig) => orig === '' ? null : curr)
            .integer(validationMessageTranslation.IntegerOnlyMsg)
            .min(0, validationMessageTranslation.valueTooSmallMsg)
            .max(99999, validationMessageTranslation.valueTooBigMsg)
            .nullable(),
          description: Yup.string()
            .required(validationMessageTranslation.requiredMessage)
            .max(2400, validationMessageTranslation.wordLimitExceedMsg)
            .nullable(),
          // marksAndNumbers: Yup.string()
          //   .required(validationMessageTranslation.requiredMessage)
          //   .max(350, validationMessageTranslation.wordLimitExceedMsg)
          //   .nullable(),
          hsCode: Yup.string()
            .test(
              'hsCode',
              validationMessageTranslation.invalidHSCode,
              (value) => validateHSCode(value)
            )
            .max(16, validationMessageTranslation.invalidHSCode)
            .nullable(),
          packageType: Yup.string()
            .max(3, validationMessageTranslation.wordLimitExceedMsg)
            .nullable(),
          grossVolume: Yup.mixed().test(
            'grossVolume',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          grossWeight: Yup.mixed().test(
            'grossWeight',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          netVolume: Yup.mixed().test(
            'netVolume',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          netWeight: Yup.mixed().test(
            'netWeight',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          uom: Yup.string().nullable(),
          length: Yup.mixed().test(
            'length',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          height: Yup.mixed().test(
            'height',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          ),
          width: Yup.mixed().test(
            'width',
            validationMessageTranslation.numberErrorMsg,
            (value) => validateWeightVolumeNumber(value)
          )
          // containerNo: Yup.array()
          //   .required(validationMessageTranslation.requiredMessage)
          //   .nullable()
        })
      ),
      otherwise: Yup.array().nullable()
    }),

    shippingInfoDGCargoes: Yup.array().when('hazardous', {
      is: true,
      then: Yup.array(
        Yup.object({
          quantity: Yup.number()
            .transform((curr, orig) => orig === '' ? null : curr)
            .integer(validationMessageTranslation.IntegerOnlyMsg)
            .min(0, validationMessageTranslation.valueTooSmallMsg)
            .max(99999, validationMessageTranslation.valueTooBigMsg)
            .nullable(),
          description: Yup.string()
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
          hsCode: Yup.string()
            .test(
              'hsCode',
              validationMessageTranslation.invalidHSCode,
              (value) => validateHSCode(value)
            )
            .nullable()
          // containerNo: Yup.array()
          //   .required(validationMessageTranslation.requiredMessage)
          //   .nullable()
        })
      ),
      otherwise: Yup.array().nullable()
    })
  })
}

export default CargoValidationSchema
