const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-helpdesk`

const VIEW_BOOKING_PATH = `${MODULE_BASE}/view-booking`
const VIEW_SI_PATH = `${MODULE_BASE}/view-si`
// view booking (admin) function used to use "/view-booking" as the menu url, however, due to 
// framework bug, the menu cannot direct user to the correct url. 
// to fix this issue without waiting from framework team, now introducing new url "/frb" 

const FRB_PATH = `${MODULE_BASE}/frb`
const SI_PATH = `${MODULE_BASE}/si`
const SEARCH_SI_PATH = `${MODULE_BASE}/search-si`

const pathMap = {
  VIEW_BOOKING: VIEW_BOOKING_PATH,
  VIEW_BOOKING_DETAILS: `${VIEW_BOOKING_PATH}/details`,
  VIER_BOOKING_TEST: `${VIEW_BOOKING_PATH}-test`,
  TEST: `${MODULE_BASE}/test`,
  VIEW_BOOKING_REVIEW: `${VIEW_BOOKING_PATH}/review`,
  FRB: FRB_PATH,
  FRB_DETAILS: `${FRB_PATH}/details`,
  VIEW_SI: VIEW_SI_PATH,
  VIEW_SI_DETAILS: `${VIEW_SI_PATH}/details`,
  SI: SI_PATH,
  SI_DETAILS: `${SI_PATH}/details`,
  SEARCH_SI: SEARCH_SI_PATH,
}

export default pathMap

