import { ethers, providers } from "ethers";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { INFURA_API_KEY, NETWORK_NAME } from "../config";
import { utils } from "@govtechsg/oa-verify";
import { magic } from "../common/helpers";
import { ChainId, ChainInfo } from "../constants/chain-info";
import { UnsupportedNetworkError } from "../common/errors/UnsupportedNetworkError";
import { getChainInfo, getChainInfoFromNetworkName, walletSwitchChain } from "../common/chain-utils";

export const SIGNER_TYPE = {
    IDENTITY: "Identity",
    METAMASK: "Metamask",
    MAGIC: "Magic"
}

const createProvider = (chainId) => {
    const url = ChainInfo[chainId].rpcUrl;
    const opts = url
        ? { url }
        : {
            network: getChainInfo(chainId).networkName,
            providerType: "infura",
            apiKey: INFURA_API_KEY,
        };
    return chainId === ChainId.Local ? new providers.JsonRpcProvider() : utils.generateProvider(opts);
};

// Utility function for use in non-react components that cannot get through hooks
let currentProvider = createProvider(getChainInfoFromNetworkName(NETWORK_NAME).chainId);
export const getCurrentProvider = () => currentProvider;

export const ProviderContext = createContext({
    providerType: SIGNER_TYPE.IDENTITY,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    upgradeToMetaMaskSigner: async () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    upgradeToMagicSigner: async () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    changeNetwork: async (_chainId) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reloadNetwork: async () => { },
    supportedChainInfoObjects: [],
    currentChainId: undefined,
    provider: currentProvider,
    providerOrSigner: currentProvider,
    account: undefined,
});

export const ProviderContextProvider = ({
    children,
    networks: supportedChainInfoObjects,
    defaultChainId,
}) => {
    const defaultProvider = useRef(createProvider(defaultChainId));

    const isSupportedNetwork = useCallback(
        (chainId) =>
            supportedChainInfoObjects.some((chainInfoObj) => chainInfoObj.chainId.toString() === chainId.toString()),
        [supportedChainInfoObjects]
    );

    const [providerType, setProviderType] = useState(SIGNER_TYPE.IDENTITY);
    const [currentChainId, setCurrentChainId] = useState(isSupportedNetwork(defaultChainId) ? defaultChainId : undefined);
    const [account, setAccount] = useState();
    const [providerOrSigner, setProviderOrSigner] = useState(
        defaultProvider.current
    );
    const [provider, setProvider] = useState(defaultProvider.current);

    const changeNetwork = async (chainId) => {
        await walletSwitchChain(chainId);
        setCurrentChainId(chainId);
    };

    const updateProvider = useCallback(async () => {
        const { ethereum, web3 } = window;
        const metamaskExtensionNotFound = typeof ethereum === "undefined" || typeof web3 === "undefined";
        if (metamaskExtensionNotFound || !ethereum.request) {
            setProvider(createProvider(currentChainId || defaultChainId));
            setAccount(undefined);
        } else {
            const newProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const network = await newProvider.getNetwork();
            if (!isSupportedNetwork(network.chainId)) {
                console.warn("User wallet is connected to an unsupported network, will fallback to default network");
                setProvider(undefined);
                setAccount(undefined);
                setCurrentChainId(undefined);
            } else {
                setProvider(newProvider);
                setCurrentChainId(network.chainId);
            }
        }
    }, [currentChainId, defaultChainId, isSupportedNetwork]);

    const updateSigner = useCallback(async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            setAccount(address);
            setProviderOrSigner(signer);
        } catch (e) {
            setAccount(undefined);
            setProviderOrSigner(provider);
        }
    }, [provider]);

    const initializeMetaMaskSigner = async () => {
        const web3Provider = window.ethereum;
        await web3Provider.send("eth_requestAccounts", []);
        const chainInfo = getChainInfo(currentChainId ?? defaultChainId);
        await walletSwitchChain(chainInfo.chainId);

        setProviderType(SIGNER_TYPE.METAMASK);
    };

    const initialiseMagicSigner = async () => {
        // needs to be cast as any before https://github.com/magiclabs/magic-js/issues/83 has been merged.
        const magicProvider = new window.ethereum(magic.rpcProvider);

        setProvider(magicProvider);
        setProviderType(SIGNER_TYPE.MAGIC);
    };

    const upgradeToMetaMaskSigner = async () => {
        return initializeMetaMaskSigner();
    };

    const upgradeToMagicSigner = async () => {
        if (providerType === SIGNER_TYPE.MAGIC) return;
        return initialiseMagicSigner();
    };

    const reloadNetwork = async () => {
        if (!provider) throw new UnsupportedNetworkError();

        const chainId = (await provider.getNetwork()).chainId;
        await changeNetwork(chainId);
    };

    useEffect(() => {
        updateProvider();
    }, [updateProvider]);

    useEffect(() => {
        updateSigner();
    }, [updateSigner]);

    useEffect(() => {
        currentProvider = provider;
    }, [provider]);

    useEffect(() => {
        if (!window.ethereum) return;

        window.ethereum
            .on("accountsChanged", updateProvider)
            .on("chainChanged", (chainIdHex) => changeNetwork(parseInt(chainIdHex, 16)));

        return () => {
            if (!window.ethereum) return;

            try {
                window.ethereum.off("chainChanged").off("accountsChanged");
            } catch (e) {
                console.log('ebl error', e);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const globalContextValue = useMemo(
        () => ({
            providerType,
            upgradeToMetaMaskSigner,
            upgradeToMagicSigner,
            changeNetwork,
            reloadNetwork,
            supportedChainInfoObjects,
            currentChainId,
            provider,
            providerOrSigner,
            account
        }),
        [providerType,
            upgradeToMetaMaskSigner,
            upgradeToMagicSigner,
            changeNetwork,
            reloadNetwork,
            supportedChainInfoObjects,
            currentChainId,
            provider,
            providerOrSigner,
            account]
    );

    return (
        <ProviderContext.Provider
            value={globalContextValue}
        >
            {children}
        </ProviderContext.Provider>
    );
};

export const useProviderContext = () => useContext(ProviderContext);
