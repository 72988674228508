import { MessageTitle } from "@govtechsg/tradetrust-ui-components";
import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import AssetInformationPanel from "../../components/AssetInformationPanel/index";
import AssetManagementDropdown from "../AssetManagementDropdown";
import EditableAssetTitle from "../FormVariants/EditableAssetTitle/EditableAssetTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';

export const ActionSelectionForm = ({
  formAction,
  onSetFormAction,
  tokenRegistryAddress,
  beneficiary,
  holder,
  account,
  canSurrender,
  canHandleShred,
  canHandleRestore,
  onConnectToWallet,
  canChangeHolder,
  canEndorseBeneficiary,
  isSurrendered,
  isTokenBurnt,
  canNominateBeneficiary,
  canEndorseTransfer,
  setShowEndorsementChain,
  isTitleEscrow,
  history,
  confirmDialogOpen,
  setConfirmDialogOpen,
  modalData,
  setModalData
}) => {
  const canManage =
    canHandleShred ||
    canHandleRestore ||
    canSurrender ||
    canChangeHolder ||
    canEndorseBeneficiary ||
    canNominateBeneficiary ||
    canEndorseTransfer;

  const handleNoAccess = () => {
    setModalData({
      content: MessageTitle.NO_MANAGE_ACCESS,
      title: 'Metamask Access',
      okMsg: 'Close',
      type: 'Info'
    })
    setConfirmDialogOpen(true);
  };

  const handleMetamaskError = (errorMesssage, errorCode) => {
    setModalData({
      content: errorMesssage,
      title: 'Metamask Error',
      okMsg: 'Close',
      type: 'Info'
    })
    setConfirmDialogOpen(true);
  };

  const handleConnectWallet = async () => {
    try {
      await onConnectToWallet();
    } catch (error) {
      handleMetamaskError(error.message, error.code);
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <AssetInformationPanel
            setShowEndorsementChain={setShowEndorsementChain}
            tokenRegistryAddress={tokenRegistryAddress}
            formAction={formAction}
          />
        </Grid>
        {isSurrendered && (
          <Grid item xs={4}>
            <Box ml={6}>
              <Typography id="surrender-sign" className="bg-white rounded-xl text-scarlet-500 border-scarlet-500">
                <h3 style={{ fontWeight: 600 }} id="issuedby" className="break-words leading-tight py-4" data-testid="surrenderToIssuer">
                  Surrendered To Issuer
                </h3>
              </Typography>
            </Box>
          </Grid>
        )}
        {isTokenBurnt && (
          <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
            <Box ml={6}>
              <Typography id="surrender-sign" className="bg-white rounded-xl text-scarlet-500 border-scarlet-500">
                <h3 style={{ fontWeight: 600 }} id="issuedby" className="break-words leading-tight py-4" data-testid="surrenderToIssuer">
                  Surrendered
                </h3>
              </Typography>
            </Box>
          </Grid>
        )}
        {!isSurrendered && !isTokenBurnt && isTitleEscrow && (
          <>
            <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
              <div style={{marginTop : "4.5rem"}}></div>
              <EditableAssetTitle role="Owner" value={beneficiary} isEditable={false} />
            </Grid>
            <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
              <div style={{marginTop : "4.5rem"}}></div>
              <EditableAssetTitle role="Holder" value={holder} isEditable={false} />
            </Grid>
          </>
        )}
      </Grid>
      {!isTokenBurnt && (
        <Grid container spacing={1}>
          <Grid item style={{ overflowWrap: 'anywhere' }}>
            {account ? (
              <>
                <Box ml={3}>
                  {canManage ? (
                    <AssetManagementDropdown
                      onSetFormAction={onSetFormAction}
                      canSurrender={canSurrender}
                      canChangeHolder={canChangeHolder}
                      canEndorseBeneficiary={canEndorseBeneficiary}
                      canNominateBeneficiary={canNominateBeneficiary}
                      canEndorseTransfer={canEndorseTransfer}
                      canHandleRestore={canHandleRestore}
                      canHandleShred={canHandleShred}
                    />
                  ) : (
                    <SubmitButton
                      onClick={handleNoAccess}
                    >
                      No Access
                    </SubmitButton>
                  )}
                </Box>
                <br /><br /><br />
              </>
            ) : (
              <Box ml={3}>
                <SubmitButton
                  onClick={handleConnectWallet}
                >
                  Connect Wallet
                </SubmitButton>
                <br /><br /><br />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
