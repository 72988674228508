import React from "react";

export const Tag = ({ className, children, ...props }) => {
    return (
        <div className={`inline-block rounded-lg py-1 px-2 ${className}`} {...props}>
            {children}
        </div>
    );
};

export const TagBordered = ({ className, children, ...props }) => {
    return (
        <Tag className={`border-solid border ${className}`} {...props}>
            {children}
        </Tag>
    );
};

export const TagBorderedSm = ({ className, children, ...props }) => {
    return (
        <TagBordered className={`text-sm ${className}`} {...props}>
            {children}
        </TagBordered>
    );
};

export const TagBorderedLg = ({ className, children, ...props }) => {
    return (
        <TagBordered className={`border-4 text-4xl py-2 px-4 font-gilroy-bold ${className}`} {...props}>
            {children}
        </TagBordered>
    );
};