import React, { useState, useCallback, useRef } from 'react'
import { components } from 'cng-web-lib'
import DocumentUtility from './DocumentUtility';
import { DecentralisedRendererContainer } from './DecentralisedRenderer';

const {
  CngGridItem,
  table: { useDefaultNotification }
} = components

const DocumentViewer = (document) => {

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const childRef = useRef();

  const updateTemplates = useCallback((templateList) => {
    // filter all templates that are renderable currently
    const templatesModified = templateList.filter((item) => {
      return item.type === "custom-template" || item.type === "application/pdf" || !item.type; // !item.type caters to renderers that still has decentralized-renderer-react-components dependency at <2.3.0, where type does not exists
    });

    // set modified templates
    setTemplates(templatesModified);
    setSelectedTemplate(templatesModified[0].id);
  }, []);

  const onPrint = () => {
    if (childRef.current) {
      childRef.current.print();
    }
  };

  return (
    <>
      <div className="bg-white py-6">
        <div className="block">
          {templates.length > 0 && <DocumentUtility document={document} onPrint={onPrint} />}
          <DecentralisedRendererContainer
            rawDocument={document}
            updateTemplates={updateTemplates}
            selectedTemplate={selectedTemplate}
            ref={childRef}
          />
        </div>
      </div>
    </>
  );
}

export default DocumentViewer;