import { Box, Paper, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CngBackdrop from '../components/CngBackdrop'
import { useFormContext } from 'react-hook-form'
import FTAVersionLogsForm from './FTAVersionLogsForm'
import FtaActivityHistoryListHeaderComponent from './FtaActivityHistoryListHeaderComponent'
import FtaActivityHistoryListCardComponent from './FtaActivityHistoryListCardComponent'
import FtaListNoResult from '../shared/FtaListNoResult'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import Utils from 'src/shared/Utils'

const { CngGridItem } = components

const useStyles = makeStyles((theme) => ({
  sectionBlueBg: {
    backgroundColor: Utils.hexToRgba(theme.palette.primary.main, 0.06),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    padding: '20px'
  },
  sectionMain: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    padding: '20px'
  }
}))

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...FTAVersionLogsForm.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ showNotification, ...props }) {
  const { setValue, getValues, handleSubmit } = useFormContext()
  const history = useHistory()
  const { securedSendRequest } = useServices()
  const [loading, setLoading] = useState(false)
  const [version, setVersion] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const [shownCount, setShownCount] = useState(0)
  const [pageError, setPageError] = useState(false)
  const [displayedProductsList, setDisplayedProductsList] = useState([])
  const [countryCodes, setCountryCodes] = useState({
    origin: '',
    destination: ''
  })
  const location = useLocation()
  const classes = useStyles()

  const [pageNumber, setPageNumber] = useState(0)
  let loadAmount = 10
  const [searchCriteria, setSearchCriteria] = useState()
  const [shouldShowDeleted, setShouldShowDeleted] = useState(false)
  const [activityDelete, setActivityDelete] = useState(false)


  const onShouldShowDeleteChange = () => {
    console.log(shouldShowDeleted)
    setLoading(true)
    if (shouldShowDeleted) {
      setShouldShowDeleted(false)
    } else {
      setShouldShowDeleted(true)
    }
    setLoading(false)

  }

  // @purpose: Fetch FTA Activity History data
  const fetchFtaActiviyHistory = (formData) => {
    setLoading(true)

    console.log('From child form ::\n', formData)

    // This is carry forwarded to child components
    setSearchCriteria({
      origin: getValues('origin') !== undefined ? getValues('origin') : '',
      destination: getValues('destination') !== undefined ? getValues('destination') : '',
      materialNo:
        getValues('materialNoSkuProductDesc') !== undefined
          ? getValues('materialNoSkuProductDesc')
          : '',
      hsCode: getValues('hsCode') !== undefined ? getValues('hsCode') : '',
      hsDescription:
        getValues('hsDescription') !== undefined
          ? getValues('hsDescription')
          : '',
      productDescription:
        getValues('productDescription') !== undefined
          ? getValues('productDescription')
          : '',
      companyName:
        getValues('companyName') !== undefined ? getValues('companyName') : '',
      companyRefNo:
        getValues('companyRefNo') !== undefined ? getValues('companyRefNo') : '',
      isDeleted: shouldShowDeleted
    })

    // Search payload
    const searchPayload = {
      pageNumber: pageNumber,
      pageSize: loadAmount,
      origin: getValues('origin') !== undefined ? getValues('origin') : '',
      destination: getValues('destination') !== undefined ? getValues('destination') : '',
      materialNo:
        getValues('materialNoSkuProductDesc') !== undefined
          ? getValues('materialNoSkuProductDesc')
          : '',
      hsCode: getValues('hsCode') !== undefined ? getValues('hsCode') : '',
      hsDescription:
        getValues('hsDescription') !== undefined
          ? getValues('hsDescription')
          : '',
      productDescription:
        getValues('productDescription') !== undefined
          ? getValues('productDescription')
          : '',
      companyName:
        getValues('companyName') !== undefined ? getValues('companyName') : '',
      companyRefNo:
        getValues('companyRefNo') !== undefined ? getValues('companyRefNo') : '',
      isDeleted: shouldShowDeleted,
      sortType: getValues('sortType') !== undefined ? getValues('sortType') : '',
    }

    // API call
    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.GET_ACTIVITY_HISTORY,
      searchPayload,
      (response) => {
        setDisplayedProductsList(response?.data?.results || [])
        setShownCount(response?.data?.results?.length || 0)

        setLoading(false)
        setPageError(false)
      },
      (error) => {
        onError(error)
        console.error(error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )

  }

  // @purpose: Set state whenever the error is encountered
  const onError = (error) => {
    setPageError(true)
    setLoading(false)
  }

  // @purpose: Make sure the results set is empty or not
  const showNoResultPage = () => {
    return displayedProductsList.length === 0 && !loading
  }

  // @purpose: This will be called from child to update the records count
  const updateShownCount = (countFromChild) => {
    setShownCount(countFromChild)
  }

  // @purpose: Manage delete activity history
  const deleteActivityHistory = (activityHistoryId) => {
    setLoading(true)

    console.log(
      'deleting activity history :: activityHistoryId',
      activityHistoryId
    )

    // API call
    securedSendRequest.execute(
      'delete',
      TradeMgmtApiUrls.DELETE_ACTIVITY_HISTORY,
      {
        ftaActivityHistoryId: activityHistoryId
      },
      (response) => {
        console.log(response)
        setDisplayedProductsList(response?.data?.results || [])
        setShownCount(response?.data?.results?.length || 0)
        setActivityDelete(activityDelete)
        // Show success notification
        showNotification(
          'success',
          `Activity History Id: ${activityHistoryId} deleted successfully`
        )

        setLoading(false)
        setPageError(false)
      },
      (error) => {
        onError(error)
        console.error(error)

        // Show error notification
        showNotification('error', error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(true)
        fetchFtaActiviyHistory()
      },
      {}
    )
  }

  // @purpose: Export data as PDF
  const exportPdf = (activityHistoryId) => {
    console.log('Export as PDF :: activityHistoryId'.activityHistoryId)
  }

  // @purpose: Export data as excel
  const exportExcel = (activityHistoryId) => {
    console.log('Export as Excel :: activityHistoryId'.activityHistoryId)
  }


  // @purpose: Prepare route to view details page
  const fetchProductAndRouteToFtaDetails = (ftaActivityHistoryIdFromChild, productStatusCode) => {
    console.log(
      'ftaActivityHistoryId from child',
      ftaActivityHistoryIdFromChild,
      'productStatusCode',
      productStatusCode
    )

    setLoading(true)

    // API call
    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.GET_ACTIVITY_BY_HISTORY_ID,
      {
        ftaActivityHistoryId: ftaActivityHistoryIdFromChild
      },
      (response) => {
        if (response?.data?.status == 200) {
          console.log(response)
          console.log('results length', response?.data?.results?.length)

          if (response?.data?.results?.length > 0) {
            history.push(`/cal-tmp/ftacalculator/productdetails`, {
              detailsData: {
                materialSkuProductDescription:
                  response?.data?.results?.[0].materialNoAndProductDesc,
                hsCodeAndDescription: response?.data?.results?.[0].hsCode,
                productDescription:
                  response?.data?.results?.[0].productDescription,
                chapterNo: response?.data?.results?.[0].chapterNo,
                model: response?.data?.results?.[0].model,
                unitOfMeasurement: response?.data?.results?.[0].uom,
                unitOfMeasurementName: response?.data?.results?.[0].uomName,
                currency: response?.data?.results?.[0].currency,
                exworksCost: response?.data?.results?.[0].exworkCost,
                origin: response?.data?.results?.[0].origin,
                originCode: response?.data?.results?.[0].originCode,
                destination: response?.data?.results?.[0].destination,
                destinationCode: response?.data?.results?.[0].destinationCode,
                cifValue: response?.data?.results?.[0].cifValue,
                baseUnit: response?.data?.results?.[0].baseUnit,
                netCosts: response?.data?.results?.[0].netCosts,
                fobPrice: response?.data?.results?.[0].fobPrice,
                totalCosts: response?.data?.results?.[0].totalCosts,
                transactionValue: response?.data?.results?.[0].transactionValue
              },
              calculationResults:
                response?.data?.results?.[0].calculationDetails,
              ftaActivityHistoryId:
                response?.data?.results?.[0].ftaActivityHistoryId,
              productStatusCode: productStatusCode,
              fromViewDetails: false,
              isRoutefromCalcScreen: false
            })
            setLoading(false)
            setPageError(false)
          }
        }
        // On client-side & server-side error
        else if (
          response?.data?.status == 400 ||
          response?.data?.status == 500
        ) {
          // Hide data loader overlay
          setLoading(false)

          let errorData = response?.data?.errorDetails

          // Show error notification
          if (errorData.length > 0) {
            errorData.map((errorItem, index) => {
              return showNotification('error', errorItem.errorMessage)
            })
          }
        }
      },
      (error) => {
        onError(error)
        console.error(error)

        // Show error notification
        showNotification('error', error)
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )
  }

  useEffect(() => {
    fetchFtaActiviyHistory()
  }, [shouldShowDeleted])

  return (
    <Box>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box
          p={2}
          className='cng-details-page__content-container edge-to-edge'
          style={{ borderRadius: 'inherit' }}
        >
          <Box className={`cng-details-page__section ${classes.sectionBlueBg}`}>
            <FTAVersionLogsForm.FormFields
              history={history}
              showNotification={showNotification}
              disabled={disabled}
              onSearchClick={fetchFtaActiviyHistory}
            />
          </Box>

          {showNoResultPage() && (
            <FtaListNoResult
              displayedProductsList={displayedProductsList}
              shownCount={shownCount}
              pageError={pageError}
            />
          )}

          {!showNoResultPage() && (
            <Box className={`cng-details-page__section ${classes.sectionMain}`}>
              <FtaActivityHistoryListHeaderComponent.FormBody
                shownCount={shownCount}
                onShouldShowDeleteChange={onShouldShowDeleteChange}
              />

              <FtaActivityHistoryListCardComponent.FormBody
                displayedProductsList={displayedProductsList}
                updateShownCount={updateShownCount}
                searchCriteria={searchCriteria}
                deleteActivityHistory={deleteActivityHistory}
                exportPdf={exportPdf}
                exportExcel={exportExcel}
                showNotification={showNotification}
                fetchProductAndRouteToFtaDetails={
                  fetchProductAndRouteToFtaDetails
                }
                shouldShowDeleted={shouldShowDeleted}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const FtaActivityHistoryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default FtaActivityHistoryFormProperties
