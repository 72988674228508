import Namespace from 'src/constants/locale/Namespace'
import TradeManagementKeys from 'src/constants/locale/key/TradeManagement'
import { useTranslation } from 'cng-web-lib'

const TradeManagementTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TRADE_MANAGEMENT
  ])

  // Module titles, Section tiles, Breadcrumbs
  let moduleTitle = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FTACALCULATORTITLE
  )

  let productInformation = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.SectionTitles.PRODUCT_INFORMATION
  )

  let originDestinationInformation = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.SectionTitles.ORIGIN_DESTINATION_INFORMATION
  )

  let dutySavingsTitle = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.SectionTitles.DUTY_SAVINGS_INFORMATION
  )

  let selfCertificateTitle = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.SectionTitles.SELF_CERTIFICATE_INFORMATION
  )

  let bcExport = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.BreadCrumbs.BC_EXPORT
  )

  let bcFtaCalculator = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.BreadCrumbs.BC_FTA_CALCULATOR
  )

  let bcViewFtaCalculations = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.BreadCrumbs.BC_VIEW_FTA_CALCULATIONS
  )

  let bcFtaActivityHistory = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.BreadCrumbs.BC_FTA_ACTIVITY_HISTORY
  )

  // General labels, messages
  let aboutFTAPopoverContent = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.ABOUT_FTA_POPOVER_CONTENT
  )

  let provideNecessaryInfo = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.PROVIDE_NECESSARY_INFORMATION
  )

  let allFieldsAreMandatory = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.ALL_FIELDS_ARE_MANDATORY
  )

  let materialNoSkuPdtDescription = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MATERIAL_NO_SKU_PDT_DESCRIPTION
  )

  let materialNoSku = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MATERIAL_NO_SKU
  )

  let entityName = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ENTITY_NAME
  )

  let materialNumberSku = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MATERIAL_NUMBER_SKU
  )

  let hsCodeAndDescription = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.HSCODE_DESCRIPION
  )

  let materialNo = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MATERIAL_NO
  )

  let sku = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.SKU
  )

  let productDescription = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.PRODUCT_DESCRIPTION
  )

  let hsCode = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.HSCODE
  )

  let hsDescription = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.HS_DESCIRPTION
  )

  let description = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DESCIRPTION
  )

  let model = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MODEL
  )

  let unitOfMeasurement = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.UNIT_OF_MEASUREMENT
  )

  let uom = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.UOM
  )

  let currency = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CURRENCY
  )

  let exworksCost = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.EX_WORKS_COST
  )

  let origin = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ORIGIN
  )

  let destination = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DESTINATION
  )

  let cifValue = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CIF_VALUE
  )

  let backToCalculator = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.BACK_TO_CALCULATOR
  )

  let calculationResults = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CALCULATION_RESULTS
  )

  let itemsPerPage = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ITEMS_PER_PAGE
  )

  let previous = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.PREVIOUS
  )

  let next = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.NEXT
  )

  let dutySavingPercentAndAmount = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DUTY_SAVING_PERCENT_AMOUNT
  )

  let dutySavingPercentage = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DUTY_SAVING_PERCENT
  )

  let amount = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.AMOUNT
  )

  let qualificationDate = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.QUALIFICATION_DATE
  )

  let qualificationExpiry = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.QUALIFICATION_EXPIRY
  )

  let assessedBy = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ASSESSED_BY
  )

  let chapter = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CHAPTER
  )

  let chapterDescription = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CHAPTER_DESCRIPTION
  )

  let calculation = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CALCULATION
  )

  let thresholdValuePerUOM = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.THRESHOLD_VALUE_PER_UOM
  )

  let profit = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.PROFIT
  )

  let nonOriginatingValue = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.NON_ORIGINATING_VALUE
  )

  let nonOriginatingValuePercentage = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.NON_ORIGINATING_VALUE_PERCENT
  )

  let directLabourCost = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DIRECT_LABOUR_COST
  )

  let directOverheardCost = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DIRECT_OVERHEARD_COST
  )

  let otherCost = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.OTHER_COST
  )

  let baseUnit = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.BASE_UNIT
  )

  let qvc = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.QVC
  )

  let rvc = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.RVC
  )

  let defaultDutyAmount = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.DEFAULT_DUTY_AMOUNT
  )

  let ftaDutyAmount = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.FTA_DUTY_AMOUNT
  )

  let potentialDutySavings = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.POTENTIAL_DUTY_SAVINGS
  )

  let potentialDutySavingsPercentage = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.POTENTIAL_DUTY_SAVINGS_PERCENT
  )

  let countryOrigin = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.COUNTRY_ORIGIN
  )

  let countryDestination = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.COUNTRY_DESTINATION
  )

  let materialType = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.MATERIAL_TYPE
  )

  let originatingValue = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ORIGINATING_VALUE
  )

  let originatingValuePercentage = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.ORIGINATING_VALUE_PERCENT
  )

  let ltsdStatus = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.LTSD_STATUS
  )

  let ltsdStartEndDate = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.LTSD_START_END_DATE
  )

  let unit = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.UNIT
  )

  let standardPricePerUOM = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.STANDARD_PRICE_PER_UOM
  )

  let customsTariffNo = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.CUSTOMS_TARIFF_NO
  )

  let componentNo = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.COMPONENT_NO
  )

  let bomName = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.BOM_NAME
  )

  let companyName = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.COMPANY_NAME
  )

  let companyRefNo = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.COMPANY_REF_NO
  )

  let lastModifiedOn = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.LAST_MODIFIED_ON
  )

  let fobPrice = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.FREE_ON_BOARD_PRICE
  )

  let transactionValue = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.TRANSACTION_VALUE
  )

  let netCosts = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.NET_COSTS
  )

  let totalCosts = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.TOTAL_COSTS
  )

  let principal = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.PRINCIPAL
  )

  let fta = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FieldLabels.FTA
  )






  // Statuses
  let statusQualified = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_QUALIFIED
  )

  let statusDNQualified = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_DN_QUALIFIED
  )

  let statusSelfAssessmentRequired = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_SELF_ASSESSMENT_REQUIRED
  )

  let statusPriceDependent = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_PRICE_DEPENDENT
  )

  let statusDeleted = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_DELETED
  )

  let statusActive = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_ACTIVE
  )

  let statusInsufficientData = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Statuses.STATUS_INSUFFICIENT_DATA
  )

  // Validation messages
  let materialNoSKURequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation
      .MATERIAL_NO_SKU_PDT_DESCRIPTION_REQUIRED_MSG
  )

  let materialNoSKUInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation
      .MATERIAL_NO_SKU_PDT_DESCRIPTION_INVALID_MSG
  )

  let hsCodeAndDescRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.HSCODE_DESCRIPION_REQUIRED_MSG
  )

  let hsCodeAndDescInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.HSCODE_DESCRIPION_INVALID_MSG
  )

  let modelRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.MODEL_REQUIRED_MSG
  )

  let modelInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.MODEL_INVALID_MSG
  )

  let unitOfMeasurementRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.UNIT_OF_MEASUREMENT_REQUIRED_MSG
  )

  let unitOfMeasurementInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.UNIT_OF_MEASUREMENT_INVALID_MSG
  )

  let currencyRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.CURRENCY_REQUIRED_MSG
  )

  let currencyInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.CURRENCY_INVALID_MSG
  )

  let exworksCostRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.EX_WORKS_COST_REQUIRED_MSG
  )

  let exworksCostInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.EX_WORKS_COST_INVALID_MSG
  )

  let originRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.ORIGIN_REQUIRED_MSG
  )

  let originInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.ORIGIN_INVALID_MSG
  )

  let originCannotBeSameMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.ORIGIN_CANNOT_BE_SAME_MSG
  )

  let destinationRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.DESTINATION_REQUIRED_MSG
  )

  let destinationInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.DESTINATION_INVALID_MSG
  )

  let destinationCannotBeSameMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.DESTINATION_CANNOT_BE_SAME_MSG
  )

  let cifValueRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.CIF_VALUE_REQUIRED_MSG
  )

  let cifValueInvalidMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.CIF_VALUE_INVALID_MSG
  )

  let calculationSuccessMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.CALCULATION_SUCCESS_MSG
  )

  let problemFetchingDataMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.PROBLEM_FETCHING_DATA_MSG
  )

  let importHsCodeRequiredMsg = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.YupValidation.IMPORT_HS_CODE_REQUIRED
  )

  // Tooltip
  let ttipEditProductInformation = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.Tooltip.TTIP_EDIT_PRODUCT_INFORMATION
  )


  let otherOptions = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.OTHER_OPTIONS
  )

  let fieldsAreNonMandatory = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.FIELDS_ARE_NON_MANDATORY
  )

  return {
    moduleTitle,
    productInformation,
    originDestinationInformation,
    dutySavingsTitle,
    selfCertificateTitle,
    bcExport,
    bcFtaCalculator,
    bcViewFtaCalculations,
    bcFtaActivityHistory,
    aboutFTAPopoverContent,
    provideNecessaryInfo,
    allFieldsAreMandatory,
    materialNoSkuPdtDescription,
    materialNoSku,
    entityName,
    materialNumberSku,
    hsCodeAndDescription,
    materialNo,
    sku,
    productDescription,
    hsCode,
    hsDescription,
    description,
    model,
    unitOfMeasurement,
    uom,
    currency,
    exworksCost,
    origin,
    destination,
    cifValue,
    backToCalculator,
    calculationResults,
    itemsPerPage,
    previous,
    next,
    dutySavingPercentAndAmount,
    dutySavingPercentage,
    amount,
    qualificationDate,
    qualificationExpiry,
    assessedBy,
    chapter,
    chapterDescription,
    calculation,
    thresholdValuePerUOM,
    profit,
    nonOriginatingValue,
    nonOriginatingValuePercentage,
    directLabourCost,
    directOverheardCost,
    otherCost,
    baseUnit,
    qvc,
    rvc,
    defaultDutyAmount,
    ftaDutyAmount,
    potentialDutySavings,
    potentialDutySavingsPercentage,
    countryOrigin,
    countryDestination,
    materialType,
    originatingValue,
    originatingValuePercentage,
    ltsdStatus,
    unit,
    ltsdStartEndDate,
    standardPricePerUOM,
    customsTariffNo,
    componentNo,
    bomName,
    companyName,
    companyRefNo,
    lastModifiedOn,
    fobPrice,
    transactionValue,
    netCosts,
    totalCosts,
    principal,
    fta,
    statusQualified,
    statusDNQualified,
    statusSelfAssessmentRequired,
    statusPriceDependent,
    statusDeleted,
    statusActive,
    statusInsufficientData,
    materialNoSKURequiredMsg,
    materialNoSKUInvalidMsg,
    hsCodeAndDescRequiredMsg,
    hsCodeAndDescInvalidMsg,
    modelRequiredMsg,
    modelInvalidMsg,
    unitOfMeasurementRequiredMsg,
    unitOfMeasurementInvalidMsg,
    currencyRequiredMsg,
    currencyInvalidMsg,
    exworksCostRequiredMsg,
    exworksCostInvalidMsg,
    originRequiredMsg,
    originInvalidMsg,
    originCannotBeSameMsg,
    destinationRequiredMsg,
    destinationInvalidMsg,
    destinationCannotBeSameMsg,
    cifValueRequiredMsg,
    cifValueInvalidMsg,
    calculationSuccessMsg,
    problemFetchingDataMsg,
    ttipEditProductInformation,
    importHsCodeRequiredMsg,
    otherOptions,
    fieldsAreNonMandatory
  }
}

export default TradeManagementTranslationText
