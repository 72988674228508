import React from "react";
import { AssetManagementActions } from "../constants/AssetManagementActions";
import { Grid } from '@material-ui/core'

export const AssetManagementTitle = ({
    setFormActionNone,
    formAction,
    disabled,
}) => {
    return (
        <Grid container alignItems="center" className="ms-4">
            <div
                className={`mb-2 ${disabled ? "text-cloud-200 cursor-default" : "text-cloud-500 cursor-pointer"}`}
                onClick={() => setFormActionNone(disabled)}
                data-disabled={disabled}
            >
                <h2 style={{ fontWeight: 600 }} id="issuedby" className="break-words leading-tight py-4">
                    {formAction === AssetManagementActions.Surrender && <>Surrender Document</>}
                    {formAction === AssetManagementActions.AcceptSurrendered && <>Accept Surrender of Document</>}
                    {formAction === AssetManagementActions.RejectSurrendered && <>Reject Surrender of Document</>}
                    {formAction === AssetManagementActions.TransferHolder && <>Transfer Holdership</>}
                    {formAction === AssetManagementActions.EndorseBeneficiary && <>Endorse Change of Ownership</>}
                    {formAction === AssetManagementActions.NominateBeneficiary && <>Nominate Change of Ownership</>}
                    {formAction === AssetManagementActions.EndorseTransfer && <>Endorse Transfer of Ownership</>}
                </h2>
            </div>
        </Grid>
    );
};