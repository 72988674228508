import React from 'react'
import { CheckCircle, XCircle } from "react-feather";

const StatusCheck = (props) => {
    const icon = props.valid ? <CheckCircle className="text-forest-500" color = "green"/> : <XCircle className="text-scarlet-500" color = "red" />;

    return (
        <>
            <div className="flex justify-start items-center">
                <div className="flex-grow">
                    <span>{icon}</span>
                    <span className="pl-2 mb-0 text-sm leading-5" style={{font:'Gilroy-Medium, sans-serif'}}>{props.message}</span>
                </div>
            </div>
        </>
    )
}

export default StatusCheck