import React, { useEffect, useState } from 'react'
import { Grid, Button, Tooltip } from '@material-ui/core'
import IconBox from '../components/IconBox'
import WordBox from '../components/WordBox'
import CargoInformationComponents from '../components/CargoInformationComponents'
import TonPerTEUComponent from '../components/TonPerTEUComponent'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { LocationOnOutlined } from '@material-ui/icons'
import { SelectAdvanComponent } from '../components/select-component'
import PortSelectAutoComplete from '../components/PortSelectAutoComplete'
import { OPTION_LOCATION } from '../constants'
import { useServices, constants, useTranslation } from 'cng-web-lib'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CheckboxOptionComponent from '../components/CheckOptionComponent'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import IconButton from '@material-ui/core/IconButton'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmDialog from '../../../../components/dialog/ConfirmDialog'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useHistory } from 'react-router'
import { chekkeyCode } from '../ultls'
import CarbonFootprintApiUrls from '../../../../apiUrls/CarbonFootprintApiUrls'
import Loading from '../components/Loading'
import Namespace from 'src/constants/locale/Namespace'

const CalculatePageAdvan = () => {
  const { fetchRecords } = useServices()
  const [country, setCountry] = useState([])
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [location, setlocation] = useState(null)
  const [locationD, setlocationD] = useState(null)

  const { CodeMaintenanceType } = constants
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])

  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const formData = history?.location?.state?.dataLocationForm ?? {}

  const methods = useForm({
    defaultValues: formData
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'optionRequests'
  })
  useEffect(() => {
    setLoading(true)
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      (content) => {
        setLoading(false)
        setCountry(content)
      }
    )

    if (fields.length < 1) append({})
  }, [])
  const addOption = (name) => () => {
    if (fields.length < 5) {
      append(name ? methods.getValues(name) : {})
    }
  }

  const onDelete = (index) => () => {
    if (fields.length > 1) {
      remove(index)
    }
    return false
  }

  const [modalData, setModalData] = useState({})
  const modalAction = () => {
    if (modalData.type === 'start-over') {
      setConfirmDialogOpen(false)
      methods.reset({})
    } else {
      setConfirmDialogOpen(false)
      methods.reset({})
      history.push('/cal-cfc/eco-calculator')
    }
  }
  const validateForm = (forms) => {
    if (forms?.departure?.code === forms?.destination?.code) {
      methods.setError('destination', {
        message: t('label_check_location')
      })
      methods.setError('departure', {
        message: t('label_check_location')
      })
      return true
    } else {
      return validateOptions({ optionRequests: forms.optionRequests, forms })
    }
  }
  const validateOptions = ({ optionRequests = [], forms = {} }) => {
    let isError = false
    for (let i = 0; i < optionRequests.length; i++) {
      const item = optionRequests[i]
      if (item?.viaRequestList) {
        let lengthVia = item?.viaRequestList?.length ?? 1
        if (
          item?.viaRequestList[lengthVia - 1]?.transportRequest?.code ==
          forms?.destination?.code
        ) {
          methods.setError(
            `optionRequests.${i}.viaRequestList.${
              lengthVia - 1
            }.transportRequest`,
            {
              message: t('label_check_location')
            }
          )
          methods.setError('destination', {
            message: t('label_check_location')
          })
          isError = true
        }
        if (
          item?.viaRequestList[0]?.transportRequest?.code ==
          forms?.departure?.code
        ) {
          methods.setError(
            `optionRequests.${i}.viaRequestList.0.transportRequest`,
            {
              message: t('label_check_location')
            }
          )
          methods.setError('departure', {
            message: t('label_check_location')
          })
          isError = true
        }
      }
    }
    return isError
  }
  const onSubmit = (values) => {
    methods.reset(values)
    if (!validateForm(values)) {
      const returnValue = {
        cargoWeight: +values?.cargoVolume,
        tonsPerTeu: +values?.tonsPerTeu,
        departure: chekkeyCode(values?.locationType, values?.departure),
        destination: chekkeyCode(values?.locationTypePOD, values?.destination),
        optionRequests: values?.optionRequests.map((item) => {
          return {
            ...item,
            transport: item?.transport === 'rail' ? 'train' : item?.transport,
            viaRequestList: item?.viaRequestList?.map((elm) => {
              return {
                ...elm,
                transportRequest: chekkeyCode(
                  elm?.viaLocationType,
                  elm?.transportRequest
                  ),
                  transport: elm?.transport === 'rail' ? 'train' : elm?.transport
              }
            })
          }
        })
      }
      setLoading(true)
      fetchRecords.execute(
        CarbonFootprintApiUrls.CALCULATE_ADVANCE,
        { customData: returnValue },
        (data) => {
          history.push({
            pathname: '/cal-cfc/eco-calculator/result',
            state: {
              dataLocationForm: values,
              data: data,
              country: country,
              locations: {
                startLocation: {
                  code: values?.departure?.code,
                  description: values?.departure?.descriptionEn,
                  flag: values?.departure?.countryCode,
                  locationType: values?.locationType
                },
                endLocation: {
                  code: values?.destination?.code,
                  description: values?.destination?.descriptionEn,
                  flag: values?.destination?.countryCode,
                  locactionType: values?.locationTypePOD
                }
              }
            }
          })
        },
        (error) => {
          console.log(error)
        },
        () => {
          setLoading(false)
        }
      )
    }
  }
  const onChangeLocation = (val) => {
    setlocation(val)
  }
  const onChangeLocationD = (val) => {
    setlocationD(val)
  }
  return (
    <Loading loading={loading}>
      <FormProvider {...methods}>
        <ConfirmDialog
          isConfirmDialogOpen={confirmDialogOpen}
          closeDialog={() => setConfirmDialogOpen(false)}
          confirmDialog={modalAction}
          content={modalData.content}
          title={modalData.title}
          okMsg={modalData.okMsg}
          cancelMsg={modalData.cancelMsg}
        />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            className='cfc-calculate-advan'
            style={{ position: 'relative' }}
          >
            <Grid item xs={12} className='cfc-cargo-infomation p-20'>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <div style={{ fontWeight: 600, fontSize: '20px' }}>
                    {t('label_cargo')}
                    <div
                      style={{ fontSize: '11px', color: '#8996AF' }}
                      display='block'
                    >
                      {t('label_fields_mandatory')}
                    </div>
                  </div>
                  {/* text box 1 */}
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    value='advanced-mode'
                    control={
                      <Switch
                        color='primary'
                        checked={true}
                        onClick={() => {
                          setModalData({
                            content: t('label_modal_content'),
                            title: t('label_switch_mode'),
                            okMsg: t('label_ok'),
                            cancelMsg: t('label_cancel'),
                            type: 'switch-mode'
                          })
                          setConfirmDialogOpen(true)
                        }}
                      />
                    }
                    label={t('label_advanced')}
                    labelPlacement='start'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <WordBox
                        label={t('label_amount')}
                        description={t('label_teu')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CargoInformationComponents />
                    </Grid>
                    <Grid item xs={3}>
                      <TonPerTEUComponent
                        isAdvan={true} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className='bg-w p-20'>
              <Grid item xs={12}>
                <div style={{ fontWeight: 600, fontSize: '20px' }}>
                  {t('label_schedule_scenario')}
                </div>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <IconBox
                        icon={<LocationOnOutlined />}
                        label={t('label_origin')}
                      />
                    </Grid>
                    {/* <Grid item xs={3}> */}
                    <SelectAdvanComponent
                      xs={3}
                      label={t('label_location_type')}
                      name={'locationType'}
                      options={OPTION_LOCATION}
                      rules={{ required: t('label_location_type_valid') }}
                      onChangeSelect={onChangeLocation}
                    />

                    {/* </Grid> */}
                    <Grid item xs={3}>
                      <PortSelectAutoComplete
                        name='departure'
                        label={t('label_location')}
                        fName='locationType'
                        country={country}
                        location={location}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* start option */}
                <Grid item xs={12} className='bd-b pb-30'>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <WordBox
                        label={t('label_transport_mode')}
                        description={t('label_transport_select')}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={2}>
                        {fields.map((field, index) => {
                          return (
                            <Grid item xs={12}>
                              <Grid container spacing={2} key={field.id}>
                                <Grid
                                  item
                                  xs={12}
                                  justifyContent='space-between'
                                >
                                  <div className='fl fl-sb'>
                                    <div>
                                      {t('label_option')} {index + 1}
                                    </div>
                                    <div>
                                      <Tooltip
                                        title={t('label_duplicate')}
                                        placement='bottom'
                                      >
                                        <IconButton
                                          onClick={addOption(
                                            `optionRequests.${index}`
                                          )}
                                        >
                                          <FileCopyOutlinedIcon fontSize='inherit' />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={t('label_delete')}
                                        placement='bottom'
                                      >
                                        <IconButton>
                                          <DeleteOutlineIcon
                                            onClick={onDelete(index)}
                                            fontSize='inherit'
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </Grid>
                                <CheckboxOptionComponent
                                  index={index}
                                  t={t}
                                  country={country}
                                />
                              </Grid>
                            </Grid>
                          )
                        })}
                        <Grid item xs={12}>
                          <div className='via-add fl' onClick={addOption()}>
                            <div className='icon-add'>
                              <AddOutlinedIcon fontSize='inherit' />
                            </div>
                            <span className='fl'>
                              <span className='add-leg'>
                                {t('label_transport_service')}
                              </span>
                              <span className='show-detail'>
                                {t('label_clone_previous')}
                              </span>
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className='mt-20 grid-bottom'>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <IconBox
                        icon={<LocationOnOutlined />}
                        label={t('label_destination')}
                      />
                    </Grid>
                    <SelectAdvanComponent
                      xs={3}
                      label={t('label_location_type')}
                      name={'locationTypePOD'}
                      options={OPTION_LOCATION}
                      rules={{ required: t('label_location_type_valid') }}
                      onChangeSelect={onChangeLocationD}
                    />

                    <Grid item xs={3}>
                      <PortSelectAutoComplete
                        name='destination'
                        label={t('label_location')}
                        fName='locationTypePOD'
                        country={country}
                        location={locationD}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button
                            startIcon={<RefreshIcon />}
                            fullWidth
                            style={{ height: '56px', color: '#5E81F4' }}
                            variant='contained'
                            onClick={() => {
                              setModalData({
                                content: t('label_modal_reset_content'),
                                title: t('label_modal_reset_title'),
                                okMsg: t('label_modal_reset_msg'),
                                cancelMsg: t('label_modal_reset_msg_cancel'),
                                type: 'start-over'
                              })
                              setConfirmDialogOpen(true)
                            }}
                          >
                            {t('label_start_over')}
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            startIcon={
                              <FontAwesomeIcon icon={['fas', 'calculator']} />
                            }
                            type='submit'
                            fullWidth
                            style={{ height: '56px' }}
                            variant='contained'
                            color='primary'
                          >
                            {t('label_calculate')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Loading>
  )
}
export default CalculatePageAdvan
