import { Box } from '@material-ui/core'
import { components, constants,useServices } from 'cng-web-lib'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CalScoApiUrls from 'src/apiUrls/SCOJobApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import SCOJobRequestTranslationText from './SCOJobRequestTranslationText'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'

import { useHistory } from 'react-router-dom'



const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      },
      button: { 
            CngPrimaryButton,
            CngSecondaryButton
      },
      CngTabs
} = components

const {
      filter: {
        EQUAL
      }
} = constants


function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const {
            success: showSuccessNotification,
            error: showErrorNotification
          } = useDefaultNotification(showNotification)

      const fetchCustomLookup = useFetchCustomLookup();
      const translatedTextsObject = SCOJobRequestTranslationText();
      const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
      const partyId = getPartyID();

      const history = useHistory()
      const createJobPathName = '/cal-sco/job/create-new-job'
      const { fetchRecords } = useServices()
      const tableRef = useRef()


      const jobRequestSentCol = [
            {
                  field: "jobReqRefNo",
                  title: translatedTextsObject.scoJobReqRefNo,
                  defaultSort: 'desc'
            },
            {
                  field: "operatorId",
                  title: translatedTextsObject.vendor,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${CecAccountApiUrls.GET_ACCOUNT}`,
                              {
                                    status: 'ACTV',
                                    partyId: partyId
                              },
                              'content',
                              'displayName',
                              'id',
                              (error) => console.error(error)
                        )
                  },
                  sorting:false
            },
            {
                  field: "jobRefNo",
                  title: translatedTextsObject.scoJobId,
                  sorting:false
            },
            {
                  field: "jobDescription",
                  title: translatedTextsObject.scoJobDescription,
                  sorting:false
            },
            {
                  field: "statusId",
                  title: translatedTextsObject.requestStatus,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    customData: { 
                                          baseFilterDTO:{
                                                filterProperties: [
                                                      {
                                                            fieldName : 'codeType',
                                                            operatorType: EQUAL.toUpperCase(),
                                                            value1: 'JOB_REQ_STATUS'
                                                      }
                                                ],
                                                filterType: 'AND',
                                                sortProperties: []
                                          } 
                                    } ,
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
                  sorting:false

            }
      ];

      const jobRequestReceivedCol = [
            {
                  field: "jobReqRefNo",
                  title: translatedTextsObject.scoJobReqRefNo,
                  defaultSort: 'desc'
            },
            {
                  field: "requestorPartyId",
                  title: translatedTextsObject.requestor,
                  customLookup: () => {
                        return fetchCustomLookup(
                              CalScoApiUrls.GET_REQUESTOR_BY_RECEIVER_PARTY,
                              {},
                              'content',
                              'partyName',
                              'id',
                              (error) => console.error(error)
                        )
                  },
                  sorting:false
            },
            {
                  field: "jobDescription",
                  title: translatedTextsObject.scoJobDescription,
                  sorting:false
            },
            {
                  field: "statusId",
                  title: translatedTextsObject.requestStatus,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                              {
                                    customData: { 
                                          baseFilterDTO:{
                                                filterProperties: [
                                                      {
                                                            fieldName : 'codeType',
                                                            operatorType: EQUAL.toUpperCase(),
                                                            value1: 'JOB_REQ_STATUS'
                                                      }
                                                ],
                                                filterType: 'AND',
                                                sortProperties: []
                                          } 
                                    } ,
                              },
                              'content',
                              'name', // label accessor
                              'id', // value accessor
                              (error) => console.error(error)
                        )
                  },
                  sorting:false
            }
      ]

      const goToCreateJobPage = (id) => {
            history.push({
                pathname: '/cal-sco/job/create-new-job/' + id,
            })
        }
      

      function performDelete(urlRec, record) {
            return fetchRecords.execute(
                  CalScoApiUrls.REJECT_JOB_REQUEST,
                  {
                        data: 
                        { 
                              jobReqRefNo: record.jobReqRefNo 
                        },
                  },
                  (data) => {
                        showSuccessNotification(record.jobReqRefNo + ' Rejected successfully.')
                        tableRef.current.performRefresh()
                  },
                  (error) => {
                        showErrorNotification('Unable to Reject Job Request.')
                        tableRef.current.performRefresh()
                  }
            )
          }

          function performCancel(urlRec, record) {
            return fetchRecords.execute(
                  CalScoApiUrls.CANCEL_JOB_REQUEST,
                  {
                        data: 
                        { 
                              jobReqRefNo: record.jobReqRefNo 
                        },
                  },
                  (data) => {
                        showSuccessNotification(record.jobReqRefNo + ' Cancelled successfully.')
                        tableRef.current.performRefresh()
                  },
                  (error) => {
                        showErrorNotification('Unable to Cancel Job Request.')
                        tableRef.current.performRefresh()
                  }
            )
          }


      return (
                  <Box>
                        <CngTabs
                              headerColor='primary'
                              tabs={[
                                    { 
                                          tabName: translatedTextsObject.scoJobRequestSent,
                                          tabContent: ( 
                                                <CngCrudTable
                                                      {...props}
                                                      cngTableRef ={tableRef}
                                                      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                      columns={jobRequestSentCol}
                                                      fetch={{ url: CalScoApiUrls.GET_JOB_REQUEST }}
                                                      idAccessor="id"
                                                      fetchFilters={[{  field: 'requestor', operator: EQUAL, value: true}]}
                                                      notification={props.showNotification}
                                                      //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                                                      del={{ url: CalScoApiUrls.CANCEL_JOB_REQUEST}}
                                                      onDeleteButtonClick={
                                                            CalScoApiUrls.CANCEL_JOB_REQUEST ? performCancel : undefined
                                                      }
                                                      deleteButtonProps={{
                                                            icon: ({ color, ...otherProps }) => (
                                                                  <FontAwesomeIcon className='reject' icon={['fa', 'xmark']} />
                                                            ),
                                                            tooltip: 'Cancel',
                                                            hideIf: (rowData) => {
                                                                  return (rowData.status.code !== 'PEND')
                                                            }
                                                      }}
                                                      deleteConfirmationDialogProps={{
                                                            dialogTitle: "Cancel Job Request",
                                                            dialogContent: "Confirm Cancel Job Request?",
                                                            dialogAction: (onDelete, onCancel) => {
                                                                  return (
                                                                        <Box style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                                                                              <CngSecondaryButton onClick={onCancel}>
                                                                                    Cancel
                                                                              </CngSecondaryButton>
                                                                              <CngPrimaryButton onClick={onDelete}>
                                                                                    Proceed
                                                                              </CngPrimaryButton>
                                                                        </Box>
                                                                  )
                                                            }
                                                      }}
                                                />
                                          ) 
                                    },
                                    {
                                        tabName: translatedTextsObject.scoJobRequestReceived,
                                        tabContent: ( 
                                          <CngCrudTable
                                                {...props}
                                                cngTableRef ={tableRef}
                                                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                                columns={jobRequestReceivedCol}
                                                fetch={{ url: CalScoApiUrls.GET_JOB_REQUEST }}
                                                idAccessor="id"
                                                fetchFilters={[{  field: 'receiver', operator: EQUAL, value: true}]}
                                                notification={props.showNotification}
                                                //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                                               
                                                editRoute={`${createJobPathName}/:id`}
                                                editButtonProps={{
                                                      icon: ({ color, ...otherProps }) => (
                                                      <FontAwesomeIcon className='accept' icon={['fal', 'check']} />
                                                      ),
                                                      tooltip: 'Accept',
                                                      hideIf: (rowData) => {
                                                            return (rowData.status.code !== 'PEND')
                                                      },
                                                      onClick: (event, rowData) => {
                                                            goToCreateJobPage(rowData.jobReqRefNo)
                                                      }
                                                    }}
                                                del={{ url: CalScoApiUrls.REJECT_JOB_REQUEST}}
                                                onDeleteButtonClick={
                                                      CalScoApiUrls.REJECT_JOB_REQUEST ? performDelete : undefined
                                                }
                                                deleteButtonProps={{
                                                      icon: ({ color, ...otherProps }) => (
                                                      <FontAwesomeIcon className='reject' icon={['fa', 'xmark']} />
                                                      ),
                                                      tooltip: 'Reject',
                                                      hideIf: (rowData) => {
                                                            return (rowData.status.code !== 'PEND')
                                                      }
                                                }}
                                                deleteConfirmationDialogProps={{
                                                      dialogTitle: "Reject Job Request",
                                                      dialogContent: "Confirm Reject Job Request?",
                                                      dialogAction: (onDelete, onCancel) => {
                                                            return (
                                                                  <Box style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                                                                        <CngSecondaryButton onClick={onCancel}>
                                                                              Cancel
                                                                        </CngSecondaryButton>
                                                                        <CngPrimaryButton onClick={onDelete}>
                                                                              Reject
                                                                        </CngPrimaryButton>
                                                                  </Box>
                                                            )
                                                      }
                                                }}
                                          />
                                    )
                                    }
                              ]}  
                        />
                  </Box>
      )


}

export default TablePage