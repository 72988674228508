const FreightBookingKeys = {
  FREIGHT_BOOKING: 'freightBooking',
  FREIGHT_BOOKING_TITLE: 'freightBookingTitle',
  CREATE_NEW_BOOKING_TITLE: 'createNewBookingTitle',
  MY_BOOKING_TITLE: 'myBookingTitle',
  VIEW_ONLY_BOOKING_TITLE: 'viewOnlyBookingTitle',
  BACK_TO_VIEW_ONLY_BOOKING_PAGE: 'backToViewOnlyBookingPage',
  MANAGE_BOOKING_TITLE: 'manageBookingTitle',
  BOOK_FROM_SCHEDULE: 'bookFromScheduleTitle',
  AMEND_BOOKING_TITLE: 'stepLabelAmendBooking',
  BOOKING_DETAILS_TITLE: 'bookingDetailsTitle',

  STEP_LABEL_CREATE_BOOKING: 'stepLabelCreateBooking',
  STEP_LABEL_REVIEW_BOOKING: 'stepLabelReviewBooking',
  STEP_LABEL_BOOKING_SUBMITTED: 'stepLabelBookingSubmitted',
  STEP_LABEL_AMEND_BOOKING: 'stepLabelAmendBooking',
  STEP_LABEL_REVIEW_AMENDMENTS: 'stepLabelReviewAmendments',
  STEP_LABEL_AMENDMENTS_SUBMITTED: 'stepLabelAmendmentsSubmitted',
  STEP_LABEL_SUBMIT: 'stepLabelSubmit',

  FREIGHT_BOOKING_ID: 'freightBookingId',
  FREIGHT_BOOKING_REF_NO: 'freightBookingRefNo',
  BOOKING_ID: 'bookingId',
  LAST_MODIFIED_ON: 'lastModifiedOn',
  BOOKING_REF_NO: 'bookingRefNo',
  SHIPPER_REF_NO: 'shipperRefNo',
  CONTAINERS_TYPE: 'containersType',
  DEPARTING_ON: 'departingOn',
  CUTOFF: 'cutoff',
  SHIPPER: 'shipper',

  SAVE_DRAFT_SUCCESS: 'saveDraftSuccess',
  SUBMIT_SUCCESS: 'submitSuccess',
  COPY_SUCCESS: 'copySuccess',
  CANCEL_SUCCESS: 'cancelSuccess',
  EDIT_SUCCESS: 'editSuccess',
  DELETE_SUCCESS: 'deleteSuccess',
  ARCHIVED_SUCCESS: 'archivedSuccess',
  APPROVE_SUCCESS: 'approveSuccess',
  REJECT_SUCCESS: 'rejectSuccess',
  AMEND_SUCCESS: 'amendSuccess',
  HELPDESK_AMEND_BOOKING_STATUS_SUCCESS: 'helpdeskAmendBookingStatusSuccess',

  BOOKING_STATUS: 'bookingStatus',

  //booking type
  BOOKING_TYPE: 'bookingType',
  TYPE_OF_SHIPMENT: 'typeOfShipment',
  HAZARDOUS: 'hazardous',
  HAZARD_IDENTIFICATION: 'hazardIdentification',
  RADIOACTIVE_MEASUREMENT: 'radioactiveMeasurement',
  ACID_CONCENTRATION: 'acidConcentration',
  MARINE_POLLUTANT_STATUS: 'marinePollutantStatus',
  TREM_CARD_NUMBER: 'tremCardNo',
  REGULARTORY_INFORMATION: 'regulatoryInfo',
  RADIOACTIVE_GOODS_INFORMATION: 'radioactiveGoodsInfo',
  PLACARD_INFORMATION: 'placardInfo',
  ADDITIONAL_HAZMAT_SETTINGS: 'additionalHazmatSettings',
  POISON_INHALATION_HAZARD_ZONE: 'poisonInhalationHazardZone',
  AGGREGATION_STATE: 'aggregationState',
  LOCAL_CATEGORY: 'localCategory',
  PHYSICAL_AND_CHEMICAL_PROPERTIES: 'physicalChemicalProps',
  FLASHPOINT: 'flashpoint',
  BOILING_POINT: 'boilingPoint',
  SADT: 'sadt',
  CONTROL_TEMPERATURE: 'controlTemp',
  EMERGENCY_TEMPERATURE: 'emergencyTemp',
  DATE_OF_FUMIGATION: 'dateOfFumigation',
  FORWARD_HUB_SCHEME: 'forwardHubScheme',
  OUTBOUND_REF_NO: 'outboundRefNo',
  ADDITIONAL_BOOKING_TYPE_DETAILS: 'additionalBookingTypeDetails',

  //shedule
  SCHEDULE: 'schedule',
  CARRIER_NVOCC_BOOKING_AGENT: 'carrierNvoccBookingAgent',
  PORT_OF_LOADING: 'portOfLoading',
  PORT_OF_DISCHARGE: 'portOfDischarge',
  POL_PLANNED_ETA: 'polPlannedEta',
  POD_PLANNED_ETA: 'podPlannedEta',
  POL_PLANNED_ETD: 'polPlannedEtd',
  POD_PLANNED_ETD: 'podPlannedEtd',
  LOAD_TERMINAL: 'loadTerminal',
  DISCHARGE_TERMINAL: 'dischargeTerminal',
  VESSEL_NAME: 'vesselName',
  INTERNATIONAL_VOYAGE_NUMBER: 'internationalVoyageNumber',
  VOYAGE_NUMBER: 'voyageNumber',
  VOYAGE_NO: "voyageNo",
  ADDITIONAL_SCHEDULE_DETAILS: 'additionalScheduleDetails',
  //Container details
  CONTAINER_DETAILS: 'containerDetails',
  QUANTITY: 'quantity',
  TYPE: 'type',
  SIZE: 'size',
  CONTAINER_SIZE_TYPE: 'containerSizeType',
  HEIGHT_TYPE: 'heightType',
  STATUS: 'status',
  SHIPPER_OWNED: 'shipperOwned',
  ADD_A_CONTAINER: 'addAContainer',
  CLONE_CONTAINER: 'cloneContainer',
  OLD: 'old',
  NEW: 'new',
  DELETED: 'deleted',
  ADDED: 'added',
  REMOVED: 'removed',
  UPDATED: 'updated',
  RENAMED: 'renamed',

  //Reefer Details
  REEFER_DETAILS: 'reeferDetails',
  NON_ACTIVE_REEFER: 'nonActiveReefer',
  EQUIPMENT_TEMP: 'equipmentTemp',
  TEMP_UOM: 'tempUom',
  SPECIAL_HANDLING: 'specialHandling',
  SUPER_FREEZER_SERVICE: 'superFreezerService',
  GENSET_REQUIRED: 'gensetRequired',
  IN_TRANSIT_COLD_STERILIZATION: 'inTransitColdSterilization',
  NUMBER_OF_TEMP_PROBES: 'numberOfTempProbes',
  NUMBER_OF_USD_PROBES: 'numberOfUSDProbes',
  TEMP_VARIANCE: 'tempVariance',
  VENT_SETTING: 'ventSetting',
  EQUIPMENT_AIRFLOW: 'equipmentAirFlow',
  EQUIPMENT_AIRFLOW_UOM: 'equipmentAirFlowUom',
  EQUIPMENT_CONTROLLED_ATMOSPHERE: 'equipmentControlledAtmosphere',
  EQUIPMENT_CONTROLLED_ATMOSPHERE_CO2: 'equipmentControlledAtmosphereCO2',
  EQUIPMENT_CONTROLLED_ATMOSPHERE_N2: 'equipmentControlledAtmosphereN2',
  EQUIPMENT_CONTROLLED_ATMOSPHERE_O2: 'equipmentControlledAtmosphereO2',
  HUMIDTY: 'humidty',
  EQUIPMENT_HUMIDITY: 'equipmentHumidity',
  HUMIDITY_UOM: 'humidityUom',
  EQUIPMENT_COMMENTS: 'equipmentComments',
  ADDITIONAL_REEFER_DETAILS: 'Additional Reefer Details',
  PERCENT_OF_MOISTURE_IN_AIR: 'Percent Of Moisture In Air',

  //cargo
  CARGO: 'cargo',
  DG_CARGO: 'dgCargo',
  CARGO_ID: 'cargoId',
  GROSS: 'gross',
  VGM: 'vgm',
  NET: 'net',
  CARGO_DESC: 'cargoDesc',
  HSCODE: 'hsCode',
  PACKAGE_TYPE: 'packageType',
  CBM: 'cbm',
  KG: 'kg',
  FULL_VGM_KG: 'fullVgmKg',
  GROSS_VOLUME: 'grossVolume',
  GROSS_WEIGHT: 'grossWeight',
  DG_GROSS_WEIGHT: 'dgGrossWeight',
  NET_VOLUME: 'netVolume',
  NET_WEIGHT: 'netWeight',
  TOTAL_VGM_WEIGHT: 'totalVgmWeight',
  TOCAL_CARGO_WEIGHT: 'totalCargoWeight',
  GROSS_WEIGHT_NO_UNIT: 'grossWeightNoUnit',
  NET_WEIGHT_NO_UNIT: 'netWeightNoUnit',
  VGM_WEIGHT: 'vgmWeight',
  GROSS_VOLUME_NO_UNIT: 'grossVolumeNoUnit',
  NET_VOLUME_NO_UNIT: 'netVolumeNoUnit',
  GROSS_WEIGHT_NO_UOM: 'grossWeightNoUom',
  NET_WEIGHT_NO_UOM: 'netWeightNoUom',
  VGM_WEIGHT: 'vgmWeight',
  CHANGES_DETECTED: 'changesDetected',
  ADDITIONAL_CARGO_DETAILS: 'additionalCargoDetails',
  ADD_CARGO: 'addCargo',

  //dgcargo
  ADD_DG_DETAILS: 'addDGDetails',
  HAZARDOUS_DETAILS: 'hazardousDetails',
  MARKS_AND_NUMBERS: 'marksAndNumbers',
  TECHNICAL_NAME: 'technicalName',
  PROPER_SHIPPING_NAME: 'properShippingName',
  IMDG_CODE_PAGE: 'IMDGCodePage',
  REGULATION_PAGE_NUMBER: 'regulationPageNumber',
  REGULATION_VERSION_NUMBER: 'regulationVersionNumber',
  EMS_NUMBER: 'EMSNumber',
  UOM: 'uom',
  LIMITED_QTY: 'limitedQTY',
  EXPECTED_QTY: 'expectedQTY',
  ADDITIONAL_CLASSIDEN_DETAILS: 'additionalClassIdenDetails',
  ADDITIONAL_PACK_WEIGHT_DETAILS: 'additionalPackWeightDetails',
  ADDITIONAL_HAZARD_IDEN_DETAILS: 'additionalHazardIdenDetails',
  ADDITIONAL_PHYSICHEMI_DETAILS: 'additionalPhysiChemiDetails',
  PACKING_GROUP: 'packingGroup',
  IBC_PACKAGE_CODE: 'ibcPackageCode',
  PACKAGING_DETAILS: 'packagingDetails',
  GENERAL_COMMENTS: 'generalComments',

  RADIOACTIVE_MEASUREMENT_MBQ: 'radioactiveMeasurementMbq',

  REGULATORY_INFORMATION: 'regulatoryInformation',

  INHALANT_HAZARD: 'inhalantHazard',
  EMPTY_UNCLEANED_RECEPTACLE: 'emptyUncleanedReceptacle',
  TRANSPORT_OF_DANGEROUS_GOODS_IN_LIMITED_QUANTITIES:
    'transportOfDangerousGoodsInLimitedQuantities',

  REPORTABLE_QUANTITY: 'reportableQuantity',
  NET_EXPLOSIVE_CONTENT: 'netExplosiveContent',
  WEIGHT_KGS: 'weightKgs',
  WEIGHT_LBS: 'weightKgs',
  FUMIGATION_TYPE: 'fumigationType',
  AMOUNT_OF_FUMIGATION: 'amountOfFumigation',

  //shipment
  SHIPMENT_TTTLE: 'shipmentTitle',
  MOVE_TYPE: 'moveType',
  EMPTY_PICKUP_TITLE: 'emptyPickupTitle',
  EMPTY_PICKUP_INSTR: 'emptyPickupInstruction',
  SHIP_TO_TITLE: 'shipToTitle',
  SHIP_FROM_TITLE: 'shipFromTitle',
  PLACE_OF_RECEIPT: 'placeOfReceipt',
  PLACE_OF_DELIVERY: 'placeOfDelivery',
  CARGO_READY_DATE: 'cargoReadyDate',
  CARGO_DESTINATION_DATE: 'cargoDestinationDate',
  PLACE_OF_RECEIPT_DESC: 'placeOfReceiptDesc',
  PLACE_OF_DELIVERY_DESC: 'placeOfDeliveryDesc',
  PLACE_OF_RECEIPT_ETD: 'placeOfReceiptEtd',
  PLACE_OF_DELIVERY_ETA: 'placeOfDeliveryEta',
  DESTINATION_CODE: 'destinationCode',
  FINAL_DESTINATION: 'finalDestination',
  LADEN_GATE_IN_CUT_OFF_DATE: 'ladenGateInCutOffDate',
  SI_CUT_OFF_DATE: 'siCutOffDate',
  EVGM_CUT_OFF_DATE: 'evgmCutOffDate',
  SHIPPER_REMARKS: 'shipperRemarks',
  COMP_NAME: 'compName',
  COMPANY: 'company',
  COMP_ADDRESS: 'compAddress',
  CONTACT_NAME: 'contactName',
  CONTACT: 'contact',
  CONTACT_PHONE_NO: 'contactPhoneNo',
  EMPTY_PICKUP_DATE: 'emptyPickupDate',
  FULL_PICKUP_DATE: 'fullPickupDate',
  SHIP_FROM_DATE_PICKUP: 'shipFromDatePickup',
  SHIP_FROM_DATE_POSITIONING: 'shipFromDatePositioning',
  SHIP_TO_DATE_DELIVERY: 'shipToDateDelivery',
  ADDITIONAL_SHIPMENT_DETAILS: 'addtionalShipmentDetails',
  PORETD_AND_PODETA_WARNING_MESSAGE: 'PoRETDAndPoDETAWarningMessage',
  PORETD_WARNING_MESSAGE: 'PoRETDWarningMessage',
  PODETA_WARNING_MESSAGE: 'PoDETAWarningMessage',

  //document
  DOCUMENT: 'document',
  DOCUMENTS: 'documents',
  ATTACH_DOCUMENT: 'attachDocument',
  DOCUMENT_INSTRUCTION: 'documentInstruction',
  FILE_TYPE: 'fileType',
  FILE_DESC: 'fileDesc',
  FILE_NAME: 'fileName',

  //contact details
  CONTACT_DETAILS: 'contactDetails',
  ADDITIONAL_CONTACT_DETAILS: 'additionalContactDetails',
  REPRESENTATIVE_NAME: 'representativeName',
  REPRESENTATIVE_EMAIL: 'representativeEmail',
  REPRESENTATIVE_CONTACT_NUMBER: 'representativeContactNumber',
  REFERENCES: 'references',
  CONTRACT_NUMBER: 'contractNumber',
  PURCHASE_ORDER_NUMBER: 'purchaseOrderNumber',
  SHIPPER_REF_NUMBER: 'shipperRefNumber',
  FORWARDER_REF_NUMBER: 'forwarderRefNumber',
  PRE_ASSIGNMENT_BOOKING_NUMBER: 'preAssignmentBookingNumber',
  PARTIES: 'parties',
  CONTRACT_PARTY_NAME: 'contractPartyName',
  MAIN_NOTIFY_PARTY: 'mainNotifyParty',
  SHIPPER_NAME: 'shipperName',
  CONSIGNEE_NAME: 'consigneeName',
  FORWARDER_NAME: 'forwarderName',
  PRE_SHIPMENT_NOTIFICATION_CONTACTS: 'preShipmentNotificationContacts',
  ADD_CONTACT_DETAILS: 'addContactDetails',
  PARTNER_EMAIL_NTF: 'partnerEmailNtf',
  EMAIL_TO_NOTIFY: 'emailToNotify',
  NOTIFY_BOOKING_STATUS: 'notifyBookingStatus',
  NOTIFY_EVENT_MILESTONES: 'notifyEventMilestones',
  PARTNER_EMAIL_CAPTION: 'partnerEmailCaption',

  //payment instruction
  PAYMENT_INSTRUCTION: 'paymentInstruction',
  CHARGE_TYPE: 'chargeType',
  PAYER: 'payer',
  FREIGHT_TERM: 'freightTerm',
  PMT_LOC: 'pmtLoc',
  ADD_PAYMENT_INSTRUCTION: 'addPaymentInstruction',
  PAYMENT_LOCATION: 'paymentLocation',
  //Dialog
  DIALOG_DISCARD_BOOKING_TITLE: 'dialogDiscardBookingTitle',
  DIALOG_DISCARD_BOOKING_CONTENT: 'dialogDiscardBookingContent',
  DIALOG_CANCEL_BOOKING_TITLE: 'dialogCancelBookingTitle',
  DIALOG_DELETE_BOOKING_TITLE: 'dialogDeleteBookingTitlet',
  DIALOG_ARCHIVE_BOOKING_TITLE: 'dialogArhiveBookingTitle',
  DIALOG_CHANGE_STATUS_GENERAL_CONENT: 'dialogChangeStatusGeneralContent',
  DIALOG_SWITCH_DG_CONTENT: 'dialogSwitchDGContent',
  FORM_ERROR_WARNING: 'formErrorWarning',

  //Review Booking
  REVIEW_BOOKING: 'reviewBooking',
  CONTAINERS: 'containers',
  CONTAINER: 'container',
  //Booking Status Header
  BOOKING_SUBMITTED: 'bookingSubmitted',
  BOOKING_APPROVED: 'bookingApproved',
  BOOKING_CLOSED: 'bookingClosed',
  BOOKING_CANCELLED: 'bookingCancelled',
  BOOKING_AMENDED: 'bookingAmended',
  BOOKING_REJECTED: 'bookingRejected',
  BOOKING_SUBMITTED_ON: 'bookingSubmittedOn',
  BOOKING_APPROVED_ON: 'bookingApprovedOn',

  //My Booking
  MY_BOOKING: 'myBooking',
  BOOKING_FILTER_DATE: 'date',
  LAST_MODIFIED_DATE: 'lastModifiedDate',
  BOOKING_FILTER_ALL: 'All',
  BOOKING_FILTER_DATE_15: 'Past15Days',
  BOOKING_FILTER_DATE_30: 'Past30Days',
  BOOKING_FILTER_DATE_60: 'Past60Days',

  //Manage Booking
  PENDING_APPROVAL: 'pendingApproval',
  PROCESSED: 'processed',
  SHOWN: 'shown',
  SUBMITTED_INFO: 'submittedInfo',
  APPROVED_INFO: 'approvedInfo',
  REJECTED_INFO: 'rejectedInfo',
  AMENDED_INFO: 'amendedInfo',
  CANCELLED_INFO: 'cancelledInfo',
  CLOSED_INFO: 'closedInfo',

  SHIPPER_COMMENTS: 'shipperComments',
  APPROVAL: 'approval',
  CARRIER_REMARKS: 'carrierRemarks',
  BOOKING_REFERENCE_NUMBER: 'bookingRefenceNumber',
  APPROVE_BOOKING: 'approveBooking',
  DECLINE_BOOKING: 'declineBooking',

  END: 'end',
  CARRIER_COMMENTS: 'carrierComments',
  CLASSIFICATION_IDENTICATION: 'classificationIdentication',
  IMO_CLASS: 'imoClass',
  UNDG_NUMBER: 'undgNumber',
  SHIPPING_NAME: 'shippingName',
  PACKAGING_WEIGHT: 'packagingWeight',
  OUTER_PKG_QTY: 'outerPkgQTY',
  INNER_PKG_QTY: 'innerPkgQTY',
  FOR_EMERGENCY: 'forEmergency',
  EMERGENCY_CONTACT: 'emergencyContact',
  EMERGENCY_CONTACT_NUMBER: 'emergencyContactNumber',
  EMPTY_PICKUP: 'emptyPickup',
  EMPTY_PICKUP_DATETIME: 'emptyPickupDateTime',
  FULL_PICKUP_DATETIME: 'fullPickupDateTime',

  REPORTABLE_QTY: 'reportableQty',
  TRANSPORT_DG_LIMIT_QUANT: 'transportDgLimitQuant',
  EMPTY_UNCLEANED_RECEPTABLE: 'emptyUncleanedReceptable',
  EXCEPTED_QTY: 'exceptedQty',
  PACKING_DETAILS: 'packingDetails',

  BOOKING_CREATED_ON: 'bookingCreatedOn',
  BOOKING_CONFIRMED_ON: 'bookingConfirmedOn',
  BOOKING_REQUESTED_ON: 'bookingRequestedOn',

  NEW_VESSEL_VOYAGE: 'newVesselVoyage',
  VESSEL_VOYAGE: 'vesselVoyage',
  VESSEL_SEARCH: 'vesselSearch',

  NOTHING_HERE_YET: 'nothingHereYet',
  NO_ITEM_HERE_YET: 'noItemHereYet',
  NO_ITEM_DESC_SHIPPER: 'noItemDescShipper',
  NO_ITEM_DESC_CARRIER: 'noItemDescCarrier',
  SMTH_WRONG: 'smthWrong',
  SMTH_WRONG_DESC: 'smthWrongDesc',
  REFRESH: 'refresh',
  CREATE_A_NEW_BOOKING: 'createANewBooking',
  SHOW_SCHEDULE_DETAILS: 'showScheduleDetails',
  HIDE_SCHEDULE_DETAILS: 'hideScheduleDetails',
  SHOW_SHIPMENT_DETAILS: 'showShipmentDetails',
  HIDE_SHIPMENT_DETAILS: 'hideShipmentDetails',
  SHOW_CONTAINER_DETAILS: 'showContainerDetails',
  HIDE_CONTAINER_DETAILS: 'hideContainerDetails',
  SHOW_REEFER_DETAILS: 'showReeferDetails',
  HIDE_REEFER_DETAILS: 'hideReeferDetails',
  SHOW_CARGO_DETAILS: 'showCargoDetails',
  HIDE_CARGO_DETAILS: 'hideCargoDetails',
  SHOW_DG_CARGO_DETAILS: 'showDgCargoDetails',
  HIDE_DG_CARGO_DETAILS: 'hideDgCargoDetails',
  SHOW_CONTACT_DETAILS: 'showContactDetails',
  HIDE_CONTACT_DETAILS: 'hideContactDetails',
  SHOW_PAYMENT_DETAILS: 'showPaymentDetails',
  HIDE_PAYMENT_DETAILS: 'hidePaymentDetails',
  DETAILS_DG: 'dgDetails',
  SHOW_DETAILS_DG: 'showDgDetails',
  HIDE_DETAILS_DG: 'hideDgDetails',
  CARRIER: 'carrier',
  SHOW_DOCUMENT_DETAILS: 'showDocumentDetails',
  HIDE_DOCUMENT_DETAILS: 'hideDocumentDetails',

  ARCHIVED: 'archived',
  BOOKING: 'booking'
}
export default FreightBookingKeys
