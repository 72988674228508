import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { components, constants, useServices } from 'cng-web-lib'
import TradeTrustTranslationText from 'src/views/tradetrust/TradeTrustTranslationText'
import CodeMaintainanceApiUrls from "../../../apiUrls/CodeMaintainanceApiUrls";
import { useFormContext } from 'react-hook-form'

const {
  dropzone: { CngFileUpload, HelperTextArea },
  form: {
    field: { CngSelectField },
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngGridItem
} = components
const { NotificationType, CodeMaintenanceType, } = constants

const DEFAULT_INITIAL_VALUES = {
  fileType: ''
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

//* Fields function ------------------------------------------------------------
function Fields({ networkOption, setNetworkOption, ...props }) {
  const { disabled, showNotification } = props
  const { setValue } = useFormContext()
  const { fetchRecords } = useServices()

  useEffect(() => {

    const onSuccess = (response) => {
      let options = response.content.map((record) => ({
        text: record['descriptionEn'],
        value: record["code"]
      }));
      setNetworkOption(options);
    }

    const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

    const onComplete = () => { console.log("On Complete") }

    fetchRecords.execute(
      CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
      {
        customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
        filters: [
          {
            field: 'codeType',
            operator: 'EQUAL',
            value: 'TT_NETWORK_TYPE'
          }
        ]
      },
      onSuccess,
      onError,
      onComplete
    )

  }, []);


  const fieldsTranslatedTextObject = TradeTrustTranslationText()

  const moreActions = [
    {
      action: 'remove',
      name: 'Remove',
      icon: ['fal', 'trash']
    }
  ]

  return (
    <>
      <Grid container spacing={3}>
        <CngGridItem xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <AccordionHeaderComponent
                  title={fieldsTranslatedTextObject.moduleTitle}
                />
                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={4}>
                    <CngSelectField
                      disabled={disabled}
                      name='networkType'
                      label={fieldsTranslatedTextObject.networkType}
                      required
                      items={networkOption}
                      defaultValue={['mainnet']}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={4} shouldHide={true}></CngGridItem>
                  <CngGridItem xs={12} sm={4}></CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <CngFileUpload
                      accept={['.tt']}
                      maxFiles={1}
                      maxSize={10485760}
                      onFileSelect={(file) => {
                        setValue('file', file)
                      }}
                      //files={props.UploadFile}
                      //showFormFields={false}
                      moreActions={moreActions}
                      onDropRejected={(errorMessages) => {
                        showNotification(
                          NotificationType.ERROR,
                          errorMessages[0]
                        )
                      }}
                      renderHelperText={() => {
                        return (
                          <HelperTextArea
                            accept={['.tt']}
                            maxFiles={1}
                            maxSize={10485760}
                          />
                        )
                      }}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DocumentComponent = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default DocumentComponent
