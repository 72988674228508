import React, { useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import EditJobFormProperties from './EditJobFormProperties'
import JobValidationSchema from './JobValidationSchema'
import SCOJobApiurls from 'src/apiUrls/SCOJobApiUrls'
import pathMap from 'src/paths/PathMap_SCO_Job'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const { updateRecord, createRecord, securedSendRequest, fetchRecords } = useServices()
  const validationSchema = JobValidationSchema()
  const [cloneData, setCloneData] = useState([])
  const [isClone, setIsClone] = useState(false)
  const [hasCloned, setHasCloned] = useState(false)
  const [oldJobId, setOldJobId] = useState("")
  const [newJobId, setNewJobId] = useState("")
  const [clonedOutboundForm, setClonedOutboundForm] = useState([])

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const reqData = (jobId) => ({
    "baseFilterDTO": {
      "filterType": "AND",
      "filterProperties": [
        {
          "fieldName": "id",
          "operatorType": "EQUAL",
          "value1": jobId.toString()
        }
      ],
      "sortProperties": []
    }
  })
  
  useEffect(() => {
    if (isClone && !hasCloned) {
      const onSuccess = (data) => {
        console.log("Clone :: old job ", data)
        const list = data.data.content[0]
        const joblist = data.data.content[0]?.jobTasksList
        checkOutboundFormExist(list.id)
        .then(result => {
          setClonedOutboundForm(result)
          joblist.forEach(item => {
            item.timezoneId = null
            item.localEventTimestamp = null
            item.id= null
            item.jobId= null
            if (item.movementType?.code !== 'OUT' || result === null) {
              item.taskStatus = 'NOTSTARTED'
              item.taskStatusId = 49
            } else if (item.movementType?.code === 'OUT' && result !== null) {
              item.taskStatus = 'PROGRESS'
              item.taskStatusId = 42
            }
          })
          list.jobTasksList = joblist
          list.createdDate = ""
          list.id = ""
          list.jobRefNo = ""
          list.jobStatus = ""
          list.jobStatusId = ""
          setHasCloned(true)
          createNewJob(list)
        })
        .catch(error => {
          console.error(error)
        })
      }
      const onError = (data) => {
        console.log("Clone :: old job error ", data)
      }
      securedSendRequest.execute(
        'post',
        SCOJobApiurls.GETMYJOB,
        reqData(oldJobId),
        onSuccess,
        onError
      )
    }
  }, [isClone, hasCloned])

  function checkOutboundFormExist(jobId) {
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => {
        console.log("Clone :: check outbound", response)
        if (response?.id === null) {
          resolve(null)
        } else {
          resolve(response)
        }
      }
      const onError = (error) => {
        console.log("Clone :: check outbound error " + JSON.stringify(error))
        reject(error)
      }
      fetchRecords.execute(
        SCOJobApiUrls.ORDER_FORM_GET_BY_JOB_ID,
        {
          filters: [
            {
              field: 'jobId',
              operator: 'EQUAL',
              value: jobId
            }
          ]
        },
        onSuccess,
        onError
      )
    })
  }

  function createNewJob(data) {
    setLoading(true)
    const jobObj = [{...data, jobTaskItems: data.jobTasksList, jobAttributeList: data.jobAttributes}]
    delete jobObj[0].jobAttributes

    const mergedJobObj = Object.assign({}, ...jobObj)

    function onSuccess(response) {
      console.log('Clone :: create new job ', response)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
        setLoading(false)
      } else {
        showSuccessNotification(response.jobRefNo + ' created successfully.')
        setNewJobId(response.id)
        getNewJobObj(response.id)
        setLoading(false)
        const path = pathMap.SCO_JOB_DETAILS_EDIT_VIEW.replace(':id', response.id)
        history.push(
          path, {
          state: {
            jobReqRefNo: response.jobRefNo,
            jobTasksList: mergedJobObj.jobTasksList
          }
        })
        
      }
    }

    function onError(error) {
      setLoading(false)
      console.log('Clone :: create new job error ', error)
      showErrorNotification(error.message)
    }

    createRecord.execute(
      SCOJobApiurls.HDR_CREATE_JOB,
      mergedJobObj,
      onSuccess,
      onError
    )

  }

  function getNewJobObj(id) {
    const onSuccess = (data) => {
      setCloneData(data.data.content[0])
    }
    const onError = (error) => {
      console.log("Clone :: get new job error", error)
    }
    securedSendRequest.execute(
      'post',
      SCOJobApiurls.GETMYJOB,
      reqData(id),
      onSuccess,
      onError
    )
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function uploadDocumentAndSaveDraft(data) {
    var formData = new FormData();
    data.pendingJobDocuments.map((file, i) => {
      var fileBlob = file.file
      formData.append("fileContents", fileBlob)
      return null
    })


    function onSuccess(response) {
      if (response.length > 0 && response.errorMessages != null) {
        console.log('error >> ')
        showErrorNotification(response.errorMessages)
      } else {
        let jobDocuments = data.jobDocuments || []
        if (response && response.length > 0) {
          console.log('this is response >>>> ' + JSON.stringify(response))
          response.map((file, i) => {
            let jobDocu = {
              docType: data.pendingJobDocuments[i].fields[0].value,
              fileName: file.fileName,
              fileDescription: data.pendingJobDocuments[i].fields[1].value,
              id: file.id,
              fileSize: file.fileSize
            }
            jobDocuments.push(jobDocu)
          })
        }
        data.jobDocs = jobDocuments;


        if (data.isDraft === true) {
          console.log('save draft')
          saveDraft(data)
        } else if (data.isDraft === false) {
          console.log('save job')
          updateJob(data)
        }
      }
    }

    function onError(error) {
      showErrorNotification(error.message)
    }

    createRecord.execute(
      SCOJobApiurls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }


  function saveDraft(data) {
    setLoading(true)


    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + " save succesfully.")
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_UPDATE_JOB,
      data,
      onSuccess,
      onError
    )
  }

  function updateJob(data) {
    setLoading(true)
    console.log('Updating job >>>>> ' + JSON.stringify(data))

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + ' updated successfully.')
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_UPDATE_JOB,
      data,
      onSuccess,
      onError
    )
  }


  function onError(error) {
    console.log('connection-request error', error.response.data.errorMessages)
    setLoading(false)
    showErrorNotification(error.response.data.errorMessages)
  }

  function cancelJob(data) {
    setLoading(true)
    console.log('Cancelling job >>>>> ' + JSON.stringify(data))

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(response.jobRefNo + ' Cancel Job successfully.')
        history.push({
          pathname: '/cal-sco/job'
        })
      }
    }

    updateRecord.execute(
      SCOJobApiurls.HDR_CANCEL_JOB,
      data,
      onSuccess,
      onError
    )
  }


  function onSubmit(data) {
    setLoading(true)
    console.log('this is data ' + JSON.stringify(data))
    if (data.pendingJobDocuments && !isEmpty(data.pendingJobDocuments)) {
      uploadDocumentAndSaveDraft(data)
    } else {
      if (data.isDraft === true) {
        saveDraft(data)
      } else if (data.isDraft === false && data.cancelJob === false) {
        console.log("Sending Update Job >>> to BE")
        updateJob(data)
      } else if (data.isDraft === false && data.cancelJob === true) {
        //this works here
        console.log("Sending Cancel Job >>> to BE")
        cancelJob(data)

      }
    }


  }


  return (

    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <EditJobFormProperties.FormFields
          history={history}
          showNotification={showNotification}
          loading={loading}
          id={!isClone?id:newJobId}
          newJobId={newJobId}
          data={cloneData}
          isClone={isClone}
          setIsClone={setIsClone}
          setOldJobId={setOldJobId}
          clonedOutboundForm={clonedOutboundForm}
        />
      }
      formikProps={{
        ...EditJobFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit,
      }}
      fetch={{
        id: id,
        url: SCOJobApiUrls.GETMYJOB
      }}
    />

  )

}

export default EditPage
