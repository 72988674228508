import React from 'react'
import ValidationSchema from './ValidationSchema'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import CertificateOfOriginComponent from '../components/CertificateOfOriginComponent'
import { useFormContext } from 'react-hook-form'
import EntityInformationDetails from '../components/EntityInformationDetails'
import CertificateItemsDetails from '../components/CertificateItemsDetails'
import BlanketPeriodComponent from '../components/BlanketPeriodComponent'
import SignatureComponent from '../components/SignatureComponent'
import ViewDetailedPanel from '../components/ViewDetailedPanel'
import GeneratedCertificateViewComponent from '../components/view/GeneratedCertificateViewComponent'
import StepperButtonSection from '../components/StepperButtonSection'
import html2pdf from 'html2pdf.js'
import { convertDataToAPIFromFormData } from '../util/Utils'

const {
  table: { useDefaultNotification },
} = components

const {
  CngGridItem
} = components


function Fields({ ...props }) {

  const {
    activeStep,
    actionType,
    showNotification,
    steps,
    handleNext,
    handleBack,
    principalId,
    selfCertifyIds,
    certificateId,
    ftaCode,
    ftaName,
    certifierInformation,
    signatureDetails,
    skuMaterials,
    signatureImage,
    setSignatureImage,
    displayDate,
    displayTime
  } = props
  const { formState, getValues } = useFormContext()

  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification);


  const prepareSelfCertificatePayload = () => {
    const formData = getValues()

    return convertDataToAPIFromFormData(formData, ftaCode,
      ftaName, certificateId, principalId,
      selfCertifyIds, skuMaterials)
  }


  const downloadPDFOnClick = () => {

    let element = document.getElementById('certificateToPrint');

    let opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: `${certificateId}.pdf`,
      image: {
        type: 'jpeg',
        quality: 1
      },
      html2canvas: {
        scale: 2,
        imageTimeout: 0,
        useCORS: true
      },
      jsPDF: {
        unit: 'in',
        format: 'a2',
        orientation: 'portrait',
        precision: 6
      }
    };

    html2pdf().set(opt).from(element).save()
  }



  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12}>
        </Grid>
        <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none') }}>
          <CertificateOfOriginComponent
            ftaCode={ftaCode}
            ftaName={ftaName}
            certificateId={certificateId}
          />
        </CngGridItem>
        <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none'), paddingTop: '10px' }}>
          <EntityInformationDetails
            certifierInformation={certifierInformation}
          />
        </CngGridItem>

        <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none'), paddingTop: '10px' }}>
          <CertificateItemsDetails
            skuMaterialInfo={skuMaterials}
          />
        </CngGridItem>

        <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none'), paddingTop: '10px' }}>
          <BlanketPeriodComponent />
        </CngGridItem>

        <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none'), paddingTop: '10px' }}>
          <SignatureComponent
            showNotification={showNotification}
            signatureDetails={signatureDetails}
            principalProfileId={principalId}
            signatureImage={signatureImage}
            setSignatureImage={setSignatureImage}
          />
        </CngGridItem>

        <CngGridItem xs={12} style={{ display: (activeStep === 1 ? 'block' : 'none'), paddingTop: '10px' }}>
          <ViewDetailedPanel
            viewPanelSelfCertificate={prepareSelfCertificatePayload()}
            signatureImage={signatureImage}
          />
        </CngGridItem>

        <CngGridItem xs={12} style={{ display: (activeStep === 2 ? 'block' : 'none'), paddingTop: '10px' }}>
          <div id={'certificateToPrint'} >
            <GeneratedCertificateViewComponent
              selfCertificatePayload={prepareSelfCertificatePayload()}
              signatureImage={signatureImage}
              displayDate={displayDate}
              displayTime={displayTime}
            />
          </div>
        </CngGridItem>

        <CngGridItem xs={12}>
          <StepperButtonSection
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
            actionType={actionType}
            downloadPDFOnClick={downloadPDFOnClick}
            showErrorNotification={showErrorNotification}
            signatureImage={signatureImage}
            formState={formState} />
        </CngGridItem>
      </Grid>
    </>
  )
}




const DEFAULT_INITIAL_VALUES = {
  selfCertificateId: '',
  ftaCode: '',
  ftaName: '',
  certificateId: '',
  certifierType: '',
  certifierName: '',
  certifierJobTitle: '',
  certifierEmailAddress: '',
  certifierContactNo: '',
  certifierAddress: '',
  exporterName: '',
  exporterJobTitle: '',
  exporterEmailAddress: '',
  exporterContactNo: '',
  exporterAddress: '',
  producerName: '',
  producerJobTitle: '',
  producerEmailAddress: '',
  producerContactNo: '',
  producerAddress: '',
  isProducerConfidentiality: false,
  producerSameAsIsTrue: false,
  producerSameAsCertifier: false,
  producerSameAsExporter: false,
  importerName: '',
  importerJobTitle: '',
  importerEmailAddress: '',
  importerContactNo: '',
  importerAddress: '',
  importerUnknownImporter: false,
  importerVariousImporter: false,
  importerSameAsCertifier: false,
  skuAndMaterials: [],
  blanketStartDate: '',
  blanketEndDate: '',
  signatureId: '',
  signatureImage: '',
  signerPrintedName: '',
  signerCertificationDate: ''
}


const hookFormProps = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}


const FormProperties = Object.freeze({
  hookFormProps: hookFormProps, Fields: Fields
})


export default FormProperties