import React from "react";
import { ExternalLinkEtherscanAddress } from "../../ExternalLink";
import AssetTitle from "../../AssetTitle";
import { SkeletonPlaceholder } from "../../SkeletonPlaceholder";
import { Typography, Box, makeStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputComponent from 'src/components/input/input'

export const EditableAssetTitle = ({
  role,
  value,
  newValue,
  isEditable,
  onSetNewValue,
  isError: error,
}) => {

  const useStyles = makeStyles({
    myAlert: {
      backgroundColor: '#ECECF2'
    }
  });
  const classes = useStyles();

  if (!value) return <SkeletonPlaceholder />;
  if (!isEditable)
    return (
      <div className="flex items-start">
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          <AssetTitle role={role} address={value}>
            <ExternalLinkEtherscanAddress
              name={value}
              address={value}
              data-testid={`non-editable-input-${role.toLowerCase()}`}
            />
          </AssetTitle>
        </Typography>
        {error && (
          <Box>
            <Alert
              severity='error'
              icon={<FontAwesomeIcon icon={['fal', 'exclamation-circle']} color='#8996AF' />}
              className={classes.myAlert}
            >
              The transaction was aborted.
            </Alert>
          </Box>
        )}
      </div>
    );
  return (
    <AssetTitle role={role} address={newValue || ""}>
      <div className="flex items-start">
        <InputComponent
          type='text'
          value={newValue}
          label={`Input ${role}'s address`}
          style={{ width: '100%' }}
          onChange={(event) => {
            if (!onSetNewValue) return;
            onSetNewValue(event.target.value);
          }}
          error={error}
        />
        {error && (
          <Box>
            <br />
            <Alert
              severity='error'
              icon={<FontAwesomeIcon icon={['fal', 'exclamation-circle']} color='#8996AF' />}
              className={classes.myAlert}
            >
              The transaction was aborted.
            </Alert>
          </Box>
        )}
      </div>
    </AssetTitle >
  );
};

export default EditableAssetTitle;