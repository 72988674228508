import { useState, useEffect } from "react";
import { TradeTrustToken__factory } from "@govtechsg/token-registry/contracts";

export const useTokenRegistryContract = (address, provider) => {
    const [tokenRegistry, setTokenRegistry] = useState();

    useEffect(() => {
        if (!address || !provider) return;
        const instance = TradeTrustToken__factory.connect(address, provider);
        setTokenRegistry(instance);
        return () => {
            setTokenRegistry(undefined);
        };
    }, [address, provider]);

    return { tokenRegistry };
};
