import CommonCityMappingRoute from './CommonCityMappingRoute'
import ConnectorAppConfigRoute from './ConnectorAppConfigRoute'
import CecUomConversionRoute from './CecUomConversionRoute'
import CecDoUomMappingRoute from './CecDoUomMappingRoute'
import CecPostcodeConfigRoute from './CecPostcodeConfigRoute'
import ConnectorMappingRoute from './ConnectorMappingRoute'
import ShipmentProviderRoute from './ShipmentProviderRoute'
import ShipmentProviderConnectorMappingRoute from './ShipmentProviderConnectorMappingRouter'
import PartyConfigRoute from './PartyConfigRoute'
import ReportRoute from "./ReportRoute";
import QuotationRoute from "./QuotationRoute"

const routes = [
    new CommonCityMappingRoute(),
    new ConnectorAppConfigRoute(),
    new ConnectorMappingRoute(),
    new CommonCityMappingRoute(),
    new CecUomConversionRoute(),
    new CecDoUomMappingRoute(),
    new CecPostcodeConfigRoute(),
    new ShipmentProviderRoute(),
    new ShipmentProviderConnectorMappingRoute(),
    new PartyConfigRoute(),
    new ReportRoute(),
    new QuotationRoute()
]

export default routes
