const MGTT = {
    // General
    MODULE_TITLE: "Transshipment Management",
    DRIVER_LIST: "Driver List",
    CONTAINER_LIST: "Container List",
    TRANSPORTER_LIST: "Transporter List",
    SELECTION: "Selection",
    CONTAINER_NO: "Container No.",
    CONTAINER_NUM: "Container Number",
    CONTAINER_NO_OPERATOR: "Unit",
    PSA_ID: "PSA ID",
    CONT_CREATED_DATE: "Container Creation Date",
    CONT_UPDATED_DATE: "Container Modified On",
    PSA_APPROVAL: "PSA Approval",
    ORIGIN: "Origin",
    TT_NO: "TT No.",
    DRIVER_NAME: "Driver Name",
    CREATE_DATE: "Creation Date",
    STATUSES: "Statuses",
    DOWNLOAD_EIR: "Download EIR",
    PRINT_TT: "Print TT",
    EDIT_SENT: "Edit/Sent",
    PRIORITY: "Priority",
    CONTAINER_SIZE: "Container Size",
    LICENSE_PLATE: "License Plate",
    IN_YARD: "In Yard",
    YARD: "Yard",
    ON_HOLD: "On Hold",
    PLATE_NUMBER: "Plate Number",
    DRIVER_ID: "Driver ID",
    TT: "TT",
    DESTINATION: "Destination",
    TO: "To",
    ISO: "ISO",
    TEMPERATURE: "Temperature (in C)",
    TEMP_OPERATOR: "Temperature",
    WEIGHT: "Total Weight (in KG)",
    WEIGHT_OPERATOR: "Weight",
    YARD_AREA: "Yard Area",
    TT_CREATED_DATE: "TT Creation Date",
    TT_UPDATED_DATE: "TT Modified Date",
    ORIGINATING_VESSEL: "Originating Vessel",
    CONTAINER_TYPE: "Container Type",
    IMO_NO: "IMO No.",
    CARGO_UN_CODE: "Cargo UN Code",
    NO_OF_SHIFTING: "Number of Shifting",
    CATEGORY: "Transshipment Category",
    EIR: "EIR",
    ACHECK: "ACheck"
}
export default MGTT