import { Card, CardContent } from '@material-ui/core'
import { useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import TranslationText from './TranslationText'
import TransshipmentApiUrls from 'src/apiUrls/TransshipmentApiUrls'
import MGTTTableView from './MGTTTableView'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'


function OperatorPage(props) {
	const {
		showNotification
	} = props

	const { fetchRecords } = useServices()
	const translatedTextObject = TranslationText()
	const [checkedRows, setCheckedRows] = useState([])
	const [archivedCheckedRows, setArchivedCheckedRows] = useState([])
	const [data, setData] = useState([])
	const [searchData, setSearchData] = useState([])
	const [searchArchivedData, setSearchArchivedData] = useState([])
	const [loading, setLoading] = useState(true)
	const [refresh, setRefresh] = useState(false)
	const [showArchivedField, setShowArchivedField] = useState(false)
	const [archivedData, setArchivedData] = useState([])
	const [emptyData, setEmptyData] = useState(false)
	const [emptyArchivedData, setEmptyArchivedData] = useState(false)
	const [columns, setColumns] = useState([
		{
			field: "containerNo",
			title: translatedTextObject.containerNoOperator,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "licensePlate",
			title: translatedTextObject.plateNumber,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "driverId",
			title: translatedTextObject.driverId,
			show: true,
			active: true,
			archived: false,
			order: 'asc',
			value: ''
		},
		{
			field: "sigaDocId",
			title: translatedTextObject.tt,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "priority",
			title: translatedTextObject.priority,
			show: true,
			active: true,
			archived: false,
			order: 'asc',
			value: ''
		},
		{
			field: "noOfShifting",
			title: translatedTextObject.noOfShifting,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "destination",
			title: translatedTextObject.to,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "containerType",
			title: translatedTextObject.iso,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "yardArea",
			title: translatedTextObject.yardArea,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "temperature",
			title: translatedTextObject.tempOperator,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "weight",
			title: translatedTextObject.weightOperator,
			show: true,
			active: false,
			archived: false,
			value: ''
		}
	])

	useEffect(() => {
		let newArchivedData = []
		let newData = []
		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT, false,
			(data) => {
				setLoading(false)
				data.map(d => {
					if (d.sigaDocId === null) {
						d.sigaDocId = 'Not Found'
					}
					if (d.archived) {
						newArchivedData.push(d)
					} else {
						d.checked = false
						newData.push(d)
					}
				})
				sortData(newData)
				setArchivedData(newArchivedData)
			}
		)
	}, [refresh])

	const sortData = (newData) => {
		setLoading(true)
		const sortedData = newData.slice().sort(
			comparator("driverId", "priority", columns.find((column) => column.field === "driverId").order === 'desc', columns.find((column) => column.field === "priority").order === 'desc')
		).map((v, index) => ({
			...v,
			seqNo: index + 1
		}))

		setData(sortedData)
		setLoading(false)
	}

	const comparator = (fieldName, secFieldName, isDesc, secIsDec) => (a, b) => {
		const valueA = a[fieldName]
		const valueB = b[fieldName]

		const valueC = a[secFieldName]
		const valueD = b[secFieldName]

		if (valueC === null && valueD === null) {
			return 0
		}
		if (valueC === null) {
			return secIsDec ? -1 : 1
		}
		if (valueD === null) {
			return secIsDec ? 1 : -1
		}

		if (valueC < valueD) {
			return secIsDec ? 1 : -1
		}
		if (valueC > valueD) {
			return secIsDec ? -1 : 1
		}

		if (valueA === null && valueB === null) {
			return 0
		}
		if (valueA === null) {
			return isDesc ? 1 : -1
		}
		if (valueB === null) {
			return isDesc ? -1 : 1
		}

		if (valueA < valueB) {
			return isDesc ? 1 : -1
		}
		if (valueA > valueB) {
			return isDesc ? -1 : 1
		}
		return 0
	}

	const manualRefresh = () => {
		setRefresh(!refresh)
		setLoading(true)
		let newArchivedData = []
		let newData = []

		const onSuccess = (data) => {
			data.map(d => {
				if (d.archived) {
					newArchivedData.push(d)
				} else {
					newData.push(d)
				}
			})

			columns.map(column => {
				if (column.value != '') {
					newData = newData.filter(d => (d[column.field] != null ? d[column.field].toString().toLowerCase().includes(column.value.toLowerCase()) : null))
				}
			})
			if (searchData.length != 0) {
				setSearchData(newData)
			} else {
				setData(newData)
			}
			if (searchArchivedData.length != 0) {
				setSearchArchivedData(newArchivedData)
			} else {
				setArchivedData(newArchivedData)
			}

			setEmptyData(false)
			setEmptyArchivedData(false)
			setLoading(false)
		}

		const onError = (error) => {
			console.log('connection-request error', error.message)
			setLoading(false)
		}

		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT,
			false,
			onSuccess,
			onError
		)
	}

	return (
		<Card>
			<CardContent>
				<CngBackdrop loading={loading} />

				{!showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={data}
					columns={columns}
					archivedButtonText={'Archive'}
					viewOnly={true}
					setColumns={setColumns}
					setData={setData}
					setCheckedRows={setCheckedRows}
					checkedRows={checkedRows}
					emptyData={emptyData}
					setEmptyData={setEmptyData}
					searchData={searchData}
					setSearchData={setSearchData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
				/>
				}
				{showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={archivedData}
					columns={columns}
					archivedButtonText={'Unarchive'}
					viewOnly={true}
					setColumns={setColumns}
					setData={setArchivedData}
					setCheckedRows={setArchivedCheckedRows}
					checkedRows={archivedCheckedRows}
					emptyData={emptyArchivedData}
					setEmptyData={setEmptyArchivedData}
					searchData={searchArchivedData}
					setSearchData={setSearchArchivedData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
				/>
				}
			</CardContent>
		</Card >
	)
}

export default OperatorPage