import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import {Button, Card, CardContent, Grid} from '@material-ui/core'
import ReportSchedulerKeys from 'src/constants/locale/key/ReportSchedulerKeys';
import {components, constants, DataFlattener, useServices, useTranslation} from 'cng-web-lib'
import SelectComponent from './SelectComponent'
import CommonUtils from 'src/views/common/utils/Utils';
import moment from 'moment';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import ReportManagementApiUrls from "../../../apiUrls/ReportManagementApiUrls";
import DynamicFieldsHolder from "../scheduler-report-admin/DynamicFieldsHolder";
import ReportDownloadApiUrls from "../../../apiUrls/ReportDownloadApiUrls";
import {frequencyDataOptions, frequencyOptions, hourlyOptions, timezoneOptions} from "../scheduler-report-admin/FormProperties";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    btnCustom: {
        bottom: "-3.3rem",
        left: "6rem",
        zIndex: "10",
        width: "100px",
        height: "40px",
        position: "absolute",
        textTransform: 'capitalize'
    }
}));

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngSwitchField
        },
    },
    table: {
        useDefaultNotification
    },
    CngGridItem,
} = components


// Date Time
const SG_DATE_FORMAT = CommonUtils.FORMAT_DATE_SERVER_DATE
const SG_DATE_TIME_FORMAT = CommonUtils.UI_FORMAT_DATE_TIME

function Fields({ disabled, showNotification, shouldHideMap }) {
    const { getValues } = useFormContext()
    const classes = useStyles();
    const { translate } = useTranslation(Namespace.REPORT_MANAGEMENT)
    const translatedTextsObject = makeTranslatedTextsObject()
    const { fetchRecords } = useServices()
    const [usersOptions, setUsersOptions] = useState([])
    const [isDisabledTB, setIsDisabledTB] = useState(false)
    const [dynamicFieldsKey, setDynamicFieldsKey] = useState(1)
    const [currentParty, setCurrentParty] = useState()

    const {
        success: showSuccessNotification
    } = useDefaultNotification(showNotification);

    function makeTranslatedTextsObject() {

        let title = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.TITLE
        )

        let partyId = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.PARTY
        )
        let reportId = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.REPORT_NAME
        )
        let scheduleName = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULE_NAME
        )
        let frequency = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.FREQUENCY
        )
        let userPartyReportList = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.USERS
        )
        let scheduledStartDate = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULED_START_DATE
        )
        let scheduledEndDate = translate(
            Namespace.REPORT_SCHEDULER,
            ReportSchedulerKeys.SCHEDULED_END_DATE
        )
        let cusScheduledStartTime = translate(
          Namespace.REPORT_SCHEDULER,
          ReportSchedulerKeys.SCHEDULED_START_TIME
        )
        let cusTimezone = translate(
          Namespace.REPORT_SCHEDULER,
          ReportSchedulerKeys.TIMEZONE
        )
        let cusBlankReportGenerate = translate(
          Namespace.REPORT_SCHEDULER,
          ReportSchedulerKeys.BLANK_REPORT
        )

        return {
            title,
            partyId,
            reportId,
            scheduleName,
            frequency,
            userPartyReportList,
            scheduledStartDate,
            scheduledEndDate,
            cusScheduledStartTime,
            cusTimezone,
            cusBlankReportGenerate
        }
    }

    const onSelectReportName = (ev) => {
        setDynamicFieldsKey(dynamicFieldsKey + 1)
    }

    useEffect( () => {

        const onSuccess = (data) => {
            setCurrentParty(data[0].value)
            localStorage.setItem('currentPartyId', data[0].value)
        }

        const onError = (error) => {
            console.log("Error:[" + JSON.stringify(error) + "]")
        }

        const onComplete = () => {
            console.log("Finish retrieving current party")
        }

        fetchRecords.execute(
            ReportDownloadApiUrls.GET_CURRENT_USER_PARTY,
            {},
            onSuccess,
            onError,
            onComplete
        );

    },[]);


    useEffect(() => {
        if(currentParty) {
            getUsersInTheParty()
        }
    }, [currentParty])

    const getUsersInTheParty = () => {
        const onSuccess = (r) => {
            let options = []
            options = r.content.map((record) => ({
                text: record.userProfile.loginId,
                value: record.id
            }));
            setUsersOptions(options);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => { console.log("On Complete") }

        fetchRecords.execute(
            `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/get`,
            {
                "customData": {
                    "baseFilterDTO": {
                        "filterType": "AND",
                        "filterProperties": [
                            {
                                "fieldName": "partyId",
                                "operatorType": "EQUAL",
                                "value1": currentParty
                            }
                        ],
                        "sortProperties": []
                    }
                }
            },
            onSuccess,
            onError,
            onComplete
        )
    }

    function base64toBlob(base64Data) {
        let size = 1024;
        let byteCharacters = atob(base64Data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / size);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * size;
            let end = Math.min(begin + size, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
    }

    function downloadExcel(byteArrays, filename, contentTypeHeader) {
        let blob = new Blob(byteArrays, { type: contentTypeHeader });
        let linkToDownload = document.createElement('a');
        linkToDownload.href = window.URL.createObjectURL(blob);
        linkToDownload.download = filename;
        linkToDownload.click();
    }

    const triggerSampleReportDownload = () => {
        setIsDisabledTB(true);
        const onSuccess = (r) => {
            setIsDisabledTB(false);
            showSuccessNotification("Downloaded Successfully");
            let contentType = 'application/vnd.ms-excel';
            let byteArrays = base64toBlob(r.base64Data);
            downloadExcel(byteArrays, r.fileName, contentType);
        }

        const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

        const onComplete = () => { console.log("On Complete") }

        fetchRecords.execute(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report/generated-report/hdr/trigger-sample-report`,
            {
                "customData": toServerDataFormat(getValues())
            },
            onSuccess,
            onError,
            onComplete
        )

    }
    return (
        <Grid container spacing={3} style={{ position: "relative" }}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextsObject.title} />
                    <CardContent>
                        <Grid container spacing={3}>


                            <CngGridItem xs={12} sm={6} shouldHide={false}>
                                <CngSelectField
                                    name="reportId"
                                    label={translatedTextsObject.reportId + ' *'}
                                    disabled={false}
                                    fetch={
                                        {
                                            url: ReportManagementApiUrls.GET_BY_USER_ID,
                                            textAccessor: "reportName",
                                            valueAccessor: "id"
                                        }
                                    }
                                    onClick={onSelectReportName}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.blankReportGenerate}>
                                <CngSwitchField
                                  name="blankReportGenerate"
                                  label={translatedTextsObject.cusBlankReportGenerate}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userPartyReportList}>
                                <SelectComponent
                                    name="userPartyReportList"
                                    label={translatedTextsObject.userPartyReportList}
                                    options={usersOptions} />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleName}>
                                <CngTextField
                                    name="scheduleName"
                                    label={translatedTextsObject.scheduleName + ' *'}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.frequency}>
                                <CngSelectField
                                    name="frequency"
                                    label={translatedTextsObject.frequency + ' *'}
                                    items={frequencyOptions}
                                    disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledStartTime}>
                                <CngSelectField
                                  name="scheduledStartTime"
                                  label={translatedTextsObject.cusScheduledStartTime + ' *'}
                                  items={hourlyOptions}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.timezone}>
                                <CngSelectField
                                  name="timezone"
                                  label={translatedTextsObject.cusTimezone + ' *'}
                                  items={timezoneOptions}
                                  disabled={disabled}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} >
                                
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledStartDate}>
                                <CngDateField
                                    label={translatedTextsObject.scheduledStartDate + ' *'}
                                    name='scheduledStartDate'
                                    disabled={disabled}
                                    format={"YYYY-MM-DD"}
                                    shouldDisableDate={(date) => {
                                        return moment(moment().format(SG_DATE_FORMAT)).isAfter(
                                            moment(date)
                                        )
                                    }}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledEndDate}>
                                <CngDateField
                                    label={translatedTextsObject.scheduledEndDate}
                                    name='scheduledEndDate'
                                    disabled={disabled}
                                    format={"YYYY-MM-DD"}
                                    shouldDisableDate={(date) => {
                                        return moment(moment().format(SG_DATE_FORMAT)).isAfter(
                                            moment(date)
                                        )
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.reportStartDate}>
                                <CngSelectField
                                    name="reportStartDate"
                                    label="Report Start Date"
                                    items={frequencyDataOptions}
                                    disabled={false}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.reportEndDate}>
                                <CngSelectField
                                    name="reportEndDate"
                                    label="Report End Date"
                                    items={frequencyDataOptions}
                                    disabled={false}
                                />
                            </CngGridItem>

                            {
                                <DynamicFieldsHolder key={dynamicFieldsKey} reportId={getValues("reportId")}/>
                            }

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Button variant="contained" disabled={isDisabledTB} color="primary" onClick={triggerSampleReportDownload} className={classes.btnCustom}>Trigger</Button>
        </Grid>
    )
}

const avoidFieldList = ["param_from_date", "param_to_date"]
function toClientDataFormat(serverData) {
    const parseData = DataFlattener.parse(serverData);
    const dynamicParam = parseData.dynamicParameterList
    dynamicParam.forEach( t => {
        if(avoidFieldList.includes(t.paramKey)) {
            // Values are taken from report scheduler main from "from_date" and "to_date"
        } else {
            let dynamicParamValue = t.paramValue;
            if(dynamicParamValue.includes(",") || t.paramKey.includes("multi_select") ) {
                // For multi-selects, initial values need to de-structure
                parseData[t.paramKey] = dynamicParamValue.split(',')
            } else {
                parseData[t.paramKey] = dynamicParamValue
            }
        }
    })
    return parseData
}

// added additional fields to the base fields
const reportSchedulerBaseFields = ["id", "title", "partyId", "reportId", "scheduleName", "frequency", "scheduledStartDate", "scheduledEndDate", "dynamicParameterList", "userPartyReportList", "reportStartDate", "reportEndDate", "scheduledStartTime", "timezone", "blankReportGenerate"]
function toServerDataFormat(localData) {
    const flattenerData = DataFlattener.unflatten(localData)
    console.log("Report scheduler formDate:", flattenerData)
    let dynamicParameterList = []
    let updatedFormDate = {}
    Object.keys(flattenerData).forEach(( key, index) => {
        if(reportSchedulerBaseFields.includes(key)) {
            updatedFormDate[key] = flattenerData[key]
        } else {
            dynamicParameterList.push({"paramKey": key, "paramValue": getParamValue(flattenerData[key]) })
        }
    })
    updatedFormDate["dynamicParameterList"] = dynamicParameterList
    updatedFormDate["partyId"] = localStorage.getItem('currentPartyId')
    console.log("Updated form data:", updatedFormDate)
    return updatedFormDate;
}

function getParamValue(paramValue) {
    if(Array.isArray(paramValue)){
        paramValue = paramValue.toString()
    }
    return paramValue;
}

const FormScheduler = Object.freeze({
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormScheduler
