import React, { useEffect, useState } from 'react'
import { Box, Paper, IconButton, Card, CardContent, Grid } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import JobConfigButtonComponents from './components/JobConfigButtonComponents'
import JobConfigurationNameComponent from './components/JobConfigurationNameComponent'
import WorkflowComponent from './components/WorkflowComponent'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import WarningDialog from 'src/components/dialog/WarningDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SCOJobConfigTranslationText from 'src/views/scojob/jobconfig/SCOJobConfigTranslationText'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import { useFieldArray } from "react-hook-form"
import { components, constants, useServices } from 'cng-web-lib'
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import AttributeDialog from './AttributeDialog'
import DeleteIcon from '@material-ui/icons/Delete'



const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  attributeList: [],
  ...JobConfigurationNameComponent.initialValues,
  ...WorkflowComponent.initialValues
})

const {
  form: {
    field: { CngTextField },
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem

} = components

const {
  filter: { EQUAL }
} = constants


const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ history, showNotification, disabled, id, mode }) {

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const { submitForm, setFieldValue, errors } = useFormikContext()
  const { fields: attributeList } = useFieldArray({ name: 'attributeList', keyName: 'key' });
  const [isOpen, setIsOpen] = useState(false);
  const [attrConfigOptions, setAttrConfigOptions] = useState([]);
  const [filteredAttrConfigOptions, setFilteredAttrConfigOptions] = useState([]);
  const { fetchRecords } = useServices();





  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const translatedTextsObject = SCOJobConfigTranslationText()

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const onCreate = async () => {
    console.log('this is errors onCreate ' + JSON.stringify(errors))
    if (!isEmpty(errors)) {
      console.log('errors' + JSON.stringify(errors))
      setWarningDialogOpen(true)
    } else {
      await setFieldValue("saveJob", true);

      submitForm()
    }
  }

  const onUpdate = async () => {
    if (!isEmpty(errors)) {
      console.log('errors' + JSON.stringify(errors))
      setWarningDialogOpen(true)
      await setFieldValue("updateJob", true)
      await setFieldValue("jobId", id)
      submitForm()
    } else {
      await setFieldValue('updateJob', true)
      await setFieldValue("jobId", id)
      submitForm()
    }

  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const onBack = () => {
    history.push({
      pathname: '/cal-sco/jobconfig'
    })
  }


  const cancelJobConfig = () => {
    history.push({
      pathname: '/cal-sco/jobconfig'
    })
  }


  useEffect(() => {
    getJobAttribute();
  }, [id])

  useEffect(() => {
    getAttributeLists()
  }, [attrConfigOptions])

  const getJobAttribute = () => {
    const onSuccess = (response) => {
      const options = response.content.map((attr) => {
        return attr;
      })

      setAttrConfigOptions(options)
      let filteredAttr = options.map((opt) => {
        return { text: opt.attributeName, value: opt.id }
      })
      setFilteredAttrConfigOptions(filteredAttr);
    }

    fetchRecords.execute(
      SCOJobAttributeApiUrls.GET_JOB_ATTRIBUTE_OPTION,
      {
        filters: [
          {
            field: 'attributeType',
            operator: EQUAL,
            value: 'JOB'
          }
        ]
      },
      onSuccess
    )
  }

  const getAttributeLists = () => {
    const onSuccess = (response) => {
      const attributesResp = response.content.map((attr) => {
        return { id: attr.id, attributeName: attr.attributeName }
      })
      setFieldValue("attributeList", attributesResp)
      setNewFilteredOptions(attributesResp)
    }

    fetchRecords.execute(
      SCOJobApiUrls.GETJOBATTRIBUTE,
      {
        filters: [
          {
            field: 'id',
            operator: EQUAL,
            value: id
          }
        ]
      },
      onSuccess
    )
  }


  const openAttributeDialog = () => {
    setIsOpen(true);
  }

  const setNewFilteredOptions = (newList) => {
    let filteredAttr = attrConfigOptions.filter(attrConfig => !newList.some(existingAttr => existingAttr.id == attrConfig.id)).map((attrConfig) => {
      return { text: attrConfig.attributeName, value: attrConfig.id }
    });
    setFilteredAttrConfigOptions(filteredAttr);
  }

  const handleRemoveAttribute = (index) => {
    const list = attributeList;
    list.splice(index, 1);
    setFieldValue("attributeList", list)
    setNewFilteredOptions(list)
  };

  return (
    <Box>
      <Paper>
        <Box className="page-content">
          <Box pt={5} p={5} pb={3}>
            <JobConfigurationNameComponent.FormBody
              id={id}
              disabled={disabled}
              mode={mode}
            />
          </Box>
          <Box pt={0} p={5} pb={3}>
            <AttributeDialog
              isOpen={isOpen}
              setFieldValue={setFieldValue}
              attributeList={attributeList}
              setIsOpen={setIsOpen}
              attrOptions={attrConfigOptions}
              filteredAttrOptions={filteredAttrConfigOptions}
              setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
              setNewFilteredOptions={setNewFilteredOptions}
            />


            <Card>
              <CardContent>
                <Grid container>
                  <AccordionHeaderComponent title={translatedTextsObject.scoJobAttribute} />
                </Grid>
                {attrConfigOptions.length === 0 &&
                  < CngGridItem xs={12}>
                    <p>{translatedTextsObject.scoNoAttributeConfigured}</p>
                  </CngGridItem>
                }



                {attributeList.map((attribute, index) => {
                  return (
                    <Box mb={2} width={`100%`} key={attribute.key}>
                      <Grid container spacing={2}>
                        <CngGridItem xs={12} sm={4}>
                          <CngTextField
                            disabled={true}
                            name={`attributeList[${index}].attributeName`}
                            label={translatedTextsObject.scoAttribute}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={4}>
                          <Box style={{ textAlign: 'left' }}>
                            <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveAttribute(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </CngGridItem>
                      </Grid>
                    </Box>
                  )
                }
                )}

                <CngGridItem xs={12} sm={4}>
                  <IconButton style={{ width: 100, height: 50 }} aria-label="add" disabled={false}
                    onClick={() => openAttributeDialog()}>

                    <AddCircleOutlineIcon
                      fontSize="small"
                      style={{ fontSize: 20, textAlign: 'center', color: 'grey' }}
                    />
                    <span style={{ fontSize: 15, textAlign: 'center' }}>Add Attribute</span>
                  </IconButton>
                </CngGridItem>

              </CardContent>
            </Card>
          </Box>

          <Box pt={0} p={5}>
            <WorkflowComponent.FormBody
              id={id}
              disabled={disabled}
              mode={mode}
            />
          </Box>

        </Box>
      </Paper >

      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <JobConfigButtonComponents mode={mode} onUpdate={onUpdate} onBack={onBack} onDiscard={onDiscard} onCreate={onCreate} />
        </Box>
      </Paper>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelJobConfig}
        content={"Confirm?"}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={translatedTextsObject.scoDisacardJobConfigTitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={translatedTextsObject.scoFormError}
        okMsg={uiTranslatedTextsObject.ok}
      />
    </Box >
  )
}

function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const NewJobConfigFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default NewJobConfigFormProperties
