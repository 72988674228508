//TradeTrust eBL statuses, check TradeTrust GitHub, EndorsementChainLayout class for more details.
export const TransferEventStatuses = {
    TRANSFER_OWNERS: "TRANSFER_OWNERS",
    TRANSFER_BENEFICIARY: "TRANSFER_BENEFICIARY",
    TRANSFER_HOLDER: "TRANSFER_HOLDER",
    INITIAL: "INITIAL",
    SURRENDER_ACCEPTED: "SURRENDER_ACCEPTED",
    SURRENDERED: "SURRENDERED",
    SURRENDER_REJECTED: "SURRENDER_REJECTED",
}

//Config Key which contains the mapping between TradeTrust statuses to our Ping statuses
export const TransferEventPingStatusMapConfig = "TRADETRUST_TRANSFER_PINGSTATUSMAPPINGS";

//Config Key which contains the mapping of Wallet addresses to different companies
export const WalletAddressesMapConfig = "TRADETRUST_WALLETADDRESSES_MAPPINGS";

//Key in the response  obtained after querying for TransferEventPingStatusMapConfig config.
export const PingStatusGeneralTransfer = "GENERALTRANSFER";

//General EBL Transfer PING event code
export const PingStatusGeneralEventCode = "EBLTG";

//Max number of characters to show if the wallet address is unknown/unmapped before the "..." is appended after
export const WalletAddressesMaxLength = 10;