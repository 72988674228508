import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { components, constants } from 'cng-web-lib'
import DocumentComponent from './components/DocumentComponent';
import { useHistory } from 'react-router-dom'
import { isValid, verificationBuilder, openAttestationVerifiers } from "@govtechsg/oa-verify";
import pathMap from 'src/paths/PathMap_TradeTrust'
import Loading from './components/Loading';

const {
  card: { CngSimpleCardHeader },
  dropzone: { CngFileUpload, HelperTextArea },
  CngGridItem,
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    CngAddForm,
    CngAddFormButtonSection
  }
} = components
const { FormState, NotificationType, locale: {
  key: { UiComponentKeys }
} } = constants

function TradeTrusteBLPage(props) {
  const { showNotification } = props
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [networkOption, setNetworkOption] = useState([{}])

  const goToViewer = (fragments, jsonObject) => {
    let issuedValid = isValid(fragments, ["DOCUMENT_STATUS"]);
    let hashValid = isValid(fragments, ["ISSUER_IDENTITY"]);
    let identityValid = isValid(fragments, ["DOCUMENT_INTEGRITY"])
    setLoading(false);
    //change fragment to useState
    history.push(
      pathMap.TRADETRUST_VIEW,
      {
        issuedValid: issuedValid,
        identityValid: identityValid,
        hashValid: hashValid,
        document: jsonObject,
        fragments: fragments
      }
    )
  }

  const onSubmit = async (data) => {
    setLoading(true)
    if (data && data.file.length > 0) {
      let document = new Blob
      data.file.map((file, i) => {
        document = file.file
      })
      VerifyComponent(document, data.networkType)
    }
    else {
      showNotification(NotificationType.ERROR, 'Please upload a .tt file.')
      setLoading(false);
    }
  }

  const VerifyComponent = async (document, networkType) => {


    const jsonObject = JSON.parse(await document.text());
    const verify = verificationBuilder(openAttestationVerifiers, {
      network: networkType
    })

    const fragments = await verify(jsonObject);

    if (isValid(fragments)) {
      goToViewer(fragments, jsonObject);
    }
    else {
      //DetailedError
      let errMsg = '';

      if (!isValid(fragments, ["DOCUMENT_INTEGRITY"])) {
        errMsg = errMsg.concat('\nDocument Integrity is invalid.');
      }

      if (!isValid(fragments, ["DOCUMENT_STATUS"])) {
        errMsg = errMsg.concat('\nDocument Status is invalid.');
      }

      if (!isValid(fragments, ["ISSUER_IDENTITY"])) {
        errMsg = errMsg.concat('\nIssuer Identity is invalid.');
      }

      showNotification(NotificationType.ERROR, 'This document is not valid.\n' + errMsg);
      setLoading(false);
    }
  }

  return (
    <>
      <Loading loading={loading}></Loading>
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
      </CngGridItem>
      <CngAddForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <DocumentComponent.Fields
            disabled={false}
            showNotification={showNotification}
            networkOption={networkOption}
            setNetworkOption={setNetworkOption}
          />
        }
        formikProps={{
          initialValues: {
            file: []
          },
          onSubmit: onSubmit
        }}
        toClientDataFormat={DocumentComponent.toClientDataFormat}
        toServerDataFormat={DocumentComponent.toServerDataFormat}
        renderButtonSection={() => (
          <div>
            <Box>
              <CngAddFormButtonSection
                shouldHideResetButton={true}
                primaryButtonLabel={'Submit'}>
              </CngAddFormButtonSection>
            </Box>
          </div>
        )}

      />
    </>
  )
}


export default TradeTrusteBLPage