import prettyBytes from "pretty-bytes";
import { Box, Grid} from '@material-ui/core'
import React from "react";
import { components } from 'cng-web-lib'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const {
  CngGridItem
} = components

export const AttachmentLink = ({ filename, data, type, path }) => {
  const hasBase64 = !!(data && type);
  const downloadHref = hasBase64 ? `data:${type};base64,${data}` : path || "#";
  const decodedData = atob(data);
  let filesize = decodedData? prettyBytes(decodedData.length) : "0";

  return (
    <Grid container direction="row" alignItems="center">
      <CngGridItem className="view-card-content" ><Box className="view-card-content-value" ><FileCopyIcon /></Box></CngGridItem>
      <CngGridItem>
        <Box className="view-card-content-value">
          {filename}
        </Box>
        {hasBase64 && <Box className="view-card-content-label">{filesize}</Box>}
        <div className="flex">
          <Box className="view-card-content-value">
            <a
              href={downloadHref}
              download={`${filename}`}
              data-testid="attachment-download-link"
              className="text-cerulean-300 font-gilroy-bold hover:underline"
            >
              Download
            </a>
          </Box>
        </div>
      </CngGridItem>
    </Grid>
  );
};
