import React from "react";

export const AddressInfo = ({
  className,
  title,
  name,
  resolvedBy,
  source,
  children,
}) => {
  return (
    <div className={`${className ? className : ""}`}>
      <h4 className="text-muted mb-2 font-bolder">{title}:</h4>
      {name && (
        <div className="flex">
          <h5 className="text-muted mb-1">{name}</h5>
        </div>
      )}
      <div className="inline-block break-all w-full font-semibold text-muted">{children}</div>
      {resolvedBy && (
        <div className="flex">
          <h6 className="text-muted">
            (Resolved by: {resolvedBy}
            {source && `; Source: ${source}`})
          </h6>
        </div>
      )}
    </div>
  );
};

export default AddressInfo