const HelpdeskKeys = {
    TITLE: "title",
    VIEW_BOOKING: "viewBooking",
    BOOKING_DETAILS: "bookingDetails",
    BACK_TO_VIEW_BOOKING: "backToViewBooking",
    VIEW_SI: "viewSi",
    SI_DETAILS: "siDetails",
    BACK_TO_VIEW_SI: "backToViewSI",
    SEARCH_SI: "searchSi",
    VIEW_CONSOLIDATED_AWB: "viewConsolidatedAwb",
    VIEW_MASTER_AWB: "viewMasterAwb",
    BACK_TO_CONSOLIDATED_AWB: "backToConsolidatedAwb"

}
export default HelpdeskKeys