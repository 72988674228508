const PollingKeys = {
  TITLE: "title",
  POLLING_ID: "pollingId",
  PORT_PAIR: "portPair",
  CARGO_READY_DATE: "cargoReadyDate",
  RANGE_OF_CARGO_READY_DATE: "rangeOfCargoReadyDate",
  TOTAL_SOURCE: "totalSource",
  PROCESSED_SOURCE: "processedSource",
  FAILURE_SOURCE: "failureSource",
  RUN_DATE: "runDate",
  COMPLETED_DATE: "completedDate",
  PollingSource: {
    TITLE: "pollingSource.title",
    SOURCE: "pollingSource.source",
    STATUS: "pollingSource.status",
    RUN_DATE: "pollingSource.runDate",
    COMPLETED_DATE: "pollingSource.completedDate",
    ERROR_MSG: "pollingSource.errorMsg"
  }
}

export default PollingKeys
