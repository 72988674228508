import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import PrinterIcon from '@material-ui/icons/LocalPrintshopOutlined'
import MaterialTable, { MTableToolbar } from 'material-table'

import ClearIcon from '@material-ui/icons/Clear'
import TablePagination from './TablePagination'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded'
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import pathMap from 'src/paths/PathMap_TradeManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'


import { useHistory } from 'react-router-dom'


FTASelfCertificateTable.propTypes = {}




const tableIcons = {
  // * https://www.freakyjolly.com/react-material-table-how-to-show-icons-in-action-and-other-components/
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropDownIcon {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <SkipPreviousRoundedIcon {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SkipNextRoundedIcon {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <FontAwesomeIcon icon='fa-table-columns' style={{ marginLeft: '1em' }} />)
}

const moreActionsList = [

  {
    action: 'selfcertify',
    name: 'Self Certify',
    icon: ['fal', 'print']
  }
]

export const useFTASelfCertificateStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& tbody td': {
      fontSize: '.75rem',
      color: theme.palette.text.gray900,
      '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      },
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
    }
  },
  limitedText: {
    maxWidth: '16em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  action: {
    color: theme.palette.text.textSecondary,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36, width: 36,
    fontSize: '.875rem'
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '.875rem'
  },
  primaryText: {
    fontSize: '.875rem'
  },
  secondaryText: {
    fontSize: '.75rem',
    color: theme.palette.text.textSecondary
  },
  colorBlue: {
    color: theme.palette.primary.main
  },
  colorGreen: {
    color: theme.palette.success.main
  },
  colorOrange: {
    color: '#C48E1F'
  }

}))

const printIcon = () => {
  return <>
    <Button variant='outlined' startIcon={<PrinterIcon />}
      color='primary'
      style={{ width: '100%', textTransform: 'none' }}>
      Print Label
    </Button>
    <Button variant='text'>Text</Button>

    <Button variant='text'
      color='primary'
      style={{ width: '100%', textTransform: 'none' }}>
      X
    </Button>
  </>
}

function FTASelfCertificateTable(props) {

  const history = useHistory()

  const classes = useFTASelfCertificateStyles()
  const tableRef = useRef()



  const {
    selfCertificate,
    pageNumber,
    pageSize,
    isLoading,
    totalRecords,
    sortColumn,
    sortType,
    selectedRows
  } = useSelector(({
    selfCertificate,
    pageNumber,
    pageSize,
    isLoading,
    totalRecords,
    sortColumn,
    sortType,
    selectedRows
  }) => ({
    selfCertificate,
    pageNumber,
    pageSize,
    isLoading,
    totalRecords,
    sortColumn,
    sortType,
    selectedRows
  }))

  const dispatch = useDispatch()

  const clearSelection = () => {
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }

  const handleSinglePrint = (rowData) => {
    console.log('self-certify')
    console.log(JSON.stringify(rowData.id))


    history.push(`/cal-tmp/self-certification/generate`, {
      detailsData: {
        selfCertify: [rowData.id],
        principalId: rowData.companyId
      }
    })
  }

  const handlePrintAction = () => {
    console.log('self certified mass print ')
    const selectedRowsId = selectedRows.map(obj => obj.id)
    console.log(selectedRowsId)
    const principalId = selectedRows[0].companyId
    history.push(`/cal-tmp/self-certification/generate`, {
      detailsData: {
        selfCertify: selectedRowsId,
        principalId: principalId
      }
    })
  }

  const getColumns = () => {
    const columns = [
      {
        title: 'Material No. / SKU', field: 'materialSkuNo', render: rowData =>
          <Box>
            <Typography>{rowData.materialSkuNo}</Typography>
          </Box>
      },
      {
        title: 'HS Code / Description', field: 'hsSubheading', render: rowData =>
          <Box>
            <Typography
              className={clsx(classes.boldText, classes.limitedText)}>{rowData.hsSubheading}</Typography>
            <Typography
              className={clsx(classes.secondaryText, classes.limitedText)}>{rowData.productDescription}</Typography>
          </Box>
      }
      ,
      {
        title: 'Principal', field: 'principal', render: rowData =>
          <Box>
            <Typography
              className={clsx(classes.boldText, classes.limitedText)}>{rowData.principal}</Typography>
          </Box>
      },
      {
        title: 'FTA', field: 'fta', render: rowData =>
          <Typography
            className={clsx(classes.primaryText, classes.limitedText)}>{rowData.fta}</Typography>
      },
      {
        title: 'ROO Qualitication', field: 'qualifiedRoo', align: 'center', render: rowData =>
          <Typography
            className={clsx(classes.primaryText, classes.limitedText)}>{rowData.qualifiedRoo} / {rowData.totalRoo}</Typography>
      },
      {
        title: 'Status', field: 'status', render: rowData => {
          const status = rowData.status
          let cssClassName = ''
          switch (status) {
            case 'Pending Certification':
              cssClassName = 'colorBlue'
              break
            case 'Certified':
              cssClassName = 'colorGreen'
              break
          }
          return <Typography
            className={clsx(classes.boldText, classes[cssClassName])}>{rowData.status}</Typography>
        }
      },
      {
        title: 'Action', field: 'last_name',
        sorting: false,
        render: rowData => (
          <Button variant='outlined' startIcon={<PrinterIcon />}
            color='primary'
            style={{ textTransform: 'none' }}
            onClick={() => {
              handleSinglePrint(rowData)
            }}>
          </Button>
        )
      }
    ]

    const clonedMap = columns.map(column => {
      if (sortColumn && sortType && sortColumn === column.field) {
        let clone = { ...column, defaultSort: sortType }
        return clone
      } else {
        return column
      }

    })

    return clonedMap
  }

  const onOrderChange = (sourceIndex, destinationIndex) => {
    if (sourceIndex !== -1 && destinationIndex != null) {
      const sortColumn = tableRef.current.dataManager.columns[sourceIndex].field
      dispatch({ type: 'SET_SORT_COLUMN', payload: sortColumn })
      dispatch({ type: 'SET_SORT_TYPE', payload: destinationIndex })
    } else {
      dispatch({ type: 'SET_SORT_COLUMN', payload: null })
      dispatch({ type: 'SET_SORT_TYPE', payload: null })
    }
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }

  const onRowClick = (event, rowData) => {
    console.log('on row click ' + rowData)

  }

  const onSelectionChange = (row) => {
    const displayedIds = selfCertificate.map(result => result.id)
    const selectedRowsNotDisplayed = selectedRows.filter(selectedRow => {
      return !displayedIds.includes(selectedRow.id)
    })
    dispatch({ type: 'SET_SELECTED_ROWS', payload: [...selectedRowsNotDisplayed, ...row] })
  }

  const applyRowDataBgColorSelection = (rowData) => {
    if (selectedRows.length > 0) {
      if (rowData.fta !== selectedRows[0].fta || rowData.principal !== selectedRows[0].principal) {
        return '#C4C4C4'
      }
    }
    return '#FFF'
  }

  const applyPointerEventSelection = (rowData) => {
    if (selectedRows.length > 0) {
      if (rowData.fta !== selectedRows[0].fta || rowData.principal !== selectedRows[0].principal) {
        return 'none'
      }
    }
    return 'auto'
  }

  const indexOfTheFirstRecord = (pageNumber - 1) * pageSize + 1
  const indexOfTheLastRecord = (pageNumber - 1) * pageSize + selfCertificate?.length


  let topToolbar = props => (
    <div>
      {selectedRows.length == 0 ?
        <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
          <div><Typography
            className={classes.secondaryText}> {indexOfTheFirstRecord + '-' + indexOfTheLastRecord + ' of ' + totalRecords}</Typography>
          </div>
          <div style={{ flex: '1 1 10%' }} />
          <MTableToolbar {...props} />
        </div>

        :

        <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
          <div>
            <Typography
              className={clsx(classes.colorBlue, classes.secondaryText)}>{Number(selectedRows.length) + (selectedRows.length === 1 ? ' item ' : ' items ') + 'selected'} </Typography>
            <Typography
              className={classes.secondaryText}> {indexOfTheFirstRecord + '-' + indexOfTheLastRecord + ' of ' + totalRecords + ' items'} </Typography>
          </div>
          <div style={{ flex: '1 1 10%' }} />
          <Button variant='outlined' startIcon={<PrinterIcon />}
            color='primary'
            style={{ textTransform: 'none' }}
            onClick={() => {
              handlePrintAction()
            }}>
            Self Certify
          </Button>
          <IconButton aria-label='delete' onClick={clearSelection}>
            <ClearIcon />
          </IconButton>
        </div>
      }

    </div>
  )


  let bottomPaginationBar = (props) => (
    <>
      <Box mt={2}>
        <TablePagination
          {...props}
        />
      </Box>
    </>
  )

  useEffect(() => {
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }, [])

  return (
    <Box className={classes.table}>
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        columns={getColumns()}
        isLoading={isLoading}
        onOrderChange={onOrderChange}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
        localization={{
          toolbar: { addRemoveColumns: 'View Columns' }
        }}
        options={{
          selection: true,
          search: false,
          paginationType: 'stepped',
          columnsButton: true,
          pageSize: 100,
          loadingType: 'overlay',
          emptyRowsWhenPaging: false,
          padding: 'default',
          showTitle: false,
          showSelectAllCheckbox: false,
          rowStyle: rowData => ({
            backgroundColor: applyRowDataBgColorSelection(rowData),
            pointerEvents: applyPointerEventSelection(rowData)
          })
        }}
        data={selfCertificate?.map(row => selectedRows.find(selected => selected.id === row.id) ? {
          ...row,
          tableData: { checked: true }
        } : { ...row, tableData: { checked: false } })}
        components={{
          Toolbar: topToolbar,
          Pagination: bottomPaginationBar
        }}
      />
    </Box>
  )
}

export { FTASelfCertificateTable }
