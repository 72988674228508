import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components, constants } from 'cng-web-lib'
import React, {useState} from 'react'

const {
  form: { CngAddForm },
  table: { useDefaultNotification }
} = components

const {
  NotificationType
} = constants

function ProdMgtAddForm({
  history,
  showNotification,
  onPostSubmitSuccess,
}) {
  const { success: showSuccessNotification, error: showErrorNotification } =
  useDefaultNotification(showNotification)
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ProdMgtFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={ProdMgtFormProperties.formikProps}
      toClientDataFormat={ProdMgtFormProperties.toClientDataFormat}
      toServerDataFormat={ProdMgtFormProperties.toServerDataFormat}
      create={{
        url: ForwardHubApiUrls.PROD_MGT_POST, 
        // onPostSubmitSuccess: onPostSubmitSuccess,  <- this no longer work as we have onSuccess 
        onSuccess: (response, setCircularProgress) => {
          setCircularProgress(false)
          if (response.errorMessages != null) {
            
            showErrorNotification(response.errorMessages)
           
          } else {
            showSuccessNotification("Add record has succeeded.")
            //only allow user to update when create success
            onPostSubmitSuccess(response)
          }
        }
        
        // onError: (error, setShouldShowPrimaryButtonCircularProgress) => {
        //   //Overwrite onError to show Portnet API error messages
        //   showNotification(NotificationType.ERROR, error.response.data);
        //   setShouldShowPrimaryButtonCircularProgress(false);
        // }
      }}
    />
      )
}

export default ProdMgtAddForm
