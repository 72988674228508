import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Typography,
  Card,
  Switch,
  Snackbar,
  Menu,
  MenuItem
} from '@material-ui/core'
import ListPage from './ResultList'
import ChartPage from './ResultChart'
import { useHistory } from 'react-router'
import MuiAlert from '@material-ui/lab/Alert'
import CarbonFootprintApiUrls from '../../../../apiUrls/CarbonFootprintApiUrls'
import { useServices, useTranslation } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Namespace from 'src/constants/locale/Namespace'
import CircularProgress from '@material-ui/core/CircularProgress'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const ResultPage = () => {
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])
  const [choose, setChoose] = React.useState(true)
  const history = useHistory()
  const [data, setData] = useState([])
  const [open, setOpen] = useState(true)
  const [type, setType] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = useState(false)

  const advanceMode = history.location?.state?.data?.advanceMode
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const locationsResponse = history.location?.state?.locations
  const { securedSendRequest } = useServices()

  const onClickExport = () => {
    setLoading(true)
    const optionRequests =
      history.location?.state?.dataLocationForm?.optionRequests ?? []
    const requestDownload = {
      cargoWeight: `${history.location?.state?.data?.weight} TEU`,
      tonsPerTeu: `${history.location?.state?.data?.tonsPerTeu}`,
      inputMode: advanceMode ? 'advance' : 'standard',
      origin: `${locationsResponse?.startLocation?.description}`,
      destination: `${locationsResponse?.endLocation?.description}`,
      simpleTransportResponse: history.location?.state?.data,
      complexTransportResponse: history.location?.state?.data,
      optionRequests: optionRequests.map((item) => {
        return {
          transport: item?.transport,
          viaRequestList: item?.viaRequestList?.map((elm) => {
            return {
              transport: elm?.transport,
              transportRequest: elm?.transportRequest?.descriptionEn
            }
          })
        }
      })
    }
    securedSendRequest.execute(
      'POST',
      advanceMode
        ? CarbonFootprintApiUrls.EXPORT_ADVANCE_CSV
        : CarbonFootprintApiUrls.EXPORT_CSV,
      requestDownload,
      (exportData) => {
        console.log('success')
        console.log(exportData)
        let blob = exportData.data
        let timeStamp = new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        let fileName = `CALISTA carbon footprint calculation result ${timeStamp}.csv`
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        document.body.appendChild(link)
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      (error) => {
        console.log(error)
      },
      onComplete,
      { responseType: 'blob' }
    )
    setAnchorEl(null)
  }
  function onComplete() {
    setLoading(false)
  }

  useEffect(() => {
    if (history.location.state) {
      setData(history.location?.state?.data?.transportDetailResponses ?? [])
    } else {
      history.goBack()
    }
  }, [history.location.state])
  const onChange = ({ target: { checked } }) => {
    setType(checked)
  }

  return (
    <Card className='cfc-calculator'>
      <Grid
        container
        spacing={2}
        style={{
          paddingTop: '21px',
          paddingRight: '44px',
          paddingLeft: '38px'
        }}
      >
        {history.location?.state?.data?.errors ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity='error'>
              {history.location?.state?.data?.errors ?? ''}
            </Alert>
          </Snackbar>
        ) : null}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant='h2' style={{ fontWeight: 'bold' }}>
                {t('label_calculation_results')}
                <Typography
                  style={{ color: '#8996af', fontSize: '11px' }}
                  variant='caption'
                  display='block'
                  gutterBottom
                >
                  {t('label_calculation_en')}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                spacing={2}
                style={{ justifyContent: 'flex-end' }}
              >
                <Grid item>
                  <div>
                    <Button
                      aria-controls='simple-menu'
                      aria-haspopup='true'
                      onClick={handleClick}
                      className='cfc-export-button'
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <FontAwesomeIcon icon={['fal', 'file-export']} />
                      )}
                    </Button>
                    <Menu
                      id='simple-menu'
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      className='cfc-menu'
                    >
                      <MenuItem className='export-label'>
                        {t('label_export')}
                      </MenuItem>
                      <MenuItem onClick={onClickExport}>
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={['fal', 'file-csv']}
                        />
                        <div> CSV </div>
                      </MenuItem>
                    </Menu>
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      backgroundColor: '#5E81F4',
                      width: '137px',
                      height: '42px',
                      color: '#ffffff'
                    }}
                    startIcon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                    variant='contained'
                    onClick={() => {
                      history.push({
                        pathname: advanceMode
                          ? '/cal-cfc/eco-calculator/advan'
                          : '/cal-cfc/eco-calculator',
                        state: {
                          dataLocationForm:
                            history?.location?.state?.dataLocationForm
                        }
                      })
                    }}
                  >
                    {t('label_change')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*end tittle*/}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => setChoose(true)}
                    className='cfc-list-chart-style'
                    variant={choose ? 'outlined' : 'text'}
                  >
                    {t('label_list')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setChoose(false)}
                    className='cfc-list-chart-style'
                    variant={!choose ? 'outlined' : 'text'}
                  >
                    {t('label_chart')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                style={{ justifyContent: 'flex-end' }}
              >
                <Grid item className='bold' style={{ color: '#8996af' }}>
                  {t('label_show_wtt')}
                  <Switch
                    color='primary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*end list chart*/}
        {/* <ListPage /> */}
        {choose ? (
          <ListPage
            data={data}
            history={history}
            typeShow={type}
            advanceMode={advanceMode}
            t={t}
            country={history.location?.state?.country}
          />
        ) : (
          <ChartPage
            data={
              history.location?.state?.data?.listValueChartTransportResponse ??
              []
            }
            typeShow={type}
            lessEmissionIndex={
              advanceMode ? data?.findIndex((item) => item?.lessEmission) : 0
            }
            t={t}
          />
        )}
      </Grid>
    </Card>
  )
}

export default ResultPage
