const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-cfc`

const CALISTA_MGTT_DRIVER = `${MODULE_BASE}/driver`
const CALISTA_MGTT_TRANSPORTER = `${MODULE_BASE}/transporter`
const CALISTA_MGTT_OPERATOR = `${MODULE_BASE}/operator`
const CALISTA_MGTT_INTERNAL_USER = `${MODULE_BASE}/internaluser`
const CALISTA_MGTT_BATCH_JOB = `${MODULE_BASE}/internaluser/batchjob`

const pathMap = {
    MGTT_DRIVER: `${CALISTA_MGTT_DRIVER}`,
    MGTT_TRANSPORTER: `${CALISTA_MGTT_TRANSPORTER}`,
    MGTT_OPERATOR: `${CALISTA_MGTT_OPERATOR}`,
    MGTT_INTERNAL_USER: `${CALISTA_MGTT_INTERNAL_USER}`,
    MGTT_BATCH_JOB: `${CALISTA_MGTT_BATCH_JOB}`
}

export default pathMap