import {Card, Grid} from '@material-ui/core'
import React from 'react'

import { components} from 'cng-web-lib'
import { AttachmentLink } from './AttachmentLink'
import { v4 as uuid } from 'uuid'

const {
    CngGridItem,
  
    table: { useDefaultNotification }
} = components

const FormBody = (props) => {
    return (
      <Grid container xs={12} sm={12}>
              {props.attachments.map(({ filename, data, type }, index) => {
                const rowKey = uuid(); // Generate a unique key for each attachment

                return(
                  <CngGridItem key={rowKey} xs={4} sm={4} style={{ padding: '1rem' }}>
                    <Card>
                      <div className="flex lg:w-1/3 xl:w-1/4 mb-3 px-4">
                        <AttachmentLink filename={filename} data={data} type={type} />
                      </div>
                    </Card>
                  </CngGridItem>
                )
              })}
      </Grid>
    )
  }


const initialValues = Object.freeze({})

const ViewAttachmentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewAttachmentComponent