import {
    MessageTitle,
    OverlayContext,
    LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from '@material-ui/core';
import { FormState } from "../../constants/FormState";
import { isValidEndorseTransfer } from "../../utils";
import AssetInformationPanel from "../../components/AssetInformationPanel/index";
import { AssetManagementTitle } from "../../components/AssetManagementTitle";
import { EditableAssetTitle } from "../../components/FormVariants/EditableAssetTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';
import CancelButton from 'src/components/button/CancelButton.js'
import FormBackButton from "../FormBackButton";
import Loading from '../Loading';

export const EndorseTransferForm = ({
    formAction,
    tokenRegistryAddress,
    holder,
    handleEndorseTransfer,
    transferOwnersState,
    setFormActionNone,
    setShowEndorsementChain,
    setConfirmDialogOpen,
    setModalData,
    sendPingMilestone,
    blNumber,
    walletAddressesMapping,
    pingStatusMapping,
    pingMilestone,
    processPingMilestone
}) => {
    const [newHolder, setNewHolder] = useState(holder || "");
    const [newOwner, setNewOwner] = useState(holder || ""); // Can only use this when owner is holder
    const isPendingConfirmation = transferOwnersState === FormState.PENDING_CONFIRMATION;
    const isConfirmed = transferOwnersState === FormState.CONFIRMED;
    const isEditable =
        transferOwnersState !== FormState.PENDING_CONFIRMATION && transferOwnersState !== FormState.CONFIRMED;

    const { showOverlay } = useContext(OverlayContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isConfirmed) {
            const arrMsg = [
                { id: 1, value: MessageTitle.ENDORSE_TRANSFER_SUCCESS },
                { id: 2, value: <br /> },
                { id: 3, value: <br /> },
                { id: 4, value: 'Current Owner:' },
                { id: 5, value: <br /> },
                { id: 6, value: newOwner },
                { id: 7, value: <br /> },
                { id: 8, value: <br /> },
                { id: 9, value: 'Current Holder:' },
                { id: 10, value: <br /> },
                { id: 11, value: newHolder }
            ]
            setModalData({
                content: arrMsg.map(msg => (msg.value)),
                title: 'Success',
                okMsg: 'Close',
                type: 'Info'
            })
            setLoading(false);
            setConfirmDialogOpen(true);

            //PING Milestone processing and sending to the PING API endpoint
            let pingMilestoneRequest = processPingMilestone(pingMilestone, newHolder);
            sendPingMilestone(pingMilestoneRequest);

            setFormActionNone();
        }
    }, [isConfirmed, newOwner, newHolder, showOverlay, setFormActionNone]);

    useEffect(() => {
        if (transferOwnersState === FormState.ERROR) {
            setLoading(false);
        }
    }, [transferOwnersState]);

    return (
        <>
            <Loading loading={loading}>
                <FormBackButton
                    setFormActionNone={setFormActionNone}
                />
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isPendingConfirmation}
                />

                < Grid container spacing={1}>
                    <Grid item xs={4}>
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle
                            role="Owner"
                            value={holder}
                            newValue={newOwner}
                            isEditable={isEditable}
                            onSetNewValue={setNewOwner}
                            isError={transferOwnersState === FormState.ERROR}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle
                            role="Holder"
                            value={holder}
                            newValue={newHolder}
                            isEditable={isEditable}
                            onSetNewValue={setNewHolder}
                            isError={transferOwnersState === FormState.ERROR}
                        />
                    </Grid>
                </Grid >
                <Grid container spacing={1}>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <Box ml={3}>
                            <CancelButton onClick={setFormActionNone} disabled={isPendingConfirmation} />
                        </Box>
                    </Grid>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <SubmitButton
                            onClick={() => {
                                handleEndorseTransfer(newOwner || "", newHolder || "");
                                setLoading(true);
                            }}
                            disabled={!isValidEndorseTransfer(holder, newHolder, newOwner) || isPendingConfirmation}
                        >
                            {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Endorse Transfer</>}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Loading>
        </>
    );
};