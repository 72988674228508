import { Grid, Box, Button, Input, InputAdornment } from '@material-ui/core'
import React, { useState } from 'react'
import { Search, X } from 'react-feather'
import { components } from 'cng-web-lib'

import GeneralButtonTabComponent from 'src/views/common/ui/GeneralButtonTabComponent'

const {
  form: {
    field: { CngSelectField }
  }
} = components

function TabSearchResultFilter(props) {
  return (
    <FormBody
      totalCount={props.totalCount}
      resultCount={props.resultCount}
      title={props.title}
      buttonComponent={props.buttonComponent}
      filterText={props.filterText}
      sortByList={props.sortByList}
      handleSortByChange={props.handleSortByChange}
      handleFilterTextChange={props.handleFilterTextChange}
      clearFilterText={props.clearFilterText}
    />
  )
}

function FormBody({
  totalCount,
  resultCount,
  title,
  buttonComponent,
  clearFilterText,
  handleFilterTextChange,
  filterText,
  sortByList,
  handleSortByChange
}) {
  const [openSearch, setOpenSearch] = useState(false)

  function handleSearchClick() {
    setOpenSearch(!openSearch)
    clearFilterText()
  }

  function renderSearch() {
    if (openSearch) {
      return (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ padding: '2em 0em 2em 1em' }}
        >
          <Grid
            item
            xs={11}
            sm={11}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Input
              style={{ width: '100%' }}
              type='text'
              name='filterText'
              value={filterText}
              placeholder='Search'
              endAdornment={
                <InputAdornment
                  position='end'
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSearchClick()}
                >
                  <X />
                </InputAdornment>
              }
              onChange={(e) => handleFilterTextChange(e, false)}
            />
          </Grid>
          <Grid item xs={1} sm={1}>
            <Button
              name='search'
              variant='contained'
              color='primary'
              style={{ padding: '1em' }}
              startIcon={<Search />}
              className={'search-button'}
              onClick={() => handleFilterTextChange(null, true)}
            ></Button>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ padding: '1em 0em 1em 1em' }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box>
              Showing {resultCount} out of {totalCount} results
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Search onClick={handleSearchClick} style={{ cursor: 'pointer' }} />
          </Grid>
          {handleSortByChange && (
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '1.2em inherit'
              }}
            >
              <CngSelectField
                name='sortCode'
                label='Sort By'
                items={sortByList}
                onClick={handleSortByChange}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  }

  return (
    <Box>
      <Grid container xs={12}>
        <Grid container item xs={12} sm={6}>
          <GeneralButtonTabComponent.FormBody
            tabInfo={{
              tabClicked: true,
              tabText: `${title} (${totalCount})`
            }}
          />
        </Grid>
        <Grid container item xs={12} sm={6} justify='flex-end'>
          {buttonComponent}
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {renderSearch()}
        </Grid>
      </Grid>
    </Box>
  )
}
export default TabSearchResultFilter
