import { utils } from "@govtechsg/open-attestation";
import React from "react";

export const ObfuscatedMessage = ({ document }) => {
  if (!utils.isObfuscated(document)) return null;

  return (
    <div className="container">
      <div className="text-lg font-gilroy-bold text-scarlet-500" data-testid="obfuscation-info">
        <p className="py-6">Note: There are fields/data obfuscated in this document.</p>
      </div>
    </div>
  );
};

export default ObfuscatedMessage