import React from "react";
import { useEndorsementChain } from "../common/useEndorsementChain";
import EndorsementChainLayout from "./EndorsementChainLayout";

export const EndorsementChainContainer = ({
	tokenRegistry,
	tokenId,
	setShowEndorsementChain,
}) => {
	const endorsementChainProps = useEndorsementChain(tokenRegistry, tokenId);
	return (
		< div >
			<EndorsementChainLayout {...endorsementChainProps} setShowEndorsementChain={setShowEndorsementChain} />
		</div>
	);
};

export default EndorsementChainContainer