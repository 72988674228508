import { useServices } from 'cng-web-lib'
import EssDocsApi from 'src/views/essdocs/shared/api'
import { useEffect } from "react";

function EssdocsSSOPage() {
  const { fetchRecords } = useServices()

  useEffect(() => {
    EssDocsApi.fetchSIConfig(fetchRecords, getSsoUrlFromDb)
  }, [])

  function getSsoUrlFromDb(data) {
    var content = data["content"]
    content.map(({ configFor, configValue1 }) => {
      if (configFor === 'SSO_ESSDOCS_URL') {
        window.open(configValue1, 'Essdocs SSO')
      }
    })

  }
  return null
}

export default EssdocsSSOPage
