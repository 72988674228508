import React, { useState } from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import TranslationText from './TranslationText'
import ImageUpload from './ImageUpload'
import { useFormContext } from "react-hook-form"

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext, useField }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants


const DEFAULT_INITIAL_VALUES = ({
  name: "",
  registerNo: "",
  referenceNo: "",
  address: "",
  signatureId: "",
  letterheadId: "",
  contactName: "",
  contactTitle: "",
  contactEmail: "",
  contactPhoneNumber: "",
  letterheadImage: "",
  signatureImage: "",
  letterheadImageName: "",
  signatureImageName: "",
  letterheadImageUrl: "",
  signatureImageUrl: "",

})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}



function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const form = useFormContext()
  const translatedTextsObject = TranslationText();

  const [letterheadImage] = useField('letterheadImage');
  const [signatureImage] = useField('signatureImage');
  const [letterheadImageName] = useField('letterheadImageName');
  const [signatureImageName] = useField('signatureImageName');

  const handleLetterheadChange = (image, fileName) => {
    form.setValue('letterheadImage', image)
    form.setValue('letterheadImageName', fileName)
  };

  const handleLetterheadDelete = () => {
    form.setValue('letterheadImage', '')
    form.setValue('letterheadImageName', '')
  };

  const handleSignatureChange = (image, fileName) => {
    form.setValue('signatureImage', image)
    form.setValue('signatureImageName', fileName)
  };

  const handleSignatureDelete = () => {
    form.setValue('signatureImage', '')
    form.setValue('signatureImageName', '')
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="name"
                  label={translatedTextsObject.name + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="registerNo"
                  label={translatedTextsObject.registerNo}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="referenceNo"
                  label={translatedTextsObject.referenceNo}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="address"
                  label={translatedTextsObject.address}
                  disabled={disabled}
                  multiline={true}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <ImageUpload name="letterheadImage"
                  label={translatedTextsObject.letterhead}
                  onImageChange={handleLetterheadChange}
                  onDelete={handleLetterheadDelete}
                  initialHeight={255}
                  initialWidth={2550}
                  initialDPI={600}
                  imageSrc={letterheadImage}
                  imageSrcName={letterheadImageName}
                  disabled={disabled} />
              </CngGridItem>

            </Grid>

            <h5 style={{ fontWeight: 'bold', marginTop: '21px' }}>Person of Contact</h5>
            <hr />
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="contactName"
                  label={translatedTextsObject.contactName}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="contactTitle"
                  label={translatedTextsObject.contactTitle}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="contactEmail"
                  label={translatedTextsObject.contactEmail}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="contactPhoneNumber"
                  label={translatedTextsObject.contactPhoneNumber}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={4}>
                <ImageUpload name="signature"
                  label={translatedTextsObject.signature}
                  onImageChange={handleSignatureChange}
                  onDelete={handleSignatureDelete}
                  initialHeight={145}
                  initialWidth={400}
                  initialDPI={600}
                  imageSrc={signatureImage}
                  imageSrcName={signatureImageName}
                  disabled={disabled} />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
