import { groupBy } from "lodash";
import { Magic } from "magic-sdk";
import { MAGIC_API_KEY } from "../config";

export const fetchEventTime = async (blockNumber, provider) => {
    const msecToSec = 1000;
    const eventTimestamp = (await provider.getBlock(blockNumber)).timestamp * msecToSec;
    return eventTimestamp;
};

/*
  Get available owner/holder from list of events
*/
export const getHolderOwner = (events) => {
    let owner = "";
    let holder = "";
    for (const event of events) {
        owner = event.owner || owner;
        holder = event.owner || holder;
    }
    return { owner, holder };
};

export const mergeTransfers = (transferEvents) => {
    const groupedEventsDict = groupBy(transferEvents, "transactionHash");
    const transactionHashValues = Object.values(groupedEventsDict);
    const mergedTransaction = transactionHashValues.flatMap((groupedEvents) => {
        if (groupedEvents.length === 1) return groupedEvents;
        if (groupedEvents.length === 2) {
            // 2 Transaction with the same transactionHash, (transactionIndex and blockNumber)
            // Merging HOLDER_TRANSFER and OWNER_TRANSFER transactions
            const type = "TRANSFER_OWNERS";
            const base = groupedEvents[0];
            const { owner, holder } = getHolderOwner(groupedEvents);
            return [{ ...base, type, owner, holder }];
        }
        if (groupedEvents.length === 3) {
            // 3 Transaction with the same transactionHash, (transactionIndex and blockNumber)
            // Merging HOLDER_TRANSFER, OWNER_TRANSFER and INITIAL/SURRENDER_ACCEPTED transactions
            // SURRENDER_ACCPTED: changes owner and holder to 0x0
            const base = groupedEvents[0];
            const type = "INITIAL";
            const { owner, holder } = getHolderOwner(groupedEvents);
            const found = groupedEvents.find((x) => {
                return x.type === "INITIAL" || x.type === "SURRENDER_ACCEPTED";
            });
            return [{ ...base, owner, holder, type: found?.type || type }];
        }
        throw new Error("Invalid hash, update your configuration");
    });
    return mergedTransaction;
};
/*
  Sort based on blockNumber
*/
export const sortLogChain = (logChain) => {
    return logChain.sort((a, b) => {
        return a.blockNumber - b.blockNumber;
    });
};

export const magic = new Magic(MAGIC_API_KEY, {
    //network: "goerli", // fix to goerli network only
    network: "sepolia", // fix to goerli network only
  });
  
