const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco/cec`

const CEC_ADMIN_CORRECTORS_PATH = `${MODULE_BASE}/connector-apps`;
const CEC_ADMIN_SHIPMENT_PROVIDERS_PATH =  `${MODULE_BASE}/shipment-providers`
const CEC_ADMIN_CORRECTOR_MAPPINGS_PATH = `${MODULE_BASE}/connector-mappings`
const CEC_ADMIN_COMMON_CITY_MAPPING_PATH = `${MODULE_BASE}/commoncity-mapping`
const CEC_UOM_CONVERSION = `${MODULE_BASE}/uom/conversion`
const CEC_DO_UOM_MAPPING = `${MODULE_BASE}/douom/map`
const CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH =  `${MODULE_BASE}/shipment-providers/connector-mappings`
const CEC_POSTCODE_CONFIG = `${MODULE_BASE}/postcodeConfig`
const CEC_ADMIN_PARTY_CONFIG = `${MODULE_BASE}/party-config`
const CEC_REPORT = `${MODULE_BASE}/report`
const CEC_DELIVERY_ORDER = '${MODULE_BASE}/delivery-orders'
const CEC_ADMIN_QUOTATION = `${MODULE_BASE}/quotation`

const pathMap = {

    //Connector Mappings
    CONNECTOR_APPS: CEC_ADMIN_CORRECTORS_PATH,

    CONNECTOR_APPS_LIST_VIEW: CEC_ADMIN_CORRECTORS_PATH,
    CONNECTOR_APPS_ADD_VIEW: `${CEC_ADMIN_CORRECTORS_PATH}/add`,
    CONNECTOR_APPS_EDIT_VIEW: `${CEC_ADMIN_CORRECTORS_PATH}/edit/:connectorAppId`,
    CONNECTOR_APPS_DETAILS_VIEW: `${CEC_ADMIN_CORRECTORS_PATH}/view/:connectorAppId`,
    CONNECTOR_APPS_DELETE_VIEW: `${CEC_ADMIN_CORRECTORS_PATH}/delete/:connectorAppId`,


    // CONNECTOR_MAPPING_RESOURCE_NAME : '/mappings',
    CONNECTOR_MAPPING: CEC_ADMIN_CORRECTOR_MAPPINGS_PATH,
    CONNECTOR_MAPPING_ADD_VIEW: `${CEC_ADMIN_CORRECTOR_MAPPINGS_PATH}/add`,
    CONNECTOR_MAPPING_EDIT_VIEW: `${CEC_ADMIN_CORRECTOR_MAPPINGS_PATH}/edit/:mappingId`,
    CONNECTOR_MAPPING_DETAILS_VIEW: `${CEC_ADMIN_CORRECTOR_MAPPINGS_PATH}/view/:mappingId`,
    CONNECTOR_MAPPING_DELETE_VIEW: `${CEC_ADMIN_CORRECTOR_MAPPINGS_PATH}/delete/:mappingId`,


    //COMMON_CITY_MAPPING
    COMMON_CITY_MAPPING: CEC_ADMIN_COMMON_CITY_MAPPING_PATH,
    COMMON_CITY_MAPPING_ADD_VIEW: `${CEC_ADMIN_COMMON_CITY_MAPPING_PATH}/create`,
    COMMON_CITY_MAPPING_EDIT_VIEW: `${CEC_ADMIN_COMMON_CITY_MAPPING_PATH}/edit/:id`,
    COMMON_CITY_MAPPING_DETAILS_VIEW: `${CEC_ADMIN_COMMON_CITY_MAPPING_PATH}/view/:id`,
    COMMON_CITY_MAPPING_DELETE: `${CEC_ADMIN_COMMON_CITY_MAPPING_PATH}/delete/:id`,

    //shipment provider
    SHIPMENT_PROVIDER_LIST_VIEW : CEC_ADMIN_SHIPMENT_PROVIDERS_PATH,
    SHIPMENT_PROVIDER_ADD_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_PATH}/add`,
    SHIPMENT_PROVIDER_EDIT_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_PATH}/edit/:shipmentProviderId`,
    SHIPMENT_PROVIDER_DETAILS_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_PATH}/view/:shipmentProviderId`,
    SHIPMENT_PROVIDER_DELETE_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_PATH}/delete/:shipmentProviderId`,

    //shipment provider connector mapping
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING : CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH,
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING_ADD_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH}/add`,
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING_EDIT_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH}/edit/:mappingId`,
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING_DETAILS_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH}/view/:mappingId`,
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING_DELETE_VIEW: `${CEC_ADMIN_SHIPMENT_PROVIDERS_CONNECTOR_MAPPING_PATH}/delete/:mappingId`,

    //UOM_CONVERSION
    CEC_UOM_CONVERSION: CEC_UOM_CONVERSION,
    CEC_UOM_CONVERSION_LIST_VIEW: CEC_UOM_CONVERSION,
    CEC_UOM_CONVERSION_ADD_VIEW: `${CEC_UOM_CONVERSION}/add`,
    CEC_UOM_CONVERSION_DETAILS_VIEW: `${CEC_UOM_CONVERSION}/view/:id`,
    CEC_UOM_CONVERSION_EDIT_VIEW: `${CEC_UOM_CONVERSION}/edit/:id`,

    //DO_UOM_MAPPING
    CEC_DO_UOM_MAPPING: CEC_DO_UOM_MAPPING,
    CEC_DO_UOM_MAPPING_LIST_VIEW: CEC_DO_UOM_MAPPING,
    CEC_DO_UOM_MAPPING_ADD_VIEW: `${CEC_DO_UOM_MAPPING}/add`,
    CEC_DO_UOM_MAPPING_DETAILS_VIEW: `${CEC_DO_UOM_MAPPING}/view/:id`,
    CEC_DO_UOM_MAPPING_EDIT_VIEW: `${CEC_DO_UOM_MAPPING}/edit/:id`,

    //POSTCODE_CONFIG
    CEC_POSTCODE_CONFIG: CEC_POSTCODE_CONFIG,
    CEC_POSTCODE_CONFIG_LIST_VIEW: CEC_POSTCODE_CONFIG,
    CEC_POSTCODE_CONFIG_ADD_VIEW: `${CEC_POSTCODE_CONFIG}/add`,
    CEC_POSTCODE_CONFIG_DETAILS_VIEW: `${CEC_POSTCODE_CONFIG}/view/:id`,
    CEC_POSTCODE_CONFIG_EDIT_VIEW: `${CEC_POSTCODE_CONFIG}/edit/:id`,

    //PARTY CONFIG
    CEC_ADMIN_PARTY_CONFIG: CEC_ADMIN_PARTY_CONFIG,
    CEC_ADMIN_PARTY_CONFIG_LIST_VIEW: CEC_ADMIN_PARTY_CONFIG,
    CEC_ADMIN_PARTY_CONFIG_DETAILS_VIEW: `${CEC_ADMIN_PARTY_CONFIG}/view/:id`,
    CEC_ADMIN_PARTY_CONFIG_EDIT_VIEW: `${CEC_ADMIN_PARTY_CONFIG}/edit/:id`,

    //PARTY CONFIG (SAVED ADDRESS)
    CEC_ADMIN_PARTY_CONFIG_SA: `${CEC_ADMIN_PARTY_CONFIG}/saved-address`,
    CEC_ADMIN_PARTY_CONFIG_SA_LIST_VIEW: `${CEC_ADMIN_PARTY_CONFIG}/saved-address`,
    CEC_ADMIN_PARTY_CONFIG_SA_DETAILS_VIEW: `${CEC_ADMIN_PARTY_CONFIG}/saved-address/view/:id`,
    CEC_ADMIN_PARTY_CONFIG_SA_EDIT_VIEW: `${CEC_ADMIN_PARTY_CONFIG}/saved-address/edit/:id`,

    //REPORT
    CEC_REPORT: CEC_REPORT,

    //QUOTATION
    CEC_ADMIN_QUOTATION: CEC_ADMIN_QUOTATION,
    CEC_ADMIN_QUOTATION_LIST_VIEW: CEC_ADMIN_QUOTATION,
    CEC_ADMIN_QUOTATION_DETAILS_VIEW: `${CEC_ADMIN_QUOTATION}/view/:id`,
    CEC_ADMIN_QUOTATION_EDIT_VIEW: `${CEC_ADMIN_QUOTATION}/edit/:id`,


}

export default pathMap
