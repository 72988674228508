import React, { useState, useEffect } from "react";
import { FormState } from "../constants/FormState";
import { AssetManagementActions } from "../constants/AssetManagementActions";
import { ActionSelectionForm } from "./FormVariants/ActionSelectionForm";
import { EndorseBeneficiaryForm } from "./FormVariants/EndorseBeneficiary";
import { EndorseTransferForm } from "./FormVariants/EndorseTransferForm";
import { NominateBeneficiaryForm } from "./FormVariants/NominateBeneficiary";
import { SurrenderForm } from "./FormVariants/SurrenderForm";
import { TransferHolderForm } from "./FormVariants/TransferHolderForm";
import { AcceptSurrenderedForm } from "./FormVariants/AcceptSurrenderedForm";
import { RejectSurrenderedForm } from "./FormVariants/RejectSurrenderedForm";
import { InitialAddress } from "../constants/chain-info";

import { useServices } from 'cng-web-lib'
import TradeTrustApiUrls from 'src/apiUrls/TradeTrustApiUrls'
import TradeTrustApi from 'src/views/tradetrust/shared/api'
import { TransferEventPingStatusMapConfig, WalletAddressesMapConfig, WalletAddressesMaxLength, PingStatusGeneralEventCode, PingStatusGeneralTransfer } from 'src/views/tradetrust/constants/TransferEventStatuses'

export const AssetManagementForm = ({
  account,
  formAction,
  tokenRegistryAddress,
  onConnectToWallet,
  beneficiary,
  holder,
  approvedBeneficiary,
  onSetFormAction,
  surrenderingState,
  destroyTokenState,
  onSurrender,
  onDestroyToken,
  documentOwner,
  isRestorer,
  isAcceptor,
  onTransferHolder,
  holderTransferringState,
  onEndorseBeneficiary,
  beneficiaryEndorseState,
  isSurrendered,
  isTokenBurnt,
  nominateBeneficiary,
  approveNewTransferTargetsState,
  transferOwners,
  transferOwnersState,
  setShowEndorsementChain,
  isTitleEscrow,
  onRestoreToken,
  restoreTokenState,
  tokenId,
  history,
  setConfirmDialogOpen,
  setModalData,
  pingMilestone,
  blNumber
}) => {
  const isActiveTitleEscrow = isTitleEscrow && !isSurrendered;
  const isHolder = isTitleEscrow && account === holder;
  const isBeneficiary = isTitleEscrow && account === beneficiary;
  const canSurrender = isBeneficiary && isHolder && !isSurrendered;
  /*
    In order for surrender we need to check 3 conditions
    - document is surrendered
    - documentOwner is the tokenRegistry
    -  currentUser === tokenRegistryMinter
  */
  const canHandleRestore = isTitleEscrow && isRestorer && isSurrendered && documentOwner === tokenRegistryAddress;
  const canHandleShred = isTitleEscrow && isAcceptor && isSurrendered && documentOwner === tokenRegistryAddress;

  // canEndorseBeneficiary
  // function transferBeneficiary(address beneficiaryNominee) external;
  // Only if (isHolder and isBeneficiary) or (nominee is previously nominated and isHolder)

  // function transferHolder(address newHolder) external;
  // onlyHolder, current holder !== new holder

  // canNominateBeneficiary
  // function nominate(address beneficiaryNominee) external;
  // Must be beneficiary, current beneficiary cannot nominate self
  // user requirements: onlyHolder

  // function transferOwners(address beneficiaryNominee, address newHolder) external;
  // transferHolder
  // transferBeneficiary

  const canNominateBeneficiary = isActiveTitleEscrow && isBeneficiary && !isHolder;

  const hasNominee = !!approvedBeneficiary && approvedBeneficiary !== InitialAddress;
  const canTransferBeneficiary = isActiveTitleEscrow && isHolder && hasNominee;
  const canTransferHolder = isActiveTitleEscrow && isHolder;
  const canTransferOwners = isActiveTitleEscrow && isHolder && isBeneficiary;

  const setFormActionNone = () => {
    if (
      surrenderingState === FormState.PENDING_CONFIRMATION ||
      destroyTokenState === FormState.PENDING_CONFIRMATION ||
      holderTransferringState === FormState.PENDING_CONFIRMATION ||
      beneficiaryEndorseState === FormState.PENDING_CONFIRMATION ||
      approveNewTransferTargetsState === FormState.PENDING_CONFIRMATION ||
      transferOwnersState === FormState.PENDING_CONFIRMATION
    )
      return;
    onSetFormAction(AssetManagementActions.None);
  };

  //================================== FOR PING MILESTONE PURPOSES
  const [pingStatusMapping, setPingStatusMapping] = useState([])
  const [walletAddressesMapping, setWalletAddressesMapping] = useState([])
  const { fetchRecords, createRecord } = useServices()

  /**
   * This function will send the PING milestone to the API endpoint.
   * @param {*} pingMilestoneRequest 
   */
  const sendPingMilestone = (pingMilestoneRequest) => {
    createRecord.execute(
      TradeTrustApiUrls.TRANSFER_STATUS_PING_MILESTONE,
      pingMilestoneRequest,
      sendMilestoneOnSuccess,
      sendMilestoneOnError
    )
  }

  function sendMilestoneOnSuccess(response) {
    console.log('Send Ping milestone connection-request onSuccess', response.message)
  }

  function sendMilestoneOnError(error) {
    console.log('Send Ping milestone connection-request error', error.message)
  }

  /**
   * This function will process the remaining fields in the Ping milestone request. 
   * It will obtain the wallet address mapping, the party name to be set in the ping milestone, and the current timestamp, and set them in the request.
   * It will then call the endpoint in SI API which will then send the milestone to PING.
   * @returns The actual Ping Milestone request to be sent to the API.
   */
  const processPingMilestone = (pingMilestone, newHolder) => {
    //Creating a copy of the Ping milestone prop passed into this component to prevent unnecessary rerenders.
    let pingMilestoneRequest = pingMilestone;

    /*
        First, we set the party name depending on the wallet addresss.
        If we have the wallet address mapping, set its name as the party name
        Else, we take the first few characters of the wallet address instead
    */
    let walletAddressRole;
    try {
      walletAddressRole = walletAddressesMapping[newHolder]
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
    let partyName;
    let basePartyNameMessage = "Transferred to ";
    if (walletAddressRole === undefined || walletAddressRole === null) {
      partyName = basePartyNameMessage.concat(newHolder.substring(0, WalletAddressesMaxLength), "...")
    }
    else {
      partyName = basePartyNameMessage.concat(walletAddressRole)
    }

    /*
        Second, we set the EBL event code depending on what is the formAction.
        For now, this is only the default EBL event code. (7 June 2023)
    */
    let pingEventCodeConfig;
    let pingEventCode;
    try {
      //This code segment is just in case we want to set up more event codes via the config.
      pingEventCodeConfig = pingStatusMapping[PingStatusGeneralTransfer]
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
    pingEventCode = (pingEventCodeConfig === undefined) ? PingStatusGeneralEventCode : pingEventCodeConfig

    //Get the latest timestamp for the Ping milestone.
    let currentTimestamp = Math.floor(Date.now())

    //Update the Ping milestone state variable.
    pingMilestoneRequest.partyName = partyName;
    pingMilestoneRequest.eventCode = pingEventCode;
    pingMilestoneRequest.eventDate = currentTimestamp;

    return pingMilestoneRequest;
  }


  /**
   * This function will get the SI configuration from the DB. 
   * It will obtain the PING statuses mapping, and the Wallet address mappings.
   * @param {*} data the configuration data obtained
   */
  const getSIConfigFromDb = (data) => {
    let content = data["content"]
    content.map(({ configFor, configValue1 }) => {
      if (configFor === TransferEventPingStatusMapConfig) {
        if (!configValue1) {
          console.error("TradeTrust PING status configuration is empty. Please ensure the configurations have been set up.")
          return;
        }
        setPingStatusMapping(JSON.parse(configValue1));
      }
      else if (configFor === WalletAddressesMapConfig) {
        if (!configValue1) {
          console.error("TradeTrust wallet addresses configuration is empty. Please ensure the configurations have been set up.")
          return;
        }
        setWalletAddressesMapping(JSON.parse(configValue1));
      }
    })
  }
  //================================== END CODE SEGMENT FOR PING MILESTONE PURPOSES

  useEffect(() => {
    //Get Ping status mapping, and Wallet Address mapping configs
    TradeTrustApi.fetchSIConfig(fetchRecords, getSIConfigFromDb)
  }, []);
  //================================== END CODE SEGMENT FOR PING MILESTONE PURPOSES

  switch (formAction) {
    case AssetManagementActions.Surrender:
      return (
        <SurrenderForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          handleSurrender={onSurrender}
          surrenderingState={surrenderingState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
        />
      );

    case AssetManagementActions.AcceptSurrendered:
      return (
        <AcceptSurrenderedForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          handleDestroyToken={onDestroyToken}
          destroyTokenState={destroyTokenState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
        />
      );

    case AssetManagementActions.RejectSurrendered:
      return (
        <RejectSurrenderedForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          handleRestoreToken={onRestoreToken}
          restoreTokenState={restoreTokenState}
        />
      );

    case AssetManagementActions.NominateBeneficiary:
      return (
        <NominateBeneficiaryForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          handleNomination={nominateBeneficiary}
          nominationState={approveNewTransferTargetsState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          history={history}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
        />
      );

    case AssetManagementActions.EndorseBeneficiary:
      return (
        <EndorseBeneficiaryForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          nominee={approvedBeneficiary}
          handleBeneficiaryTransfer={onEndorseBeneficiary}
          beneficiaryEndorseState={beneficiaryEndorseState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
        />
      );

    case AssetManagementActions.TransferHolder:
      return (
        <TransferHolderForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          handleTransfer={onTransferHolder}
          holderTransferringState={holderTransferringState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
          sendPingMilestone={sendPingMilestone}
          blNumber={blNumber}
          walletAddressesMapping={walletAddressesMapping}
          pingStatusMapping={pingStatusMapping}
          pingMilestone={pingMilestone}
          processPingMilestone={processPingMilestone}
        />
      );

    case AssetManagementActions.EndorseTransfer:
      return (
        <EndorseTransferForm
          formAction={formAction}
          tokenRegistryAddress={tokenRegistryAddress}
          holder={holder}
          handleEndorseTransfer={transferOwners}
          transferOwnersState={transferOwnersState}
          setFormActionNone={setFormActionNone}
          setShowEndorsementChain={setShowEndorsementChain}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
          sendPingMilestone={sendPingMilestone}
          blNumber={blNumber}
          walletAddressesMapping={walletAddressesMapping}
          pingStatusMapping={pingStatusMapping}
          pingMilestone={pingMilestone}
          processPingMilestone={processPingMilestone}
        />
      );

    default:
      return (
        <ActionSelectionForm
          formAction={formAction}
          onSetFormAction={onSetFormAction}
          tokenRegistryAddress={tokenRegistryAddress}
          beneficiary={beneficiary}
          holder={holder}
          account={account}
          canSurrender={canSurrender}
          canHandleRestore={canHandleRestore}
          canHandleShred={canHandleShred}
          onConnectToWallet={onConnectToWallet}
          canChangeHolder={canTransferHolder}
          canEndorseBeneficiary={canTransferBeneficiary}
          isSurrendered={isSurrendered}
          isTokenBurnt={isTokenBurnt}
          canNominateBeneficiary={canNominateBeneficiary}
          canEndorseTransfer={canTransferOwners}
          setShowEndorsementChain={setShowEndorsementChain}
          isTitleEscrow={isTitleEscrow}
          history={history}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
        />
      );
  }
};

export default AssetManagementForm;
