import {
    Button,
    MessageTitle,
    OverlayContext,
    showDocumentTransferMessage,
    LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect } from "react";
import { FormState } from "../../constants/FormState";
import { TagBorderedLg } from "../Tag";
import AssetInformationPanel from "../AssetInformationPanel/index";
import { AssetManagementTitle } from "../AssetManagementTitle";

export const RejectSurrenderedForm = ({
    formAction,
    tokenRegistryAddress,
    beneficiary,
    holder,
    setFormActionNone,
    setShowEndorsementChain,
    handleRestoreToken,
    restoreTokenState,
}) => {
    const isRestoreTokenPendingConfirmation = restoreTokenState === FormState.PENDING_CONFIRMATION;
    const isRestoreTokenConfirmed = restoreTokenState === FormState.CONFIRMED;
    const { showOverlay } = useContext(OverlayContext);

    const onClickRejectSurrender = () => {
        showOverlay(
            showDocumentTransferMessage(MessageTitle.CONFIRM_REJECT_SURRENDER_DOCUMENT, {
                isSuccess: true,
                beneficiaryAddress: beneficiary,
                holderAddress: holder,
                isConfirmationMessage: true,
                onConfirmationAction: () => handleRestoreToken(),
            })
        );
    };

    useEffect(() => {
        if (isRestoreTokenConfirmed) {
            showOverlay(showDocumentTransferMessage(MessageTitle.REJECT_SURRENDER_DOCUMENT, { isSuccess: true }));
            setFormActionNone();
        }
    }, [showOverlay, setFormActionNone, isRestoreTokenConfirmed]);

    return (
        <div className="flex flex-wrap py-4">
            <div className="w-full">
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isRestoreTokenPendingConfirmation}
                />
                <div className="flex flex-wrap pb-4">
                    <div className="w-full lg:flex-grow lg:w-auto">
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                        />
                    </div>
                    <div className="w-full lg:w-auto self-end">
                        <div className="py-4">
                            <TagBorderedLg id="surrender-sign" className="bg-white rounded-xl text-scarlet-500 border-scarlet-500">
                                <h3 className="text-4xl">Surrendered To Issuer</h3>
                            </TagBorderedLg>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap pb-4">
                    <div className="w-auto lg:ml-auto">
                        <div className="flex flex-wrap">
                            <div className="w-auto">
                                <Button
                                    className="bg-white rounded-xl text-lg py-2 px-3text-lg py-2 px-3 border-cloud-100 text-cloud-800 shadow-none hover:bg-cloud-200"
                                    onClick={setFormActionNone}
                                    disabled={isRestoreTokenPendingConfirmation}
                                    data-testid={"cancelSurrenderBtn"}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className="w-auto ml-2">
                                <Button
                                    className="bg-scarlet-500 text-white rounded-xl text-lg py-2 px-3 shadow-none hover:bg-scarlet-400"
                                    onClick={onClickRejectSurrender}
                                    disabled={isRestoreTokenPendingConfirmation}
                                    data-testid={"rejectSurrenderBtn"}
                                >
                                    {isRestoreTokenPendingConfirmation ? (
                                        <LoaderSpinner data-testid={"reject-loader"} />
                                    ) : (
                                        <>Reject Document</>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
