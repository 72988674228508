import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Ping'
import PingSearchBookingList from './components/Pages/PingSearch_BookingList'
import PingSearchFavouriteList from './components/Pages/PingSearch_FavouriteList'
import PingSearchHistoryList from './components/Pages/PingSearch_HistoryList'
import PingSearchBookingSearch from './components/Pages/PingSearch_BookingSearch'
import PingSearchContainerSearch from './components/Pages/PingSearch_ContainerSearch'
import PingSearchBLNumSearch from './components/Pages/PingSearch_BLNumSearch'
import PingSearchVesselSearch from './components/Pages/PingSearch_VesselSearch'
import PingSearchEventSubscription from './components/Pages/PingSearch_EventSubscription'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
    const { translate } = useTranslation(Namespace.PING)
    let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)
    // console.log('translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)', useTranslation(Namespace.PING))
    return (title);
}

//* Wrapped Search Page - Booking List -----------------------------------------
function WrappedSearchPage_BookingList(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchBookingList showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Page - Favourite List ---------------------------------------
function WrappedSearchPage_FavouriteList(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchFavouriteList showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Page - History List -----------------------------------------
function WrappedSearchPage_HistoryList(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchHistoryList showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Result Page - Booking ---------------------------------------
function WrappedSearchPage_Booking(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchBookingSearch showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Result Page - Container -------------------------------------
function WrappedSearchPage_Container(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchContainerSearch showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Result Page - BLNum -------------------------------------
function WrappedSearchPage_BLNum(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchBLNumSearch showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Search Result Page - Vessel Name -----------------------------------
function WrappedSearchPage_Vessel(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchVesselSearch showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* ----------------------------------------------------------------------------
function WrappedSearchPage_EventSubscription(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <PingSearchEventSubscription showNotification={showSnackbar} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={"Event Subscription"}
        />
    )
}

export {
    WrappedSearchPage_BookingList as PingSearch_BookingList,
    WrappedSearchPage_FavouriteList as PingSearch_FavouriteList,
    WrappedSearchPage_HistoryList as PingSearch_HistoryList,
    WrappedSearchPage_Booking as PingSearch_BookingSearch,
    WrappedSearchPage_Container as PingSearch_ContainerSearch,
    WrappedSearchPage_BLNum as PingSearch_BLNumSearch,
    WrappedSearchPage_Vessel as PingSearch_VesselSearch,
    WrappedSearchPage_EventSubscription as PingSearch_EventSubscription
}