import { Box, Button, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SCOJobTranslationText from '../SCOJobTranslationText'
import { components } from 'cng-web-lib'
const { 
	button: { CngSecondaryButton },
} = components

function JobButtonComponents({ onDiscard, onSaveDraft, onCreate, onUpdate, jobStatus, onCancel, onClone }) {
    const translatedTextsObject = CalistaUiComponentTranslationText();
    const scoJobTranslatedTextObject =  SCOJobTranslationText()
    const [status, setStatus] = useState('DRFT')

    useEffect(() => {
        if (jobStatus !== undefined) {
            setStatus(jobStatus)
        } else {
            setStatus('DRFT')
        }

        return () => {
            {
                //empty block
            }
        }

    }, [jobStatus])


    if (status == 'DRFT') { //draft or create new job
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12} justify="flex-start">
                    <Grid container xs={3} sm={6} >
                        <Button variant="contained"
                            color="secondary"
                            onClick={() => {
                                onDiscard()
                            }
                            }
                            classes={{ root: 'ng-button-filled-secondary' }}
                        >
                            {translatedTextsObject.discard}
                        </Button>
                    </Grid>
                    <Grid container xs={9} sm={6} justify="flex-end" >
                        <Box pr={2}>
                            <Button variant="contained"
                                className="button-blue originalText"
                                // type="submit"
                                onClick={() => {
                                    onSaveDraft()
                                }
                                }
                            >
                                {translatedTextsObject.saveDraft}
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="contained"
                                className="button-light-green originalText"
                                // type="submit"
                                onClick={() => {
                                    onCreate()
                                }
                                }
                            >
                                {translatedTextsObject.create}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    } else if (status == 'COMPL') { //created
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12} justify="flex-start">
                    <Grid container xs={9} sm={6}>
                        <Box pr={2}>
                            <Button variant="contained"
                                color="secondary"
                                onClick={() => {
                                    onDiscard()
                                }
                                }
                                classes={{ root: 'ng-button-filled-secondary' }}
                            >
                                {translatedTextsObject.discard}
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="contained"
                                className="ng-button-filled-primary"
                                onClick={() => {
                                    onClone()
                                }}
                            >
                                {scoJobTranslatedTextObject.scoCloneJob}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid container xs={3} sm={6} justify="flex-end" >
                        <Button variant="contained"
                            className="button-light-green originalText"
                            type="submit"
                            onClick={() => {
                                onUpdate()
                            }}
                        >
                            {translatedTextsObject.update}
                        </Button>
                    </Grid>
                </Grid>
            </Box >
        )
    } else if (status == 'ACTV') { //created
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12} justify="flex-start">
                    <Grid container xs={9} sm={6} >
                        <Box pr={2}>
                             {/* Empty on purpose */}
                        </Box>
                        <Box>
                            <Button variant="contained"
                                color="secondary"
                                onClick={() => {
                                    onDiscard()
                                }
                                }
                                classes={{ root: 'ng-button-filled-secondary' }}
                            >
                                {translatedTextsObject.discard}
                            </Button>
                        </Box>
                        <Box pr={2}>
                            {/* Empty on purpose */}
                        </Box>
                        <Box>
                            <Button variant="contained"
                                className="ng-button-filled-primary"
                                onClick={() => {
                                    onClone()
                                }}
                            >
                                {scoJobTranslatedTextObject.scoCloneJob}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid container xs={9} sm={6} justify="flex-end" >
                        <Box pr={2}>
                             {/* Empty on purpose */}
                        </Box>
                        <Box>
                            <Button variant="contained"
                                className="button-light-green originalText"
                                type="submit"
                                onClick={() => {
                                    onUpdate()
                                }}
                            >
                                {translatedTextsObject.update}
                            </Button>
                        </Box>

                        <Box pr={2}>
                            {/* Empty on purpose */}
                        </Box>

                        <Box>
                            <CngSecondaryButton 
                            variant="contained"  
                                className=" originalText"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                {scoJobTranslatedTextObject.scoCancelJob}
                            </CngSecondaryButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    } else if (status == 'CANC') { //cancelled
        return (
            <Box pl={5} pr={5} pt={4} pb={4} >
                <Grid container xs={12} sm={12} justify="flex-start">
                    <Box>
                        <Button variant="contained"
                            className="ng-button-filled-primary"
                            onClick={() => {
                                onClone()
                            }}
                        >
                            {scoJobTranslatedTextObject.scoCloneJob}
                        </Button>
                    </Box>
                </Grid>
            </Box >
        )
    } else {
        return (
            <br />
        )
    }

}

export default JobButtonComponents