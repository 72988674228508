import React, { useState, useEffect } from 'react'
import { Grid, Container } from '@material-ui/core'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

// Moment library
import Moment from 'moment';
import 'moment-timezone';
import PingTranslationText from 'src/views/ping/PingTranslationText'
import PingMyMilestoneUtils from 'src/views/ping/my-milestones/form-page/PingMyMilestoneUtils'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeSolid)

function BookingMilestoneRow({ milestone, onChange, collapseExpandAll }) {

    const [isCollapsed, setCollapsed] = useState(true)

    // format the event date and time
    let moment = Moment(milestone.eventDate)

    if (milestone.eventTimezone) {
        moment.tz(milestone.eventTimezone)
    }

    /*
        EventDetailsLabel is to check if the Event Detail is a TradeTrust general transfer event. If it is, then overwrite the label.
        This is a quick and dirty way to do it, but it has been informally approved (for now). 
        Most likely temporary and will be removed in the future in place of a better way.
    */
    let eventDetailsLabel = milestone.eventCode === "EBLTG" ? "Additional Information" : "Party Name";

    const eventDateStr = moment.format('D MMM yyyy')
    const eventTimeStr = moment.format('HH:mm ([GMT] Z)')
    const pingTranslatedTextsObject = PingTranslationText()
    const handleExpandButtonClick = (event) => {
        let prevState = isCollapsed
        let targetState = !isCollapsed

        setCollapsed(targetState)

        // trigger the parent's onChange handler, if applicable.
        if (onChange) {
            onChange(prevState, targetState)
        }
    }

    // Listen on the Collapse/Expand-All, and overwrite the current row's collapse/expand state if needed.
    useEffect(() => {
        if (collapseExpandAll != null) {
            // true = expand. false = collapse.
            setCollapsed(!collapseExpandAll)
        }
    }, [collapseExpandAll])

    //* return JSX -------------------------------------------------------------
    let eventDetails = milestone.eventDetails
    let hasEventDetails = (eventDetails && Object.entries(eventDetails).length > 0)

    let containerDetails = milestone.containerDetails
    let hasContainerDetails = (containerDetails && Object.entries(containerDetails).length > 0)

    let isPCIOT = (containerDetails && Object.entries(containerDetails).length > 0) && milestone.eventCode == 'PCIOT'
    let isPCCOF = (containerDetails && Object.entries(containerDetails).length > 0) && milestone.eventCode == 'PCCOF'
    let isPCTSP = (containerDetails && Object.entries(containerDetails).length > 0) && milestone.eventCode == 'PCTSP'
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid className="milestone_row container_milestone_row" data-is_collapsed={isCollapsed} container>
            <Grid className="milestone_date" item xs={3} container>
                <label>
                    <span className="date">{eventDateStr}</span>
                    <span className="time">{eventTimeStr}</span>
                </label>
                <div className="line">
                    <div className="dot"></div>
                </div>
            </Grid>
            <Grid className="milestone_event" item xs={9} container>
                <Container>
                    <Grid className="milestone_event_header container_milestone_event_header" container alignItems="center">
                        <Grid className="event_locode_box" xs={11}>
                            <Grid className="event_name" item >
                                <span>{milestone.eventName}</span>
                            </Grid>

                            <Grid className="event_locode" item>
                                <div>
                                    <img className="flFag" src={`${process.env.PUBLIC_URL}/static/images/countries/${milestone.eventCountryCode}.svg`} />
                                    <span>{milestone.eventLocationName}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className="arrow" item xs={1}>
                            {
                                (hasEventDetails || hasContainerDetails) && // show only when has event or container details

                                <div onClick={handleExpandButtonClick}>
                                    <FontAwesomeIcon icon={["far", "chevron-down"]} />
                                </div>
                            }
                        </Grid>

                    </Grid>
                    {
                        (hasEventDetails) && // show only when has event details

                        // event details will only have party name.
                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper">
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={12}><label>{eventDetailsLabel}</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} container alignItems="center">
                                        <span>{milestone.eventDetails.partyName || ""}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        (hasContainerDetails) && (!isPCIOT) && (!isPCCOF) && (!isPCTSP) && // show only when has container details

                        // Container details will only have container number, and optional vessel/voyage info
                        // Manually calculate the maxHeight... Because css cannot animate 100% or Auto height...
                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper"
                            style={{ maxHeight: (isCollapsed) ? 0 : `${(containerDetails.length + 1) * 6}em` }}
                        >
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={3}><label>Container Num</label></Grid>
                                <Grid item xs={3}><label>Vessel Name</label></Grid>
                                <Grid item xs={3}><label>Voyage Num</label></Grid>
                                <Grid item xs={3}><label>Date &amp; Time</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                {
                                    containerDetails.map((containerDetail, index) => {

                                        // format the event date and time
                                        let _moment = Moment(containerDetail.eventDate)
                                        const eventDateTimeStr = _moment.format('D MMM yyyy HH:mm ([GMT] Z)')

                                        return (
                                            <Grid container alignItems="center">
                                                <Grid item xs={3} container alignItems="center">
                                                    <span>{containerDetail.containerNumber}</span>
                                                </Grid>
                                                <Grid item xs={3} container alignItems="center">
                                                    <span>{containerDetail.eventDetails.vesselName || ""}</span>
                                                </Grid>
                                                <Grid item xs={3} container alignItems="center">
                                                    <span>{containerDetail.eventDetails.voyageNum || ""}</span>
                                                </Grid>
                                                <Grid item xs={3} container alignItems="center">
                                                    <span>{eventDateTimeStr}</span>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        (isPCIOT) && // show only when has event details

                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper"
                        style={{ maxHeight: (isCollapsed) ? 0 : `${(containerDetails.length + 1) * 6}em` }}>
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={3}><label>Bee ID</label></Grid>
                                <Grid item xs={3}><label>Temp</label></Grid>
                                <Grid item xs={3}><label>Humidity</label></Grid>
                                <Grid item xs={3}><label>Ambience</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                {
                                    containerDetails.map((containerDetail, index) => {
                                        return (
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    <span>{containerDetail.eventDetails.beeId || ""}</span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span>{containerDetail.eventDetails.temp || ""}</span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span>{containerDetail.eventDetails.humidity || ""}</span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span>{containerDetail.eventDetails.ambience || ""}</span>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        ((isPCTSP) || (isPCCOF)) &&// Container event
                      
                        <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper"
                            style={{ maxHeight: (isCollapsed) ? 0 : `${(containerDetails.length + 1) * 6}em` }}
                        >
                            <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                                <Grid item xs={2}>{pingTranslatedTextsObject.sn}</Grid>
                                <Grid item xs={5}><label>Container Num</label></Grid>
                                <Grid item xs={5}><label>Date &amp; Time</label></Grid>
                            </Grid>
                            <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                                {
                                    containerDetails.map((containerDetail, index) => {

                                        // format the event date and time
                                        let _moment = Moment(containerDetail.eventDate)
                                        const eventDateTimeStr = _moment.format('D MMM yyyy HH:mm ([GMT] Z)')

                                        return (
                                            <Grid container alignItems="center">
                                                 <Grid item xs={2} container alignItems="center">
                                                    <span>{index+1}</span>
                                                </Grid>
                                                <Grid item xs={5} container alignItems="center">
                                                    <span>{containerDetail.containerNumber}</span>
                                                </Grid>
                                                <Grid item xs={5} container alignItems="center">
                                                    <span>{eventDateTimeStr}</span>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                    (isPCTSP) && 
                    <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper"
                    style={{ maxHeight: (isCollapsed) ? 0 : `${(containerDetails.length + 1) * 6}em` }}>
                        <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                            <Grid item xs={1}><label>{pingTranslatedTextsObject.sn}</label></Grid> 
                            <Grid item xs={3}><label>{pingTranslatedTextsObject.minTemp}</label></Grid>
                            <Grid item xs={3}><label>{pingTranslatedTextsObject.maxTemp}</label></Grid>
                            <Grid item xs={2}><label>{pingTranslatedTextsObject.unit}</label></Grid>
                            <Grid item xs={3}><label>{pingTranslatedTextsObject.instructions}</label></Grid>
                        </Grid>
                        <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                        {
                            containerDetails.map((containerDetail, index) => {
                                return (
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <span>{index+1}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>{containerDetail.eventDetails.minTemp || ""}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>{containerDetail.eventDetails.maxTemp || ""}</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <span>{PingMyMilestoneUtils.getTempDesc(containerDetail.eventDetails.unit) || ""}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {PingMyMilestoneUtils.constructRowComponnet(containerDetail.eventDetails.instructions, 17)}
                                    </Grid>
                                </Grid>
                                )
                            })
                        }
                        </Grid>
                    </Grid>
                    }
                    {
                    (isPCCOF) && 
                    <Grid className="milestone_event_detail_wrapper container_milestone_event_detail_wrapper"
                    style={{ maxHeight: (isCollapsed) ? 0 : `${(containerDetails.length + 1) * 6}em` }}>
                        <Grid className="milestone_event_detail_header container_milestone_event_detail_header" container alignItems="center">
                        <Grid item xs={2}><label>{pingTranslatedTextsObject.sn}</label></Grid> 
                        <Grid item xs={5}><label>{pingTranslatedTextsObject.cutoffType}</label></Grid>
                        <Grid item xs={5}><label>{pingTranslatedTextsObject.cutoffEventType}</label></Grid>
                        </Grid>
                        <Grid className="milestone_event_detail_row container_milestone_event_detail_row" container>
                        {
                            containerDetails.map((containerDetail, index) => {
                                return (
                                <Grid container alignItems="center" key={containerDetail.containerNumber}> {/*the key is set for sonarqube fix...*/}
                                    <Grid item xs={2}>
                                        <span>{index+1}</span>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {PingMyMilestoneUtils.constructRowComponnet(containerDetail.eventDetails.cutoffType, 37)}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {PingMyMilestoneUtils.constructRowComponnet(containerDetail.eventDetails.cutoffEventType, 37)}
                                    </Grid>
                                </Grid>
                                )
                            })
                        }
                        </Grid>
                    </Grid>
                    }

                </Container>
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BookingMilestoneRow
