import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_CeCAdmin'
import CommonCityMappingKeys from "../../constants/locale/key/cec/CommonCityMappingKeys"
import ConnectorConfigKeys from "../../constants/locale/key/cec/ConnectorConfigKeys"
import CecUomConversionKeys from 'src/constants/locale/key/CecUomConversion'
import CecDoUomMappingKeys from 'src/constants/locale/key/CecDoUomMapping'
import CecPostcodeConfigKeys from 'src/constants/locale/key/CecPostcodeConfig'
import ShipmentProviderKeys from "../../constants/locale/key/cec/ShipmentProviderKeys"
import PartyConfigKeys from "../../constants/locale/key/cec/PartyConfigKeys";
import ReportKeys from "../../constants/locale/key/cec/ReportKeys";
import QuotationKeys from "../../constants/locale/key/cec/QuotationKeys";

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.CONNECTOR_APPS, Namespace.SHIPMENT_PROVIDERS, Namespace.CEC_PARTY_CONFIG]

function useBreadcrumbNameMap() {

        const { translate } = useTranslation(translationNamespace)

        const breadcrumbNameMap = new Map()

        {
                // --------------- Common City Mappings---------------

                //Table page
                let tableTitle = translate(Namespace.CEC_COMMON_CITY_MAP, CommonCityMappingKeys.COMMON_CITY_MAPPING_TITLE)
                breadcrumbNameMap.set(pathMap.COMMON_CITY_MAPPING, tableTitle)

                let addPageTitle = translate(Namespace.CEC_COMMON_CITY_MAP, CommonCityMappingKeys.ADD_COMMON_CITY_TITLE)
                breadcrumbNameMap.set(pathMap.COMMON_CITY_MAPPING_ADD_VIEW, addPageTitle)

                let editPageTitle = translate(Namespace.CEC_COMMON_CITY_MAP, CommonCityMappingKeys.EDIT_COMMON_CITY_TITLE)
                breadcrumbNameMap.set(pathMap.COMMON_CITY_MAPPING_EDIT_VIEW, editPageTitle)

                let viewPageTitle = translate(Namespace.CEC_COMMON_CITY_MAP, CommonCityMappingKeys.VIEW_COMMON_CITY_TITLE)
                breadcrumbNameMap.set(pathMap.COMMON_CITY_MAPPING_DETAILS_VIEW, viewPageTitle)


                // --------------- Connector Configurations---------------

                // List View
                let listViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.CONNECTOR_CONFIGURATION);
                breadcrumbNameMap.set(pathMap.CONNECTOR_APPS_LIST_VIEW, listViewTitle);

                // Add View
                let addViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.CREATE_CONNECTOR);
                breadcrumbNameMap.set(pathMap.CONNECTOR_APPS_ADD_VIEW, addViewTitle);

                // Edit View
                let editViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.EDIT_CONNECTOR);
                breadcrumbNameMap.set(pathMap.CONNECTOR_APPS_EDIT_VIEW, editViewTitle);

                // Details View
                let detailsViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.VIEW_CONNECTOR);
                breadcrumbNameMap.set(pathMap.CONNECTOR_APPS_DETAILS_VIEW, detailsViewTitle);

                // --------------- Connector Mappings---------------
                // Add View
                let addMappingViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.CREATE_MAPPING);
                breadcrumbNameMap.set(pathMap.CONNECTOR_MAPPING_ADD_VIEW, addMappingViewTitle);

                // Edit View
                let editMappingViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.EDIT_MAPPING);
                breadcrumbNameMap.set(pathMap.CONNECTOR_MAPPING_EDIT_VIEW, editMappingViewTitle);

                // Details View
                let detailsMappingViewTitle = translate(Namespace.CONNECTOR_APPS, ConnectorConfigKeys.VIEW_MAPPING)
                breadcrumbNameMap.set(pathMap.CONNECTOR_MAPPING_DETAILS_VIEW, detailsMappingViewTitle)


                // --------------- Shipment Providers ---------------
                // List View
                let shipmentProviderListViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.SHIPMENT_PROVIDER_LIST);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_LIST_VIEW, shipmentProviderListViewTitle);

                // Add View
                let shipmentProviderAddViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.CREATE_SHIPMENT_PROVIDER);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_ADD_VIEW, shipmentProviderAddViewTitle);

                // Edit View
                let shipmentProviderEditViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.EDIT_SHIPMENT_PROVIDER);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_EDIT_VIEW, shipmentProviderEditViewTitle);

                // Details View
                let shipmentProviderDetailsViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.SHIPMENT_PROVIDER_DETAILS);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_DETAILS_VIEW, shipmentProviderDetailsViewTitle);


                // --------------- Shipment Providers Connector Mappings---------------

                // Add View
                let shipmentProviderConnectorMappingAddViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.CREATE_SHIPMENT_PROVIDER);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_ADD_VIEW, shipmentProviderConnectorMappingAddViewTitle);

                // Edit View
                let shipmentProviderConnectorMappingEditViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.EDIT_SHIPMENT_PROVIDER);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_EDIT_VIEW, shipmentProviderConnectorMappingEditViewTitle);

                // Details View
                let shipmentProviderConnectorMappingDetailsViewTitle = translate(Namespace.CONNECTOR_APPS, ShipmentProviderKeys.SHIPMENT_PROVIDER_DETAILS);
                breadcrumbNameMap.set(pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_DETAILS_VIEW, shipmentProviderConnectorMappingDetailsViewTitle);




                let UomConversionTableTitle = translate(Namespace.CEC_UOM_CONVERSION, CecUomConversionKeys.CEC_UOM_CONVERSION)
                breadcrumbNameMap.set(pathMap.CEC_UOM_CONVERSION_LIST_VIEW, UomConversionTableTitle)

                let UomConversionAddPageTitle = translate(Namespace.CEC_UOM_CONVERSION, CecUomConversionKeys.CEC_UOM_CONVERSION_CREATE)
                breadcrumbNameMap.set(pathMap.CEC_UOM_CONVERSION_ADD_VIEW, UomConversionAddPageTitle)

                let UomConversionEditPageTitle = translate(Namespace.CEC_UOM_CONVERSION, CecUomConversionKeys.CEC_UOM_CONVERSION_EDIT)
                breadcrumbNameMap.set(pathMap.CEC_UOM_CONVERSION_EDIT_VIEW, UomConversionEditPageTitle)

                let UomConversionViewPageTitle = translate(Namespace.CEC_UOM_CONVERSION, CecUomConversionKeys.CEC_UOM_CONVERSION_VIEW)
                breadcrumbNameMap.set(pathMap.CEC_UOM_CONVERSION_DETAILS_VIEW, UomConversionViewPageTitle)

                //------------CEC DO UOM MAP---------

                let DoUomMapTableTitle = translate(Namespace.CEC_DO_UOM_MAPPING, CecDoUomMappingKeys.CEC_DO_UOM_MAP)
                breadcrumbNameMap.set(pathMap.CEC_DO_UOM_MAPPING_LIST_VIEW, DoUomMapTableTitle)

                let DoUomMapAddPageTitle = translate(Namespace.CEC_DO_UOM_MAPPING, CecDoUomMappingKeys.CEC_DO_UOM_MAP_CREATE)
                breadcrumbNameMap.set(pathMap.CEC_DO_UOM_MAPPING_ADD_VIEW, DoUomMapAddPageTitle)

                let DoUomMapEditPageTitle = translate(Namespace.CEC_DO_UOM_MAPPING, CecDoUomMappingKeys.CEC_DO_UOM_MAP_EDIT)
                breadcrumbNameMap.set(pathMap.CEC_DO_UOM_MAPPING_EDIT_VIEW, DoUomMapEditPageTitle)

                let DoUomMapViewPageTitle = translate(Namespace.CEC_DO_UOM_MAPPING, CecDoUomMappingKeys.CEC_DO_UOM_MAP_VIEW)
                breadcrumbNameMap.set(pathMap.CEC_DO_UOM_MAPPING_DETAILS_VIEW, DoUomMapViewPageTitle)

                //------------CEC POSTCODE CONFIG---------

                let PostcodeConfigTableTitle = translate(Namespace.CEC_POSTCODE_CONFIG, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG)
                breadcrumbNameMap.set(pathMap.CEC_POSTCODE_CONFIG_LIST_VIEW, PostcodeConfigTableTitle)

                let PostcodeConfigAddPageTitle = translate(Namespace.CEC_POSTCODE_CONFIG, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_CREATE)
                breadcrumbNameMap.set(pathMap.CEC_POSTCODE_CONFIG_ADD_VIEW, PostcodeConfigAddPageTitle)

                let PostcodeConfigEditPageTitle = translate(Namespace.CEC_POSTCODE_CONFIG, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_EDIT)
                breadcrumbNameMap.set(pathMap.CEC_POSTCODE_CONFIG_EDIT_VIEW, PostcodeConfigEditPageTitle)

                let PostcodeConfigViewPageTitle = translate(Namespace.CEC_POSTCODE_CONFIG, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_VIEW)
                breadcrumbNameMap.set(pathMap.CEC_POSTCODE_CONFIG_DETAILS_VIEW, PostcodeConfigViewPageTitle)

                // --------------- PARTY CONFIG ---------------
                // List View
                let partyConfigListViewTitle = translate(Namespace.CEC_PARTY_CONFIG, PartyConfigKeys.PARTY_CONFIG_LIST);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_PARTY_CONFIG_LIST_VIEW, partyConfigListViewTitle);

                // Edit View
                let partyConfigEditViewTitle = translate(Namespace.CEC_PARTY_CONFIG, PartyConfigKeys.PARTY_CONFIG_DETAIL_EDIT);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_PARTY_CONFIG_EDIT_VIEW, partyConfigEditViewTitle);

                // Details View
                let partyConfigDetailsViewTitle = translate(Namespace.CEC_PARTY_CONFIG, PartyConfigKeys.PARTY_CONFIG_DETAIL);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_PARTY_CONFIG_DETAILS_VIEW, partyConfigDetailsViewTitle);

                // --------------- REPORT ---------------
                // View
                let reportViewTitle = translate(Namespace.CEC_REPORT, ReportKeys.MODULE_TITLE);
                breadcrumbNameMap.set(pathMap.CEC_REPORT, reportViewTitle);

                // --------------- RFQ QUOTATION ---------------
                // List View
                let quotationListViewTitle = translate(Namespace.CEC_QUOTATION, QuotationKeys.QUOTATION_LIST);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_QUOTATION_LIST_VIEW, quotationListViewTitle);

                // Edit View
                let quotationEditViewTitle = translate(Namespace.CEC_QUOTATION, QuotationKeys.QUOTATION_DETAIL_EDIT);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_QUOTATION_EDIT_VIEW, quotationEditViewTitle);

                // Details View
                let quotationDetailsViewTitle = translate(Namespace.CEC_QUOTATION, QuotationKeys.QUOTATION_DETAIL);
                breadcrumbNameMap.set(pathMap.CEC_ADMIN_QUOTATION_DETAILS_VIEW, quotationDetailsViewTitle);
                
        }

        return breadcrumbNameMap
}

export default useBreadcrumbNameMap
