const COMMON_URL = `${process.env.REACT_APP_SI_ORIGIN_URL}`

const TradeTrustApiUrls = {
  TRADETRUST_PAGE: `${COMMON_URL}/tradetrust/upload/`,
  TRADETRUST_VIEW_PAGE: `${COMMON_URL}/tradetrust/upload/viewer/`,
  TRADETRUST_ENDORSEMENT_PAGE: `${COMMON_URL}/tradetrust/upload/endorsement/`,
  TRANSFER_STATUS_PING_MILESTONE: `${COMMON_URL}/tradetrust/milestone-add/shipping-info`,
  GET_SI_CONFIG: `${COMMON_URL}/config/hdr/get`,
  GET_SI_DETAILS_BLNUM: `${COMMON_URL}/shippinginfo/details/getByBolNum`,
}

export default TradeTrustApiUrls