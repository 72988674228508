import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices,
  Yup
} from 'cng-web-lib'

import ShippingInstructionKeys from 'src/constants/locale/key/ShippingInstruction'
import Namespace from 'src/constants/locale/Namespace'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import SIApi from 'src/views/shippinginstruction/shared/api'
import React, { useEffect, useState } from 'react'

//import {  Yup, components, useServices } from 'cng-web-lib'
//import React, { Fragment, useEffect, useState } from 'react'

import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import Utils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import helpdeskPathMap from 'src/paths/PathMap_Helpdesk'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {
    FilterOperator: EQUAL
  }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateRange: 'all',
  pendingClicked: true,
  filterText: '',
  sortCode: '',
  pendingStatusCount: 0,
  processedStatusCount: 0,
  statusCodes: [
    CalistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_CONFIRMED,
    CalistaConstant.SI_STATUS_PENDING_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SI_RESUBMISSION,
    CalistaConstant.SI_STATUS_SI_CANCELLED,
    CalistaConstant.SI_STATUS_BL_ISSUED,
    CalistaConstant.SI_STATUS_BL_SURRENDERED,
    CalistaConstant.SI_STATUS_PENDING_SUBMISSION,
    CalistaConstant.SI_STATUS_BOOKING_CANCELLED

  ],
  status: {},
  bls: {},
  partyIds: [],
  carriers: {},
  showArchived: true,
  showDirectSI: false,
  statusTabChanged: true,
  blAppTypeCodes: ['SI_SB', 'SI_BL']
})

function TablePage(props) {

  const {
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SHIPPING_INSTRUCTION
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let searchSI = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: searchSI
      }
    )
    let shippingInfoId = translate (
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.SHIPPING_INFO_ID
    )
    let freightBookingId = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.FREIGHT_BOOKING_ID
    )
    let bookingReferenceNumber = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.BOOKING_REF_NUMBER
    )
    let shipperRefNumber = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.SHIPPER_REF_NUMBER
    )
    let blApplicationNo = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.BL_NO
    )
    let blApplicationType = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.BL_APPLICATION_TYPE
    )
    let shipperName = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.SHIPPER_NAME
    )
    let carrierNvoccBookingAgentName = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CARRIER_NVOCC_BOOKING_AGENT_NAME
    )
    let vesselName = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.VOYAGE_NUMBER
    )
    let noOfOriginals = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.NO_OF_ORIGINALS
    )
    let printedOriginals = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.PRINTED_ORIGINALS
    )
    let status = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.STATUS
    )

    return {
      searchSI,
      tableTitle,
      shippingInfoId,
      freightBookingId,
      bookingReferenceNumber,
      shipperRefNumber,
      blApplicationNo,
      blApplicationType,
      shipperName,
      carrierNvoccBookingAgentName,
      vesselName,
      voyageNumber,
      noOfOriginals,
      printedOriginals,
      status
    }
  }

  const columns = [
    {
      field: "shippingInfoId",
      title: translatedTextsObject.shippingInfoId,
      filtering: true
    },
    {
      field: "freightBookingId",
      title: translatedTextsObject.freightBookingId,
      filtering: true
    },
    {
      field: "bookingRefNo",
      title: translatedTextsObject.bookingReferenceNumber,
      filtering: true,
      sorting: false
    },
    {
      field: "shipperRefNo",
      title: translatedTextsObject.shipperRefNumber,
      filtering: true,
      sorting: false
    },
    {
      field: "billOfLadingNo",
      title: translatedTextsObject.blApplicationNo,
      filtering: true
    },
    {
      field: "billOfLadingAppType",
      title: translatedTextsObject.blApplicationType,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => { notification.error(error.message) },
          [{ field: 'codeType', operator: EQUAL, value: 'CAL_BL_TYPE' }]
        )
      }
    },
    {
      field: "shipperPartyId",
      title: translatedTextsObject.shipperName,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          { },
          'content',
          'name', // label accessor
          'id', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "carrierPartyId",
      title: translatedTextsObject.carrierNvoccBookingAgentName,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          { },
          'content',
          'name', // label accessor
          'id', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "vesselName",
      title: translatedTextsObject.vesselName,
      filtering: true
    },
    {
      field: "voyageNo",
      title: translatedTextsObject.voyageNumber,
      filtering: true
    },
    {
      field: 'noOfOriginals',
      title: translatedTextsObject.noOfOriginals,
      filtering: true
    },
    {
      field: "noOfOriginalsPrinted",
      title: translatedTextsObject.printedOriginals,
      filtering: true
    },
    {
      field: "siStatus",
      title: translatedTextsObject.status,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.STATUS, // get status codes from SI TTFB_SYS_STATUS
          (error) => { notification.error(error.message) }, 
          [{ field: 'id', operator: EQUAL, value: DEFAULT_INITIAL_VALUES.statusCodes }], //filter status codes for helpdesk
          'descriptionEn', //show description of status codes
          'id' //pass status codes id to request instead of string code
        )
      }
    }
  ]

  const { fetchRecords } = useServices()
  const [, setStatusCodes] = useState([]);
  const [sortCode, setSortCode] = useState();
  const [partyIds, setPartyIds] = useState([]);

  const [, setLoading] = useState(true)
  const [, setTotalCount] = useState(0)

  let searchCriteria = {
    filterText: DEFAULT_INITIAL_VALUES.filterText,
    dateRange: DEFAULT_INITIAL_VALUES.dateRange, //dateRangeField.value,
    blAppTypeCodes: DEFAULT_INITIAL_VALUES.blAppTypeCodes, //blAppTypeCodesField.value,
    statusCodes: DEFAULT_INITIAL_VALUES.statusCodes, //statusCodesField.value,
    showArchived: DEFAULT_INITIAL_VALUES.showArchived, //showArchivedField.value,
    carrierCodes: partyIds, //partyIdsField.value,
    helpdesk: true,
    partyIds: DEFAULT_INITIAL_VALUES.partyIds, //partyIdsField.value,
    // sortCode: Utils.isEmptyString(sortCodeField.value)
    //   ? 1
    //   : sortCodeField.value,
    sortCode: Utils.isEmptyString(sortCode)
    ? 1
    : sortCode,
    showDirectSIOnly: DEFAULT_INITIAL_VALUES.showDirectSI //showDirectSIField.value
  }

  console.log("search criteria", searchCriteria)

  const [, setCarriers] = useState([])

  const [, setStatus] = useState([])

  const [, setPageError] = useState(false)
  const [refresh] = useState(false)



  useEffect(() => {
    setPageError(false)
    // setSortCodeField(
    //   sortCodeField.value == null || sortCodeField.value == ''
    //     ? 1
    //     : sortCodeField.value
    // )
    setSortCode(
      sortCode == null || sortCode == ''
        ? 1
        : sortCode
    )

    //get status code and count
    fetchStatusCodeAndCountFromAPI()

    //get party id based on status code, date range and sort code
  }, [refresh])

  function populatePartyId(dat) {
    console.log('populatePartyId()');
    console.log(dat);
    let FromApi = SiApiParser.parsePartyId(dat)
    console.log('FromApi', FromApi);
    console.log('FromApi[0]', FromApi[0]);
    if (FromApi[0] !== undefined) {
      FromApi.map((elem) => (elem.bookingCount = elem.shippingCount))
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.shippingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForSIList(dat)
      console.log(partyIdsForSearch)
      //console.log(partyIdsField)
      console.log(searchCriteria)

    } else {
      setLoading(false)
      setPartyIds(
        Utils.isEmptyString(partyIds) ? [] : partyIds
      )
    }

    setCarriers(FromApi)
  }
  function fetchPartyIdFromAPI() {
    searchCriteria.helpdesk = true;
    SIApi.fetchHelpdeskPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function getPartyIdForSIList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    console.log('loading party id from api')

    carrierList.map((v) => {
      console.log(v.carrierPartyId)
      partyIdss.push(v.carrierPartyId)
    })

    //temp for booking list
    searchCriteria.carrierCodes = partyIdss
    // searchCriteria.statusCodes = [5107]
    //setPartyIdsField(partyIdss)
    setPartyIds(partyIdss)
    console.log("PartyIds", partyIdss)
    return partyIdss
  }

  function populateStatusCodeAndCount(dat) {
    let isHelpdesk = true
    const data = SiApiParser.parseStatusCodeAndCount(dat, false, isHelpdesk)
    console.log("populateStatusCodeAndCount", data)

    setTotalCount(data.totalCount)
    const SiStatusList = data.siStatusList

    setStatus(
      //Utils.isEmptyObject(statusField.value) ? SiStatusList : statusField.value
      //Utils.isEmptyObject(status) ? SiStatusList : status
      SiStatusList
    )

    setStatusCodes(SiStatusList.map(sc => sc.statusCode))
    // setStatusCodesField(
    //   location.state == null ? pendingStatusCode : location.state.statusCodes
    // )
    /* setStatusField(
      location.state == null ? data.pendingList : location.state.status
    ) */
    fetchPartyIdFromAPI()
  }

  function fetchStatusCodeAndCountFromAPI() {
    searchCriteria.helpdesk = true;
    SIApi.fetchHelpdeskStatusCodeAndCount(
      fetchRecords,
      populateStatusCodeAndCount,
      onError
    )
  }


  function onError(error) {
    setPageError(true)
    setLoading(false)
  }

  const history = useHistory();

  const onViewButtonClick = (event, record) => {
    history.push({
      pathname: helpdeskPathMap.SI_DETAILS,
      // state: {
      //   shippingInfoId: record['shippingInfoId'],
      //   freightBookingId: record['freightBookingId'],
      //   siStatus: record['siStatus']
      // }
      state: {
        shippingInfoId: record.shippingInfoId,
        freightBookingId: record.freightBookingId,
        siStatus: record.siStatus
      }
    })
  }
  
  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {
              //wait for searchCriteria to fetch all data before rendering table
              searchCriteria.carrierCodes.length != 0 &&             
              <CngCrudTable
                {...props}
                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                columns={columns}
                fetchCustomData={searchCriteria}
                fetch= {{ url: ShippingInstructionApiUrls.HELPDESK_SHIPPING_LIST }}
                //idAccessor= "shippingInfoId"
                notification={notification}
                //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
                //viewRoute={`${pathname}/view-si`}
                onViewButtonClick = {onViewButtonClick}
                showRefreshButton = {false}
              />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
