import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeTrust.js'
import TradeTrusteBLPage from './TradeTrusteBLPage.js'
import TradeTrusteBLViewPageContainer from './TradeTrusteBLViewPageContainer.js'
import TradeTrustTranslationText from './TradeTrustTranslationText'

function ModuleTitle() {
  const translatedTextsObject = TradeTrustTranslationText();
  return translatedTextsObject.moduleTitle;
}

function WrappedTradeTrusteBLPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <TradeTrusteBLPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedTradeTrusteBLViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <TradeTrusteBLViewPageContainer showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedTradeTrusteBLPage as TradeTrusteBLPage,
  WrappedTradeTrusteBLViewPage as TradeTrusteBLViewPage
}
