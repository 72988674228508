import React, { useEffect, useState } from 'react'
import { Card, Tab, Tabs, Box, Typography } from '@material-ui/core'
import DocumentViewer from './components/DocumentViewer'
import EndorsementChainContainer from './components/EndorsementChainContainer'
import ObfuscatedMessage from './components/ObfuscatedMessage'
import { components, useServices } from 'cng-web-lib'
import { utils } from "@govtechsg/open-attestation";
import { getTokenRegistryAddress, getAttachments } from './common/shared'
import { useTokenInformationContext } from './common/contexts/TokenInformationContext/TokenInformationContext'
import { AssetManagementApplication } from './components/AssetManagementApplication'
import pathMap from 'src/paths/PathMap_TradeTrust'

import TradeTrustApi from 'src/views/tradetrust/shared/api'
import ViewAttachmentComponent from './components/ViewAttachmentComponent'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    CngAddForm,
    CngAddFormButtonSection
  },
  table: { useDefaultNotification }
} = components

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TradeTrusteBLViewPage({ history, showNotification, ...props }) {
  //When session times out then document will be undefined
  //So in this case, redirect user to upload page
  if (history.location.state.document == undefined || history.location.state.document == null) {
    history.push(pathMap.TRADETRUST_UPLOAD)
  }

  let document = history.location.state.document;

  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const isTransferableAsset = utils.isTransferableAsset(document);
  let tokenId = "";
  if (isTransferableAsset) {
    try {
      tokenId = `0x${utils.getAssetId(document)}`;
    } catch (err) {
      showErrorNotification(err.message)
    }
  }

  const tokenRegistryAddress = isTransferableAsset ? getTokenRegistryAddress(document) : "";
  const isTransferableDocument = !!tokenRegistryAddress;
  const [showEndorsementChain, setShowEndorsementChain] = useState(false);
  const { initialize, resetStates: resetTokenInformationState } = useTokenInformationContext();

  const [pingMilestone, setPingMilestone] = useState();
  const [blNumber, setBlNumber] = useState()
  const [value, setValue] = useState(0);
  const { fetchRecords } = useServices();
  const attachments = getAttachments(document);
  const hasAttachments = attachments ? attachments.length > 0 : false;

  // Comments: Omitted Certificate as might not be needed for eBL

  useEffect(() => {
    let tempBlNum;
    //Get BL number from document
    if (document != null && document != undefined) {
      const splitBlNum = (document.data.blNumber).split(':')
      tempBlNum = splitBlNum[splitBlNum.length - 1]
      setBlNumber(tempBlNum);
    }

    createMilestoneBase(tempBlNum);
  }, []);

  useEffect(() => {
    if (tokenRegistryAddress) {
      initialize(tokenRegistryAddress, tokenId);
    }
    return () => {
      resetTokenInformationState();
    };
  }, [tokenId, tokenRegistryAddress, initialize, resetTokenInformationState]);

  // ============================ FUNCTIONS FOR PING MILESTONE 
  /**
   * This function will create the base milestone object. 
   * It will put in the default values, and insert the BL number passed in from the parent object.
   */
  function createMilestoneBase(tempBlNum) {
    let currentTimestamp = Math.floor(Date.now())
    let milestoneBody = {
      eventCode: '',
      eventDate: currentTimestamp,
      eventLocode: '',
      eventSourceCode: 'EBL',
      blNumber: tempBlNum,
      partyName: ''
    };
    populateMilestoneSIDetails(milestoneBody) //now populate the SI related fields
  }

  /**
   * This function will call the SI API to get the SI details based on the BL number Then it will populate some of the PING milestone properties.
   * @param {*} milestoneBody 
   */
  function populateMilestoneSIDetails(milestoneBody) {
    const siRequest = {
      'billOfLadingNo': blNumber
    }
    TradeTrustApi.fetchSIDetailsByBlNum(fetchRecords, siRequest, getSiDetailsFromBlNumSuccess, milestoneBody)
  }

  /**
   * This function will set the details of the SI into the state variable once calling the SI REST API is successful.
   * @param {*} siDetails the SI details obtained from the SI API
   * @param {*} milestoneBody the milestone body that we passed in, containing previously set default values and BL number
   */
  function getSiDetailsFromBlNumSuccess(siDetails, milestoneBody) {
    milestoneBody.eventLocode = siDetails[0].polCode ? siDetails[0].polCode : 'SGSIN'
    setPingMilestone(prevState => ({ ...prevState, ...milestoneBody }))
  }
  // ============================ END FUNCTIONS FOR PING MILESTONE 

  const handleTabComponent = (event, newValue) => {
    setValue(newValue);
  }

  const renderedEndorsementChain = (
    <div className="bg-cerulean-50 no-print">
      <ObfuscatedMessage document={document} />
      {isTransferableDocument && (
        <EndorsementChainContainer
          tokenId={tokenId}
          tokenRegistry={tokenRegistryAddress}
          setShowEndorsementChain={setShowEndorsementChain}
        />
      )}
    </div>
  );

  const renderedCertificateViewer = (
    <>
      <Card>
        <AssetManagementApplication
          tokenId={tokenId}
          tokenRegistryAddress={tokenRegistryAddress}
          setShowEndorsementChain={setShowEndorsementChain}
          history={history}
          pingMilestone={pingMilestone}
          blNumber={blNumber}
        />
      </Card>
      <br />
      <Card>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, backgroundColor: 'white'}}>
            <Tabs value={value} onChange={handleTabComponent} indicatorColor='primary'>
              <Tab label = "Bill of Lading" {...a11yProps(0)} >
              </Tab>
              <Tab label = "Attachments" {...a11yProps(1)} hidden = {hasAttachments? false: true}>
              </Tab>
            </Tabs>
            <TabPanel value = {value} index = {0}>
              <DocumentViewer
                document={history.location.state.document}
              />
            </TabPanel>
            <TabPanel value = {value} index = {1}>
              <ViewAttachmentComponent.FormBody
                attachments = {attachments}
              />
            </TabPanel>
          </Box>
        </Box>
      </Card>
    </>
  );

  return (
    <div>
      {showEndorsementChain ? renderedEndorsementChain : renderedCertificateViewer}
    </div>
  );
}

export default TradeTrusteBLViewPage