import { utils } from "ethers";
import { compareDesc, compareAsc } from "date-fns";
import { getChainInfo } from "../common/chain-utils";

export const makeEtherscanAddressURL = (address, chainId) => {
    const baseUrl = getChainInfo(chainId).explorerUrl;
    return new URL(`/address/${address}`, baseUrl).href;
};

export const isValidEndorseTransfer = (holder, newHolder, newOwner) => {
    if (!newHolder || !newOwner) return false;
    if (newHolder === holder) return false;
    if (!isEthereumAddress(newHolder) || !isEthereumAddress(newOwner)) return false;

    return true;
};

export const isEthereumAddress = (address) => {
    try {
        if (utils.getAddress(address)) {
            return true;
        }
    } catch (e) {
        if (e.reason === "invalid address") {
            return false;
        }
        //Do not throw for other errors in address
        //else throw e;
    }
};

export const getSortedByDateDesc = (items) => {
    items.sort((a, b) => {
        return compareDesc(new Date(a.attributes.date), new Date(b.attributes.date));
    });

    return items;
};

export const getSortedByDateAsc = (items) => {
    items.sort((a, b) => {
        return compareAsc(new Date(a.attributes.date), new Date(b.attributes.date));
    });

    return items;
};

// https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
export const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

export const addClassNameIfExist = (className) => {
    if (!className) {
        return "";
    }

    return className;
};

export const currentDateStr = () => {
    return new Date().toLocaleString("en-SG", { hour12: true, timeZoneName: "short" });
};