import makeValidationSchema from './PollingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PollingKeys from 'src/constants/locale/key/Polling'
import CommonUtils from 'src/views/common/utils/Utils'
import { Grid } from '@material-ui/core'
import {
  components,
  DateTimeFormatter,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngDateTimeField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  pollingId: 0,
  portPair: "",
  cargoReadyDate: "",
  rangeOfCargoReadyDate: "",
  totalSource: 0,
  processedSource: 0,
  failureSource: 0,
  runDate: "",
  completedDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.POLLING)
  const translatedTextsObject = makeTranslatedTextsObject()
  const SG_DATE_FORMAT = CommonUtils.UI_FORMAT_DATE_SERVER_DATE

  function makeTranslatedTextsObject() {
    let pollingId = translate(
      Namespace.POLLING,
      PollingKeys.POLLING_ID
    )
    let portPair = translate(
      Namespace.POLLING,
      PollingKeys.PORT_PAIR
    )
    let cargoReadyDate = translate(
      Namespace.POLLING,
      PollingKeys.CARGO_READY_DATE
    )
    let rangeOfCargoReadyDate = translate(
      Namespace.POLLING,
      PollingKeys.RANGE_OF_CARGO_READY_DATE
    )
    let totalSource = translate(
      Namespace.POLLING,
      PollingKeys.TOTAL_SOURCE
    )
    let processedSource = translate(
      Namespace.POLLING,
      PollingKeys.PROCESSED_SOURCE
    )
    let failureSource = translate(
      Namespace.POLLING,
      PollingKeys.FAILURE_SOURCE
    )
    let runDate = translate(
      Namespace.POLLING,
      PollingKeys.RUN_DATE
    )
    let completedDate = translate(
      Namespace.POLLING,
      PollingKeys.COMPLETED_DATE
    )

    return {
      pollingId,
      portPair,
      cargoReadyDate,
      rangeOfCargoReadyDate,
      totalSource,
      processedSource,
      failureSource,
      runDate,
      completedDate
    }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pollingId}>
        <CngTextField
          name="pollingId"
          type="number"
          label={translatedTextsObject.pollingId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portPair}>
        <CngTextField
          name="portPair"
          label={translatedTextsObject.portPair}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoReadyDate}>
        <CngTextField
          name="cargoReadyDate"
          label={translatedTextsObject.cargoReadyDate}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.rangeOfCargoReadyDate}>
        <CngTextField
          name="rangeOfCargoReadyDate"
          label={translatedTextsObject.rangeOfCargoReadyDate}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalSource}>
        <CngTextField
          name="totalSource"
          type="number"
          label={translatedTextsObject.totalSource}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processedSource}>
        <CngTextField
          name="processedSource"
          type="number"
          label={translatedTextsObject.processedSource}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.failureSource}>
        <CngTextField
          name="failureSource"
          type="number"
          label={translatedTextsObject.failureSource}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.runDate}>
        <CngDateTimeField
          name="runDate"
          label={translatedTextsObject.runDate}
          format={SG_DATE_FORMAT}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.completedDate}>
        <CngDateTimeField
          name="completedDate"
          label={translatedTextsObject.completedDate}
          format={SG_DATE_FORMAT}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.runDate = DateTimeFormatter.toClientDateTime(
    localData.runDate
  );
  localData.completedDate = DateTimeFormatter.toClientDateTime(
    localData.completedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.runDate = DateTimeFormatter.toServerDateTime(
    localData.runDate
  );
  localData.completedDate = DateTimeFormatter.toServerDateTime(
    localData.completedDate
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
