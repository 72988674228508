import React from "react";
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';

export const SkeletonPlaceholder = () => {
    return (
        <div className="py-4">
            <Stack spacing={1}>
                <Skeleton variant="rounded" width={300} height={25} />
                <Skeleton variant="rounded" width={300} height={25} />
            </Stack>
        </div>
    );
};

export default SkeletonPlaceholder