import Namespace from 'src/constants/locale/Namespace'
import TradeTrust from 'src/constants/locale/key/TradeTrust'
import { useTranslation } from 'cng-web-lib'


const TradeTrustTranslationText = () => {
      const { translate } = useTranslation([
            Namespace.UI_COMPONENT,
            Namespace.TradeTrust
      ])
      const moduleTitle = translate(Namespace.TradeTrust, TradeTrust.TITLE)
      const networkType = translate(Namespace.TradeTrust, TradeTrust.NETWORK_TYPE)

      return {
            moduleTitle,
            networkType
      }

}

export default TradeTrustTranslationText