import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_MGTT'
import TranslationText from './TranslationText'
import DriverPage from './DriverPage'
import TransporterPage from './TransporterPage'
import OperatorPage from './OperatorPage'
import InternalUserPage from './InternalUserPage'
import BatchJobPage from './BatchJobPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = TranslationText();
      return translatedTextsObject.moduleTitle;
}

function WrappedDriverPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <DriverPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedTransporterPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <TransporterPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


function WrappedOperatorPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <OperatorPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


function WrappedInternalUserPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <InternalUserPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

function WrappedBatchJobPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()

      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <BatchJobPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}



export {
      WrappedDriverPage as DriverPage,
      WrappedTransporterPage as TransporterPage,
      WrappedOperatorPage as OperatorPage,
      WrappedInternalUserPage as InternalUserPage,
      WrappedBatchJobPage as BatchJobPage
}