import { Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useRef, useState } from "react";
import { components } from 'cng-web-lib';
import moment from 'moment';
import BackButton from 'src/components/button/BackButton';
import { Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const {
	card: { CngSimpleCardHeader }
} = components

const ActionType = {
	INITIAL: "Document has been issued",
	NEW_OWNERS: "Change Owners",
	ENDORSE: "Endorse change of ownership",
	TRANSFER: "Transfer holdership",
	SURRENDERED: "Document surrendered to issuer",
	SURRENDER_REJECTED: "Surrender of document rejected",
	SURRENDER_ACCEPTED: "Surrender of document accepted", // burnt token
	TRANSFER_TO_WALLET: "Transferred to wallet",
}

function EndorsementChainLayout({ showNotification, endorsementChain, showEndorsementChain, setShowEndorsementChain, error }) {
	const getHistoryChain = (endorsementChain) => {
		const historyChain = [];

		if (endorsementChain != null) {
			endorsementChain.forEach((endorsementChainEvent) => {
				const beneficiary = endorsementChainEvent.owner;
				const holder = endorsementChainEvent.holder;
				const timestamp = moment.unix(endorsementChainEvent.timestamp / 1000).format("DD/MM/YYYY, hh:mm:ss");
				const hash = endorsementChainEvent.transactionHash;
				switch (endorsementChainEvent.type) {
					case "TRANSFER_OWNERS":
						historyChain.push({
							action: ActionType.NEW_OWNERS,
							isNewBeneficiary: true,
							isNewHolder: true,
							beneficiary,
							holder,
							timestamp,
							hash,
						});
						break;
					case "TRANSFER_BENEFICIARY":
						historyChain.push({
							action: ActionType.ENDORSE,
							isNewBeneficiary: true,
							isNewHolder: false,
							beneficiary,
							holder,
							timestamp,
							hash,
						});
						break;
					case "TRANSFER_HOLDER":
						historyChain.push({
							action: ActionType.TRANSFER,
							isNewBeneficiary: false,
							isNewHolder: true,
							beneficiary,
							holder,
							timestamp,
							hash,
						});
						break;
					case "SURRENDERED":
						historyChain.push({
							action: ActionType.SURRENDERED,
							isNewBeneficiary: true,
							isNewHolder: false,
							timestamp,
						});
						break;
					case "SURRENDER_ACCEPTED":
						historyChain.push({
							action: ActionType.SURRENDER_ACCEPTED,
							isNewBeneficiary: false,
							isNewHolder: false,
							timestamp,
						});
						break;
					case "SURRENDER_REJECTED":
						historyChain.push({
							action: ActionType.SURRENDER_REJECTED,
							isNewBeneficiary: true,
							isNewHolder: true,
							timestamp,
							beneficiary,
							holder: beneficiary,
							hash,
						});
						break;
					case "INITIAL":
						historyChain.push({
							action: ActionType.INITIAL,
							isNewBeneficiary: true,
							isNewHolder: true,
							beneficiary,
							holder,
							timestamp,
							hash,
						});
						break;

					default:
						throw Error("eventType not matched");
				}
			});
		}
		return historyChain;
	};

	const historyChains = getHistoryChain(endorsementChain);

	const timerRef = useRef();
	const [timeoutError, setTimeoutError] = useState(false);
	const loadingRows = 3;

	useEffect(() => {
		if (endorsementChain) {
			clearTimeout(timerRef.current);
			setTimeoutError(false);
		}

		if (!endorsementChain) {
			timerRef.current = setTimeout(() => {
				setTimeoutError(true);
			}, 60000)
		}
	}, [endorsementChain])

	return (
		< div >
			{//showEndorsementChain && (
				(
					<Card>
						<BackButton style={{ marginBlock: '15px', marginInline: '15px' }} onClick={() => {
							setShowEndorsementChain(false);
						}} />
						<br />
						<Grid container alignItems="center">
							<h2 style={{ fontWeight: 600, marginBlock: '15px', marginInline: '15px' }} className="break-words leading-tight">View Endorsement Chain</h2>
						</Grid>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									{!endorsementChain && !(error || timeoutError) &&
										<Stack sx={{ width: '30%' }}>
											<Alert variant="outlined" severity="info">
												<b>Please wait while the transactional records are loaded.</b>
											</Alert>
											<br />
										</Stack>
									}
									{!endorsementChain && (error || timeoutError) &&
										<Stack sx={{ width: '30%' }}>
											<Alert variant="outlined" severity="error">
												<b>An error has occurred, please try again later. Click Back button to go to Main page.</b>
											</Alert>
											<br />
										</Stack>
									}
									<TableContainer component={Paper}>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Action/Date</TableCell>
													<TableCell>Owner</TableCell>
													<TableCell>Holder</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{!endorsementChain && !error &&
													<>
														{[...Array(loadingRows)].map((index) => (
															<TableRow key={index}>
																<TableCell component="th" scope="row">
																	<Skeleton variant="rounded" width={300} height={25} />
																</TableCell>
																<TableCell component="th" scope="row">
																	<Skeleton variant="rounded" width={300} height={25} />
																</TableCell>
																<TableCell component="th" scope="row">
																	<Skeleton variant="rounded" width={300} height={25} />
																</TableCell>
															</TableRow>
														))}
													</>

												}
												{endorsementChain && !error && (
													<>
														{historyChains.map((historyChain) => (
															<TableRow key={historyChain.hash}>
																<TableCell component="th" scope="row">
																	<b>{historyChain.action}</b>
																	<br />
																	{historyChain.timestamp}
																</TableCell>
																<TableCell>{historyChain.isNewBeneficiary ? historyChain.beneficiary : ""}</TableCell>
																<TableCell>{historyChain.isNewHolder ? historyChain.holder : ""}</TableCell>
															</TableRow>
														))}
													</>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)
			}
		</div >

	);
};

export default EndorsementChainLayout