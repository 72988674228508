import React from "react";
import { AddressInfo } from "../AddressInfo";
import { ExternalLinkEtherscanAddress } from "../ExternalLink";
import { Button, Typography } from "@material-ui/core";
import { AssetManagementActions } from "../../constants/AssetManagementActions";

export const AssetInformationPanel = ({
    tokenRegistryAddress,
    setShowEndorsementChain,
    document,
    formAction
    // className,
}) => {
    return (
        <div className="py-4 ms-4">
            {formAction === AssetManagementActions.None && (
                <>
                    <div className="flex w-full py-2">
                        <span className="badge bg-light mr-2 mb-2" style={{ fontSize: '1rem', color: 'rgb(255 130 0)', font: 'font-gilroy-bold' }}>Transferable</span>
                        <span className="badge bg-light mr-2 mb-2" style={{ fontSize: '1rem', color: 'rgb(255 130 0)' }}>Negotiable</span>
                    </div>
                    <br/>
                </>
            )}
            <AddressInfo title="NFT information" name="" resolvedBy="" source="">
                <Button style={{ paddingLeft: '0px' }}>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                        <ExternalLinkEtherscanAddress name="View NFT Registry" address={tokenRegistryAddress} />
                    </Typography>
                </Button>
                <br />
                <Button style={{ paddingLeft: '0px' }}>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                        <a style={{ color: '#007bff' }} onClick={() => setShowEndorsementChain(true)}>View Endorsement Chain</a>
                    </Typography>
                </Button>
            </AddressInfo>
        </div>
    );
};

export default AssetInformationPanel
