import { useState, useEffect } from "react";
import { useContractFunctionHook } from "@govtechsg/ethers-contract-hook";

export const useTokenRegistryRole = ({
    account,
    role,
    tokenRegistry,
}) => {
    const [hasRoleState, setHasRoleState] = useState();
    const { call: checkRole, value: hasRole, reset: resetCheckRole } = useContractFunctionHook(tokenRegistry, "hasRole");

    useEffect(() => {
        if (account) {
            checkRole(role, account);
            resetCheckRole();
        }
    }, [role, account, checkRole, tokenRegistry, resetCheckRole]);

    useEffect(() => {
        setHasRoleState(hasRole?.[0]);
    }, [hasRole]);

    return { hasRole: hasRoleState };
};