import React, { useCallback, useEffect, useState } from "react";
import { useProviderContext } from "../contexts/provider";
import { useTokenInformationContext } from "../common/contexts/TokenInformationContext/TokenInformationContext";
import { useTokenRegistryContract } from "../common/UseTokenRegistryContract";
import { AssetManagementForm } from "./AssetManagementForm";
import { AssetManagementActions } from "../constants/AssetManagementActions"
import { constants } from "@govtechsg/token-registry";
import VerifyStatusComponent from "./VerifyStatusComponent";
import { useTokenRegistryRole } from "../common/useTokenRegistryRole";
import ConfirmDialog from 'src/components/dialog/ConfirmDialog';

export const AssetManagementApplication = ({
  isMagicDemo,
  tokenId,
  tokenRegistryAddress,
  setShowEndorsementChain,
  history,
  pingMilestone,
  blNumber
}) => {
  const {
    holder,
    approvedBeneficiary,
    beneficiary,
    changeHolder,
    changeHolderState,
    endorseBeneficiary,
    endorseBeneficiaryState,
    surrender,
    surrenderState,
    destroyTokenState,
    destroyToken,
    isSurrendered,
    isTokenBurnt,
    isTitleEscrow,
    nominate,
    nominateState,
    transferOwners,
    transferOwnersState,
    documentOwner,
    restoreToken,
    restoreTokenState,
  } = useTokenInformationContext();
  const [assetManagementAction, setAssetManagementAction] = useState(AssetManagementActions.None);
  const { upgradeToMetaMaskSigner, provider, account } = useProviderContext();
  const { tokenRegistry } = useTokenRegistryContract(tokenRegistryAddress, provider);
  const { hasRole: hasAccepterRole } = useTokenRegistryRole({
    tokenRegistry,
    account,
    role: constants.roleHash.AccepterRole,
  });
  const { hasRole: hasRestorerRole } = useTokenRegistryRole({
    tokenRegistry,
    account,
    role: constants.roleHash.RestorerRole,
  });

  const onDestroyToken = () => {
    destroyToken(tokenId);
  };

  const onSetFormAction = useCallback(
    (assetManagementActions) => {
      setAssetManagementAction(assetManagementActions);
    },
    [setAssetManagementAction]
  );

  useEffect(() => {
    onSetFormAction(AssetManagementActions.None);
  }, [account, onSetFormAction]); // unset action panel to none, whenever user change metamask account

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleClose = () => {
    setConfirmDialogOpen(false);
  }
  return (
    <div id="title-transfer-panel">
      {assetManagementAction === AssetManagementActions.None && (
        // ui design requirement, to not show DocumentStatus & AssetManagementTags when user is on other actions
        <VerifyStatusComponent
          issuedValid={history.location.state.issuedValid}
          identityValid={history.location.state.identityValid}
          hashValid={history.location.state.hashValid}
          document={history.location.state.document}
          fragments={history.location.state.fragments}
        />

      )}
      {isTitleEscrow !== undefined && (
        <AssetManagementForm
          account={account}
          onConnectToWallet={upgradeToMetaMaskSigner}
          beneficiary={beneficiary}
          approvedBeneficiary={approvedBeneficiary}
          holder={holder}
          documentOwner={documentOwner}
          formAction={assetManagementAction}
          tokenRegistryAddress={tokenRegistryAddress}
          onSetFormAction={onSetFormAction}
          surrenderingState={surrenderState}
          destroyTokenState={destroyTokenState}
          onSurrender={surrender}
          onTransferHolder={changeHolder}
          holderTransferringState={changeHolderState}
          onEndorseBeneficiary={endorseBeneficiary}
          beneficiaryEndorseState={endorseBeneficiaryState}
          isSurrendered={isSurrendered}
          isTokenBurnt={isTokenBurnt}
          nominateBeneficiary={nominate}
          approveNewTransferTargetsState={nominateState}
          transferOwners={transferOwners}
          transferOwnersState={transferOwnersState}
          setShowEndorsementChain={setShowEndorsementChain}
          isTitleEscrow={isTitleEscrow}
          isAcceptor={hasAccepterRole}
          isRestorer={hasRestorerRole}
          onDestroyToken={onDestroyToken}
          onRestoreToken={restoreToken}
          restoreTokenState={restoreTokenState}
          tokenId={tokenId}
          history={history}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setModalData={setModalData}
          pingMilestone={pingMilestone}
          blNumber={blNumber}
        />
      )}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleClose}
        content={modalData.content}
        title={modalData.title}
        okMsg={modalData.okMsg}
      />
    </div>
  );
};
