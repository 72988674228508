import { Card, CardContent } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import TranslationText from './TranslationText'
import TransshipmentApiUrls from 'src/apiUrls/TransshipmentApiUrls'
import MGTTTableView from './MGTTTableView'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const {
	table: {
		useDefaultNotification
	}
} = components

function InternalUserPage(props) {
	const {
		showNotification
	} = props

	const {
		success: showSuccessNotification,
		error: showErrorNotification
	} = useDefaultNotification(showNotification)

	const { fetchRecords, createRecord } = useServices()
	const translatedTextObject = TranslationText()
	const [checkedRows, setCheckedRows] = useState([])
	const [archivedCheckedRows, setArchivedCheckedRows] = useState([])
	const [data, setData] = useState([])
	const [searchData, setSearchData] = useState([])
	const [searchArchivedData, setSearchArchivedData] = useState([])
	const [loading, setLoading] = useState(true)
	const [refresh, setRefresh] = useState(false)
	const [showArchivedField, setShowArchivedField] = useState(false)
	const [archivedData, setArchivedData] = useState([])
	const [emptyData, setEmptyData] = useState(false)
	const [emptyArchivedData, setEmptyArchivedData] = useState(false)
	const [columns, setColumns] = useState([
		{
			field: "sigaDocId",
			title: translatedTextObject.ttNo,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "containerNo",
			title: translatedTextObject.containerNum,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "priority",
			title: translatedTextObject.priority,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "containerSize",
			title: translatedTextObject.containerSize,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "driverName",
			title: translatedTextObject.driverName,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "licensePlate",
			title: translatedTextObject.licensePlate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "yardArea",
			title: translatedTextObject.yard,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "statuses",
			title: translatedTextObject.statuses,
			show: true,
			active: false,
			value: ''
		}
	])

	useEffect(() => {
		let newArchivedData = []
		let newData = []
		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT, false,
			(data) => {
				setLoading(false)
				data.map(d => {
					if (d.sigaDocId === null) {
						d.sigaDocId = 'No TT Found'
					}
					if (d.archived) {
						d.checked = false
						newArchivedData.push(d)
					} else {
						d.checked = false
						newData.push(d)
					}
				})
				setData(newData)
				setArchivedData(newArchivedData)
			}
		)
	}, [refresh])

	const manualRefresh = () => {
		setRefresh(!refresh)
		setLoading(true)
		let newArchivedData = []
		let newData = []

		const onSuccess = (data) => {
			data.map(d => {
				if (d.archived) {
					newArchivedData.push(d)
				} else {
					newData.push(d)
				}
			})

			columns.map(column => {
				if (column.value != '') {
					newData = newData.filter(d => (d[column.field] != null ? d[column.field].toString().toLowerCase().includes(column.value.toLowerCase()) : null))
				}
			})
			if (searchData.length != 0) {
				setSearchData(newData)
			} else {
				setData(newData)
			}
			if (searchArchivedData.length != 0) {
				setSearchArchivedData(newArchivedData)
			} else {
				setArchivedData(newArchivedData)
			}

			setEmptyData(false)
			setEmptyArchivedData(false)
			setLoading(false)
		}

		const onError = (error) => {
			console.log('connection-request error', error.message)
			setLoading(false)
		}

		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT,
			false,
			onSuccess,
			onError
		)
	}

	const onArchivedButtonClick = () => {
		setLoading(true)
		const contId = checkedRows !== null && checkedRows.length > 0
			? checkedRows.map(cont => cont.containerNo)
			: archivedCheckedRows.map(cont => cont.containerNo)

		const toArchive = checkedRows !== null && checkedRows.length > 0

		const requestBody = {
			contId: contId,
			toArchive: toArchive
		}

		function onSuccess(response) {
			console.log('Archived TT successfully ', response)
			if (response.errorMessages != null) {
				setLoading(false)
				showErrorNotification(response.errorMessages)
			} else {
				const units = response.map(item => item.unit).join(', ')
				toArchive ? showSuccessNotification(`Container(s): ${units} archived successfully`)
					: showSuccessNotification(`Container(s): ${units} unarchived successfully`)
				manualRefresh()
			}
		}

		function onError(error) {
			console.log('connection-request error', error.message)
			setLoading(false)
			showErrorNotification(error.message)
		}

		createRecord.execute(
			TransshipmentApiUrls.ARCHIVE_TT,
			requestBody,
			onSuccess,
			onError
		)
	}

	return (
		<Card>
			<CardContent>
				<CngBackdrop loading={loading} />
				{!showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={data}
					columns={columns}
					onArchivedButtonClick={onArchivedButtonClick}
					archivedButtonText={'Archive'}
					viewOnly={false}
					setColumns={setColumns}
					setData={setData}
					setCheckedRows={setCheckedRows}
					checkedRows={checkedRows}
					emptyData={emptyData}
					setEmptyData={setEmptyData}
					searchData={searchData}
					setSearchData={setSearchData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
				/>
				}
				{showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={archivedData}
					columns={columns}
					onArchivedButtonClick={onArchivedButtonClick}
					archivedButtonText={'Unarchive'}
					viewOnly={false}
					setColumns={setColumns}
					setData={setArchivedData}
					setCheckedRows={setArchivedCheckedRows}
					checkedRows={archivedCheckedRows}
					emptyData={emptyArchivedData}
					setEmptyData={setEmptyArchivedData}
					searchData={searchArchivedData}
					setSearchData={setSearchArchivedData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
				/>
				}
			</CardContent>
		</Card >
	)
}

export default InternalUserPage