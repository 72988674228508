import { CircularProgress } from '@material-ui/core'
import React from 'react'

const Loading = ({ loading, children }) => {
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            padding:'10px',
            margin:'auto',
            alignItems:'center',
            justifyContent:'center'}}>
            {loading && (
                <div className={'cfc-loading'}>
                    <CircularProgress />
                </div>
            )}

            {children}
        </div>
    )
}

export default Loading
