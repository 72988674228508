import { Box, Button, Card, CardContent, Grid } from '@material-ui/core'
import React, { useState } from 'react'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSelectField }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()

  let pendingShown = props.pendingClicked
    ? 'shown(' + props.shownCount + ')'
    : ''
  let processedShown = !props.pendingClicked
    ? 'shown(' + props.shownCount + ')'
    : ''
  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        <div className={`cng-summary--header`}>
          <div className={`cng-summary--header-col auto`}>
            <Box
              display='flex'
              justifyContent='flex-start'
              className={`frb-tabs-btn-wrapper`}
            >
              <Button
                onClick={props.clickPending}
                variant={props.pendingClicked ? 'outlined' : 'text'}
                className={props.pendingClicked ? `tab-active` : `tab-normal`}
              >
                {translatedTextsObject.pendingApproval}
                <em>({props.pendingCount})</em>
              </Button>

              <Button
                onClick={props.clickProcessed}
                variant={!props.pendingClicked ? 'outlined' : 'text'}
                className={!props.pendingClicked ? `tab-active` : `tab-normal`}
              >
                {translatedTextsObject.processed}
                <em>({props.processedCount})</em>
              </Button>
            </Box>
          </div>
          <div className={`cng-summary--header-col w-190px`}>
            <CngSelectField
              name='sortCode'
              label='Sort By'
              items={props.sortByList}
              onClick={props.handleSortByChange}
              size='small'
              inlineLabel
            />
          </div>
        </div>
      </CngGridItem>
      <CngGridItem item xs={12} sm={12}>
        <span className={`summary-count-label-wrapper`}>
          <label>
            {translatedTextsObject.shown} &#40;{props.shownCount}&#41;
          </label>
        </span>
      </CngGridItem>
    </Grid>
  )
}

const ManageBookingButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default ManageBookingButtonTabComponent
