import React from 'react'
import { TokenInformationContextProvider } from './common/contexts/TokenInformationContext/TokenInformationContext'
import TradeTrusteBLViewPage from './TradeTrusteBLViewPage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_TradeTrust'
import TradeTrustTranslationText from './TradeTrustTranslationText'
import { BasePage as CngBasePage } from 'cng-web-lib'
import { ProviderContextProvider } from './contexts/provider'
import { getChainInfoFromNetworkName, getSupportedChainInfo } from './common/chain-utils'
import { NETWORK_NAME } from './config'

import { OverlayContextProvider, Overlay } from "@govtechsg/tradetrust-ui-components";


function ModuleTitle() {
    const translatedTextsObject = TradeTrustTranslationText();
    return translatedTextsObject.moduleTitle;
}

function TradeTrusteBLViewPageContainer({ history, showNotification, ...props }) {
    //Purpose of this Container is to wrap the TradeTrusteBLViewPage component to initialize global variables

    const breadcrumbNameMap = useBreadcrumbNameMap()

    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={() => (
                <OverlayContextProvider>
                    <Overlay />
                    <ProviderContextProvider
                        defaultChainId={getChainInfoFromNetworkName(NETWORK_NAME).chainId}
                        networks={getSupportedChainInfo()}
                    >
                        <TokenInformationContextProvider>
                            <TradeTrusteBLViewPage
                                history={history}
                            />
                        </TokenInformationContextProvider>
                    </ProviderContextProvider>
                </OverlayContextProvider>
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    );

}

export default TradeTrusteBLViewPageContainer