import Namespace from 'src/constants/locale/Namespace'
import CompanyPreferenceKeys from 'src/constants/locale/key/CompanyPreference'
import ShippingInstructionKeys from 'src/constants/locale/key/ShippingInstruction'
import { useTranslation } from 'cng-web-lib'

const ManageScheduleTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SHIPPING_INSTRUCTION
  ])

  let title = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TITLE
  )

  let createNewSITitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATE_NEW_SI_TITLE
  )

  let myShippingInstructionTitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MY_SHIPPING_INSTRUCTION_TITLE
  )

  let viewSIOnlyTitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VIEW_SI_ONLY_TITLE
  )

  let manageShippingInstructionsTitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MANAGE_SHIPPING_INSTRUCTIONS_TITLE
  )

  let stepLabelFillupDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FILLUP_DETAILS
  )

  let stepLabelReviewDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REVIEW_DETAILS
  )

  let stepLabelSubmit = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SUBMIT
  )

  let stepLabelFinish = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FINISH
  )

  let shipmentOverview = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPMENT_OVERVIEW
  )
  let drafts = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DRAFTS
  )
  let createSINote = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATESI_NOTE
  )

  let preCarriageVesselName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRE_CARRIAGE_VESSEL_NAME
  )

  let preCarriageVoyageNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRE_CARRIAGE_VOYAGE_NO
  )

  let blApplicationType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BL_APPLICATION_TYPE
  )

  let blNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BL_NO
  )

  let surrenderBl = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SURRENDER_BL
  )

  let retractSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.RETRACT_SI
  )

  let printBl = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINT_BL
  )

  let approveSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.APPROVE_SI
  )
  let rejectSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REJECT_SI
  )
  let issueBL = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ISSUE_BL
  )
  let revokeBL = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REVOKE_BL
  )
  let revokeIssuedBL = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REVOKE_ISSUED_BL
  )

  let noOfOriginals = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_OF_ORIGINALS
  )

  let nvoccRefNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NVOCC_REF_NUMBER
  )

  let additionalRefDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_REF_DETAILS
  )
  let additionalBLDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_BL_DETAILS
  )
  let additionalPartyDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_PARTY_DETAILS
  )

  let blDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BL_DETAILS
  )
  let parties = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PARTIES
  )
  let shippedOnBoardDateTime = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPED_ON_BOARD_DATE_TIME
  )
  let noOfContainersPackagesInWords = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_OF_CONTAINERS_PACKAGES_IN_WORDS
  )
  let issueDateTime = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ISSUE_DATE_TIME
  )
  let issuePlace = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ISSUE_PLACE
  )
  let carrierClause = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARRIER_CLAUSE
  )
  let carrierClauseDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARRIER_CLAUSE_DESC
  )
  let documentLayout = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DOCUMENT_LAYOUT
  )
  let freightCharge = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FREIGHT_CHARGE
  )
  let depot = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DEPOT
  )
  let shipper = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER
  )
  let shipperName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_NAME
  )
  let shipperAddress = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_ADDRESS
  )
  let consigneeName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONSIGNEE_NAME
  )
  let consigneeAddress = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONSIGNEE_ADDRESS
  )
  let notifyPartyName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NOTIFY_PARTY_NAME
  )
  let notifyPartyAddress = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NOTIFY_PARTY_ADDRESS
  )
  let usccNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.USCC_NO
  )
  let usccNoNote = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.USCC_NO_NOTE
  )
  let preshipmentNotificationContacts = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRESHIPMENT_NOTIFICATION_CONTACTS
  )
  let agentName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.AGENT_NAME
  )
  let agentAddress = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.AGENT_ADDRESS
  )
  let bookingRepresentativeDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_REPRESENTATIVE_DETAILS
  )

  let partyTemplateButton = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PARTY_TEMPLATE_BUTTON
  )

  let excelTemplateButton = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EXCEL_TEMPLATE_BUTTON
  )

  let cargoTemplateButton = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_TEMPLATE_BUTTON
  )

  let particulars = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PARTICULARS
  )

  let exportCsv = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EXPORTCSV
  )

  // Container details

  let container = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER
  )

  let addAContainer = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADD_A_CONTAINER
  )

  let type = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TYPE
  )

  let status = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.STATUS
  )

  let containerDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER_DETAILS
  )

  let containerNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER_NO
  )
  let sealNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SEAL_NO
  )
  let containerGrossWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER_GROSS_WEIGHT
  )
  let containerGrossWeightTonne = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER_GROSS_WEIGHT_TONNE
  )
  let totalVgmWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_VGM_WEIGHT
  )
  let specialHandling = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SPECIAL_HANDLING
  )
  let stowage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.STOWAGE
  )
  let marksNos = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MARKS_NOS
  )
  let shipperOwned = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_OWNED
  )
  let nonActiveReefer = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NONACTIVE_REEFER
  )
  let addReeferDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADD_REEFER_DETAILS
  )
  let reeferContainer = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REEFER_CONTAINER
  )
  let equipmentTemp = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EQUIPMENT_TEMP
  )
  let tempUom = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TEMP_UOM
  )
  let equipmentAirFlow = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EQUIPMENT_AIRFLOW
  )
  let equipmentComments = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EQUIPMENT_COMMENTS
  )
  let editSettings = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EDIT_SETTINGS
  )

  // Cargo Details
  let addCargo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADD_CARGO
  )
  let uploadCargo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPLOAD_CARGO
  )
  let length = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.LENGTH
  )
  let height = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.HEIGHT
  )
  let width = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.WIDTH
  )

  let cargo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO
  )

  let cargoId = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_ID
  )

  let gross = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.GROSS
  )

  let net = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NET
  )

  let cargoDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_DESC
  )

  let hsCode = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.HSCODE
  )

  let packageType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PACKAGE_TYPE
  )

  let volume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VOLUME
  )

  let packageTypeNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PACKAGE_TYPE_NUMBER
  )

  let grossVolume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.GROSS_VOLUME
  )

  let grossWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.GROSS_WEIGHT
  )

  let grossWeightT = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.GROSS_WEIGHT_T
  )

  let netVolume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NET_VOLUME
  )

  let netWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NET_WEIGHT
  )

  let uom = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UOM
  )

  let additionalCargoDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_CARGO_DETAILS
  )

  let quantity = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.QUANTITY
  )

  let partnerEmail = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PARTNEREMAIL
  )
  let emailToNotify = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EMAILTONOTIFY
  )
  let emailDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EMAIL_DESC
  )
  let notifyBookingStatus = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NOTIFY_BOOKING_STATUS
  )
  let notifyEventMilestones = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NOTIFY_EVENT_MILESTONES
  )
  let term = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TERM
  )

  let alignContainerWithCargo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ALIGN_CONTAINER_WITH_CARGO
  )

  let controlTotal = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTROLTOTAL
  )

  let controlTotalDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTROL_TOTAL_DESC
  )

  let createSIViewSIDraftPage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATE_SI_VIEW_SI_DRAFT
  )

  let updateSIDrafts = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPDATE_SI_DRAFTS
  )
  let viewSIDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VIEW_SI_DETAILS
  )
  let manageSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MANAGE_SI
  )

  let uploadSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPLOAD_SI
  )

  let upload = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPLOAD
  )

  let bookingType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_TYPE
  )
  let shipmentType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TYPE_OF_SHIPMENT
  )
  let contactDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTACT_DETAILS
  )

  let representativeName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REPRESENTATIVE_NAME
  )

  let representativeEmail = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REPRESENTATIVE_EMAIL
  )

  let representativeContactNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REPRESENTATIVE_CONTACT_NUMBER
  )

  let purchaseOrderNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PURCHASE_ORDER_NUMBER
  )

  let shipperRefNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_REF_NUMBER
  )

  let forwarderRefNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FORWARDER_REF_NUMBER
  )
  let shipperRemarks = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_REMARKS
  )
  let carrierRemarks = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARRIER_REMARKS
  )
  let additionalShipmentDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_SHIPMENT_DETAILS
  )
  let references = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REFERENCES
  )
  let addPaymentInstruction = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADD_PAYMENT_INSTRUCTION
  )
  let paymentInstruction = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PAYMENT_INSTRUCTION
  )

  let chargeType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CHARGE_TYPE
  )

  let payer = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PAYER
  )

  let freightTerm = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FREIGHT_TERM
  )

  let pmtLoc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PMT_LOC
  )
  let schedule = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SCHEDULE
  )
  let portOfLoading = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PORT_OF_LOADING
  )

  let portOfDischarge = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PORT_OF_DISCHARGE
  )

  let polDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POL_DESC
  )

  let podDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POD_DESC
  )

  let polPlannedEta = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POL_PLANNED_ETA
  )

  let podPlannedEta = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POD_PLANNED_ETA
  )

  let polPlannedEtd = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POL_PLANNED_ETD
  )

  let podPlannedEtd = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POD_PLANNED_ETD
  )

  let dischargeTerminal = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DISCHARGE_TERMINAL
  )

  let vesselName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VESSEL_NAME
  )

  let internationalVoyageNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.INTERNATIONAL_VOYAGE_NUMBER
  )

  let voyageNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VOYAGE_NUMBER
  )

  let additionalScheduleDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_SCHEDULE_DETAILS
  )

  let carrierNvoccBookingAgent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARRIER_NVOCC_BOOKING_AGENT
  )

  let loadTerminal = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.LOAD_TERMINAL
  )
  let shipment = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPMENT_TTTLE
  )

  let moveType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MOVE_TYPE
  )

  let emptyPickup = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EMPTY_PICKUP_TITLE
  )

  let shipTo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIP_TO_TITLE
  )

  let shipFrom = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIP_FROM_TITLE
  )

  let placeOfReceipt = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_RECEIPT
  )

  let placeOfDelivery = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_DELIVERY
  )

  let cargoReadyDate = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_READY_DATE
  )

  let cargoDeliveryDate = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_DESTINATION_DATE
  )

  let placeOfReceiptDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_RECEIPT_DESC
  )

  let placeOfDeliveryDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_DELIVERY_DESC
  )

  let placeOfReceiptETD = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_RECEIPT_ETD
  )

  let placeOfDeliveryETA = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PLACE_OF_DELIVERY_ETA
  )

  let destinationCode = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DESTINATION_CODE
  )

  let finalDestination = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FINAL_DESTINATION
  )

  let emptyPickupDate = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EMPTY_PICKUP_DATE
  )

  let fullPickupDate = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FULL_PICKUP_DATE
  )

  let shipFromDatePositioning = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIP_FROM_DATE_POSITIONING
  )
  let company = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.COMPANY
  )

  let contact = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTACT
  )

  let document = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DOCUMENT
  )

  let attachDocument = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ATTACH_DOCUMENT
  )

  let fileType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FILE_TYPE
  )

  let fileDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FILE_DESC
  )

  let uploadSiInfo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPLOAD_SI_INFO
  )

  let confirm = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM
  )

  let container1 = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER1
  )
  let container2 = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER2
  )

  let totalQuantity = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_QUANTITY
  )

  let totalGrossVolume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_GROSS_VOLUME
  )

  let totalNetVolume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_NET_VOLUME
  )

  let totalGrossWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_GROSS_WEIGHT
  )

  let totalNetWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TOTAL_NET_WEIGHT
  )

  let cbm = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CBM
  )

  let ft = translate(Namespace.SHIPPING_INSTRUCTION, ShippingInstructionKeys.FT)

  let kg = translate(Namespace.SHIPPING_INSTRUCTION, ShippingInstructionKeys.KG)

  let marksAndNos = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MARK_AND_NOS
  )

  let noOfContainers = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_OF_CONTAINERS
  )

  let noOfPackages = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_OF_PACKAGES
  )

  let shipmentWeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPMENT_WEIGHT
  )

  let shipmentWeightNoMetric = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPMENT_WEIGHT_NO_METRIC
  )

  let vgmWeightNoMetric = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.VGM_WEIGHT_NO_METRIC
  )

  let shipmentVolume = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPMENT_VOLUME
  )

  let containerSizeTypeHeight = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONTAINER_SIZE_TYPE_HEIGHT
  )

  let size = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SIZE
  )

  let heightType = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.HEIGHT_TYPE
  )

  let soc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SOC
  )

  let success = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SUCCESS
  )

  let fileError = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FILE_ERROR
  )

  let pendingSISubmission = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PENDING_SI_SUBMISSION
  )

  let previewBLDocument = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PREVIEW_BL_DOCUMENT
  )

  let draftedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DRAFTED_ON
  )

  let submittedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SUBMITTED_ON
  )

  let approvedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.APPROVED_ON
  )
  let issuedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ISSUED_ON
  )
  let surrenderedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SURRENDERED_ON
  )
  let to = translate(Namespace.SHIPPING_INSTRUCTION, ShippingInstructionKeys.TO)
  let by = translate(Namespace.SHIPPING_INSTRUCTION, ShippingInstructionKeys.BY)

  let bookingId = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_ID
  )

  let back = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BACK
  )

  let backToMySIPage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BACK_TO_MY_SI_PAGE
  )

  let backToViewOnlySIPage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BACK_TO_VIEW_ONLY_SI_PAGE
  )

  let backToManageSIPage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BACK_TO_MANAGE_SI_PAGE
  )
  let manageMySI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.MANAGE_MY_SI
  )

  let referenceHeader = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REFERENCE_HEADER
  )

  let fowardHubScheme = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FORWARD_HUB_SCHEME
  )

  let outboundReferenceNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.OUTBOUND_REFERENCE_NO
  )

  let notifyMe = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NOTIFY_ME
  )

  let termPor = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TERM_POR
  )

  let termPod = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TERM_POD
  )

  let alignContainer = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ALIGN_CONTAINER
  )

  let bookingRefNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_REF_NO
  )

  let shipperRefNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SHIPPER_REF_NO
  )

  let blrefNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BL_REF_NO
  )

  let bookingDateAndTime = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_DATE_AND_TIME
  )

  let noItemHere = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_ITEM_HERE
  )

  let noItemDescShipper = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.NO_ITEM_DESC_SHIPPER
  )

  let createDirectSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATE_DIRECT_SI
  )

  let directSI = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIRECT_SI
  )

  let createBooking = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATE_BOOKING
  )

  let smthWrong = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SMTH_WRONG
  )
  let smthWrongDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SMTH_WRONG_DESC
  )
  let refresh = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REFRESH
  )

  let lastModifiedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.LAST_MODIFIED_ON
  )

  let createdOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATED_ON
  )

  let createdShippingInstructions = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CREATED_SHIPPING_INSTRUCTIONS
  )

  let submittedShippingInstructions = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SUBMITTED_SHIPPING_INSTRUCTIONS
  )

  let bookingReferenceNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_REF_NUMBER
  )

  let freightBookingReferenceNumber = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FREIGHT_BOOKING_REF_NUMBER
  )

  let hazardous = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.HAZARDOUS
  )

  let outboundRefNo = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.OUTBOUND_REF_NO
  )

  let forwardHubScheme = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FORWARD_HUB_SCHEME
  )

  let additionalBookingTypeDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ADDITIONAL_BOOKING_TYPE_DET
  )

  let bookingExist = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOOKING_EXIST
  )

  let populateForm1 = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POPULATE_FORM_1
  )

  let populateForm2 = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.POPULATE_FORM_2
  )

  let confirmDeleteSITitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_DELETE_SI_TITLE
  )
  let confirmCancelSITitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_CANCEL_SI_TITLE
  )
  let confirmArchiveSITitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_ARCHIVE_SI_TITLE
  )
  let confirmDeleteSIContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_DELETE_SI_CONTENT
  )
  let confirmCancelSIContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_CANCEL_SI_CONTENT
  )
  let confirmArchiveSIContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_ARCHIVE_SI_CONTENT
  )
  let confirmDeleteSIOption = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_DELETE_SI_OPTION
  )
  let confirmCancelSIOption = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_CANCEL_SI_OPTION
  )
  let confirmArchiveSIOption = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_ARCHIVE_SI_OPTION
  )

  let confirmWithoutChangeCaption = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_WITHOUT_CHANGE_CAPTION
  )

  let dialogDiscardSITitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_DISCARD_SI_TITLE
  )
  let dialogDiscardSIContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_DISCARD_SI_CONTENT
  )
  let dialogDiscardCargoUploadTitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_DISCARD_CARGO_TITLE
  )
  let dialogDiscardCargoUploadContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_DISCARD_CARGO_CONTENT
  )
  let dialogOverwriteCargoTitle = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_OVERWRITE_CARGO_TITLE
  )
  let dialogOverwriteCargoContent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_OVERWRITE_CARGO_CONTENT
  )
  let dialogCloseDialog = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DIALOG_CLOSE_DIALOG
  )
  let siSubmitted = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_SUBMITTED
  )
  let siSubmittedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_SUBMITTED_ON
  )

  let siConfirmed = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_CONFIRMED
  )

  let siConfirmedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_CONFIRMED_ON
  )

  let siApproved = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_APPROVED
  )

  let siApprovedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_APPROVED_ON
  )

  let siUpdateConfirmed = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_UPDATE_CONFIRMED
  )

  let siUpdateConfirmedOn = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_UPDATE_CONFIRMED_ON
  )

  let siSubmittedForApproval = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SI_SUBMITTED_FOR_APPROVAL
  )

  let auditSummaryHeader = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.AUDIT_SUMMARY_HEADER
  )

  let relatedContainerNos = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.RELATED_CONTAINER_NOS
  )

  let reeferSettings = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REEFER_SETTINGS
  )

  let cargoDetails = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARGO_DETAILS
  )

  let resetFilterDesc = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.RESET_FILTER_DESC
  )

  let seawayBillClause = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SEAWAY_BILL_CLAUSE
  )

  let bombayClause = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.BOMBAY_CLAUSE
  )

  let deleteSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DELETE_SUCCESS_MSG
  )
  let cancelSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CANCEL_SUCCESS_MSG
  )
  let editSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.EDIT_SUCCESS_MSG
  )
  let archiveSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ARCHIVE_SUCCESS_MSG
  )

  let copySuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.COPY_SUCCESS_MSG
  )

  let submitSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SUBMIT_SUCCESS_MSG
  )

  let updateSubmitSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPDATE_SUBMIT_SUCCESS_MSG
  )

  let draftSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DRAFT_SUCCESS_MSG
  )

  let cannotCreateSIMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CANNOT_CREATE_SI_MSG
  )

  let cannotAddContainerMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CANNOT_ADD_CONTAINER_MSG
  )

  let cannotAddContainerDirectSIMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CANNOT_ADD_CONTAINER_DIRECT_SI_MSG
  )

  let confirmSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CONFIRM_SUCCESS_MSG
  )

  let updateConfirmSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPDATE_CONFIRM_SUCCESS_MSG
  )

  let updateSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.UPDATE_SUCCESS_MSG
  )

  let sendSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SEND_SUCCESS_MSG
  )
  let retractSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.RETRACT_SUCCESS_MSG
  )
  let approveSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.APPROVE_SUCCESS_MSG
  )
  let rejectedSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REJECTED_SUCCESS_MSG
  )
  let issueSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.ISSUE_SUCCESS_MSG
  )
  let revokeSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REVOKE_SUCCESS_MSG
  )
  let surrenderSuccessMsg = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.SURRENDER_SUCCESS_MSG
  )

  let proxyPrintLoadingMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_LOADING_MESSAGE
  )
  let proxyPrintDownLoadMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_DOWNLOAD_MESSAGE
  )
  let proxyPrintRefreshMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_REFRESH_MESSAGE
  )
  let proxyPrintJobSendingMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_JOBSENDING_MESSAGE
  )
  let proxyPrintJobSentMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_JOBSENT_MESSAGE
  )

  let proxyPrintNoPrinterMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_NO_PRINTER_MESSAGE
  )

  let proxyPrintNoPrintMessage = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT_NO_PRINT_MESSAGE
  )

  let proxyPrint = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PROXY_PRINT
  )
  let availablePrintRemaining = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.AVAILABLE_PRINT_REMAINING
  )
  let printerName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINTER_NAME
  )
  let printOption = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINT_OPTION
  )
  let printOptionAll = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINT_OPTION_ALL
  )
  let printOptionCurrent = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINT_OPTION_CURRENT
  )
  let refreshPrinters = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.REFRESH_PRINTERS
  )
  // Company Preference
  let siCompanyPreference = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreferenceKeys.SI_PREF_TITLE
  )
  let closeShippingInfoUpdate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreferenceKeys.CLOSE_SHIPPING_INFO_UPDATE
  )

  // SI Helpdesk
  let freightBookingId = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.FREIGHT_BOOKING_ID
  )
  let carrierNvoccBookingAgentName = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.CARRIER_NVOCC_BOOKING_AGENT_NAME
  )
  let printedOriginals = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PRINTED_ORIGINALS
  )
  let trade = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.TRADE
  )
  let declaration = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DECLARATION
  )
  let complianceStatus = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.COMPLIANCE_STATUS
  )
  let pkgCnt = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PACKAGE_TYPE_NUMBER
  )
  //Essdocs Document Push
  let pushToEssdocs = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.PUSH_TO_ESSDOCS
  )
  let docPushCount = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DOC_PUSH_COUNT
  )
  let docPushDate = translate(
    Namespace.SHIPPING_INSTRUCTION,
    ShippingInstructionKeys.DOC_PUSH_DATE
  )
  return {
    title,
    createNewSITitle,
    myShippingInstructionTitle,
    viewSIOnlyTitle,
    manageShippingInstructionsTitle,
    stepLabelFillupDetails,
    stepLabelReviewDetails,
    stepLabelSubmit,
    stepLabelFinish,
    shipmentOverview,
    drafts,
    createSINote,
    preCarriageVesselName,
    preCarriageVoyageNo,
    blApplicationType,
    blNo,
    surrenderBl,
    retractSI,
    printBl,
    approveSI,
    rejectSI,
    issueBL,
    revokeBL,
    revokeIssuedBL,
    noOfOriginals,
    nvoccRefNumber,
    additionalRefDetails,
    additionalBLDetails,
    additionalPartyDetails,
    blDetails,
    parties,
    shippedOnBoardDateTime,
    noOfContainersPackagesInWords,
    issueDateTime,
    issuePlace,
    carrierClause,
    carrierClauseDesc,
    documentLayout,
    freightCharge,
    depot,
    shipperName,
    shipperAddress,
    consigneeName,
    consigneeAddress,
    notifyPartyName,
    notifyPartyAddress,
    usccNo,
    usccNoNote,
    preshipmentNotificationContacts,
    agentName,
    agentAddress,
    bookingRepresentativeDetails,
    partyTemplateButton,
    excelTemplateButton,
    cargoTemplateButton,
    particulars,
    exportCsv,
    container,
    type,
    status,
    addAContainer,
    containerDetails,
    containerNo,
    sealNo,
    containerGrossWeight,
    totalVgmWeight,
    specialHandling,
    stowage,
    marksNos,
    shipperOwned,
    nonActiveReefer,
    addReeferDetails,
    reeferContainer,
    equipmentTemp,
    tempUom,
    equipmentAirFlow,
    equipmentComments,
    editSettings,
    addCargo,
    uploadCargo,
    length,
    height,
    width,
    cargo,
    cargoId,
    gross,
    net,
    cargoDesc,
    hsCode,
    packageType,
    grossVolume,
    grossWeight,
    netVolume,
    netWeight,
    uom,
    additionalCargoDetails,
    quantity,
    partnerEmail,
    emailToNotify,
    notifyBookingStatus,
    notifyEventMilestones,
    emailDesc,
    term,
    alignContainerWithCargo,
    controlTotal,
    controlTotalDesc,
    createSIViewSIDraftPage,
    updateSIDrafts,
    viewSIDetails,
    manageSI,
    uploadSI,
    upload,
    bookingType,
    shipmentType,
    contactDetails,
    representativeName,
    representativeEmail,
    representativeContactNumber,
    purchaseOrderNumber,
    shipperRefNumber,
    forwarderRefNumber,
    shipperRemarks,
    carrierRemarks,
    additionalShipmentDetails,
    references,
    addPaymentInstruction,
    paymentInstruction,
    chargeType,
    payer,
    freightTerm,
    pmtLoc,
    schedule,
    portOfLoading,
    portOfDischarge,
    polDesc,
    podDesc,
    polPlannedEta,
    podPlannedEta,
    polPlannedEtd,
    podPlannedEtd,
    dischargeTerminal,
    vesselName,
    internationalVoyageNumber,
    voyageNumber,
    additionalScheduleDetails,
    carrierNvoccBookingAgent,
    loadTerminal,
    shipment,
    moveType,
    emptyPickup,
    shipTo,
    shipFrom,
    placeOfReceipt,
    placeOfDelivery,
    cargoReadyDate,
    cargoDeliveryDate,
    placeOfReceiptDesc,
    placeOfDeliveryDesc,
    placeOfReceiptETD,
    placeOfDeliveryETA,
    destinationCode,
    finalDestination,
    emptyPickupDate,
    fullPickupDate,
    shipFromDatePositioning,
    company,
    contact,
    document,
    attachDocument,
    uploadSiInfo,
    fileType,
    fileDesc,
    confirm,
    container1,
    container2,
    totalQuantity,
    totalGrossVolume,
    totalNetVolume,
    totalGrossWeight,
    totalNetWeight,
    cbm,
    ft,
    kg,
    marksAndNos,
    noOfContainers,
    noOfPackages,
    shipmentWeight,
    shipmentWeightNoMetric,
    vgmWeightNoMetric,
    shipmentVolume,
    containerSizeTypeHeight,
    size,
    heightType,
    soc,
    success,
    fileError,
    pendingSISubmission,
    previewBLDocument,
    draftedOn,
    submittedOn,
    approvedOn,
    issuedOn,
    surrenderedOn,
    to,
    by,
    bookingId,
    back,
    backToMySIPage,
    backToViewOnlySIPage,
    backToManageSIPage,
    manageMySI,
    referenceHeader,
    fowardHubScheme,
    outboundReferenceNo,
    notifyMe,
    termPor,
    termPod,
    alignContainer,
    bookingRefNo,
    shipperRefNo,
    blrefNo,
    bookingDateAndTime,
    noItemHere,
    noItemDescShipper,
    createDirectSI,
    directSI,
    createBooking,
    smthWrong,
    smthWrongDesc,
    refresh,
    lastModifiedOn,
    createdOn,
    createdShippingInstructions,
    submittedShippingInstructions,
    bookingReferenceNumber,
    freightBookingReferenceNumber,
    hazardous,
    forwardHubScheme,
    outboundRefNo,
    additionalBookingTypeDetails,
    bookingExist,
    populateForm1,
    populateForm2,
    confirmDeleteSITitle,
    confirmCancelSITitle,
    confirmArchiveSITitle,
    confirmDeleteSIContent,
    confirmCancelSIContent,
    confirmArchiveSIContent,
    confirmDeleteSIOption,
    confirmCancelSIOption,
    confirmArchiveSIOption,
    confirmWithoutChangeCaption,
    dialogDiscardSITitle,
    dialogDiscardSIContent,
    dialogDiscardCargoUploadTitle,
    dialogDiscardCargoUploadContent,
    dialogOverwriteCargoTitle,
    dialogOverwriteCargoContent,
    dialogCloseDialog,
    siSubmitted,
    siSubmittedOn,
    siConfirmed,
    siConfirmedOn,
    siApproved,
    siApprovedOn,
    siUpdateConfirmed,
    siUpdateConfirmedOn,
    siSubmittedForApproval,
    auditSummaryHeader,
    relatedContainerNos,
    reeferSettings,
    cargoDetails,
    resetFilterDesc,
    seawayBillClause,
    bombayClause,
    deleteSuccessMsg,
    cancelSuccessMsg,
    editSuccessMsg,
    archiveSuccessMsg,
    copySuccessMsg,
    submitSuccessMsg,
    updateSubmitSuccessMsg,
    draftSuccessMsg,
    confirmSuccessMsg,
    updateConfirmSuccessMsg,
    updateSuccessMsg,
    sendSuccessMsg,
    retractSuccessMsg,
    approveSuccessMsg,
    rejectedSuccessMsg,
    issueSuccessMsg,
    revokeSuccessMsg,
    surrenderSuccessMsg,
    cannotCreateSIMsg,
    cannotAddContainerMsg,
    cannotAddContainerDirectSIMsg,
    proxyPrintLoadingMessage,
    proxyPrintDownLoadMessage,
    proxyPrintRefreshMessage,
    proxyPrintJobSendingMessage,
    proxyPrintJobSentMessage,
    proxyPrintNoPrinterMessage,
    proxyPrintNoPrintMessage,
    proxyPrint,
    availablePrintRemaining,
    printerName,
    printOption,
    printOptionAll,
    printOptionCurrent,
    refreshPrinters,
    siCompanyPreference,
    closeShippingInfoUpdate,
    freightBookingId,
    carrierNvoccBookingAgentName,
    printedOriginals,
    trade,
    declaration,
    complianceStatus,
    packageTypeNumber,
    volume,
    pkgCnt,
    pushToEssdocs,
    docPushCount,
    docPushDate,
    grossWeightT,
    containerGrossWeightTonne,
    shipper
  }
}

export default ManageScheduleTranslationText
