const CARBON_FOOTPRINT_URL = `${process.env.REACT_APP_CARBON_FOOTPRINT}`

const TransshipmentApiUrls = {
  GET_ALL_TT: `${CARBON_FOOTPRINT_URL}/mgtt/tt/get`,
  ARCHIVE_TT: `${CARBON_FOOTPRINT_URL}/mgtt/psa/archive/update`,
  RETRY_TT: `${CARBON_FOOTPRINT_URL}/mgtt/tt/siga/retry`,
  GET_TT_WITH_SIGA_STATUS: `${CARBON_FOOTPRINT_URL}/mgtt/tt/siga/updated/get`
}

export default TransshipmentApiUrls
