import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_MGTT'
import MGTT from 'src/constants/locale/key/MGTT'

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.MGTT
]

const mgttBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    /* CATEGORY MANAGEMENT BREADCRUMBS */
    let DriverTitle = translate(Namespace.MGTT, MGTT.DRIVER_LIST)
    let OperatorTitle = translate(Namespace.MGTT, MGTT.CONTAINER_LIST)
    let TransporterTitle = translate(Namespace.MGTT, MGTT.TRANSPORTER_LIST)

    // Driver List View
    breadcrumbNameMap.set(pathMap.MGTT_DRIVER, DriverTitle)

    // Operator List View
    breadcrumbNameMap.set(pathMap.MGTT_OPERATOR, OperatorTitle)

    // Transporter List View
    breadcrumbNameMap.set(pathMap.MGTT_TRANSPORTER, TransporterTitle)
}

function useBreadcrumbNameMap() {
    const { translate } = useTranslation(translationNamespace)
    const breadcrumbNameMap = new Map()

    mgttBreadcrumbNameMap(breadcrumbNameMap, translate);
    return breadcrumbNameMap
}

export default useBreadcrumbNameMap
