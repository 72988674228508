import Namespace from 'src/constants/locale/Namespace'
import MGTT from 'src/constants/locale/key/MGTT'
import { useTranslation } from 'cng-web-lib'
import CommonTranslationText from './CommonTranslationText'

const TranslationText = () => {
    const commonText = CommonTranslationText();

    const { translate } = useTranslation(Namespace.MGTT);
    const moduleTitle = translate(Namespace.MGTT, MGTT.MODULE_TITLE)
    const selection = translate(Namespace.MGTT, MGTT.SELECTION)
    const containerNo = translate(Namespace.MGTT, MGTT.CONTAINER_NO)
    const containerNum = translate(Namespace.MGTT, MGTT.CONTAINER_NUM)
    const containerNoOperator = translate(Namespace.MGTT, MGTT.CONTAINER_NO_OPERATOR)
    const psaId = translate(Namespace.MGTT, MGTT.PSA_ID)
    const containerCreatedDate = translate(Namespace.MGTT, MGTT.CONT_CREATED_DATE)
    const containerUpdatedDate = translate(Namespace.MGTT, MGTT.CONT_UPDATED_DATE)
    const psaApproval = translate(Namespace.MGTT, MGTT.PSA_APPROVAL)
    const origin = translate(Namespace.MGTT, MGTT.ORIGIN)
    const ttNo = translate(Namespace.MGTT, MGTT.TT_NO)
    const driverName = translate(Namespace.MGTT, MGTT.DRIVER_NAME)
    const createDate = translate(Namespace.MGTT, MGTT.CREATE_DATE)
    const statuses = translate(Namespace.MGTT, MGTT.STATUSES)
    const downloadEIR = translate(Namespace.MGTT, MGTT.DOWNLOAD_EIR)
    const printTT = translate(Namespace.MGTT, MGTT.PRINT_TT)
    const editSent = translate(Namespace.MGTT, MGTT.EDIT_SENT)
    const priority = translate(Namespace.MGTT, MGTT.PRIORITY)
    const containerSize = translate(Namespace.MGTT, MGTT.CONTAINER_SIZE)
    const licensePlate = translate(Namespace.MGTT, MGTT.LICENSE_PLATE)
    const yard = translate(Namespace.MGTT, MGTT.YARD)
    const inYard = translate(Namespace.MGTT, MGTT.IN_YARD)
    const onHold = translate(Namespace.MGTT, MGTT.ON_HOLD)
    const plateNumber = translate(Namespace.MGTT, MGTT.PLATE_NUMBER)
    const driverId = translate(Namespace.MGTT, MGTT.DRIVER_ID)
    const tt = translate(Namespace.MGTT, MGTT.TT)
    const destination = translate(Namespace.MGTT, MGTT.DESTINATION)
    const to = translate(Namespace.MGTT, MGTT.TO)
    const iso = translate(Namespace.MGTT, MGTT.ISO)
    const temperature = translate(Namespace.MGTT, MGTT.TEMPERATURE)
    const tempOperator = translate(Namespace.MGTT, MGTT.TEMP_OPERATOR)
    const weight = translate(Namespace.MGTT, MGTT.WEIGHT)
    const weightOperator = translate(Namespace.MGTT, MGTT.WEIGHT_OPERATOR)
    const yardArea = translate(Namespace.MGTT, MGTT.YARD_AREA)
    const ttCreatedDate = translate(Namespace.MGTT, MGTT.TT_CREATED_DATE)
    const ttUpdatedDate = translate(Namespace.MGTT, MGTT.TT_UPDATED_DATE)
    const originatingVessel = translate(Namespace.MGTT, MGTT.ORIGINATING_VESSEL)
    const containerType = translate(Namespace.MGTT, MGTT.CONTAINER_TYPE)
    const imoNo = translate(Namespace.MGTT, MGTT.IMO_NO)
    const cargoUnCode = translate(Namespace.MGTT, MGTT.CARGO_UN_CODE)
    const noOfShifting = translate(Namespace.MGTT, MGTT.NO_OF_SHIFTING)
    const category = translate(Namespace.MGTT, MGTT.CATEGORY)
    const eir = translate(Namespace.MGTT, MGTT.EIR)
    const acheck = translate(Namespace.MGTT, MGTT.ACHECK)

    return {
        moduleTitle,
        selection,
        containerNo,
        containerNum,
        containerNoOperator,
        psaId,
        containerCreatedDate,
        containerUpdatedDate,
        psaApproval,
        origin,
        ttNo,
        driverName,
        createDate,
        statuses,
        downloadEIR,
        printTT,
        editSent,
        priority,
        containerSize,
        licensePlate,
        yard,
        inYard,
        onHold,
        plateNumber,
        driverId,
        tt,
        destination,
        to,
        iso,
        temperature,
        tempOperator,
        weight,
        weightOperator,
        yardArea,
        ttCreatedDate,
        ttUpdatedDate,
        originatingVessel,
        containerType,
        imoNo,
        cargoUnCode,
        noOfShifting,
        category,
        eir,
        acheck,
        ...commonText
    }
}

export default TranslationText
