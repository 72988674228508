import React from "react";
import { styled, alpha } from '@mui/material/styles';
import { AssetManagementActions } from "../constants/AssetManagementActions";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import SubmitButton from 'src/components/button/SubmitButton.js';

export const OPTION_LOCATION = [
  { label: 'UN Locode', value: 'unLocode' },
  { label: 'IATA code', value: 'iataCode' },
  { label: 'Railway Station', value: 'uicCode' }
]

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export const AssetManagementDropdown = ({
  onSetFormAction,
  canSurrender,
  canHandleShred,
  canHandleRestore,
  canChangeHolder,
  canEndorseBeneficiary,
  canNominateBeneficiary,
  canEndorseTransfer,
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <div>
      <SubmitButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Manage Assets
      </SubmitButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canChangeHolder && (
          <MenuItem
            onClick={() => onSetFormAction(AssetManagementActions.TransferHolder)}
          >
            Transfer holdership
          </MenuItem>
        )}
        {canEndorseBeneficiary && (
          <MenuItem
            onClick={() => onSetFormAction(AssetManagementActions.EndorseBeneficiary)}
          >
            Endorse change of ownership
          </MenuItem>
        )}
        {canNominateBeneficiary && (
          <MenuItem
            onClick={() => onSetFormAction(AssetManagementActions.NominateBeneficiary)}
          >
            Nominate change of ownership
          </MenuItem>
        )}
        {canSurrender && (
          <MenuItem
            onClick={() => onSetFormAction(AssetManagementActions.Surrender)}
          >
            Surrender document
          </MenuItem>
        )}
        {canHandleShred && (
          <>
            <MenuItem
              onClick={() => onSetFormAction(AssetManagementActions.AcceptSurrendered)}
            >
              Accept surrender of document
            </MenuItem>
            {/* <MenuItem
              onClick={() => onSetFormAction(AssetManagementActions.RejectSurrendered)}
            >
              Reject surrender of document***
            </MenuItem> */}
          </>
        )}
        {canEndorseTransfer && (
          <MenuItem
            onClick={() => onSetFormAction(AssetManagementActions.EndorseTransfer)}
          >
            Endorse Transfer of ownership
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};

export default AssetManagementDropdown;