const CARBON_FOOTPRINT_URL = `${process.env.REACT_APP_CARBON_FOOTPRINT}`
const MAINTAINANCE_URL = `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance`

const CarbonFootprintApiUrls = {
  COUNTRY: `${MAINTAINANCE_URL}carbon-foorprint/CountryCode`,
  MAINTAINANCE_CODE: `${MAINTAINANCE_URL}/get`,
  CALCULATE_STANDARD: `${CARBON_FOOTPRINT_URL}/standard-mode/calculate`,
  CALCULATE_ADVANCE: `${CARBON_FOOTPRINT_URL}/advance-mode/calculate`,
  IATACODE_GET_API: `${CARBON_FOOTPRINT_URL}/iata/get`,
  RAILWAY_GET_API: `${CARBON_FOOTPRINT_URL}/railway/get`,
  EXPORT_CSV: `${CARBON_FOOTPRINT_URL}/standard-mode/export/csv`,
  EXPORT_ADVANCE_CSV: `${CARBON_FOOTPRINT_URL}/advance-mode/export/csv`,
  EXPORT_AUDIT_LOG: `${CARBON_FOOTPRINT_URL}/audit-log/download?fileType=CSV`,
  GET_FREIGHT_CODE: `${CARBON_FOOTPRINT_URL}/code-master/get`,
  AUDIT_LOG: `${CARBON_FOOTPRINT_URL}/audit-log/get`,

}

export default CarbonFootprintApiUrls
