import { FormControl } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { useServices, useTranslation } from 'cng-web-lib'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormContext } from 'react-hook-form'
import CarbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'
import { FLAG_NS } from '../constants'
import Namespace from 'src/constants/locale/Namespace'

export function getError(errror, name = '') {
  let messageError = errror
  try {
    name.split('.').forEach((item) => (messageError = messageError[item]))
    return messageError
  } catch (error) {
    return null
  }
}
const PortSelectAutoComplete = ({
  name,
  label,
  fName = '',
  country,
  index,
  location
}) => {
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = useState({
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    keySearch: ''
  })
  const {
    register,
    formState: { errors },
    getValues,
    setValue: setValueForm
  } = useFormContext()
  const loading = open && page?.content.length === 0
  const { fetchRecords } = useServices()
  const [value, setValue] = React.useState(getValues(name) ?? [])

  const checkValidate = (valuePor) => {
    const pre = index - 1
    const next = index + 1
    const nameViaList = name.substring(0, 31)
    const viaTranport = getValues(nameViaList)
    if (viaTranport) {
      if (viaTranport[index]) {
        if (
          viaTranport[pre]?.transportRequest?.code === valuePor?.code ||
          viaTranport[next]?.transportRequest?.code === valuePor?.code
        ) {
          return t('label_check_location')
        } else {
          return null
        }
      }
    } else {
      return null
    }
  }
  const formField = register(name, {
    required: t('label_location_valid'),
    validate: checkValidate
  })
  useEffect(() => {
    if (location) {
      setValue(null)
      setValueForm(name, '')
      setPage({ ...page, content: [] })
    }
  }, [location])
  const loadData = ({ keySearch = '', url, pageNum = 0, isScroll = false }) => {
    fetchRecords.execute(
      url,
      {
        customData: {
          baseFilterDTO: {
            filterType: 'OR',
            filterProperties: [
              {
                fieldName: 'descriptionEn',
                operatorType: 'LIKE',
                value1: keySearch
              },
              {
                fieldName: 'code',
                operatorType: 'LIKE',
                value1: keySearch
              }
            ],
            sortProperties: [],
            page: pageNum,
            size: 5
          },
          codeMType: 'PortCode'
        }
      },
      (response) => {
        const { number, size, totalPages } = response
        if (isScroll) {
          console.log([...page.content, ...response?.content])
          setPage({
            content: [...page.content, ...response?.content],
            number,
            size,
            totalPages,
            keySearch
          })
        } else {
          setPage({
            content: response?.content,
            number,
            size,
            totalPages,
            keySearch
          })
        }
      }
    )
  }

  // #321512 create new function to load data of "code" only
  const loadDataForIATA = ({ keySearch = '', url, pageNum = 0, isScroll = false }) => {
    fetchRecords.execute(
        url,
        {
          customData: {
            baseFilterDTO: {
              filterType: 'OR',
              filterProperties: [
                {
                  fieldName: 'code',
                  operatorType: 'LIKE',
                  value1: keySearch
                }
              ],
              sortProperties: [],
              page: pageNum,
              size: 5
            },
            codeMType: 'PortCode'
          }
        },
        (response) => {
          const { number, size, totalPages } = response
          if (isScroll) {
            console.log([...page.content, ...response?.content])
            setPage({
              content: [...page.content, ...response?.content],
              number,
              size,
              totalPages,
              keySearch
            })
          } else {
            setPage({
              content: response?.content,
              number,
              size,
              totalPages,
              keySearch
            })
          }
        }
    )
  }

  const onInputChange = (ev, value) => {
    if (getValues(fName) === 'uicCode') {
      loadRailway({ keySearch: value })
    } else {
      if (getValues(fName) === 'iataCode') {
        // #321512 search by "code" only
        loadDataForIATA({
          keySearch: value,
          url: CarbonFootprintApiUrls.IATACODE_GET_API
        })
      } else
        loadData({
          keySearch: value,
          url: CarbonFootprintApiUrls.MAINTAINANCE_CODE
        })
    }
  }
  const loadRailway = ({ keySearch = '', pageNum = 0, isScroll = false }) => {
    fetchRecords.execute(
      CarbonFootprintApiUrls.RAILWAY_GET_API,
      {
        customData: {
          railwayRequest: keySearch.trim(),
          page: pageNum,
          size: 5
        }
      },
      (response) => {
        const { number, size, totalPages } = response
        if (isScroll) {
          console.log([...page.content, ...response?.content])
          setPage({
            content: [...page.content, ...response?.content],
            number,
            size,
            totalPages,
            keySearch
          })
        } else {
          setPage({
            content: response?.content,
            number,
            size,
            totalPages,
            keySearch
          })
        }
      }
    )
  }

  const onChange = (ev, valuePor) => {
    checkValidate(valuePor)
    setValue(valuePor)
    formField.onChange({
      target: { name, value: valuePor }
    })
  }
  const onScroll = (ev) => {
    const elmUl = document.getElementById('cfc-country-popup')
    const { scrollTop, scrollHeight, clientHeight } = elmUl
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (getValues(fName) === 'uicCode') {
        loadRailway({
          keySearch: page.keySearch,
          isScroll: true,
          pageNum: page?.number + 1
        })
      } else {
        if (getValues(fName) === 'iataCode') {
          // #321512 search by "code" only
          loadDataForIATA({
            keySearch: page.keySearch,
            url: CarbonFootprintApiUrls.IATACODE_GET_API,
            pageNum: page?.number + 1,
            isScroll: true
          })
        } else
          loadData({
            keySearch: page.keySearch,
            url: CarbonFootprintApiUrls.MAINTAINANCE_CODE,
            isScroll: true,
            pageNum: page?.number + 1
          })
      }
    }
  }

  return (
    <Fragment>
      <FormControl error={!!errors[name]} fullWidth>
        <Autocomplete
          id='cfc-country'
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onInputChange={onInputChange}
          onClose={() => {
            setOpen(false)
          }}
          ListboxProps={{ onScroll: onScroll, style: { maxHeight: '200px' } }}
          filterOptions={(x) => x}
          freeSolo
          defaultValue={value}
          value={value}
          options={page?.content ?? []}
          autoComplete
          includeInputInList
          loading={loading}
          filterSelectedOptions
          getOptionLabel={(option) => option?.descriptionEn ?? ''}
          onChange={onChange}
          renderOption={(option) => {
            return (
              <div className={'cfc-view-option'}>
                <div className='fl fl-sb al-c full-w'>
                  <div>
                    {!FLAG_NS.includes(option?.countryCode) && (
                      <img
                        style={{ width: '20px', height: '20px' }}
                        src={`${process.env.PUBLIC_URL}/static/images/countries/${option?.countryCode}.svg`}
                      />
                    )}
                  </div>
                  <div className='font-12 text-l cfc-search-des'>
                    <div className='cfc-search-des'>
                      {option?.descriptionEn}
                    </div>
                    <div>
                      {
                        country.find(
                          (item) =>
                            item.code === option?.countryCode &&
                            !FLAG_NS.includes(item.code)
                        )?.descriptionEn
                      }
                    </div>
                  </div>
                  <div className='cfc-code-div'>
                    <div className='cfc-chip'>{option?.code}</div>
                  </div>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            return (
              <div className='cfc-view-box'>
                <TextField
                  {...params}
                  error={getError(errors, name)}
                  variant='filled'
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <div className='view-flag'>
                        {!FLAG_NS.includes(value?.countryCode) &&
                          value != '' &&
                          value && (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src={`${process.env.PUBLIC_URL}/static/images/countries/${value?.countryCode}.svg`}
                            />
                          )}
                      </div>
                    ),
                    endAdornment: (
                      <div className='fl fl-c'>
                        {value != '' && value && (
                          <div className='fl view-detail'>
                            <div className='cfc-chip'>{value?.code}</div>
                            <div className='cfc-chip'>{value?.countryCode}</div>
                          </div>
                        )}
                        {params.InputProps.endAdornment}
                      </div>
                    ),
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              </div>
            )
          }}
        />
        <div className='error'>{getError(errors, name)?.message}</div>
      </FormControl>
    </Fragment>
  )
}

export default PortSelectAutoComplete
