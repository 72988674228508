const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-tradetrust`

const TRADETRUST_UPLOAD_PATH = `${MODULE_BASE}/upload`
const TRADETRUST_VIEW_PATH = `${MODULE_BASE}/upload/viewer`
const TRADETRUST_ENDORSEMENT_PATH = `${MODULE_BASE}/upload/endorsement`

const pathMap = {
    TRADETRUST_UPLOAD: TRADETRUST_UPLOAD_PATH,
    TRADETRUST_VIEW: TRADETRUST_VIEW_PATH,
    TRADETRUST_ENDORSEMENT: TRADETRUST_ENDORSEMENT_PATH
}

export default pathMap
