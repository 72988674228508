import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'

const {
      form: {
            adapter: {
                  useFormAdapter: { useField, useFormikContext }
            },
            field: {
                  CngSelectField
            },
      },
} = components


function checkJobStatusListDisabled(disabled, taskList, index, jobStatus) {
      if ( taskList[index].isTaskStatusDisabled == true || disabled === true || taskList[index].id === undefined || (taskList[index].id === "" || jobStatus === 'DRFT')) {
            return true
      } else {
            return false
      }
}

function JobStatusListComponent({ taskList, index, jobStatus, keyValue, handleDropdownChange, disabled, isClone}) {
      let r_taskType = taskList[index].taskType
      let r_statusCode =taskList[index].taskStatus
      const { fetchRecords } = useServices()
      const [statusList, setStatusList] = useState([])
      let jobTaskId = taskList[index].id
      const translatedTextsObject = SCOJobTranslationText()

      function fetchTaskStatus() {
            fetchRecords.execute(
                  SCOJobApiUrls.GETTASKSTATUS,
                  {
                         customData: { serviceType: r_taskType, statusType: r_statusCode},
                  },
                  (data) => {
                        setStatusList(data.map((datum) => {
                              return {
                                    text: datum.descriptionEng,
                                    value: datum.taskStatusCode
                              }
                        }))
                  }
            )

      }


      useEffect(() => {
            if(r_taskType !== "") {
                  fetchTaskStatus()
            }
            return () => {
                  // this is intentional, as per compliant solution in sonarqube
            }
      }, [r_taskType])

      return (
            <CngSelectField
                  name={`jobTaskItems[${index}].taskStatus`}
                  label={translatedTextsObject.scoJobTaskStatus}
                  items={statusList}
                  key={'taskStatus' + index}
                  disabled={checkJobStatusListDisabled(disabled, taskList,index, jobStatus)}
            />
      );

}

export default JobStatusListComponent