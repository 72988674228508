import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import Api from '../shared/api'
import BookingTypeComponent from '../components/BookingTypeComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CargoComponent from '../components/CargoComponent'
import CngBackdrop from '../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import ContactDetailsComponent from '../components/ContactDetailsComponent'
import ContainerComponent from '../components/ContainerComponent'
import ReeferComponent from '../components/ReeferComponent'
import DGCargoComponent from '../components/DGCargoComponent'
import DocumentComponent from '../components/DocumentComponent'
import FrbUtils from '../shared/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import NewBookingButtonComponent from '../components/NewBookingButtonComponent'
import PaymentInstructionComponent from '../components/PaymentInstructionComponent'
import ScheduleComponent from '../components/ScheduleComponent'
import ShipmentComponent from '../components/ShipmentComponent'
import Utils from 'src/views/common/utils/Utils'
import ValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'
import WarningDialog from 'src/components/dialog/WarningDialog'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { useFormContext } from "react-hook-form"
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  //button: { CngIconButton },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveDraft: true,
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: '',
  ...BookingTypeComponent.initialValues,
  ...ScheduleComponent.initialValues,
  ...ShipmentComponent.initialValues,
  ...ContainerComponent.initialValues,
  ...ReeferComponent.initialValues,
  ...ContactDetailsComponent.initialValues,
  ...PaymentInstructionComponent.initialValues,
  ...CargoComponent.initialValues,
  ...DGCargoComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const { CodeMaintenanceType } = constants

function FormFields({ loading, showNotification, onSubmitForm, validationSchema }) {
  const history = useHistory()
  const location = useLocation()
  const data = location.state

  const [booking, setBooking] = useState({})
  const fbTranslatedTextsObject = FreightBookingTranslationText()
  const validationMessageTranslation = ValidationMessageTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const [, ,] = useField('saveDraft')
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [switchDGDialogOpen, setSwitchDGDialogOpen] = useState(false)
  const [
    PODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen,
    setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen
  ] = useState(false)
  const [
    POLETDEarlierThanCargoReadyDateMsgWarningDialogOpen,
    setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen
  ] = useState(false)
  const [
    CargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen,
    setCargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen
  ] = useState(false)
  const [dgCargo, setDGCargo] = useState(false)
  const [showLocalCategory, setShowLocalCategory] = useState(false)
  const [cargoKeyRef] = useState(uuid())

  const [, ,] = useField('bookingId')
  const [, ,] = useField('freightBookingId')

  //move this from BookingTypeComponent to share with Cargo component
  const [, , { setValue: setHazardousField }] = useField('hazardous')

  //move this from ShipmentComponent to handle onChange in ScheduleComponent
  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')
  const [, , { setValue: setPlaceOfReceiptField }] = useField('placeOfReceipt')
  const [, , { setValue: setPlaceOfDeliveryField }] =
    useField('placeOfDelivery')
  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())
  const [podEtaField, ,] = useField('podEta')
  const [polEtdField, ,] = useField('polEtd')
  const [cargoReadyDateField, ,] = useField('cargoReadyDate')
  const [cargoDeliveryDateField, ,] = useField('cargoDeliveryDate')
  const SG_DATE_TIME_FORMAT = Utils.UI_FORMAT_DATE_TIME

  const isEdit = useRef(false)
  console.log(data)

  const { fetchRecords } = useServices()
  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const bookFromSchedule =
    data == null || data.booking == null ? false : data.booking.bookFromSchedule
  const [forceExpand, setForceExpand] = useState(false)
  const { getValues, setError } = useFormContext()
  const [reeferError, setReeferError] = useState()

  const setValidatedError = (errorPath, message) => {
    console.log('errorPath:', errorPath)
    console.log('message:', message)
    let finalError = null
    let errorAttr = ''
    if (errorPath.includes(".")) {
      let errorObj = {}
      const arrErrorPath = errorPath.split(".")
      errorObj[arrErrorPath[1]] = message
      if (arrErrorPath[0].includes("[")) {
        const matches = arrErrorPath[0].match(/\[(.*?)\]/)
        if (matches) {
          errorAttr = arrErrorPath[0].replace(matches[0], "")
          const number = matches[1]
          finalError = []
          for (let i = 0; i < number; i++) {
            finalError.push(null)
          }
          finalError.push(errorObj)
        }
      } else {
        errorAttr = arrErrorPath[0]
        finalError = errorObj
      }
    } else {
      errorAttr = errorPath
      finalError = { message: message }
    }
    if (errorAttr && finalError) {
      setError(errorAttr, finalError)
    }

    //For reefer validation when reefer mandatory details are not entered and submit button is clicked
    if (errorPath.indexOf('bookingContainers') !== -1 && errorPath.indexOf('equipmentTemp')) {
      let matches = errorPath.match(/\[(.*?)\]/);

      if (matches) {
        let submatch = matches[1];
        if (submatch != undefined) {
          setReeferError(submatch)
        }
      }
    }
    else {
      setReeferError(undefined)
    }
  }

  const onConfirmReview = async () => {
    await setFieldValue('saveDraft', false)
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
        //expand the shippment section
        setForceExpand(true)
      }
    }
    // submitForm()
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onSaveDraft = async () => {
    await setFieldValue('saveDraft', true)
    // submitForm()
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
        setForceExpand(true)
      }
    }
    // submitForm()
  }

  const newBookingSteps = [
    fbTranslatedTextsObject.stepLabelCreateBooking,
    fbTranslatedTextsObject.stepLabelReviewBooking,
    fbTranslatedTextsObject.stepLabelBookingSubmitted
  ]

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const cancelBooking = () => {
    history.push({
      pathname: '/cal-freightbooking/my-booking'
    })
  }
  useEffect(() => {
    if (data === null || data === undefined) return

    if (
      (data.action === FrbUtils.Action.edit ||
        data.action === FrbUtils.Action.amend) &&
      data.prevPage === FrbUtils.Page.BookingList
    ) {
      const bookingRequest = {
        bookingId: data.booking ? data.booking.bookingId : '',
        dockey: data.booking ? data.booking.dockey : ''
      }
      Api.fetchBookingDetailsById(
        fetchRecords,
        bookingRequest,
        populateBookingData
      )
    } else if (data.booking !== null) {
      setBooking(data.booking)
    }
  }, [])

  function populateBookingData(apiData) {
    apiData.bookStatusDesc = location.state.bookStatusDesc
    setBooking(apiData)
  }

  useEffect(() => {
    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    polKeyRef.current = uuid()
    podKeyRef.current = uuid()
    PopulateDataFromReview()
    setShouldRender(true)
  }, [booking])

  function PopulateDataFromReview() {
    console.log("populateDataFromReview(): booking : ", booking)
    if (booking != null && !isEmpty(booking)) {
      //if book from schedule, need to query the port desc
      if (bookFromSchedule) {
        populatePortDesc()
      }

      console.log(
        'populate data representativeName ' + booking.representativeName
      )
      const dataArr = Object.entries(booking)
      dataArr.forEach(([key, val]) => {
        if (FrbUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (FrbUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }
        //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        if (FrbUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }
        setFieldValue(key, val)
      })

      setDGCargo(booking.hazardous)
      setShowLocalCategory(portsIncludeSG())


      isEdit.current = true
      setForceExpand(false)
    }
  }

  if (!shouldRender) {
    return null
  }

  function populatePortDesc() {
    if (
      !Utils.isEmptyString(booking.polCode) ||
      !Utils.isEmptyString(booking.podCode)
    ) {
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.PORT,
        [
          {
            field: 'code',
            operator: 'in',
            value: [booking.polCode, booking.podCode]
          }
        ],
        false,
        onLoadPortSuccess
      )
    }
  }

  function onLoadPortSuccess(portData) {
    let polDesc =
      portData.findIndex((port) => port.code === booking.polCode) !== -1
        ? portData[portData.findIndex((port) => port.code === booking.polCode)]
          .descriptionEn
        : booking.polCode

    console.log(polDesc)
    setPlaceOfReceiptField(polDesc)

    let podDesc =
      portData.findIndex((port) => port.code === booking.podCode) !== -1
        ? portData[portData.findIndex((port) => port.code === booking.podCode)]
          .descriptionEn
        : booking.podCode

    console.log(podDesc)
    setPlaceOfDeliveryField(podDesc)
  }

  function portsIncludeSG() {
    return booking.polCode === 'SGSIN' || booking.podCode === 'SGSIN'
  }

  const onDGchange = (event) => {
    console.log(event.target.checked)
    setSwitchDGDialogOpen(true)
  }

  const validatePOLTEDAndCargoReadyDate = (e, field) => {
    setFieldValue(field, e, true)

    if (
      (field === 'cargoReadyDate' &&
        moment(moment(polEtdField.value).format(SG_DATE_TIME_FORMAT)).isBefore(
          moment(e).format(SG_DATE_TIME_FORMAT)
        ) &&
        polEtdField.value != null &&
        e != null) ||
      (field === 'polEtd' &&
        cargoReadyDateField.value != null &&
        e != null &&
        moment(
          moment(cargoReadyDateField.value).format(SG_DATE_TIME_FORMAT)
        ).isAfter(moment(e).format(SG_DATE_TIME_FORMAT)))
    ) {
      setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(true)
    }
    else if (
      (field === 'cargoReadyDate' && moment(moment(e).format(SG_DATE_TIME_FORMAT)).isBefore(moment()))) {
      setCargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen(true)
    }
  }

  const validatePODETAAndCargoDeliveryDate = (e, field) => {
    console.log(cargoDeliveryDateField.value)
    console.log(podEtaField.value)
    console.log(e)
    setFieldValue(field, e, true)

    if (
      (field === 'cargoDeliveryDate' &&
        moment(moment(podEtaField.value).format(SG_DATE_TIME_FORMAT)).isAfter(
          moment(e).format(SG_DATE_TIME_FORMAT)
        ) &&
        podEtaField.value != null &&
        e != null) ||
      (field === 'podEta' &&
        cargoDeliveryDateField.value != null &&
        e != null &&
        moment(
          moment(cargoDeliveryDateField.value).format(SG_DATE_TIME_FORMAT)
        ).isBefore(moment(e).format(SG_DATE_TIME_FORMAT)))
    ) {
      setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(true)
    }
  }

  const switchDG = async () => {
    let tempDG = [
      {
        cargoId: '',
        id: uuid(),
        cargoDesc: '',
        hsCode: '',
        packageType: ''
      }
    ]

    setDGCargo(!dgCargo)
    setHazardousField(!dgCargo)
    setSwitchDGDialogOpen(false)
    //distory the existing cargo/dg cargo list
    await setFieldValue('bookingCargoes', [])
    await setFieldValue('bookingDGCargoes', tempDG)

    //setCargoKeyRef(uuid())
  }

  return (
    <Box id="top-level-box">
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={newBookingSteps} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <BookingTypeComponent.FormBody
              onDGchange={onDGchange}
              bookingTypeData={booking}
              isEdit={isEdit.current}
            />
          </Box>

          <Box pt={5}>
            <ScheduleComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef}
              setPlaceOfReceiptCodeField={setPlaceOfReceiptCodeField}
              setPlaceOfDeliveryCodeField={setPlaceOfDeliveryCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              isEdit={isEdit.current}
              polKeyRef={polKeyRef.current}
              podKeyRef={podKeyRef.current}
              setShowLocalCategory={setShowLocalCategory}
              bookFromSchedule={bookFromSchedule}
              PolEtdDateChange={validatePOLTEDAndCargoReadyDate}
              PodEtaDateChange={validatePODETAAndCargoDeliveryDate}
            />
          </Box>

          <Box pt={5}>
            <ShipmentComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef.current}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef.current}
              placeOfReceiptCodeField={placeOfReceiptCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              placeOfDeliveryCodeField={placeOfDeliveryCodeField}
              isEdit={isEdit.current}
              forceExpand={forceExpand}
              setForceExpand={setForceExpand}
              isCreateBooking
              CargoReadyDateChange={validatePOLTEDAndCargoReadyDate}
              CargoDeliveryDateChange={validatePODETAAndCargoDeliveryDate}
            />
          </Box>
          <Box pt={5}>
            <ContainerComponent.FormBody
              isEdit={isEdit.current}
              reeferError={reeferError}
            />
          </Box>

          {dgCargo ? (
            <Box pt={5}>
              <DGCargoComponent.FormBody
                isEdit={isEdit.current}
                showLocalCategory={showLocalCategory}
                cargoKeyRef={cargoKeyRef}
              />
            </Box>
          ) : (
            <Box pt={5}>
              <CargoComponent.FormBody
                isEdit={isEdit.current}
                cargoKeyRef={cargoKeyRef}
              />
            </Box>
          )}
          <Box pt={5}>
            <ContactDetailsComponent.FormBody isEdit={isEdit.current} />
          </Box>
          <Box pt={5}>
            <PaymentInstructionComponent.FormBody isEdit={isEdit.current} />
          </Box>
          <Box pt={5}>
            <DocumentComponent.FormBody isEdit={isEdit.current} showNotification={showNotification} />
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <NewBookingButtonComponent
            onSaveDraft={onSaveDraft}
            onConfirmReview={onConfirmReview}
            onDiscard={onDiscard}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelBooking}
        content={fbTranslatedTextsObject.dialogDiscardBookingContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={fbTranslatedTextsObject.dialogDiscardBookingTitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={fbTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />

      <WarningDialog
        isDialogOpen={PODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen}
        closeDialog={() =>
          setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(false)
        }
        okDialog={() =>
          setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(false)
        }
        content={
          validationMessageTranslation.PODETAlaterThanCargoDeliveryDateMsg
        }
        okMsg={uiTranslatedTextsObject.ok}
      />

      <WarningDialog
        isDialogOpen={POLETDEarlierThanCargoReadyDateMsgWarningDialogOpen}
        closeDialog={() =>
          setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(false)
        }
        okDialog={() =>
          setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(false)
        }
        content={
          validationMessageTranslation.POLETDEarlierThanCargoReadyDateMsg
        }
        okMsg={uiTranslatedTextsObject.ok}
      />

      <WarningDialog
        isDialogOpen={CargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen}
        closeDialog={() =>
          setCargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen(false)
        }
        okDialog={() =>
          setCargoReadyDateEarilierThanSystemDateMsgWarningDialogOpen(false)
        }
        content={
          validationMessageTranslation.CargoReadyDateBeforeTodayMsg
        }
        okMsg={uiTranslatedTextsObject.ok}
      />

      <ConfirmDialog
        isConfirmDialogOpen={switchDGDialogOpen}
        closeDialog={() => setSwitchDGDialogOpen(false)}
        confirmDialog={switchDG}
        content={fbTranslatedTextsObject.dialogSwitchDGContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={<h3>{uiTranslatedTextsObject.warning}</h3>}
      />
    </Box>
  )
}

const NewBookingFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default NewBookingFormProperties
