import React, { useState } from "react";
import { Download, Printer } from "react-feather";
import { Button } from '@material-ui/core';
import { utils } from "@govtechsg/open-attestation";
import QRCode from "qrcode.react";
import { getOpenAttestationData } from "../utils/shared";
import { SvgIcon, SvgIconQRCode } from "./SvgIcon";


export const DocumentUtility = ({ document, onPrint }) => {
  const [qrCodePopover, setQrCodePopover] = useState(false);
  const documentWithMetadata = getOpenAttestationData(document.document); // Extending document data to account for undefined metadata in OA schema
  const { name, links } = utils.isRawV2Document(documentWithMetadata.document)
    ? documentWithMetadata.credentialSubject
    : documentWithMetadata;
  const fileName = name ?? "Untitled";
  const qrcodeUrl = links?.self?.href;

  const imageSettings = {
    src: `${process.env.PUBLIC_URL}/static/images/logo-tradetrust-qrcode.png`,
    height: 50,
    width: 55,
    excavate: true,
  };

  return (
    <div className="container no-print bg-white pb-8">
      <div className="flex flex-wrap">
        <div className="w-auto ml-3">
          <a
            download={`${fileName}.tt`}
            target="_black"
            href={`data:text/json;,${encodeURIComponent(JSON.stringify(document, null, 2))}`}
            role="button"
            aria-label="document-utility-download"
          >
            <Button
              className=""
              aria-label="document-utility-download-button"
              style={{ width: "auto", height: "auto", float: 'right' }}
            >
              <Download style={{ float: 'right' }} />
            </Button>
          </a>

          <Button
            className=""
            aria-label="document-utility-print-button"
            onClick={() => onPrint()}
            style={{ width: "auto", height: "auto", float: 'right' }}
          >
            <Printer style={{ float: 'right' }} />
          </Button>
        </div>
        <div className="w-auto ml-3" style={{ width: "auto", height: "auto", float: 'right' }}>
          <Button
            className=""
            aria-label="document-utility-print-button"
            onClick={() => {
              setQrCodePopover(!qrCodePopover);
            }}
            style={{ width: "auto", height: "auto", float: 'right' }}
          >
            <SvgIcon className="text-cerulean-500" strokeWidth="0.5" fill="currentColor">
              <SvgIconQRCode />
            </SvgIcon>
          </Button>
          {qrCodePopover
            ? <div classname="absolute border">
              <QRCode
                value={qrcodeUrl}
                level="Q"
                size={200}
                bgColor="#FFFFFF"
                fgColor="#000000"
                imageSettings={imageSettings}
              />
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default DocumentUtility;
