import {
    MessageTitle,
    OverlayContext,
    LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from '@material-ui/core';
import { FormState } from "../../constants/FormState";
import { isEthereumAddress } from "../../utils";
import AssetInformationPanel from "../AssetInformationPanel/index";
import { AssetManagementTitle } from "../AssetManagementTitle";
import { EditableAssetTitle } from "./EditableAssetTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';
import CancelButton from 'src/components/button/CancelButton.js'
import FormBackButton from "../FormBackButton";
import Loading from '../Loading';

export const NominateBeneficiaryForm = ({
    formAction,
    tokenRegistryAddress,
    beneficiary,
    holder,
    handleNomination,
    nominationState,
    setFormActionNone,
    setShowEndorsementChain,
    history,
    setConfirmDialogOpen,
    setModalData
}) => {
    const [newBeneficiary, setNewBeneficiary] = useState("");
    const isPendingConfirmation = nominationState === FormState.PENDING_CONFIRMATION;
    const isConfirmed = nominationState === FormState.CONFIRMED;
    const isEditable = nominationState !== FormState.PENDING_CONFIRMATION && nominationState !== FormState.CONFIRMED;
    const { showOverlay } = useContext(OverlayContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isConfirmed) {
            setModalData({
                content: MessageTitle.NOMINATE_BENEFICIARY_HOLDER_SUCCESS,
                title: 'Nomination Success',
                okMsg: 'Close',
                type: 'Info'
            })
            setLoading(false);
            setConfirmDialogOpen(true);
            setFormActionNone();
        }
    }, [isConfirmed, newBeneficiary, showOverlay, setFormActionNone]);

    useEffect(() => {
        if (nominationState === FormState.ERROR) {
            setLoading(false);
        }
    }, [nominationState])

    const isInvalidNomination =
        !newBeneficiary || !holder || newBeneficiary === beneficiary || !isEthereumAddress(newBeneficiary);

    return (
        <>
            <Loading loading={loading}>
                <FormBackButton
                    setFormActionNone={setFormActionNone}
                />
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isPendingConfirmation}
                />

                < Grid container spacing={1}>
                    <Grid item xs={4}>
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                            document={history.location.state.document}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle
                            role="Owner"
                            value={beneficiary}
                            newValue={newBeneficiary}
                            isEditable={isEditable}
                            onSetNewValue={setNewBeneficiary}
                            isError={nominationState === FormState.ERROR}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle role="Holder" value={holder} isEditable={false} />
                    </Grid>
                </Grid >

                <Grid container spacing={1}>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <Box ml={3}>
                            <CancelButton onClick={setFormActionNone} disabled={isPendingConfirmation} />
                        </Box>
                    </Grid>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <SubmitButton
                            onClick={() => {
                                handleNomination(newBeneficiary);
                                setLoading(true);
                            }}
                            disabled={isInvalidNomination || isPendingConfirmation}
                        >
                            {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Nominate</>}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Loading>
        </>
    );
};
