import { Box, Grid, Paper, Typography } from '@material-ui/core'

import DocumentDetailsComponents from './DocumentDetailsComponents'
import ManageScheduleTranslationText from '../ManageScheduleTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'

const {
  CngGridItem,
  CngFilesDropzone,
  dropzone: { CngFileUpload, HelperTextArea },
  button: { CngPrimaryButton },
} = components

const FormBody = (props) => {
  const msTranslatedTextsObject = ManageScheduleTranslationText()

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  const setSelectedFiles = (file) => {
    console.log('selected!', file);
    let processedFileObjs = []
    file.forEach((item) => {

      if (item.file.name.length > 26) {
        var newFile = new File(
          [item.file],
          Utils.truncLongFileName(item.file.name)
        )

        let processedFileObj = {
          file: newFile,
          fields: item.fields
        }
        processedFileObjs.push(processedFileObj)
      } else {
        processedFileObjs.push(item)
      }
    })

    console.log('processedFileObjs', processedFileObjs)
    props.setItem(processedFileObjs)
  }

  return (
    <Box>
      <Box mb={2}>
        {props.uploadedDocs.length > 0 ? (
          <DocumentDetailsComponents
            tile={props.uploadedDocs}
            hideEdit={true}
            hideDelete={false}
            removeRowClick={props.removeUploadedRowClick}
            downloadRowClick={props.downloadUploadedRowClick}
            downloadLogClick={props.downloadLogClick}
          />
        ) : (
          <Paper style={{ backgroundColor: '#fafbfc' }}>
            <Box className={'vs-nofile'}>
              <Box mt={3}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/static/images/vesselSchedule/FolderEmpty.svg'
                  }
                  alt='No Result'
                ></img>
              </Box>
              <Box mt={1}>
                <Typography variant='h4' style={{ fontWeight: 'bold' }}>
                  {msTranslatedTextsObject.noFilesYet}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='caption'
                  style={{ padding: '10px', opacity: '0.5' }}
                >
                  {msTranslatedTextsObject.uploadDesc}
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}
      </Box>
      <Box>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <CngFileUpload
              accept={['.csv']}
              maxSize={10485760}
              onFileSelect={setSelectedFiles}
              files={props.pendingDocs}
              moreActions={moreActions}
              onDropRejected={(error) => props.showError(error[0])}
              renderHelperText={() => {
                return (
                  <HelperTextArea
                    accept={['.csv']}
                    maxSize={10485760}
                  />
                )
              }}
            />
          </CngGridItem>
        </Grid>
      </Box>
      {(props.pendingDocumentsField && props.pendingDocumentsField.value && props.pendingDocumentsField.value.length) > 0 && (
        <Box mt={2} className={'vs-nofile'}>
          <CngPrimaryButton onClick={props.upload}>
            Start Upload
          </CngPrimaryButton>
        </Box>
      )}
    </Box>
  )
}

const DocumentComponent = Object.freeze({
  FormBody: FormBody
})

export default DocumentComponent
