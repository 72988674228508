import HelpdeskKeys from 'src/constants/locale/key/Helpdesk'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const HelpdeskTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.HELPDESK
  ])

  let title = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.TITLE
  )

  let viewBooking = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.VIEW_BOOKING
  )

  let bookingDetails = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.BOOKING_DETAILS
  )

  let backToViewBooking = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.BACK_TO_VIEW_BOOKING
  )

  let viewShippingInstruction = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.VIEW_SI
  )

  let shippingInstructionDetails = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.SI_DETAILS
  )

  let backToViewShippingInstruction = translate(
    Namespace.HELPDESK,
    HelpdeskKeys.BACK_TO_VIEW_SI
  )

  return {
    title,
    viewBooking,
    bookingDetails,
    backToViewBooking,
    viewShippingInstruction,
    shippingInstructionDetails,
    backToViewShippingInstruction,
  }
}

export default HelpdeskTranslationText
