import {
    MessageTitle, OverlayContext, LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Box } from '@material-ui/core';
import { FormState } from "../../constants/FormState";
import AssetInformationPanel from "../AssetInformationPanel/index";
import { AssetManagementTitle } from "../AssetManagementTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';
import CancelButton from 'src/components/button/CancelButton.js'
import FormBackButton from "../FormBackButton";
import Loading from '../Loading';

export const AcceptSurrenderedForm = ({
    formAction,
    tokenRegistryAddress,
    handleDestroyToken,
    destroyTokenState,
    setFormActionNone,
    setShowEndorsementChain,
    setConfirmDialogOpen,
    setModalData
}) => {
    const isDestroyTokenPendingConfirmation = destroyTokenState === FormState.PENDING_CONFIRMATION;
    const isDestroyTokenConfirmed = destroyTokenState === FormState.CONFIRMED;
    const { showOverlay } = useContext(OverlayContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isDestroyTokenConfirmed) {
            setModalData({
                content: MessageTitle.ACCEPT_SURRENDER_DOCUMENT,
                title: 'Success',
                okMsg: 'Close',
                type: 'Info'
            })
            setLoading(false);
            setConfirmDialogOpen(true);
            setFormActionNone();
        }
    }, [showOverlay, setFormActionNone, isDestroyTokenConfirmed]);

    useEffect(() => {
        if (destroyTokenState === FormState.ERROR) {
            setLoading(false);
        }
    }, [destroyTokenState])

    return (
        <>
            <Loading loading={loading}>
                <FormBackButton
                    setFormActionNone={setFormActionNone}
                />
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isDestroyTokenPendingConfirmation}
                />
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <div className="py-4">
                            <Typography id="surrender-sign" className="bg-white rounded-xl text-scarlet-500 border-scarlet-500">
                                <h3 style={{ fontWeight: 600 }} id="issuedby" className="break-words leading-tight py-4" data-testid="surrenderToIssuer">
                                Surrendered To Issuer
                                </h3>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <Box ml={3}>
                            <CancelButton onClick={setFormActionNone} disabled={isDestroyTokenPendingConfirmation} />
                        </Box>
                    </Grid>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <SubmitButton
                            onClick={() => {
                                handleDestroyToken();
                                setLoading(true);
                            }}
                            disabled={isDestroyTokenPendingConfirmation}
                        >
                            {isDestroyTokenPendingConfirmation ? (
                                <LoaderSpinner data-testid={"accept-loader"} />
                            ) : (
                                <>Shred Document</>
                            )}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Loading>
        </>
    );
};
