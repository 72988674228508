import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import PollingApiUrls from 'src/apiUrls/PollingApiUrls'
import React from 'react'
import PollingKeys from 'src/constants/locale/key/Polling'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.POLLING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let polling = translate(
      Namespace.POLLING,
      PollingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: polling
      }
    )
    let pollingId = translate(
      Namespace.POLLING,
      PollingKeys.POLLING_ID
    )
    let portPair = translate(
      Namespace.POLLING,
      PollingKeys.PORT_PAIR
    )
    let cargoReadyDate = translate(
      Namespace.POLLING,
      PollingKeys.CARGO_READY_DATE
    )
    let rangeOfCargoReadyDate = translate(
      Namespace.POLLING,
      PollingKeys.RANGE_OF_CARGO_READY_DATE
    )
    let totalSource = translate(
      Namespace.POLLING,
      PollingKeys.TOTAL_SOURCE
    )
    let processedSource = translate(
      Namespace.POLLING,
      PollingKeys.PROCESSED_SOURCE
    )
    let failureSource = translate(
      Namespace.POLLING,
      PollingKeys.FAILURE_SOURCE
    )
    let runDate = translate(
      Namespace.POLLING,
      PollingKeys.RUN_DATE
    )
    let completedDate = translate(
      Namespace.POLLING,
      PollingKeys.COMPLETED_DATE
    )

    return {
      polling,
      tableTitle,
      pollingId,
      portPair,
      cargoReadyDate,
      rangeOfCargoReadyDate,
      totalSource,
      processedSource,
      failureSource,
      runDate,
      completedDate
    }
  }

  const columns = [
    {
      field: 'pollingId',
      title: translatedTextsObject.pollingId,
      type: 'numeric',
    },
    {
      field: 'portPair',
      title: translatedTextsObject.portPair,
    },
    {
      field: 'cargoReadyDate',
      title: translatedTextsObject.cargoReadyDate,
    },
    {
      field: 'rangeOfCargoReadyDate',
      title: translatedTextsObject.rangeOfCargoReadyDate,
    },
    {
      field: 'totalSource',
      title: translatedTextsObject.totalSource,
      type: 'numeric',
    },
    {
      field: 'processedSource',
      title: translatedTextsObject.processedSource,
      type: 'numeric',
    },
    {
      field: 'failureSource',
      title: translatedTextsObject.failureSource,
      type: 'numeric',
    },
    {
      field: 'runDate',
      title: translatedTextsObject.runDate,
      type: 'datetime'
      //filterComponent: CngDateRangeFilter,
    },
    {
      field: 'completedDate',
      title: translatedTextsObject.completedDate,
      type: 'datetime'
      //filterComponent: CngDateRangeFilter,
    }
  ];

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: PollingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: PollingApiUrls.EXPORT }}
              fetch={{ url: PollingApiUrls.GET }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
