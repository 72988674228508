//This class is to interface with the NG API
import React from 'react'
import TradeTrustApiUrls from 'src/apiUrls/TradeTrustApiUrls'

/**
 * Fetch the SI Config based on config key
 * For example, the PING status mapping and the Wallet address mapping.
 * @param {*} fetchRecords 
 * @param {*} onSuccess function to execute on success
 */
function fetchSIConfig(fetchRecords, onSuccess) {
    fetchRecords.execute(
        TradeTrustApiUrls.GET_SI_CONFIG,
        {
            customData: ""
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            console.log(error)
        }
    )
}

/**
 * This function will get the full SI details by passing in a Bill of Lading number. Will call to SI API in the BE.
 * @param {*} fetchRecords 
 * @param {*} siRequest Request body containing BoL number
 * @param {*} onSuccess function to execute on success
 * @param {*} milestoneBody the actual PING milestone body. This is passed here because it is needed in the onSuccess function
 */
function fetchSIDetailsByBlNum(fetchRecords, siRequest, onSuccess, milestoneBody) {
    console.log('getSiDetailsByBlNum...')
    fetchRecords.execute(
        TradeTrustApiUrls.GET_SI_DETAILS_BLNUM,
        { customData: siRequest },
        (data) => {
            onSuccess(data, milestoneBody)
        },
        (error) => {
            console.log(error)
        }
    )
}

const TradeTrustApi = Object.freeze({
    fetchSIConfig,
    fetchSIDetailsByBlNum
})

export default TradeTrustApi
