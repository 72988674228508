import React, { useState } from 'react'
import { Card, makeStyles, Typography } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import ClearFieldsButton from 'src/components/button/ClearFieldsButton'
import CalculateButton from 'src/components/button/CalculateButton'
import TradeMgmtApiUrls from 'src/apiUrls/TradeMgmtApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'
import TradeManagementTranslationText from '../shared/TradeManagementTranslationText'
import { useFormContext } from 'react-hook-form'
import CngBackdrop from '../components/CngBackdrop'
import HsCodeSearch from '../shared/HsCodeSearch'
import { Field } from './components/Field'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.text.widgetTitle
  },
  formCaptionBig: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.text.widgetTitle
  },
  formCaptionSmall: {
    fontSize: '0.75rem',
    color: theme.palette.text.widgetSubTitle
  },
  stickyFooter: {
    backgroundColor: theme.palette.background.white,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    boxShadow: '0px -1px 2px -1px rgba(0, 0, 0, 0.2)',
    position: 'sticky',
    bottom: 0,
    zIndex: 1
  }
}))

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hsCodeAndDescription: '',
  materialNoSkuProductDesc: '',
  model: '',
  unitOfMeasurement: '',
  currency: '',
  exworksCost: '',
  fobPrice: '',
  transactionValue: '',
  netCosts: '',
  totalCosts: '',
  origin: '',
  destination: '',
  cifValue: '',
  check_hsCode: '', // updated.
  isValid: false,
  uomList: []
})

const {
  form: {
    field: { CngTextField, CngLookupAutocompleteField, CngServerSideAutocompleteField },
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ history, showNotification, disabled }) {
  const classes = useStyles()

  const { setValue, getValues, handleSubmit, clearErrors } = useFormContext()
  const [hsMasterId, setHsMasterId] = useState()
  const [productId, setProductId] = useState()
  const { securedSendRequest } = useServices()
  const [loading, setLoading] = useState(false)
  const [chapterDescription, setChapterDescription] = useState('')
  const [uomName, setUomName] = useState('')
  const [chapterNo, setChapterNo] = useState('')
  const [skuProductDesc, setSkuProductDesc] = useState('')

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()

  // @purpose: A function to clear form fields
  const resetForm = () => {
    console.log('Resetting form.')
    setValue('hsCodeAndDescription', '')
    setValue('materialNoSkuProductDesc', '')
    setValue('model', '')
    setValue('unitOfMeasurement', '')
    setValue('currency', '')
    setValue('exworksCost', '')
    setValue('origin', '')
    setValue('destination', '')
    setValue('cifValue', '')
    setValue('check_hsCode', '')
    setValue('fobPrice', '')
    setValue('transactionValue', '')
    setValue('netCosts', '')
    setValue('totalCosts', '')
  }

  // @purpose: A function to validiate the form data
  const calculateFTA = () => {
    handleSubmit(onValid, onInvalid)()
  }

  // @purpose: Upon form is valid
  const onValid = (data) => {
    // Show data loader overlay
    setLoading(true)

    // Calcualte payload
    const calcPayload = {
      hsMasterId: hsMasterId,
      productId: productId,
      origin: getValues('origin'),
      destination: getValues('destination'),
      cifValue: getValues('cifValue'),
      isValid: getValues('isValid'),
      check_hsCode: getValues('check_hsCode'),
      hsItem: getValues('hsItem'),
      measures: getValues('measures')
    }


    // AXIOS call to back-end
    securedSendRequest.execute(
      'post',
      TradeMgmtApiUrls.FTA_CALCULATE,
      calcPayload,
      (response) => {
        // On success with data
        if (response?.data?.status == 200) {
          // If response data has results then route to details
          // |-> And store data for processing
          if (response?.data?.results.length > 0) {
            // Hide data loader overlay
            setLoading(false)

            // Show success notification
            showNotification(
              'success',
              FtaTranslatedTextsObject.calculationSuccessMsg
            )

            // Group fta names
            /* const calcDetail = new Array()
            let temp = response?.data?.results[0].calculationDetails
            const ftaCalcResult = temp.reduce(function (obj, key) {
              obj[key.fta] = obj[key.fta] || []
              obj[key.fta].push(key)
              return obj
            }, Object.create(null))

            calcDetail.push(ftaCalcResult) */

            history.push(`/cal-tmp/ftacalculator/productdetails`, {
              detailsData: {
                materialSkuProductDescription: getValues(
                  'materialNoSkuProductDesc'
                ),
                hsCodeAndDescription: getValues('hsCodeAndDescription'),
                productDescription: chapterDescription,
                chapterNo: chapterNo,
                model: getValues('model'),
                unitOfMeasurement: getValues('unitOfMeasurement'),
                unitOfMeasurementName: uomName,
                currency: getValues('currency'),
                exworksCost: getValues('exworksCost'),
                fobPrice: getValues('fobPrice'),
                transactionValue: getValues('transactionValue'),
                netCosts: getValues('netCosts'),
                totalCosts: getValues('totalCosts'),
                origin: response?.data?.results[0].origin,
                originCode: getValues('origin'),
                destination: response?.data?.results[0].destination,
                destinationCode: getValues('destination'),
                cifValue: getValues('cifValue'),
                hsCode: response?.data?.results[0].hsCode,
                baseUnit: response?.data?.results[0].baseUnit
              },
              calculationResults: response?.data?.results[0].calculationDetails,
              ftaActivityHistoryId:
                response?.data?.results[0].ftaActivityHistoryId,
              fromViewDetails: false,
              isRoutefromCalcScreen: true
            })
          }
        }
        // On client-side & server-side error
        else if (
          response?.data?.status == 400 ||
          response?.data?.status == 500
        ) {
          // Hide data loader overlay
          setLoading(false)

          let errorData = response?.data?.errorDetails

          // Show error notification
          if (errorData.length > 0) {
            errorData.map((errorItem, index) => {
              return showNotification('error', errorItem.errorMessage)
            })
          }
        }
      },
      (onError) => {
        console.error('Error received \n', onError)

        // Show error notification
        showNotification(
          'error',
          FtaTranslatedTextsObject.problemFetchingDataMsg
        )
      },
      // On compelte
      () => {
        // Hide data loader overlay
        setLoading(false)
      },
      {}
    )
  }

  // @purpose: Upon form is invalid
  const onInvalid = (data) => {
    console.error('Missing mandatory field value(s):\n', data)

    // Show error notification
    showNotification('error', FtaTranslatedTextsObject.allFieldsAreMandatory)
  }
  const onChangeFTAAutoComplete = (formValue, options) => {
    if (options !== null) {
      const lookupObject = options.data
      // Use formiKContext / useFormContext to set the field values to form object


      setValue('hsCodeAndDescription', lookupObject.hsCodeAndDescription)
      setValue('model', lookupObject.model)
      setValue('unitOfMeasurement', lookupObject.uomCode)
      setValue('currency', lookupObject.currency)
      setValue(
        'exworksCost',
        lookupObject.exworkPrice !== null
          ? Number(lookupObject.exworkPrice).toFixed(2)
          : null
      )
      setValue(
        'fobPrice',
        lookupObject.fobPrice !== null
          ? Number(lookupObject.fobPrice).toFixed(2)
          : null
      )
      setValue(
        'transactionValue',
        lookupObject.transactionValue !== null
          ? Number(lookupObject.transactionValue).toFixed(2)
          : null
      )
      setValue(
        'netCosts',
        lookupObject.netCosts !== null
          ? Number(lookupObject.netCosts).toFixed(2)
          : null
      )
      setValue(
        'totalCosts',
        lookupObject.totalCosts !== null
          ? Number(lookupObject.totalCosts).toFixed(2)
          : null
      )
      // Set state value
      setHsMasterId(lookupObject.hsMasterId)
      setProductId(lookupObject.id)
      setUomName(lookupObject.uomName)
      setChapterDescription(lookupObject.productDescription)
      setChapterNo(lookupObject.hsHeader)
      // Clear field error
      clearErrors('materialNoSkuProductDesc')
      clearErrors('hsCodeAndDescription')
      clearErrors('model')
      clearErrors('unitOfMeasurement')
      clearErrors('currency')
      clearErrors('exworksCost')
      clearErrors('fobPrice')
      clearErrors('transactionValue')
      clearErrors('netCosts')
      clearErrors('totalCosts')
    }
  };
  return (
    <>
      {/* Section: Product information */}
      <section className='section-grid child-of-grid one-col'>
        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>

        <header className='section-grid__col one-col'>
          <Typography variant='h4' className={classes.sectionTitle}>
            {FtaTranslatedTextsObject.productInformation}
          </Typography>
        </header>

        <article className='section-grid__col has-more-childs one-col'>
          <div className='one-col'>
            <Field
              label={FtaTranslatedTextsObject.materialNoSkuPdtDescription}
              name={`materialNoSkuProductDesc`}
              key={`materialNoSkuProductDescKey`}
              required
              component={CngServerSideAutocompleteField}
              minFilterChars={-1}
              debounceInterval={1000}
              searchableFields={['productDescription']}
              lookupPageableProps={{
                url: TradeMgmtApiUrls.GET_MYPRODUCT,
                value: 'materialSkuProductDescription',
                label: 'materialSkuProductDescription',
                pageSize: 10,
              }}
              onChange={onChangeFTAAutoComplete}
              getOptionLabel={(x) => x.data?.materialSkuProductDescription}
              getOptionSelected={(opt, val) => opt.value === val.value}
              render={() => getValues("materialNoSkuProductDesc")}
              lookupProps={{ label: "data" }}
            />
          </div>

          <div className='one-col'>
            <CngTextField
              name={`hsCodeAndDescription`}
              label={FtaTranslatedTextsObject.hsCodeAndDescription}
              disabled={disabled}
              required
            />
          </div>

          <div className='four-col'>
            <span>
              <CngTextField
                disabled={disabled}
                name={`model`}
                label={FtaTranslatedTextsObject.model}
              />
            </span>

            <span>
              <CngTextField
                disabled={disabled}
                name={`unitOfMeasurement`}
                label={FtaTranslatedTextsObject.unitOfMeasurement}
                required
              />
            </span>

            <span>
              <CngTextField
                disabled={disabled}
                name={`currency`}
                label={FtaTranslatedTextsObject.currency}
                required
              />
            </span>

            <span>
              <CngTextField
                disabled={disabled}
                name={`exworksCost`}
                label={FtaTranslatedTextsObject.exworksCost}
              />
            </span>
          </div>


          <div className='four-col'>
            <span>
              <CngTextField
                disabled={disabled}
                name={`fobPrice`}
                label={FtaTranslatedTextsObject.fobPrice}
              />
            </span>

            <span>
              <CngTextField
                disabled={disabled}
                name={`transactionValue`}
                label={FtaTranslatedTextsObject.transactionValue}
              />
            </span>

            <span>
              <CngTextField
                disabled={disabled}
                name={`netCosts`}
                label={FtaTranslatedTextsObject.netCosts}
              />
            </span>
            <span>
              <CngTextField
                disabled={disabled}
                name={`totalCosts`}
                label={FtaTranslatedTextsObject.totalCosts}
              />
            </span>
          </div>

        </article>
      </section>

      {/* Section: Origin-destination information */}
      <section className='section-grid child-of-grid one-col'>
        <header className='section-grid__col one-col'>
          <Typography variant='h4' className={classes.sectionTitle}>
            {FtaTranslatedTextsObject.originDestinationInformation}
          </Typography>
        </header>

        <article className='section-grid__col has-more-childs one-col'>
          <div className='three-col'>
            <span>
              <CountryAutocompleteField
                label={FtaTranslatedTextsObject.origin}
                name='origin'
                accessor='countryList'
                url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
                lookupProps={{
                  label: 'name',
                  value: 'code',
                  dataAccessor: 'content',
                  url: TradeMgmtApiUrls.GET_TMP_COUNTRY
                }}
                required
              />
            </span>

            <span>
              <CountryAutocompleteField
                label={FtaTranslatedTextsObject.destination}
                name='destination'
                accessor='countryList'
                url={TradeMgmtApiUrls.GET_TMP_COUNTRY}
                lookupProps={{
                  label: 'name',
                  value: 'code',
                  dataAccessor: 'content',
                  url: TradeMgmtApiUrls.GET_TMP_COUNTRY
                }}
                required
              />
            </span>

            <span>
              <CngTextField
                disabled={false}
                name={`cifValue`}
                label={FtaTranslatedTextsObject.cifValue}
              />
            </span>

          </div>
        </article>

      </section>

      <Card style={{ padding: '20px', backgroundColor: '#fafbfc' }}>

        <Typography variant='h4' className={classes.formCaptionBig}>
          {FtaTranslatedTextsObject.otherOptions}
        </Typography>

        <Typography variant='caption' className={classes.formCaptionSmall}>
          {FtaTranslatedTextsObject.fieldsAreNonMandatory}
        </Typography>
        <p></p>



        <section className='section-grid child-of-grid one-col'>
          <header className='section-grid__col one-col'>
            <Typography variant='h4' className={classes.sectionTitle}>
              {FtaTranslatedTextsObject.dutySavingsTitle}
            </Typography>
          </header>

          <article className='section-grid__col has-more-childs one-col'>
            <div className='one-col'>
              <span>
                <HsCodeSearch />
              </span>
            </div>
          </article>

        </section>
      </Card>


      {/* Footer */}
      <footer className={`cta-footerbar two-col ${classes.stickyFooter}`}>
        <span>
          <ClearFieldsButton onClick={() => resetForm()} />
        </span>

        <span className='cta-btn-wrapper flex-end'>
          <CalculateButton onClick={() => calculateFTA()} type='submit' />
        </span>
      </footer>
    </>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const FTACalculatorForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FTACalculatorForm
