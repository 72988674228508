import {
  TradeTrusteBLPage
} from 'src/views/tradetrust'
import pathMap from 'src/paths/PathMap_TradeTrust'
import EndorsementChainContainer from 'src/views/tradetrust/components/EndorsementChainContainer'
import TradeTrusteBLViewPageContainer from 'src/views/tradetrust/TradeTrusteBLViewPageContainer'

class TradeTrustRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TRADETRUST_UPLOAD,
        component: TradeTrusteBLPage
      },
      {
        exact: true,
        path: pathMap.TRADETRUST_VIEW,
        component: TradeTrusteBLViewPageContainer
      },
      {
        exact: true,
        path: pathMap.TRADETRUST_ENDORSEMENT,
        component: EndorsementChainContainer
      }
    ]
  }
}

export default TradeTrustRoute
