import {
    LoaderSpinner,
    MessageTitle,
    OverlayContext,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from '@material-ui/core';
import { FormState } from "../../constants/FormState";
import AssetInformationPanel from "../AssetInformationPanel/index";
import { AssetManagementTitle } from "../AssetManagementTitle";
import { EditableAssetTitle } from "./EditableAssetTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';
import CancelButton from 'src/components/button/CancelButton.js'
import FormBackButton from "../FormBackButton";
import Loading from '../Loading';

export const SurrenderForm = ({
    formAction,
    tokenRegistryAddress,
    beneficiary,
    holder,
    handleSurrender,
    surrenderingState,
    setFormActionNone,
    setShowEndorsementChain,
    setConfirmDialogOpen,
    setModalData
}) => {
    const isPendingConfirmation = surrenderingState === FormState.PENDING_CONFIRMATION;
    const isConfirmed = surrenderingState === FormState.CONFIRMED;
    const { showOverlay } = useContext(OverlayContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isConfirmed) {
            setModalData({
                content: MessageTitle.SURRENDER_DOCUMENT_SUCCESS,
                title: 'Success',
                okMsg: 'Close',
                type: 'Info'
            })
            setLoading(false);
            setConfirmDialogOpen(true);
            setFormActionNone();
        }
    }, [isConfirmed, showOverlay, setFormActionNone]);

    useEffect(() => {
        if (surrenderingState === FormState.ERROR) {
            setLoading(false);
        }
    }, [surrenderingState])

    return (
        <>
            <Loading loading={loading}>
                <FormBackButton
                    setFormActionNone={setFormActionNone}
                />
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isPendingConfirmation}
                />
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle
                            role="Owner"
                            value={beneficiary}
                            isEditable={false}
                            isError={surrenderingState === FormState.ERROR}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle
                            role="Holder"
                            value={holder}
                            isEditable={false}
                            isError={surrenderingState === FormState.ERROR}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <Box ml={3}>
                            <CancelButton onClick={setFormActionNone} disabled={isPendingConfirmation} />
                        </Box>
                    </Grid>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <SubmitButton
                            onClick={() => {
                                handleSurrender();
                                setLoading(true);
                            }}
                            disabled={isPendingConfirmation}
                        >
                            {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Surrender Document</>}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Loading>
        </>
    );
};
