import { utils } from "@govtechsg/open-attestation";

export const getTokenRegistryAddress = (document) => {
  const issuerAddress = utils.getIssuerAddress(document);
  return issuerAddress instanceof Array ? issuerAddress[0] : issuerAddress;
};

export const getAttachments = (rawDocument) => {
  if (utils.isWrappedV2Document(rawDocument)) {
    const documentData = utils.getData(rawDocument);
    return documentData.attachments;
  } else {
    return rawDocument.attachments?.map((attachment) => {
      return {
        data: attachment.data,
        filename: attachment.fileName,
        type: attachment.mimeType,
      };
    });
  }
};