import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import FooterAddComponent from './FooterAddComponent'
import SCOJobTranslationText from 'src/views/scojob/job/SCOJobTranslationText'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import JobTaskDetailsComponent from './JobTaskDetailsComponent'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import SCOJobAttributeApiUrls from 'src/apiUrls/SCOJobAttributeApiUrls'
import DialogConfirmation from './DialogConfirmation'
import moment from 'moment'
import _ from 'lodash'


const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        },

    },
    CngTabs
} = components

const {
    filter: { EQUAL }
} = constants


const initialValues = {
    jobTaskItems: [
        {
            id: -1,
            taskSequence: '',
            taskType: '',
            taskDesc: '',
            estimatedDuration: '',
            dueDate: '',
            taskStatus: '',
            movementType: '',
            timezoneId: '',
            localEventTimestamp: '',
            userAssigned: '',
            remarks: '',
            addresseeName: '',
            addresseeAddress: '',
            addresseePostalCode: '',
            addresseeEmailAddress: '',
            addresseeContactNumber: '',
            isDeleted: 0,
            jobTaskContact: {
                jobTaskContactEmailList: []
            },
            workFlowItemExpanded: false,
            workFlowAccordionId: uuid(),
            jobTaskAttributes: [],
            rateOwnerId: '',
            rateCardId:'',
            customer : '',
            isRateCardDisabled : false,
        }
    ],
    deletedEmailList: [],
}

const FormBody = (props) => {
    const { errors, touched, setFieldValue } = useFormikContext()
    const { fetchRecords } = useServices()
    const disabled = props.disabled
    const jobId = props.jobId;
    const jobStatus = props.jobStatus;
    const timezoneList_Parent = props.timezoneList
    const loading = props.loading
    const userAssignedList_Parent = props.userAssignedList
    const showNotification = props.showNotification

    const toCloneJobTaskList = props.toCloneJobTaskList ? props.toCloneJobTaskList : undefined

    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')
            if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]]) {
                return errors[pathArr[0]][itemIndex][pathArr[1]]
            } else {
                return null
            }
        }
        return null
    }

    const getFieldErrorForEmail = (itemIndex, fieldPath, innerIndex) => {
        if (errors) {
            let pathArr = fieldPath.split('.')
            if (errors[pathArr[0]] && errors[pathArr[0]][itemIndex]
                && errors[pathArr[0]][itemIndex][pathArr[1]]
                && errors[pathArr[0]][itemIndex][pathArr[1]][pathArr[2]]
                && errors[pathArr[0]][itemIndex][pathArr[1]][pathArr[2]][innerIndex]) {
                return errors[pathArr[0]][itemIndex][pathArr[1]][pathArr[2]][innerIndex]
            } else {
                return null
            }
        }
        return null
    }

    const translatedTextsObject = SCOJobTranslationText()

    const emailItem = {
        id: uuid(),  // for react reference.
        emailId: '',
        emailAddress: ''
    }

    const jobTaskItem = {
        id: -1,
        taskSequence: '',
        taskType: '',
        taskDesc: '',
        estimatedDuration: '',
        taskStatus: '',
        movementType: '',
        timezoneId: '',
        localEventTimestamp: '',
        userAssigned: '',
        remarks: '',
        addresseeName: '',
        addresseeAddress: '',
        addresseePostalCode: '',
        addresseeEmailAddress: '',
        addresseeContactNumber: '',
        isDeleted: '',
        jobTaskContact: {
            jobTaskContactEmailList: [emailItem]
        },
        workFlowItemExpanded: false,
        workFlowAccordionId: uuid(), //for react reference
        jobTaskAttributes: [],
        rateOwnerId: '',
        rateCardId:'',
        customer : '',
        isRateCardDisabled: false
    }

    const [jobTaskList, setJobTaskList] = useState([])
    const [deletedTaskList, setDeletedTaskList] = useState([])
    const [taskSequenceOptions, setTaskSequenceOptions] = useState([])
    const [deletedEmailIds, setDeletedEmailIds] = useState([])
    const [shouldRender, setShouldRender] = useState(true)
    const { remove } = useFieldArray({ name: "jobTaskItems" })

    const { fields: jobTaskAttributes } = useFieldArray({ name: 'jobTaskAttributes', keyName: 'key' });
    const [isOpen, setIsOpen] = useState(false);
    const [attrConfigOptions, setAttrConfigOptions] = useState([]);
    const [filteredAttrConfigOptions, setFilteredAttrConfigOptions] = useState([]);
    const [jobTaskIndex, setJobTaskIndex] = useState()

    const [dialogOpen, setDialogOpen] = useState(false)
    const [taskDeleteOrRestore, setTaskDeleteOrRestore] = useState(false)
    const [taskIndex, setTaskIndex] = useState()

    const [movementType, setMovementType] = useState()
    const [selectedMoveType, setSelectedMoveType] = useState()
    const { trigger } = useFormContext()
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...jobTaskList]
        list[index][name.split('.')[1]] = value
        setJobTaskList(list)
        setFieldValue('jobTaskItems', list)
    };

    // handle date change
    const handleDateChange = (evt, index, name) => {
        const list = [...jobTaskList]
        if(evt) {
            let date = moment(evt).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            console.log('this is date ' + date)
            list[index][name.split('.'[1])] = date
            setJobTaskList(list)
            setFieldValue(`jobTaskItems[${index}].${name}`, date)
        } 
    };

    const handleDropdownChange = (val, index, name) => {
        if (val) {
            if (name === 'taskStatus') {
                if (val.target.value !== 'PROGRESS') {
                    setSelectedMoveType(selectedMoveType)
                    setMovementType(movementType)
                }
            }
            const value = val
            if (!(name === 'userAssigned' || name === 'timezoneId'))
                value = val.target.value
            const list = [...jobTaskList]
            list[index][name] = value
            setFieldValue('jobTaskItems', list)
            setJobTaskList(list)
        }
    };

    const handleTaskTypeDropdownChange = (val, evt, index, name) => {
        if (evt) {
            const { value } = evt
            const list = [...jobTaskList]
            list[index][name] = value
            setJobTaskList(list)
            setFieldValue('jobTaskItems', list)
        }
    }

    const handleRateOwnerAutoCompleteChange = (val, index) => {
        //Changes on Rate Owner
        if(val!=undefined && val!=null){
            const { value } = val
            const list = [...jobTaskList]
            list[index].rateOwnerId = value
         
            setJobTaskList(list)
            setFieldValue('jobTaskItems', list, true)
        }else{
            const list = [...jobTaskList]
            list[index].rateOwnerId = ''
            
            setJobTaskList(list)
            setFieldValue('jobTaskItems', list, true)
        }
    }

    const handleRateCardChange = (index,listChanges) => {
        //Changes on Rate Card & Customer 
        if(listChanges!=undefined && listChanges!=null){
            const optionsRO = listChanges.map((rc) => {
                return { text: rc.customerDisplayName, value: rc.customerAccountNo}
             })
           
            const list = [...jobTaskList]
            list[index].rateCardId = listChanges[0].id
            list[index].customer = optionsRO[0].value
         
            setJobTaskList(list)
            setFieldValue('jobTaskItems', list, true)
        }else{
            const list = [...jobTaskList]
            list[index].rateCardId = ''
            list[index].customer = ''
            
            setJobTaskList(list)
            setFieldValue('jobTaskItems', list, true)
        }
    }

    // handle click event of the Remove button
    const handleRemoveContainer = index => {
        const list = [...jobTaskList]
        list.splice(index, 1)
        setJobTaskList(list)
        setFieldValue('jobTaskItems', list, true)
    };

    // handle click event of the Add button
    const handleAddContainer = index => {
        let totalTask = getTotalNoOfTask() + 1;
        const addJobTaskItem = { ...jobTaskItem }
        addJobTaskItem.id = null
        addJobTaskItem.taskSequence = totalTask
        addJobTaskItem.taskType = ''
        addJobTaskItem.taskDesc = ''
        addJobTaskItem.taskStatus = 'NOTSTARTED'
        addJobTaskItem.movementType = null
        addJobTaskItem.timezoneId = ''
        addJobTaskItem.localEventTimestamp = ''
        addJobTaskItem.userAssigned = ''
        addJobTaskItem.dueDate = ''
        addJobTaskItem.remarks = ''
        addJobTaskItem.recipientName = ''
        addJobTaskItem.recipientAddress = ''
        addJobTaskItem.recipientPostalCode = ''
        addJobTaskItem.recipientEmailAddress = ''
        addJobTaskItem.recipientContactNumber = ''
        addJobTaskItem.estimatedDuration = ''
        addJobTaskItem.isDeleted = false
        addJobTaskItem.jobTaskContact.jobTaskContactEmailList = []
        addJobTaskItem.workFlowItemExpanded = false
        addJobTaskItem.workFlowAccordionId = uuid()
        addJobTaskItem.jobTaskAttributes = []
        addJobTaskItem.customer = ''
        addJobTaskItem.rateOwnerId = ''
        addJobTaskItem.rateCardId = ''
        addJobTaskItem.isRateCardDisabled = false
        setJobTaskList([...jobTaskList, addJobTaskItem])
        setFieldValue('jobTaskItems', [...jobTaskList, addJobTaskItem], true)
    };

    const addEmail = (index, jobTaskItem) => {
        const workflowEmailList = jobTaskItem.jobTaskContact.jobTaskContactEmailList
        const addNewEmailItem = emailItem
        workflowEmailList.push(addNewEmailItem)
        const list = [...jobTaskList]
        list[index].emailItems = workflowEmailList
        setJobTaskList(list)
    };

    const handleRemoveEmail = (index, indexEmail) => {
        const list = [...jobTaskList]
        if (typeof list[index].jobTaskContact.jobTaskContactEmailList[indexEmail].id === 'number') {
            const deletedEmailList = [...deletedEmailIds]
            deletedEmailList.push(list[index].jobTaskContact.jobTaskContactEmailList[indexEmail].id)
            setDeletedEmailIds(deletedEmailList)
            setFieldValue("deletedEmailList", deletedEmailList, true)
        }
        list[index].jobTaskContact.jobTaskContactEmailList.splice(indexEmail, 1)
        setJobTaskList(list)
        remove(list[index].jobTaskContact.jobTaskContactEmailList[indexEmail])
        setFieldValue("jobTaskItems", list, true)
    }

    const handleEmailInputChange = (e, workFlowEmailList, indexEmail, index) => { //i - workflow, index - emailItems
        const { name, value } = e.target
        const workFlowListEmailItems = [...workFlowEmailList]
        workFlowListEmailItems[indexEmail]['emailAddress'] = value
        const list = [...jobTaskList]
        list[index].emailItems = workFlowListEmailItems
        setJobTaskList(list)
        setFieldValue("jobTaskItems", list)
    }

    const handleWorkFlowListDetailsExpand = (idx) => () => {
        const isWorkFlowBoxExpanded = jobTaskList[idx].workFlowItemExpanded
        const temp = [...jobTaskList]
        temp[idx].workFlowItemExpanded = !isWorkFlowBoxExpanded
        setJobTaskList([].concat([], temp))
    }


    const handleRemoveTaskAttribute = (index, attrIndex) => {
        const list = [...jobTaskList]
        list[index].jobTaskAttributes.splice(attrIndex, 1);
        remove(list[index].jobTaskAttributes[attrIndex])
        setJobTaskList(list)
        setFieldValue("jobTaskItems", list, true)
    };

    const openAttributeDialog = (jobTaskAttributes, index) => {
        setNewFilteredOptions(jobTaskAttributes)
        setJobTaskIndex(index)
        setIsOpen(true);
    }

    const setNewFilteredOptions = (newList) => {
        let filteredAttr = attrConfigOptions.filter(attrConfig => !newList.some(existingAttr => existingAttr.configDTO.id == attrConfig.id)).map((attrConfig) => {
            return { text: attrConfig.attributeName, value: attrConfig.id }
        });
        setFilteredAttrConfigOptions(filteredAttr);
    }


    const getJobAttributesDDL = () => {
        const onSuccess = (response) => {
            const options = response.content.map((attr) => {
                return attr;
            })

            setAttrConfigOptions(options)
            let filteredAttr = options.map((opt) => {
                return { text: opt.attributeName, value: opt.id }
            })
            setFilteredAttrConfigOptions(filteredAttr);
        }

        fetchRecords.execute(
            SCOJobAttributeApiUrls.GET_JOB_ATTRIBUTE_OPTION,
            {
                filters: [
                    {
                        field: 'attributeType',
                        operator: EQUAL,
                        value: 'TASK'
                    }
                ]
            },
            onSuccess
        )
    }

    function loadWorkflow() {
        setJobTaskList([])
        var parseId = parseInt(props.jobTypeId)
        setJobTaskList([])
        if(props.isClone && toCloneJobTaskList != null && props.newJobId !== "") {
            let temp3 = _.cloneDeep(toCloneJobTaskList)
            temp3.forEach(function (x) {
                x.workFlowItemExpanded = false
                x.workFlowAccordionId = uuid()
            })
            console.log(temp3)
            let taskArray = []
            for (let i = 1; i <= temp3.length; i++) {
                taskArray.push(i)
            }
            setTaskSequenceOptions(taskArray.map(x => ({
                value: x,
                text: x
            })))
            setFieldValue("jobTaskItems", temp3)
            setJobTaskList(temp3)
        } else {
            fetchRecords.execute(
                SCOJobApiUrls.GETJOBCONFIG,
                { customData: { jobId: parseId } },
                (data) => {
                    let temp3 = [...data]
                    temp3.forEach(function (x) {
                        x.workFlowItemExpanded = false
                        x.workFlowAccordionId = uuid()
                        //load taskAttr at job creation page
                        let taskAttributes = [...x.jobTaskAttributes]
                        taskAttributes.forEach(function (y) {
                            y.idKey = uuid()
                        })
                        x.jobTaskAttributes = taskAttributes
                    })
                    setFieldValue("jobTaskItems", temp3)
                    setJobTaskList(temp3)
                }
            )
        }
    }

    function loadJobTask() {
        if (props.jobDataTaskLists != null) {
            let temp3 = [...props.jobDataTaskLists]
            temp3.forEach(function (x) {
                x.workFlowItemExpanded = false
                x.workFlowAccordionId = uuid()
                let date = x.localEventTimestamp
                if(date != null) {
                    x.localEventTimestamp = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                }else if (date === null) {
                    x.localEventTimestamp = ''
                }
                let taskAttributes = [...x.jobTaskAttributes]
                taskAttributes.forEach(function (y) {
                    y.idKey = uuid()
                })
                x.jobTaskAttributes = taskAttributes
                if (x.taskType === 'INV') {
                    setMovementType(x.movementType.name)
                    setSelectedMoveType(x.movementType.id)
                }
                x.invFormList = [];
                
            })
            console.log(temp3);
            

            setFieldValue("jobTaskItems", temp3)
            setJobTaskList(temp3)
        }
    }

    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    function getTotalNoOfTask() {
        return jobTaskList.length
    }

    function loadSequences() {
        const list = [...jobTaskList]
        var taskArray = []
        for (let i = 1; i <= list.length; i++) {
            taskArray.push(i)
        }
        setTaskSequenceOptions(taskArray.map(x => ({
            value: x,
            text: x
        })))
    }


    const handleTaskSequenceChange = (val, evt, index) => {
        let value = val.target.value
        let newList = [...jobTaskList]
        if (index >= value) {  //move up
            newList[index]['taskSequence'] = value
            let valIncrement = parseInt(value) + 1
            for (let i = (value - 1); i < index; i++) {
                newList[i]['taskSequence'] = valIncrement
                valIncrement++
            }

        } else if (index < value) { // move down
            newList[index]['taskSequence'] = value
            for (let i = (index + 1); i < value; i++) {
                newList[i]['taskSequence'] = i
            }
        }
        sortByKey(newList, 'taskSequence')
        setJobTaskList(newList)
        setFieldValue("jobTaskItems", newList, true)
        trigger()
    };


    const onDeleteTask = (index) => {
        setTaskDeleteOrRestore(true)
        setTaskIndex(index)
        setDialogOpen(true)
    }

    const onRestoreTask = (index) => {
        setTaskDeleteOrRestore(false)
        setTaskIndex(index)
        setDialogOpen(true)
    }

    const procDeleteTask = (index) => {
        const list = [...jobTaskList]
        list[index].isDeleted = true
        list[index].workFlowItemExpanded = false
        list[index].taskSequence = list.length

        for (let i = index + 1; i < list.length; i++) {
            let taskSeq = list[i].taskSequence - 1
            list[i]['taskSequence'] = taskSeq
        }
        sortByKey(list, 'taskSequence')
        setJobTaskList(list)
        setFieldValue('jobTaskItems', list, true)
    }

    const procRestoreTask = (index) => {
        const list = [...jobTaskList]
        list[index].isDeleted = false
        list[index].workFlowItemExpanded = false
        const findNotDeletedList = list.filter(list => list.isDeleted === false)
        list[index].taskSequence = findNotDeletedList.length
        for (let i = 0; i < list.length; i++) {
            if (list[i].isDeleted === true) {
                list[i].taskSequence = findNotDeletedList.length + 1
            }
        }
        sortByKey(list, 'taskSequence')
        setJobTaskList(list)
        setFieldValue('jobTaskItems', list, true)
    }






    useEffect(() => {
        getJobAttributesDDL()
        if (jobId != '' && props.jobDataTaskList != '' && props.jobTypeId === '') {
            loadJobTask()
        } else if (props.jobTypeId != '') {
            loadWorkflow()
        } else if (props.jobTypeId === '' && jobId === '' && props.jobDataTaskLists == '') {
            const cont = { ...jobTaskItem }
            setJobTaskList([cont])
            setFieldValue("jobTaskItems", [cont])
        } else if (toCloneJobTaskList !== '' ) {
            const cont = { ...toCloneJobTaskList }
            setJobTaskList([cont])
            setFieldValue("jobTaskItems", [cont])
        }

    }, [props.jobTypeId, jobId, props.jobDataTaskLists])




    useEffect(() => {
        if (jobTaskList != null && !props.isClone) {
            loadSequences()
            setFieldValue("jobTaskItems", jobTaskList)
            setJobTaskList(jobTaskList)
        }
    }, [jobTaskList])


    if (!shouldRender || loading) {
        return null;
    }

    return (
        <Card>
            <Box px={2} my={1.5}>
                <Grid container >
                    <AccordionHeaderComponent title={translatedTextsObject.scoJobTaskListTitle} />
                </Grid>
                <CngTabs
                    headerColor='primary'
                    tabs={[
                        {
                            tabName: translatedTextsObject.scoJobActiveTasks,
                            tabContent: (
                                <>
                                    <JobTaskDetailsComponent
                                        isActiveTask={true}
                                        taskList={jobTaskList}
                                        jobId={jobId}
                                        jobStatus={jobStatus}
                                        handleEmailInputChange={handleEmailInputChange}
                                        addEmail={addEmail}
                                        handleDropdownChange={handleDropdownChange}
                                        handleTaskTypeDropdownChange={handleTaskTypeDropdownChange}
                                        handleInputChange={handleInputChange}
                                        getFieldErrorForEmail={getFieldErrorForEmail}
                                        handleWorkFlowListDetailsExpand={handleWorkFlowListDetailsExpand}
                                        timezoneList={timezoneList_Parent}
                                        handleDateChange={handleDateChange}
                                        handleRemoveEmail={handleRemoveEmail}
                                        disabled={disabled}
                                        userAssignedList={userAssignedList_Parent}
                                        showNotification={showNotification}
                                        removeTaskAttribute={handleRemoveTaskAttribute}
                                        openAttributeDialog={openAttributeDialog}
                                        isClone={props.isClone}
                                        clonedOutboundForm={props.clonedOutboundForm}
                                        isOpen={isOpen}
                                        setFieldValue={setFieldValue}
                                        setIsOpen={setIsOpen}
                                        attrOptions={attrConfigOptions}
                                        filteredAttrOptions={filteredAttrConfigOptions}
                                        setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                                        setNewFilteredOptions={setNewFilteredOptions}
                                        jobTaskIndex={jobTaskIndex}
                                        taskSequenceOptions={taskSequenceOptions}
                                        handleTaskSequenceChange={handleTaskSequenceChange}
                                        onDeleteTask={onDeleteTask}
                                        onRestoreTask={onRestoreTask}
                                        selectedMoveType={selectedMoveType}
                                        setSelectedMoveType={setSelectedMoveType}
                                        movementType={movementType}
                                        setMovementType={setMovementType}
                                        handleRateCardChange={handleRateCardChange}
                                        handleRateOwnerAutoCompleteChange={handleRateOwnerAutoCompleteChange}
                                       
                                    />
                                    <>
                                        <br />
                                    </>
                                    <FooterAddComponent
                                        handleAddChild={handleAddContainer}
                                        footerText={translatedTextsObject.scoAddJobTask}
                                    />
                                </>
                            )
                        },
                        {
                            tabName: translatedTextsObject.scoJobDeletedTasks,
                            tabContent: (
                                <JobTaskDetailsComponent
                                    isActiveTask={false}
                                    taskList={jobTaskList}
                                    jobId={jobId}
                                    jobStatus={jobStatus}
                                    handleEmailInputChange={handleEmailInputChange}
                                    addEmail={addEmail}
                                    handleDropdownChange={handleDropdownChange}
                                    handleTaskTypeDropdownChange={handleTaskTypeDropdownChange}
                                    handleInputChange={handleInputChange}
                                    getFieldErrorForEmail={getFieldErrorForEmail}
                                    handleWorkFlowListDetailsExpand={handleWorkFlowListDetailsExpand}
                                    timezoneList={timezoneList_Parent}
                                    handleDateChange={handleDateChange}
                                    handleRemoveEmail={handleRemoveEmail}
                                    disabled={true}
                                    userAssignedList={userAssignedList_Parent}
                                    showNotification={showNotification}
                                    removeTaskAttribute={handleRemoveTaskAttribute}
                                    openAttributeDialog={openAttributeDialog}
                                    isClone={props.isClone}
                                    clonedOutboundForm={props.clonedOutboundForm}
                                    isOpen={isOpen}
                                    setFieldValue={setFieldValue}
                                    setIsOpen={setIsOpen}
                                    attrOptions={attrConfigOptions}
                                    filteredAttrOptions={filteredAttrConfigOptions}
                                    setFilteredAttrConfigOptions={setFilteredAttrConfigOptions}
                                    setNewFilteredOptions={setNewFilteredOptions}
                                    jobTaskIndex={jobTaskIndex}
                                    taskSequenceOptions={taskSequenceOptions}
                                    handleTaskSequenceChange={handleTaskSequenceChange}
                                    onDeleteTask={onDeleteTask}
                                    onRestoreTask={onRestoreTask}
                                    selectedMoveType={selectedMoveType}
                                    setSelectedMoveType={setSelectedMoveType}
                                    movementType={movementType}
                                    setMovementType={setMovementType}
                                    handleRateCardChange={handleRateCardChange}
                                />
                            )
                        }
                    ]}
                />

            </Box>
            <DialogConfirmation
                dialogOpen={dialogOpen}
                taskDeleteOrRestore={taskDeleteOrRestore}
                setDialogOpen={setDialogOpen}
                procDeleteTask={procDeleteTask}
                procRestoreTask={procRestoreTask}
                taskIndex={taskIndex}
            />


        </Card >
    )

}

const JobTaskComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues

})

export default JobTaskComponent