import {
    MessageTitle,
    OverlayContext,
    LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { Grid, Box, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormState } from "../../constants/FormState";
import { isEthereumAddress } from "../../utils";
import AssetInformationPanel from "../../components/AssetInformationPanel/index";
import { AssetManagementTitle } from "../../components/AssetManagementTitle";
import { EditableAssetTitle } from "../../components/FormVariants/EditableAssetTitle";
import SubmitButton from 'src/components/button/SubmitButton.js';
import CancelButton from 'src/components/button/CancelButton.js'
import FormBackButton from "../FormBackButton";
import Loading from '../Loading';

export const EndorseBeneficiaryForm = ({
    formAction,
    tokenRegistryAddress,
    beneficiary,
    holder,
    nominee,
    handleBeneficiaryTransfer,
    beneficiaryEndorseState,
    setFormActionNone,
    setShowEndorsementChain,
    setConfirmDialogOpen,
    setModalData
}) => {
    const isPendingConfirmation = beneficiaryEndorseState === FormState.PENDING_CONFIRMATION;
    const isConfirmed = beneficiaryEndorseState === FormState.CONFIRMED;
    const { showOverlay } = useContext(OverlayContext);
    const [loading, setLoading] = useState(false);

    const useStyles = makeStyles({
        myAlert: {
            backgroundColor: '#ECECF2'
        }
    });
    const classes = useStyles();

    useEffect(() => {
        if (isConfirmed) {
            const arrMsg = [
                { id: 1, value: MessageTitle.CHANGE_BENEFICIARY_SUCCESS },
                { id: 2, value: <br /> },
                { id: 3, value: <br /> },
                { id: 4, value: 'Current Owner:' },
                { id: 5, value: <br /> },
                { id: 6, value: nominee }
            ];
            setModalData({
                content: arrMsg.map(msg => (msg.value)),
                title: 'Success',
                okMsg: 'Close',
                type: 'Info'
            })
            setLoading(false);
            setConfirmDialogOpen(true);
            setFormActionNone();
        }
    }, [isConfirmed, nominee, showOverlay, setFormActionNone]);

    const isValidEndorse = () => {
        if (!nominee) return false;
        if (nominee === beneficiary) return false;
        if (!isEthereumAddress(nominee)) return false;

        return true;
    };

    useEffect(() => {
        if (beneficiaryEndorseState === FormState.ERROR) {
            setLoading(false);
        }
    }, [beneficiaryEndorseState])

    return (
        <>
            <Loading loading={loading}>
                <FormBackButton
                    setFormActionNone={setFormActionNone}
                />
                <AssetManagementTitle
                    setFormActionNone={setFormActionNone}
                    formAction={formAction}
                    disabled={isPendingConfirmation}
                />

                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <AssetInformationPanel
                            setShowEndorsementChain={setShowEndorsementChain}
                            tokenRegistryAddress={tokenRegistryAddress}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle role="Nominee" value={nominee} isEditable={false} />
                    </Grid>
                    <Grid item xs={4} style={{ overflowWrap: 'anywhere' }}>
                        <EditableAssetTitle role="Holder" value={holder} isEditable={false} />
                        {beneficiaryEndorseState === FormState.ERROR && (
                            <Box>
                                <Alert
                                    severity='error'
                                    icon={<FontAwesomeIcon icon={['fal', 'exclamation-circle']} color='#8996AF' />}
                                    className={classes.myAlert}
                                >
                                    The transaction was aborted.
                                </Alert>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <Box ml={3}>
                            <CancelButton onClick={setFormActionNone} disabled={isPendingConfirmation} />
                        </Box>
                    </Grid>
                    <Grid item style={{ overflowWrap: 'anywhere' }}>
                        <SubmitButton
                            onClick={() => {
                                handleBeneficiaryTransfer(nominee || "");
                                setLoading(true);
                            }}
                            disabled={!isValidEndorse() || isPendingConfirmation}
                        >
                            {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Endorse</>}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Loading>
        </>
    );
};