import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'
import { Grid } from '@material-ui/core'
import {
    components,
    DataFlattener,
    DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './BatchBookingRequestMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import moment from 'moment'

const {
    form: {
        field: {
            CngTextField,
            CngDateTimeField,
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    party: "",
    senderCode: "",
    senderQualifier: "",
    receiverQualifier: "",
    messageReferenceNo: "",
    messageType: "",
    carrierName: "",
    messageFunction: "",
    documentMsgNo: "",
    documentIssuedOnDate: "",
    documentIssuedOnTime: "",
    acknowledgementIndicator: "",
    bookingReferenceNo: "",
    orderNumber: "",
    shipperRemark: "",
    bookingShipmentType: "",
    stageCode: "",
    consignorReferenceNo: "",
    contractNumber: "",
    voyageNo: "",
    vesselName: "",
    portOfLoadingPortCode: "",
    portOfLoeadingPortCodeList: "",
    portOfLoadingDateCode: "",
    portOfLoadingDate: "",
    portOfLoadingTime: "",
    portOfDischargePortCode: "",
    portOfDischargePortCodeList: "",
    placeOfReceiptPortName: "",
    placeOfReceiptDateCode: "",
    placeOfReceiptDate: "",
    placeOfDeliveryPortName: "",
    placeOfDeliveryDateCode: "",
    placeOfDeliveyDate: "",
    consignorName: "",
    consigneeName: "",
    consigneeAddress: "",
    containerTypesAndSizes: "",
    socOrCocIndicator: "",
    fullOrEmptyIndicator: "",
    totalQuantity: "",
    bookingId: "",
    fileType: "",
    source: "",
    recordStatus: "",
    contactPersonName: "",
    emailAddress: "",
    processedDate: "",
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
    const { translate } = useTranslation(Namespace.BATCH_BOOKING_REQUEST)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let batchBookingRequest = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.TITLE
        )
        let id = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.ID
        )
        let party = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PARTY
        )
        let senderCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.SENDER_CODE
        )
        let senderQualifier = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.SENDER_QUALIFIER
        )
        let receiverQualifier = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.RECEIVER_QUALIFIER
        )
        let messageReferenceNo = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.MESSAGE_REFERENCE_NO
        )
        let messageType = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.MESSAGE_TYPE
        )
        let carrierName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CARRIER_NAME
        )
        let messageFunction = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.MESSAGE_FUNCTION
        )
        let documentMsgNo = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.DOCUMENT_MSG_NO
        )
        let documentIssuedOnDate = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.DOCUMENT_ISSUED_ON_DATE
        )
        let documentIssuedOnTime = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.DOCUMENT_ISSUED_ON_TIME
        )
        let acknowledgementIndicator = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.ACKNOWLEDGEMENT_INDICATOR
        )
        let bookingReferenceNo = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.BOOKING_REFERENCE_NO
        )
        let orderNumber = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.ORDER_NUMBER
        )
        let shipperRemark = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.SHIPPER_REMARK
        )
        let bookingShipmentType = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.BOOKING_SHIPMENT_TYPE
        )
        let stageCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.STAGE_CODE
        )
        let consignorReferenceNo = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONSIGNOR_REFERENCE_NO
        )
        let contractNumber = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONTRACT_NUMBER
        )
        let voyageNo = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.VOYAGE_NO
        )
        let vesselName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.VESSEL_NAME
        )
        let portOfLoadingPortCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_LOADING_PORT_CODE
        )
        let portOfLoeadingPortCodeList = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_LOEADING_PORT_CODE_LIST
        )
        let portOfLoadingDateCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_LOADING_DATE_CODE
        )
        let portOfLoadingDate = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_LOADING_DATE
        )
        let portOfLoadingTime = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_LOADING_TIME
        )
        let portOfDischargePortCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_DISCHARGE_PORT_CODE
        )
        let portOfDischargePortCodeList = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PORT_OF_DISCHARGE_PORT_CODE_LIST
        )
        let placeOfReceiptPortName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_RECEIPT_PORT_NAME
        )
        let placeOfReceiptDateCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_RECEIPT_DATE_CODE
        )
        let placeOfReceiptDate = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_RECEIPT_DATE
        )
        let placeOfDeliveryPortName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_DELIVERY_PORT_NAME
        )
        let placeOfDeliveryDateCode = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_DELIVERY_DATE_CODE
        )
        let placeOfDeliveyDate = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PLACE_OF_DELIVEY_DATE
        )
        let consignorName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONSIGNOR_NAME
        )
        let consigneeName = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONSIGNEE_NAME
        )
        let consigneeAddress = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONSIGNEE_ADDRESS
        )
        let containerTypesAndSizes = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.CONTAINER_TYPES_AND_SIZES
        )
        let socOrCocIndicator = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.SOC_OR_COC_INDICATOR
        )
        let fullOrEmptyIndicator = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.FULL_OR_EMPTY_INDICATOR
        )
        let totalQuantity = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.TOTAL_QUANTITY
        )
        let bookingId = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.BOOKING_ID
        )
        let fileType = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.FILE_TYPE
        )
        let source = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.SOURCE
        )
        let recordStatus = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.RECORD_STATUS
        )
        let contactPersonName = translate(
                    Namespace.BATCH_BOOKING_REQUEST,
                    BatchBookingRequestKeys.CONTACT_PERSON_NAME
         )
        let emailAddress = translate(
                             Namespace.BATCH_BOOKING_REQUEST,
                             BatchBookingRequestKeys.EMAIL_ADDRESS
        )
        let processedDate = translate(
            Namespace.BATCH_BOOKING_REQUEST,
            BatchBookingRequestKeys.PROCESSED_DATE
        )

        return {
            batchBookingRequest,
            id,
            party,
            senderCode,
            senderQualifier,
            receiverQualifier,
            messageReferenceNo,
            messageType,
            carrierName,
            messageFunction,
            documentMsgNo,
            documentIssuedOnDate,
            documentIssuedOnTime,
            acknowledgementIndicator,
            bookingReferenceNo,
            orderNumber,
            shipperRemark,
            bookingShipmentType,
            stageCode,
            consignorReferenceNo,
            contractNumber,
            voyageNo,
            vesselName,
            portOfLoadingPortCode,
            portOfLoeadingPortCodeList,
            portOfLoadingDateCode,
            portOfLoadingDate,
            portOfLoadingTime,
            portOfDischargePortCode,
            portOfDischargePortCodeList,
            placeOfReceiptPortName,
            placeOfReceiptDateCode,
            placeOfReceiptDate,
            placeOfDeliveryPortName,
            placeOfDeliveryDateCode,
            placeOfDeliveyDate,
            consignorName,
            consigneeName,
            consigneeAddress,
            containerTypesAndSizes,
            socOrCocIndicator,
            fullOrEmptyIndicator,
            totalQuantity,
            bookingId,
            fileType,
            source,
            recordStatus,
            contactPersonName,
            emailAddress,
            processedDate
        }
    }

    return (
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.party}>
                <CngTextField
                    name="party"
                    label={translatedTextsObject.party}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.senderCode}>
                <CngTextField
                    name="senderCode"
                    label={translatedTextsObject.senderCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.senderQualifier}>
                <CngTextField
                    name="senderQualifier"
                    label={translatedTextsObject.senderQualifier}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receiverQualifier}>
                <CngTextField
                    name="receiverQualifier"
                    label={translatedTextsObject.receiverQualifier}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageReferenceNo}>
                <CngTextField
                    name="messageReferenceNo"
                    label={translatedTextsObject.messageReferenceNo}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageType}>
                <CngTextField
                    name="messageType"
                    label={translatedTextsObject.messageType}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierName}>
                <CngTextField
                    name="carrierName"
                    label={translatedTextsObject.carrierName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageFunction}>
                <CngTextField
                    name="messageFunction"
                    label={translatedTextsObject.messageFunction}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.documentMsgNo}>
                <CngTextField
                    name="documentMsgNo"
                    label={translatedTextsObject.documentMsgNo}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.documentIssuedOnDate}>
                <CngTextField
                    name="documentIssuedOnDate"
                    label={translatedTextsObject.documentIssuedOnDate}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.documentIssuedOnTime}>
                <CngTextField
                    name="documentIssuedOnTime"
                    label={translatedTextsObject.documentIssuedOnTime}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.acknowledgementIndicator}>
                <CngTextField
                    name="acknowledgementIndicator"
                    label={translatedTextsObject.acknowledgementIndicator}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bookingReferenceNo}>
                <CngTextField
                    name="bookingReferenceNo"
                    label={translatedTextsObject.bookingReferenceNo}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orderNumber}>
                <CngTextField
                    name="orderNumber"
                    label={translatedTextsObject.orderNumber}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperRemark}>
                <CngTextField
                    name="shipperRemark"
                    label={translatedTextsObject.shipperRemark}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bookingShipmentType}>
                <CngTextField
                    name="bookingShipmentType"
                    label={translatedTextsObject.bookingShipmentType}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stageCode}>
                <CngTextField
                    name="stageCode"
                    label={translatedTextsObject.stageCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consignorReferenceNo}>
                <CngTextField
                    name="consignorReferenceNo"
                    label={translatedTextsObject.consignorReferenceNo}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contractNumber}>
                <CngTextField
                    name="contractNumber"
                    label={translatedTextsObject.contractNumber}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                <CngTextField
                    name="voyageNo"
                    label={translatedTextsObject.voyageNo}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
                <CngTextField
                    name="vesselName"
                    label={translatedTextsObject.vesselName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoadingPortCode}>
                <CngTextField
                    name="portOfLoadingPortCode"
                    label={translatedTextsObject.portOfLoadingPortCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoeadingPortCodeList}>
                <CngTextField
                    name="portOfLoeadingPortCodeList"
                    label={translatedTextsObject.portOfLoeadingPortCodeList}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoadingDateCode}>
                <CngTextField
                    name="portOfLoadingDateCode"
                    label={translatedTextsObject.portOfLoadingDateCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoadingDate}>
                <CngTextField
                    name="portOfLoadingDate"
                    label={translatedTextsObject.portOfLoadingDate}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoadingTime}>
                <CngTextField
                    name="portOfLoadingTime"
                    label={translatedTextsObject.portOfLoadingTime}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischargePortCode}>
                <CngTextField
                    name="portOfDischargePortCode"
                    label={translatedTextsObject.portOfDischargePortCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischargePortCodeList}>
                <CngTextField
                    name="portOfDischargePortCodeList"
                    label={translatedTextsObject.portOfDischargePortCodeList}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfReceiptPortName}>
                <CngTextField
                    name="placeOfReceiptPortName"
                    label={translatedTextsObject.placeOfReceiptPortName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfReceiptDateCode}>
                <CngTextField
                    name="placeOfReceiptDateCode"
                    label={translatedTextsObject.placeOfReceiptDateCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfReceiptDate}>
                <CngTextField
                    name="placeOfReceiptDate"
                    label={translatedTextsObject.placeOfReceiptDate}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfDeliveryPortName}>
                <CngTextField
                    name="placeOfDeliveryPortName"
                    label={translatedTextsObject.placeOfDeliveryPortName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfDeliveryDateCode}>
                <CngTextField
                    name="placeOfDeliveryDateCode"
                    label={translatedTextsObject.placeOfDeliveryDateCode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfDeliveyDate}>
                <CngTextField
                    name="placeOfDeliveyDate"
                    label={translatedTextsObject.placeOfDeliveyDate}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consignorName}>
                <CngTextField
                    name="consignorName"
                    label={translatedTextsObject.consignorName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consigneeName}>
                <CngTextField
                    name="consigneeName"
                    label={translatedTextsObject.consigneeName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consigneeAddress}>
                <CngTextField
                    name="consigneeAddress"
                    label={translatedTextsObject.consigneeAddress}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerTypesAndSizes}>
                <CngTextField
                    name="containerTypesAndSizes"
                    label={translatedTextsObject.containerTypesAndSizes}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.socOrCocIndicator}>
                <CngTextField
                    name="socOrCocIndicator"
                    label={translatedTextsObject.socOrCocIndicator}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fullOrEmptyIndicator}>
                <CngTextField
                    name="fullOrEmptyIndicator"
                    label={translatedTextsObject.fullOrEmptyIndicator}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalQuantity}>
                <CngTextField
                    name="totalQuantity"
                    label={translatedTextsObject.totalQuantity}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bookingId}>
                <CngTextField
                    name="bookingId"
                    type="number"
                    label={translatedTextsObject.bookingId}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileType}>
                <CngTextField
                    name="fileType"
                    label={translatedTextsObject.fileType + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
                <CngTextField
                    name="source"
                    label={translatedTextsObject.source + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.recordStatus}>
                <CngTextField
                    name="recordStatus"
                    label={translatedTextsObject.recordStatus + " *"}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contactPersonName}>
                   <CngTextField
                       name="contactPersonName"
                       label={translatedTextsObject.contactPersonName}
                       disabled={disabled}
                    />
            </CngGridItem>

             <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.emailAddress}>
                               <CngTextField
                                   name="emailAddress"
                                   label={translatedTextsObject.emailAddress}
                                   disabled={disabled}
                                />
              </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processedDate}>
                <CngDateTimeField
                    name="processedDate"
                    label={translatedTextsObject.processedDate + " *"}
                    format='YYYY-MM-DD HH:mm:ss'
                    toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                    disabled={disabled}
                />
            </CngGridItem>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    localData.processedDate = DateTimeFormatter.toClientDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return localData;
}

function toServerDataFormat(localData) {
    localData.processedDate = DateTimeFormatter.toServerDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
