import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import HelpdeskTranslationText from '../HelpDeskTranslationText'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Helpdesk'
import { useHistory } from 'react-router-dom'
import Utils from 'src/views/freightbooking/shared/Utils'

// import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
// import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'


const {
    button: { CngSecondaryButton }
} = components


function ViewBookingButtonComponent(props) {
    const { booking, actions, eventHandlers } = props
    //console.log('ManageBookingButtonComponent showButton' + JSON.stringify(showButton))
    const translatedTextsObject = HelpdeskTranslationText()
    const history = useHistory()
    console.log(props)
    const { showNotification } = props
    console.log(showNotification)


    const gotoViewBooking = () => {
        history.push({
            pathname: pathMap.FRB
        })
    }

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={3} sm={3} justify="flex-start">
                    <CngSecondaryButton onClick={eventHandlers.gotoViewBooking}>{translatedTextsObject.backToViewBooking}</CngSecondaryButton>
                </Grid>
                <Grid xs={3} sm={3} justify = "flex-start">
                    {
                        actions.map((action, idx) => {
                            switch (action) {
                                case Utils.Action.helpdeskAmendBookingStatus:
                                    const buttonText = booking.bookingStatus === 5102 ? "Revert status (PENDING APPROVAL)" : "Revert status (CONFIRMED)"
                                    return <CngSecondaryButton onClick={eventHandlers.helpdeskAmendBookingStatus}>{buttonText}</CngSecondaryButton>
                                default:
                                    return ''
                            }
                        })
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewBookingButtonComponent