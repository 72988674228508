import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core'
import { FileText, LogOut } from 'react-feather'
import React, { useState } from 'react'

import ExportButton from 'src/components/button/ExportButton'
import ExportCsv from '@material-table/exporters/csv'
import ExportPdf from 'src/views/vesselschedule/component/ExportPdf.js'
import ManageScheduleTranslationText from 'src/views/vesselschedule/manageschedule/ManageScheduleTranslationText'
import VesselTranslationText from 'src/views/vesselschedule/managevessels/VesselsTranslationText'
import clsx from 'clsx'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

const {
  table: { useDefaultNotification }
} = components

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

export default function ExportComponent({
  showNotification,
  toolbarClasses,
  columns,
  data,
  dataType,
  multiSelect,
  userProfile,
  user
}) {
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const translatedTextsObject = VesselTranslationText()
  const mstranslatedTextsObject = ManageScheduleTranslationText()

  const performExport = (format) => () => {
    try {
      let exportData
      let exportFileName

      if (dataType == 'Vessel') {
        exportFileName = 'Vessels'
        exportData = data
          .filter((vessel) => !multiSelect || (multiSelect && vessel.checked))
          .map((vessel) => {
            return {
              name: vessel.name,
              imoNo: vessel.imoNo,
              description: vessel.description,
              updatedBy:
                userProfile[
                  userProfile.findIndex(
                    (uprofile) =>
                      uprofile.id ===
                      user[user.findIndex((u) => u.id === vessel.updatedBy)]
                        .userProfileId
                  )
                ].name1,
              updatedDate: moment(vessel.updatedDate).format(
                'DD MMM YYYY HH:mm'
              ),
              status: vessel.status
                ? translatedTextsObject.active
                : translatedTextsObject.inactive
            }
          })

        exportData.forEach((vessel) => {
          columns.forEach((column, index) => {
            if (!column.show) {
              delete vessel[column.field]
            }
          })
        })
      } else if (dataType == 'Voyage') {
        exportFileName = 'Voyages'

        exportData = data
          .filter((voyage) => !multiSelect || (multiSelect && voyage.checked))
          .map((voyage) => {
            return {
              vesselName: voyage.vesselName,
              voyageNo: voyage.voyageNo,
              intVoyageNo: voyage.intVoyageNo,
              remarks: voyage.remarks,
              service: voyage.service,
              updatedBy:
                userProfile[
                  userProfile.findIndex(
                    (uprofile) =>
                      uprofile.id ===
                      user[user.findIndex((u) => u.id === voyage.updatedBy)]
                        .userProfileId
                  )
                ].name1,
              updatedDate: moment(voyage.updatedDate).format(
                'DD MMM YYYY HH:mm'
              )
            }
          })

        exportData.forEach((voyage) => {

          columns.forEach((column, index) => {
            if (!column.show) {
              delete voyage[column.field]
            }
          })
        })
      } else if (dataType == 'SubmittedFile') {
        exportFileName = 'SubmittedFile'

        exportData = data
          .filter(
            (submittedFile) =>
              !multiSelect || (multiSelect && submittedFile.checked)
          )
          .map((submittedFile) => {
            return {
              fileName: submittedFile.fileName,
              fileSize: submittedFile.fileSize,
              createdDate: submittedFile.createdDate
            }
          })

        exportData.forEach((submittedFile) => {
          columns.forEach((column, index) => {
            if (!column.show) {
              delete submittedFile[column.field]
            }
          })
          // if (!columns[0].show) {
          //   delete submittedFile.fileName
          // }

          // if (!columns[1].show) {
          //   delete submittedFile.fileSize
          // }

          // if (!columns[2].show) {
          //   delete submittedFile.createdDate
          // }
        })
      } else if (dataType == 'Schedule') {
        exportFileName = 'Schedule'

        exportData = data
          // .filter((voyage) => !multiSelect || (multiSelect && voyage.checked))
          .map((schedule) => {
            return {
              seqNo: schedule.seqNo,
              portCode: schedule.portName,
              waypoint: schedule.waypoint,
              transportMode: schedule.transportMode,
              arrivalTime: schedule.arrivalTime ?
                moment(schedule.arrivalTime).format('DD MMM YYYY HH:mm') : '',
              departureTime: schedule.departureTime ?
                moment(schedule.departureTime).format('DD MMM YYYY HH:mm') : '',
              cyCutoffTime: schedule.cyCutoffTime ?
                moment(schedule.cyCutoffTime).format('DD MMM YYYY HH:mm') : '',
            }
          })

        // this can be remove if CngTableBody and CngTableHeader add properties to control the default sequence no column 
        let seqColumn = {
          title: mstranslatedTextsObject.seqNo.toUpperCase(),
          field: 'seqNo',
          show: true,
        }

        if (columns[0].field != 'seqNo') {
          columns.unshift(seqColumn)
        }
        // this can be remove if CngTableBody and CngTableHeader add properties to control the default sequence no column 

        exportData.forEach((schedule) => {

          columns.forEach((column, index) => {
            if (!column.show) {
              delete schedule[column.field]
            }
          })
        })
      } else if (dataType == 'AWB') {
        exportFileName = 'AWB'
        exportData = data
          // .filter((voyage) => !multiSelect || (multiSelect && voyage.checked))
          .map((awb) => {
            return {
              docRefNo: awb.docRefNo,
              airlines: awb.airlines,
              masterAwbNo: awb.masterAwbNo,
              createdUser: awb.createdUser,
              createdOn: awb.createdOn ?
                moment(awb.arrivalTime).format('DD MMM YYYY HH:mm') : '',
              submittedOn: awb.submittedOn ?
                moment(awb.departureTime).format('DD MMM YYYY HH:mm') : '',
              status: awb.status,
            }
          })

        exportData.forEach((awb) => {
          columns.forEach((column, index) => {
            if (!column.show) {
              delete awb[column.field]
            }
          })
        })
      }

      const exportColumn = columns.filter((column) => column.show)

      console.log("columns: " + JSON.stringify(exportColumn))
      console.log("exportdata: " + JSON.stringify(exportData))
      console.log("exportfileName: " + JSON.stringify(exportFileName))

      format === 'CSV'
        ? ExportCsv(exportColumn, exportData, exportFileName)
        : ExportPdf(exportColumn, exportData, exportFileName, null)
      showSuccessNotification('Successfully export data to datatable.' + format)

      // this can be remove if CngTableBody and CngTableHeader add properties to control the default sequence no column 
      if (dataType == 'Schedule') {
        columns.shift()
      }
      // this can be remove if CngTableBody and CngTableHeader add properties to control the default sequence no column 

    } catch (err) {
      console.log(JSON.stringify(err))
      showErrorNotification(err.message)
    }

    setAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const formatDate = (date, timezone) => {
    if (!date) {
      return ''
    }

    if (timezone) {
      return moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
    }

    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  const handleClick = (event) => {
    if (dataType == 'mgtt') {
      try {
        let exportFileName = 'MGTT'
        const timezone = data.timezone
        let exportData = data
          .map((mgtt) => {
            return {
              //seqNo: mgtt.seqNo,
              sigaDocId: mgtt.sigaDocId,
              ttCreatedDate: formatDate(mgtt.ttCreatedDate, timezone),
              ttUpdatedDate: formatDate(mgtt.ttUpdatedDate, timezone),
              statuses: mgtt.statuses,
              containerNo: mgtt.containerNo,
              psaId: mgtt.psaId,
              containerCreatedDate: formatDate(mgtt.containerCreatedDate, timezone),
              containerUpdatedDate: formatDate(mgtt.containerUpdatedDate, timezone),
              psaApproval: mgtt.psaApproval,
              origin: mgtt.origin,
              destination: mgtt.destination,
              priority: mgtt.priority,
              originatingVessel: mgtt.originatingVessel,
              containerSize: mgtt.containerSize,
              containerType: mgtt.containerType,
              imoNo: mgtt.imoNo,
              cargoUnCode: mgtt.cargoUnCode,
              temperature: mgtt.temperature,
              yard: mgtt.inYard,
              noOfShifting: mgtt.noOfShifting,
              category: mgtt.category,
              onHold: mgtt.onHold,
              weight: mgtt.weight,
              yardArea: mgtt.yardArea,
              eir: mgtt.eir,
              driverName: mgtt.driverName,
              driverId: mgtt.driverId,
              licensePlate: mgtt.licensePlate
            }
          })

        exportData.forEach((mgtt) => {
          columns.forEach((column, index) => {
            if (!column.show) {
              delete mgtt[column.field]
            }
          })
        })
        const exportColumn = columns.filter((column) => column.show)
        ExportCsv(exportColumn, exportData, exportFileName)
        showSuccessNotification('Successfully exported data.')
      } catch (err) {
        console.log(JSON.stringify(err))
        showErrorNotification(err.message)
      }
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      {multiSelect ? (
        <ExportButton
          onClick={handleClick}
          variant='outlined'
          classes={{
            root: clsx(toolbarClasses.selectButtons, 'ng-button-outline'),
            iconSizeSmall: clsx(toolbarClasses.buttonIcons),
            iconSizeMedium: clsx(toolbarClasses.buttonIcons),
            iconSizeLarge: clsx(toolbarClasses.buttonIcons)
          }}
        />
      ) : (
        <Tooltip title='Export' placement='bottom'>
          <IconButton color={'default'} onClick={handleClick}>
            <LogOut size='16px' />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={clsx(toolbarClasses.menu)}
      >
        {dataType !== 'mgtt' && (
          <><MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
            {'Export as'}
          </MenuItem><MenuItem onClick={performExport('PDF')}>
              <ListItemIcon>
                <FileText size='16px' />
              </ListItemIcon>
              <Typography variant='inherit' style={{ fontSize: 'small' }}>
                PDF
              </Typography>
            </MenuItem>

            <MenuItem onClick={performExport('CSV')}>
              <ListItemIcon>
                <FileText size='16px' />
              </ListItemIcon>
              <Typography variant='inherit' style={{ fontSize: 'small' }}>
                CSV
              </Typography>
            </MenuItem></>)}
      </Menu>
    </Box>
  )
}
