import { Card, CardContent } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import TranslationText from './TranslationText'
import TransshipmentApiUrls from 'src/apiUrls/TransshipmentApiUrls'
import MGTTTableView from './MGTTTableView'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const {
    table: {
        useDefaultNotification
    }
} = components

function BatchJobPage(props) {
    const {
        showNotification
    } = props

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)

    const { fetchRecords, createRecord } = useServices()
    const translatedTextObject = TranslationText()
    const [checkedRows, setCheckedRows] = useState([])
    const [archivedCheckedRows, setArchivedCheckedRows] = useState([])
    const [data, setData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [searchArchivedData, setSearchArchivedData] = useState([])
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [showArchivedField, setShowArchivedField] = useState(true)
    const [archivedData, setArchivedData] = useState([])
    const [emptyData, setEmptyData] = useState(false)
    const [emptyArchivedData, setEmptyArchivedData] = useState(false)
    const [columns, setColumns] = useState([
        {
            field: "sigaDocId",
            title: translatedTextObject.tt,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "containerNo",
            title: translatedTextObject.containerNo,
            show: true,
            active: false,
            order: 'desc',
            value: ''
        },
        {
            field: "priority",
            title: translatedTextObject.priority,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "containerSize",
            title: translatedTextObject.containerSize,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "driverName",
            title: translatedTextObject.driverName,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "licensePlate",
            title: translatedTextObject.licensePlate,
            show: true,
            active: false,
            order: 'desc',
            value: ''
        },
        {
            field: "yardArea",
            title: translatedTextObject.yard,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "onHold",
            title: translatedTextObject.onHold,
            show: true,
            active: false,
            value: ''
        },
        {
            field: "statuses",
            title: translatedTextObject.statuses,
            show: true,
            active: false,
            value: ''
        }
    ])

    useEffect(() => {
        let newArchivedData = []
        let newData = []
        fetchRecords.execute(
            TransshipmentApiUrls.GET_TT_WITH_SIGA_STATUS, false,
            (data) => {
                setLoading(false)
                data.map(d => {
                    if (d.sigaDocId === null) {
                        d.sigaDocId = 'No TT Found'
                    }
                    if (d.fetchSigaData) {
                        d.checked = false
                        newArchivedData.push(d)
                    } else {
                        d.checked = false
                        newData.push(d)
                    }
                })
                setData(newData)
                setArchivedData(newArchivedData)
            }
        )
    }, [refresh])

    const manualRefresh = () => {
        setRefresh(!refresh)
        setLoading(true)
        let newArchivedData = []
        let newData = []
        fetchRecords.execute(
            TransshipmentApiUrls.GET_TT_WITH_SIGA_STATUS,
            (data) => {
                setLoading(false)
                data.map(d => {
                    if (d.fetchSigaData) {
                        newArchivedData.push(d)
                    } else {
                        newData.push(d)
                    }
                })
                setData(newData)
                setArchivedData(newArchivedData)

                setSearchData([])
                setEmptyData(false)
                setSearchArchivedData([])
                setEmptyArchivedData(false)
                setColumns(columns.map(column => ({ ...column, value: '' })))
            }
        )
    }

    const onRetriggerButtonClick = () => {
        setLoading(true)
        const contId = archivedCheckedRows.map(cont => cont.containerNo)

        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            if (response.some(item => item.httpErrorCode != 201)) {
                setLoading(false)
                const errorGroups = response.reduce((acc, item) => {
                    if (item.httpErrorCode != null) {
                        const containerNo = item.errorMessage.split('Container No: ')[1]
                        if (!acc[item.httpErrorCode]) {
                            acc[item.httpErrorCode] = []
                        }
                        acc[item.httpErrorCode].push(containerNo)
                    }
                    return acc
                }, {})

                const errorMessages = Object.entries(errorGroups)
                    .map(([httpErrorCode, containerNos]) => `Container(s): ${containerNos.join(', ')} retriggered with error ${httpErrorCode}`)
                    .join(', ')

                showErrorNotification(errorMessages)
            } else {
                showSuccessNotification(`Container(s) retriggered successfully`)
                manualRefresh()
            }
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            setLoading(false)
            showErrorNotification(error.message)
        }

        createRecord.execute(
            TransshipmentApiUrls.RETRY_TT,
            { contId: contId },
            onSuccess,
            onError
        )
    }

    return (
        <Card>
            <CardContent>
                <CngBackdrop loading={loading} />
                {!showArchivedField && <MGTTTableView
                    isBatch={true}
                    onRetriggerButtonClick={onRetriggerButtonClick}
                    showNotification={showNotification}
                    data={data}
                    columns={columns}
                    viewOnly={false}
                    setColumns={setColumns}
                    setData={setData}
                    setCheckedRows={setCheckedRows}
                    checkedRows={checkedRows}
                    emptyData={emptyData}
                    setEmptyData={setEmptyData}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    manualRefresh={manualRefresh}
                    showArchivedField={showArchivedField}
                    setShowArchivedField={setShowArchivedField}
                />
                }
                {showArchivedField && <MGTTTableView
                    isBatch={true}
                    onRetriggerButtonClick={onRetriggerButtonClick}
                    showNotification={showNotification}
                    data={archivedData}
                    columns={columns}
                    viewOnly={false}
                    setColumns={setColumns}
                    setData={setArchivedData}
                    setCheckedRows={setArchivedCheckedRows}
                    checkedRows={archivedCheckedRows}
                    emptyData={emptyArchivedData}
                    setEmptyData={setEmptyArchivedData}
                    searchData={searchArchivedData}
                    setSearchData={setSearchArchivedData}
                    manualRefresh={manualRefresh}
                    showArchivedField={showArchivedField}
                    setShowArchivedField={setShowArchivedField}
                />
                }
            </CardContent>
        </Card >
    )
}

export default BatchJobPage