import React, { useState } from 'react';
import { Button } from '@material-ui/core'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog';
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'




const ButtonViewDetail = ({ text }) => {
    const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])

    const [view, setView] = useState(null);


    const handleClick = (e) => {
        setView(true)
    }

    const handleClose = () => {
        setView(false)
    }

    return (
        <>
            <Button
                onClick={handleClick}
            >
                View Detail
            </Button>
            <ConfirmDialog title={t('label_input_parameter')} confirmDialog={handleClose} maxWidth='md' isConfirmDialogOpen={view} content={<pre>{text}</pre>} okMsg="Close" closeDialog={handleClose} />
        </>
    )
}

export default ButtonViewDetail;