const convertDataToAPIFromFormData = (formData, ftaCode, ftaName, certificateId, principalId, selfCertifyIds, skuMaterials, signatureImage) => {
  return {
    certificateOfOrigin: {
      ftaCode: ftaCode,
      ftaName: ftaName,
      certificateId: certificateId,
      certifierType: formData.certifierType
    },
    certifierInformation: {
      entityName: formData.certifierName,
      jobTitle: formData.certifierJobTitle,
      emailAddress: formData.certifierEmailAddress,
      contactNo: formData.certifierContactNo,
      address: formData.certifierAddress
    },
    exporterInformation: {
      entityName: formData.exporterName,
      jobTitle: formData.exporterJobTitle,
      emailAddress: formData.exporterEmailAddress,
      contactNo: formData.exporterContactNo,
      address: formData.exporterAddress,
      exporterSameAsCertifier: formData.exporterSameAsCertifierDetails != undefined ? formData.exporterSameAsCertifierDetails : false
    },
    producerInformation: {
      entityName: formData.producerName,
      jobTitle: formData.producerJobTitle,
      emailAddress: formData.producerEmailAddress,
      contactNo: formData.producerContactNo,
      address: formData.producerAddress,
      isProducerConfidentiality: formData.isProducerConfidentiality != undefined ? formData.isProducerConfidentiality : false,
      producerVariousImporters: formData.producerVariousImporters != undefined ? formData.producerVariousImporters : false,
      producerSameAsCheckBox: formData.producerSameAsCheckBox != undefined ? formData.producerSameAsCheckBox : false,
      producerSameAs: formData.producerName === formData.certifierName ? "CERTIFIER" : "EXPORTER"
    },
    importerInformation: {
      entityName: formData.importerName,
      jobTitle: formData.importerJobTitle,
      emailAddress: formData.importerEmailAddress,
      contactNo: formData.importerContactNo,
      address: formData.importerAddress,
      unknownImporter: formData.importerUnknownImporter != undefined ? formData.importerUnknownImporter : false,
      variousImporter: formData.importerVariousImporters != undefined ? formData.importerVariousImporters : false,
      importerSameAs: formData.importerSameAsCertifier != undefined ? formData.importerSameAsCertifier : false
    },
    skuAndMaterials: {
      skuMaterials
    },
    blanketPeriod: {
      startDate: formData.blanketStartDate != undefined ? formData.blanketStartDate : null,
      endDate: formData.blanketEndDate != undefined ? formData.blanketEndDate : null
    },
    signature: {
      principalId: principalId,
      signatureId: '',
      signerPrinterName: formData.signerPrintedName != undefined ? formData.signerPrintedName : null,
      signerCertificationDate: formData.signerCertificationDate != undefined ? formData.signerCertificationDate : null,
      signatureImage: signatureImage
    },
    selfCertifyIds: selfCertifyIds
  }
}

export {
  convertDataToAPIFromFormData as convertDataToAPIFromFormData
}