import { useIdentifierResolver } from "@govtechsg/address-identity-resolver";
import React from "react";
import AddressInfo from "../components/AddressInfo"

export const AssetTitle = ({ role, address, children }) => {
  const { identityName, identityResolvedBy, identitySource } = useIdentifierResolver(address);

  return (
    <div data-testid={`asset-title-${role.toLowerCase()}`} className="py-4">
      <AddressInfo title={role} name={identityName} resolvedBy={identityResolvedBy} source={identitySource || ""}>
        {children}
      </AddressInfo>
    </div>
  );
};

export default AssetTitle;
