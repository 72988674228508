import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import TradeTrustKeys from 'src/constants/locale/key/TradeTrust'
import pathMap from 'src/paths/PathMap_TradeTrust'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.TRADETRUST]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()
  {
    let tradeTrustTitle = translate(
      Namespace.TRADETRUST,
      TradeTrustKeys.TITLE
    )

    breadcrumbNameMap.set(
      pathMap.TRADETRUST_UPLOAD,
      tradeTrustTitle
    )

    // Endorsement Chain Title
    let endorsementChainTitle = translate(Namespace.TRADETRUST, TradeTrustKeys.ENDORSEMENT_CHAIN_TITLE);
    breadcrumbNameMap.set(pathMap.TRADETRUST_ENDORSEMENT, endorsementChainTitle);

  }

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
