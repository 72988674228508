import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import { Filter } from 'react-feather'

export default function CngTableHeader(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const showSeq = (props.showSeqNo === undefined || props.showSeqNo === null || props.showSeqNo) ? true : false
  const showCheck = (props.showCheckbox === undefined || props.showCheckbox === null || props.showCheckbox) ? true : false

  return (
    <TableHead>
      <TableRow>
        {!props.viewOnly && showCheck && (
          <TableCell padding='checkbox'>
            <Checkbox
              color="primary"
              indeterminate={
                props.data.filter((item) => item.checked === true).length >
                0 &&
                props.data.filter((item) => item.checked === true).length <
                props.data.length
              }
              checked={
                props.data.filter((item) => item.checked === true)
                  .length === props.data.length
              }
              onChange={props.onHeaderCheck}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {showSeq ? (
          <TableCell className={'vs-table-cell-padding'}>
            <div className={'rs-grey'}>{translatedTextsObject.seqNo.toUpperCase()}</div>
          </TableCell>
        ) : null}
        {props.columns.map((column, index) =>
          column.show ? (
            <TableCell
              key={column.title + index}
              className={'vs-table-cell-padding'}
            >
              <TableSortLabel
                active={column.active}
                direction={column.order}
                onClick={props.onSortClick(column.field)}
              >
                <div className={'rs-grey'}>{column.title}</div>
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
        {!props.viewOnly || props.deleteEnable ? (
          <TableCell className={'vs-table-cell-padding'}>
            <div className={'rs-grey'}>{translatedTextsObject.action}</div>
          </TableCell>
        ) : null}
        {props.deleteEnable ? (
          <TableCell className={'vs-table-cell-padding'}>
            <div className={'rs-grey'}>{translatedTextsObject.action}</div>
          </TableCell>
        ) : null}
      </TableRow>
      {props.showFilter != null && props.showFilter ? (
        <TableRow className={'table-header-filter'}>
          {!props.viewOnly && showCheck && (
            <TableCell padding='checkbox' className={'vs-table-cell-padding-filter'}>
            </TableCell>
          )}
          {props.columns.map((column, index) =>
            column.show ? (
              <TableCell key={column.title} className={'vs-table-cell-padding-filter'}>
                <Filter size='16px' />
                <TextField id={column.title} label="Filter" variant="outlined" onChange={props.onFilterTextChange(column.field)} value={column.value} onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    props.onClickFilter()
                  }
                }} />
              </TableCell>
            ) : null
          )}
          {!props.viewOnly || props.deleteEnable ? (
            <TableCell className={'vs-table-cell-padding-filter'}>
            </TableCell>
          ) : null}
        </TableRow>
      ) : null
      }
    </TableHead>
  )
}
