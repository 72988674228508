export const AssetManagementActions = {
  None: "None",
  TransferHolder: "TransferHolder",
  EndorseBeneficiary: "EndorseBeneficiary",
  Surrender: "Surrender",
  AcceptSurrendered: "AcceptSurrendered",
  RejectSurrendered: "RejectSurrendered",
  NominateBeneficiary: "NominateBeneficiary",
  EndorseTransfer: "EndorseTransfer",
}
