import { Box, Card, Grid, CardContent } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import AccordionHeaderComponent from '../common/ui/AccordionHeaderComponent'
import moment from 'moment'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const {
	form: {
		CngViewForm,
		field: { CngTextField }
	},
	CngGridItem
} = components

const FormBody = (props) => {
	const { viewData } = props
	const [ttCreatedDate, setTTCreatedDate] = useState(null)
	const [ttUpdatedDate, setTTUpdatedDate] = useState(null)
	const [contCreatedDate, setContCreatedDate] = useState(null)
	const [contUpdatedDate, setContUpdatedDate] = useState(null)
	const [loading, setLoading] = useState(true)

	const formatDate = (date, timezone) => {
		return moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
	}

	useEffect(() => {
		if (!props.callTzApi) {
			return
		}

		const timezone = props.timezone || "System Timezone"

		setTTCreatedDate(formatDate(viewData.ttCreatedDate, timezone))
		setTTUpdatedDate(formatDate(viewData.ttUpdatedDate, timezone))
		setContCreatedDate(formatDate(viewData.containerCreatedDate, timezone))
		setContUpdatedDate(formatDate(viewData.containerUpdatedDate, timezone))
		setLoading(false)

	}, [props.callTzApi])

	return (
		<div style={{ overflow: 'auto', display: 'flow-root' }}>
			<Card>
				<CardContent>
					<CngBackdrop loading={loading} />
					<CngViewForm
						fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
						formikProps={{
							initialValues: { ...props.viewData },
							validateOnChange: false
						}}
						bodySection={
							<Box m={1.5}>
								<Grid container>
									<AccordionHeaderComponent title='General Description' />
									<Grid container xs={12} sm={12} spacing={2}>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='sigaDocId'
												label='TT No.'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='ttCreatedDate'
												value={!loading ? ttCreatedDate : ''}
												label='TT Creation Date'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='ttUpdatedDate'
												value={!loading ? ttUpdatedDate : ''}
												label='TT Modified Date'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='statuses'
												label='Statuses'
												disabled={true}
											/>
										</CngGridItem>
									</Grid>
								</Grid>
								<div style={{ padding: '10px' }} />
								<Grid container>
									<AccordionHeaderComponent title='Container Attibutes' />
									<Grid container xs={12} sm={12} spacing={2}>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='containerNo'
												label='Container No.'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='psaId'
												label='PSA ID'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='containerCreatedDate'
												value={!loading ? contCreatedDate : ''}
												label='Container Creation Date'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='containerUpdatedDate'
												value={!loading ? contUpdatedDate : ''}
												label='Container Modified On'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='psaApproval'
												label='PSA approval'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='origin'
												label='Origin'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='destination'
												label='Destination'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='priority'
												label='Priority'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='originatingVessel'
												label='Originating Vessel'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='containerSize'
												label='Container Size'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='containerType'
												label='Container Type'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='imoNo'
												label='IMO No.'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='cargoUnCode'
												label='Cargo UN Code'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='temperature'
												label='Temperature (in C)'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='inYard'
												label='In Yard'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='noOfShifting'
												label='Number of shifting'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='category'
												label='Transshipment Category'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='onHold'
												label='On Hold'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='weight'
												label='Total Weight (in KG)'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='yardArea'
												label='Yard'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='eir'
												label='EIR'
												disabled={true}
											/>
										</CngGridItem>
									</Grid>
								</Grid>
								<div style={{ padding: '10px' }} />
								<Grid container>
									<AccordionHeaderComponent title='Driver Details' />
									<Grid container xs={12} sm={12} spacing={2}>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='driverName'
												label='Driver Name'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='driverId'
												label='Driver ID'
												disabled={true}
											/>
										</CngGridItem>
										<CngGridItem xs={3} sm={3}>
											<CngTextField
												name='licensePlate'
												label='License Plate'
												disabled={true}
											/>
										</CngGridItem>
									</Grid>
								</Grid>
							</Box >
						}
					/> </CardContent>
			</Card>
		</div>
	)
}

const ViewTableDetails = Object.freeze({
	FormBody: FormBody
})

export default ViewTableDetails