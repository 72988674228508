import { useState, useEffect, useCallback } from "react";
import { TitleEscrowFactory__factory, TitleEscrow__factory } from "@govtechsg/token-registry/contracts";
import { BurnAddress } from "../constants/chain-info";

export const useTitleEscrowContract = (provider, tokenRegistry, tokenId) => {
    const [titleEscrow, setTitleEscrow] = useState();
    const [documentOwner, setDocumentOwner] = useState();

    const updateTitleEscrow = useCallback(async () => {
        if (!tokenRegistry || !tokenId || !provider) return;
        const titleEscrowOwner = await tokenRegistry.ownerOf(tokenId);
        setDocumentOwner(titleEscrowOwner);
        const instance = await connectToTitleEscrow({
            provider,
            tokenRegistry,
            tokenId,
        });
        setTitleEscrow(instance);
    }, [provider, tokenId, tokenRegistry]);

    useEffect(() => {
        updateTitleEscrow();
        return () => {
            setTitleEscrow(undefined);
            setDocumentOwner(undefined);
        };
    }, [updateTitleEscrow, tokenId, provider]);

    return { titleEscrow, updateTitleEscrow, documentOwner };
};

export const retrieveTitleEscrowAddressOnFactory = async (tokenRegistry, tokenId, signer) => {
    const titleEscrowFactoryAddress = await tokenRegistry.titleEscrowFactory();
    const tokenRegistryAddress = await tokenRegistry.address;
    const titleEscrowFactory = TitleEscrowFactory__factory.connect(titleEscrowFactoryAddress, signer);
    const titleEscrowAddress = await titleEscrowFactory.getAddress(tokenRegistryAddress, tokenId);
    return titleEscrowAddress;
};

export const connectToTitleEscrow = async ({ provider, tokenRegistry, tokenId, }) => {
    const tokenRegistryAddress = tokenRegistry.address;
    const titleEscrowOwner = await tokenRegistry.ownerOf(tokenId);
    const inactiveEscrow = [BurnAddress, tokenRegistryAddress].includes(titleEscrowOwner);
    let titleEscrowAddress = titleEscrowOwner;
    if (inactiveEscrow) {
        titleEscrowAddress = await retrieveTitleEscrowAddressOnFactory(tokenRegistry, tokenId, provider);
    }
    return TitleEscrow__factory.connect(titleEscrowAddress, provider);
};