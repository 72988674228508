import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import CarbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'
import Utils from 'src/views/common/utils/Utils'
import ButtonViewDetail from 'src/views/carbon-footprint-calculator/eco-calculator/AuditLog/ButtonViewDetail'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'

const {
  table: { DateTimeRangeFilter: CngDateTimeRangeFilter, CngCrudTable }

} = components
const AuditLog = ({ notification }) => {
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])
  const SG_DATE_TIME_FORMAT = Utils.UI_FORMAT_DATE_TIME

  return (
    <>
      <CngCrudTable
        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
        columns={[{ title: t('label_user_id'), field: "userLoginID" }, {
          title: t('label_parameter'),
          field: 'inputParameters',
          render: (text) => {
            return (
              <ButtonViewDetail text={JSON.stringify(JSON.parse(text.inputParameters), null, '\t')} />

            )
          },
        },
        {
          title: t('label_created_date'), field: 'createdDate', filterComponent: CngDateTimeRangeFilter, type: "datetime", defaultSort: "DESC",
          render: ({ createdDate }) => {
            console.log(">>>>>>>>>>>>>", createdDate)
            return moment(createdDate).format(SG_DATE_TIME_FORMAT)
          }
        },
        {
          title: t('label_request_date'), field: 'requestDateTime', filterComponent: CngDateTimeRangeFilter, type: "datetime",
          render: ({ requestDateTime }) => {
            return moment(requestDateTime).format(SG_DATE_TIME_FORMAT)
          }
        },
        {
          title: t('label_response_date'), field: 'responseDateTime', filterComponent: CngDateTimeRangeFilter, type: "datetime",
          render: ({ responseDateTime }) => {
            return moment(responseDateTime).format(SG_DATE_TIME_FORMAT)
          }
        },
        {
          title: t('label_result_received'), field: "resultReceive"

        },
        ]}
        exportData={{ url: CarbonFootprintApiUrls.EXPORT_AUDIT_LOG }}
        fetch={{ url: CarbonFootprintApiUrls.AUDIT_LOG }}
        idAccessor="id"
        notification={notification}
      />
    </>
  )
}
export default AuditLog
