import { DriverPage, OperatorPage, TransporterPage, InternalUserPage, BatchJobPage } from 'src/views/mgtt/index'
import pathMap from 'src/paths/PathMap_MGTT'


class MGTTRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.MGTT_DRIVER,
                component: DriverPage
            },
            {
                exact: true,
                path: pathMap.MGTT_TRANSPORTER,
                component: TransporterPage
            },
            {
                exact: true,
                path: pathMap.MGTT_OPERATOR,
                component: OperatorPage
            },
            {
                exact: true,
                path: pathMap.MGTT_INTERNAL_USER,
                component: InternalUserPage
            },
            {
                exact: true,
                path: pathMap.MGTT_BATCH_JOB,
                component: BatchJobPage
            }
        ]
    }
}

export default MGTTRoute