export const ChainId = {
    // Localhost
    Local: 1337,
    // Ethereum Mainnet
    Ethereum: 1,
    // Ethereum Testnet
    Goerli: 5,
    Sepolia: 11155111,
    // Polygon
    Polygon: 137,
    PolygonMumbai: 80001,
}

export const ChainInfoObject = {
    label: '',
    iconImage: '',
    chainId: ChainId,
    networkName: '', // network name that aligns with existing NETWORK_NAME
    networkLabel: '',
    explorerUrl: '',
    rpcUrl: '',
    nativeCurrency: {
        name: '',
        symbol: '',
        decimals: 0,
    }
}

export const InitialAddress = "0x0000000000000000000000000000000000000000";
export const BurnAddress = "0x000000000000000000000000000000000000dEaD";

export const AvailableBlockChains = ["ETH", "MATIC"];

export const ChainInfo = {
    [ChainId.Local]: {
        label: "Local",
        chainId: ChainId.Local,
        iconImage: "/static/images/networks/ethereum.gif",
        networkName: "local",
        networkLabel: "Local",
        explorerUrl: "https://localhost/explorer",
    },
    [ChainId.Ethereum]: {
        label: "Ethereum",
        chainId: ChainId.Ethereum,
        iconImage: "/static/images/networks/ethereum.gif",
        networkName: "homestead",
        networkLabel: "Ethereum",
        explorerUrl: "https://etherscan.io",
    },
    [ChainId.Goerli]: {
        label: "Goerli",
        chainId: ChainId.Goerli,
        iconImage: "/static/images/networks/ethereum.gif",
        networkName: "goerli",
        networkLabel: "Goerli",
        explorerUrl: "https://goerli.etherscan.io",
    },
    [ChainId.Sepolia]: {
        label: "Sepolia",
        chainId: ChainId.Sepolia,
        iconImage: "/static/images/networks/ethereum.gif",
        networkName: "sepolia",
        networkLabel: "Sepolia",
        explorerUrl: "https://sepolia.etherscan.io",
        rpcUrl: "https://rpc.sepolia.org",
        nativeCurrency: {
            name: "ETH",
            symbol: "sepETH",
            decimals: 18,
        },
    },
    [ChainId.Polygon]: {
        label: "Polygon (Beta)",
        chainId: ChainId.Polygon,
        iconImage: "/static/images/networks/polygon.gif",
        networkName: "matic",
        networkLabel: "Polygon",
        explorerUrl: "https://polygonscan.com",
        rpcUrl: "https://polygon-rpc.com",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
        },
    },
    [ChainId.PolygonMumbai]: {
        label: "Polygon Mumbai",
        chainId: ChainId.PolygonMumbai,
        iconImage: "/static/images/networks/polygon.gif",
        networkName: "maticmum",
        networkLabel: "Polygon Mumbai",
        explorerUrl: "https://mumbai.polygonscan.com",
        rpcUrl: "https://rpc-mumbai.maticvigil.com",
        nativeCurrency: {
            name: "MATIC",
            symbol: "mMATIC",
            decimals: 18,
        },
    }
}