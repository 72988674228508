import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import { connect } from "react-redux";
import {
  FrameConnector,
  renderDocument,
  selectTemplate,
  print,
} from '@govtechsg/decentralized-renderer-react-components';
import { utils } from '@govtechsg/open-attestation';
import {getOpenAttestationData } from "../utils/shared";

const DEFAULT_RENDERER_URL = `https://generic-templates.tradetrust.io`;

const SCROLLBAR_WIDTH = 20; // giving scrollbar a default width as there are no perfect ways to get it

export const DecentralisedRenderer = ({
  rawDocument,
  updateTemplates,
  selectedTemplate,
  setPrivacyFilter,
  forwardedRef,
}) => {
  const toFrame = useRef();
  const document = useMemo(() => getOpenAttestationData(rawDocument.document), [rawDocument.document]);
  const [height, setHeight] = useState(250);
  const [isTimeout, setIsTimeout] = useState(false);
  
  useImperativeHandle(forwardedRef, () => ({
    print() {
      if (toFrame.current) {
        toFrame.current(print());
      }
    },
  }));

  const getTemplateUrl = (rawDocument) => {
    if (utils.isWrappedV2Document(rawDocument)) {
      const documentData = utils.getData(rawDocument);
      return documentData.$template === "object" ? documentData.$template.url : undefined;
    } else {
      return rawDocument.openAttestationMetadata.template?.url;
    }
  };

  const source = getTemplateUrl(rawDocument.document) ?? DEFAULT_RENDERER_URL;

  const onConnected = useCallback(
    (frame) => {
      toFrame.current = frame;
      if (toFrame.current) {
        toFrame.current(renderDocument({ document, rawDocument }));
      }
    },
    [document, rawDocument]
  );

  const dispatch = (action) => {
    if (action.type === "UPDATE_HEIGHT") {
      setHeight(action.payload + SCROLLBAR_WIDTH); // adding SCROLLBAR_WIDTH in case the frame content overflow horizontally, which will cause scrollbars to appear
    }
    if (action.type === "UPDATE_TEMPLATES") {
      updateTemplates(action.payload);
    }
    if (action.type === "OBFUSCATE") {
      setPrivacyFilter(action.payload);
    }
    if (action.type === "TIMEOUT") {
      setIsTimeout(true);
    }
  };

  // render document onload
  useEffect(() => {
    if (toFrame.current) {
      toFrame.current(renderDocument({ document }));  
    }

    //initialize template source
    // getTemplateUrl(rawDocument.document);

  }, [document, toFrame]);

  // update document when click on template tab
  useEffect(() => {
    if (toFrame.current && selectedTemplate) {
      toFrame.current(selectTemplate(selectedTemplate));
    }
  }, [selectedTemplate, toFrame]);

  return (
    <div className={`${isTimeout ? "container" : ""}`}>
      <FrameConnector
        style={{ height: `${height}px`, width: "100%", border: "0px" }}
        source={source}
        dispatch={dispatch}
        onConnected={onConnected}
      />
    </div>
  );
};

const applyPrivacyFilter = (payload) => {
  return {
    type: "CERTIFICATE_OBFUSCATE_UPDATE",
    payload,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setPrivacyFilter: (path) => dispatch(applyPrivacyFilter(path)),
});

// eslint-disable-next-line react/display-name
const ForwardedRefDecentralisedRenderer = React.forwardRef((props, ref) => <DecentralisedRenderer {...props} forwardedRef={ref} />);

export const DecentralisedRendererContainer = connect(null, mapDispatchToProps, null, { forwardRef: true })(
  ForwardedRefDecentralisedRenderer
);
