import { makeStyles, Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from 'react'
import clsx from "clsx"
import { components, constants, useServices } from 'cng-web-lib'

const toolbarStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiMenu': {
            '&-paper': { borderRadius: 10 },
            '&-list': {
                maxWidth: '60vw',
                padding: 2,
                '& .MuiListItem-root:not(.Mui-disabled)': {
                    letterSpacing: 0.21,
                    fontSize: '.9em',
                    padding: '8px 10px',
                    minWidth: '12em',
                    '&:hover': {
                        backgroundColor:
                            theme.palette.component.popoverMenu.listItemHoverFill
                    }
                }
            }
        },
        '& .ng-mattbl-popover-checkbox': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .ng-mattbl-popover-title': {
            backgroundColor:
                theme.palette.component.textFormControl.dropdownMenuSelectedFill,
            borderRadius: 8,
            fontWeight: 900,
            color: theme.palette.text.navActive,
            padding: '10px 8px',
            marginBottom: theme.spacing(0.5)
        }
    }
}))

const {
    form: {
        field: { CngTextField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        }
    },
    CngGridItem
} = components

function FTAActivityHistorySortDropdown(props) {
    const [, , { setValue: setSortType }] = useField('sortType')
    const toolbarClasses = toolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedSortType, setSelectedSortType] = React.useState("updatedDate-desc");
    const options = [
        {
            name: 'Last Modified (ascending)',
            value: 'updatedDate-asc'
        },
        {
            name: 'Last Modified (descending)',
            value: 'updatedDate-desc'
        },
        {
            name: 'Material no. / SKU (ascending)',
            value: 'product.materialSkuNo-asc'
        },
        {
            name: 'Material no. / SKU (descending)',
            value: 'product.materialSkuNo-desc'
        }
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event, index) => {
        console.log(event, index)
        setSelectedSortType(index);
        setAnchorEl(null);
        setSortType(index);
    };

    return (
        <>
            <IconButton
                id="basic-button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={['fal', 'sort-alt']} size="xs" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                className={clsx(toolbarClasses.menu)}
            >
                <MenuItem key={'SortBy'} className={clsx('ng-mattbl-popover-title')} disabled={true}>
                    {'Sort By'}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} onClick={(event) => handleMenuItemClick(event, option.value)}>
                        <ListItemIcon>
                            {option.value === selectedSortType && <FontAwesomeIcon icon={['fas', 'check']} />}
                        </ListItemIcon>
                        <Typography variant="inherit">{option.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default FTAActivityHistorySortDropdown