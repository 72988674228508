import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  components
} from 'cng-web-lib'
import StatusCheck from "./StatusCheck"
import { utils } from "@govtechsg/oa-verify";
import { utils as oaUtils } from "@govtechsg/open-attestation";

const {
  CngGridItem
} = components

const VerifyStatusComponent = (props) => {
  const [identityMessage, setIdentityMessage] = useState([])
  const [hashMessage, setHashMessage] = useState([])
  const [issuedMessage, setIssuedMessage] = useState([])
  const title = "Issued By "

  useEffect(() => {
    setIssuedMessage(props.issuedValid ? "The document has been issued" : "The document has not been issued");
    setHashMessage(props.hashValid ? "The issuer identity has been verified" : "The issuer identity has not been verified");
    setIdentityMessage(props.identityValid ? "The document has not been tampered" : "The document has been tampered");
  }, [props.document])

  const getV2FormattedDomainNames = (fragments) => {
    const joinIssuers = issuers => {
      if (!issuers) return "Unknown";
      const issuerNames = issuers.join(", ");
      return issuerNames?.replace(/,(?=[^,]*$)/, " and"); // regex to find last comma, replace with and
    };

    const formatIdentifier = fragment => {
      switch (fragment.name) {
        case "OpenAttestationDnsTxtIdentityProof":
        // using fall through to get both cases
        case "OpenAttestationDnsDidIdentityProof":
          return joinIssuers(fragment.data?.map((issuer) => issuer.location.toUpperCase()));
        case "OpenAttestationDidIdentityProof":
          return joinIssuers(fragment.data?.map((issuer) => issuer.did.toUpperCase()));
        default:
          return "Unknown";
      }
    };
    const identityProofFragment = utils
      .getIssuerIdentityFragments(fragments)
      .find((fragment) => utils.isValidFragment(fragment));

    const dataFragment = identityProofFragment?.data;
    const fragmentValidity =
      dataFragment?.length > 0 &&
      dataFragment?.every(
        (issuer) => issuer.status === "VALID" || issuer.verified === true
      ); // every will return true even though dataFragment is empty, hence the additional check for length

    return fragmentValidity ? formatIdentifier(identityProofFragment) : "Unknown";
  };

  const getV3IdentityVerificationText = (document) => {
    return document.openAttestationMetadata.identityProof.identifier.toUpperCase();
  };

  const formattedDomainNames = oaUtils.isWrappedV2Document(props.document)
    ? getV2FormattedDomainNames(props.fragments)
    : getV3IdentityVerificationText(props.document);

  return (
    <Grid container alignItems="center" className="ms-4">
      <CngGridItem>
        <h2 style={{ fontWeight: 600 }} id="issuedby" className="break-words leading-tight py-4">
          <span className="mr-2 inline-block break-all">{title}</span>
          {/* <span style={{ color: '#2d5faa' }} className="inline-block">{getV2FormattedDomainNames(props.fragments)}</span> */}
          <span style={{ color: '#2d5faa' }} className="inline-block">{formattedDomainNames}</span>
        </h2>
        <Box display='flex'>
          <StatusCheck valid={props.issuedValid} message={issuedMessage} />
          <div style={{ paddingLeft: 10 }}>
            <StatusCheck valid={props.identityValid} message={identityMessage} />
          </div>
          <div style={{ paddingLeft: 10 }}>
            <StatusCheck valid={props.hashValid} message={hashMessage} />
          </div>
        </Box>
      </CngGridItem>
      <Grid />
    </Grid>
  )
}

export default VerifyStatusComponent;