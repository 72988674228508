import React from "react";
import { makeEtherscanAddressURL } from "../../utils";
import { useProviderContext } from "../../contexts/provider";

export const ExternalLink = ({ name, href, ...props }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
            {name}
        </a>
    );
};

export const ExternalLinkEtherscanAddress = ({
    name,
    address,
    ...props
}) => {
    const { currentChainId } = useProviderContext();
    const href = currentChainId ? makeEtherscanAddressURL(address, currentChainId) : "#";

    return <ExternalLink name={name} href={href} {...props} />;
};
