import { components } from 'cng-web-lib'
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import SCOInvMgmtApiUrls from 'src/apiUrls/SCOInvMgmtApiUrls'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import FormProperties from './SKU_FormProperties'
import SKUTranslationText from './SKUTranslationText'
import { Button } from '@material-ui/core'
import WeightCost_FormProperties from './SKU_Weight_Cost_FormProperties'

const {
    button: { CngSecondaryButton }
} = components

const { form: { CngEditForm, CngEditFormButtonSection },
    CngGridItem, CngTabs,
} = components

function EditPage({ history, showNotification }) {
    const { id } = useParams()
    const translatedTextObject = SKUTranslationText()

    return (

        <CngTabs
			headerColor='primary'
			tabs={[
	            {
					tabName: "SKU Details",
                    tabContent: (
                        <CngEditForm
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            showNotification={showNotification}
                            bodySection={
                                <FormProperties.Fields
                                    disabled={false}
                                    showNotification={showNotification}
                                    id={id}
                                    isEdit={true}
                                />
                            }
                            renderButtonSection={() => (
                                <CngEditFormButtonSection>
                                        <CngGridItem xs={10} sm={10}>
                                            <CngSecondaryButton
                                                onClick={() => { history.push(pathMap.SCO_INV_SKU_LIST) }}>
                                                {translatedTextObject.cancel}
                                            </CngSecondaryButton>
                                        </CngGridItem>
                                        
                                        <CngGridItem sx={1} sm={1} style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                            <Link to={{
                                                pathname: pathMap.SCO_INV_SKU_CREATE_VIEW,
                                                state: {id}
                                            }}
                                                >
                                                <Button
                                                    type='button'
                                                    variant='contained'
                                                    className='button-light-green originalText'
                                                    style={{ minHeight: '42px', borderRadius: '8px', marginLeft: '5px' }}
                                                    >
                                                    {translatedTextObject.clone}
                                                    
                                                </Button>
                                            </Link>
                                        </CngGridItem>
                                </CngEditFormButtonSection>
                            )}
                            formikProps={FormProperties.formikProps}
                            toClientDataFormat={FormProperties.toClientDataFormat}
                            toServerDataFormat={FormProperties.toServerDataFormat}
                            fetch={{
                                url: `${SCOInvMgmtApiUrls.GET_SKU_BY_ID}`
                            }}
                            update={{
                                url: SCOInvMgmtApiUrls.UPDATE_SKU,
                                successRedirect: pathMap.SCO_INV_SKU_LIST
                            }}
                            id={id}
                        />
                    ) //End of tab
                },
                {
                    tabName: "Weighted Cost",
                    tabContent: (
                        <CngEditForm
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            showNotification={showNotification}
                            bodySection={
                                <>
                                    <WeightCost_FormProperties.Fields
                                        disabled={false}
                                        skuId={id}
                                    />
                                </>
                            }
                            renderButtonSection={() => (
                                <>
                                    {/* <ButtonComponents 
                                        history = {history}
                                        shouldShowPrimaryButtonCircularProgress= {shouldShowPrimaryButtonCircularProgress}
                                        setShouldShowPrimaryButtonCircularProgress={setShouldShowPrimaryButtonCircularProgress}
                                    /> */}
                                </>
                            )}
                            toClientDataFormat={WeightCost_FormProperties.toClientDataFormat}
                              toServerDataFormat={WeightCost_FormProperties.toServerDataFormat}
                            formikProps={{
                                ...WeightCost_FormProperties.formikProps, 
                                // validationSchema,
                                // onSubmit: onSubmit
                            }}
                            id={id}
                            fetch={{
                                id: id,
                                url: `${SCOInvMgmtApiUrls.GET_WEIGHT_COST_BY_SKUID}`
                            }}

                        />
                    )
                }
                     
			]}
		/>


        
    )

}

export default EditPage
