import BackButton from "src/components/button/BackButton";
import { Grid } from '@material-ui/core'
import React from 'react'

const FormBackButton = ({setFormActionNone}) => {
    return (
        <Grid container spacing ={1}>
            <BackButton style={{ marginBlock: '15px', marginInline: '15px', outerWidth: '100%' }} onClick={() => {
                setFormActionNone();
            }} />  
        </Grid>
    )
}

export default FormBackButton;